import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { prospectStatus } from "../../../constants/prospectStatus";

function ModalTabs({ item, activeTab, toggleTab }) {
  return (
    <div className="flex-shrink-0 align-self-end">
      <Nav className="justify-content-start nav-tabs-custom rounded card-header-tabs">
        <NavItem>
          <NavLink
            href="#"
            className={classnames(
              {
                active: activeTab === "1",
              },
              "px-3"
            )}
            onClick={() => {
              toggleTab("1");
            }}
          >
            Datos Generales
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#"
            className={classnames(
              {
                active: activeTab === "2",
              },
              "px-3"
            )}
            onClick={() => {
              toggleTab("2");
            }}
          >
            Datos Complementarios
          </NavLink>
        </NavItem>
        {/* Mostrar solo para clientes */}
        {[prospectStatus.APPROVED, prospectStatus.BLOCKED].includes(
          item?.status
        ) && (
          <NavItem>
            <NavLink
              href="#"
              className={classnames(
                {
                  active: activeTab === "3",
                },
                "px-3"
              )}
              onClick={() => {
                toggleTab("3");
              }}
            >
              Perfil de Inversión
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  );
}

export default ModalTabs;
