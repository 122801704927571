import React from "react";
import { Link, withRouter } from "react-router-dom";

const Columns = (addUpd, destroy, policy) => {
  let columns = [
    {
      id: "full_name",
      name: "Nombre",
      selector: (row) => row.full_name,
      sortable: true,
      wrap: true,
    },
    {
      id: "ti_rfc",
      name: "RFC",
      selector: (row) => row.ti_rfc,
      sortable: true,
      wrap: true,
    },
    {
      id: "perfil_riesgo_id",
      name: "Riesgo de inversión",
      selector: (row) => row.perfil_riesgo?.name ?? "-- Sin definir --",
      sortable: true,
      wrap: true,
    },
    {
      id: "status",
      name: "Estatus",
      selector: ({ status }) => status,
      sortable: true,
      wrap: true,
    },
    {
      id: "docs",
      name: "Evaluar prospecto",
      selector: (row) => {
        return (
          <Link to={`/prospects/detail/${row.id}`}>
            <button
              className="btn btn-primary btn-sm"
              title="Evaluar prospecto"
            >
              <i className="fas fas fa-tasks"></i>
            </button>
          </Link>
        );
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <button
              className="btn btn-warning btn-sm"
              title="Editar"
              onClick={() => addUpd({
                ...row,
                ti_birth_state: row?.ti_birth_state_original ?? null,
                ti_civil: row?.ti_civil_original ?? null,
              })}
            >
              <i className="fas fa-edit"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-danger btn-sm"
              title="Eliminar"
              onClick={() => destroy(row.id)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ),
    },
  ];
  return columns;
};

export default Columns;
