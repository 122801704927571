import React from "react";
import NibbleInput from "../../Fields/NibbleInput";

import {
  ESTADOS,
  ESTADO_CIVIL,
  GENEROS,
  PAISES,
  SINO,
  TIPOS_VIVIENDA,
} from "./constants";
import {
  prospectLevels,
  prospectStatus,
} from "../../../constants/prospectStatus";

export const ProspectGeneralForm = ({ validation }) => {
  return (
    <div>
      <div>
        <div className="row">
          <h5>Titular</h5>
          <NibbleInput
            md={4}
            label="Nombre(s)"
            fieldName="ti_name"
            fieldType="text"
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Apellido Paterno"
            fieldName="ti_lname1"
            fieldType="text"
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Apellido Materno"
            fieldName="ti_lname2"
            fieldType="text"
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Fecha de nacimiento"
            fieldName="ti_birth"
            fieldType="date"
            validation={validation}
          />

          <NibbleInput
            md={4}
            label="RFC:"
            fieldName="ti_rfc"
            fieldType="text"
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="CURP:"
            fieldName="ti_curp"
            fieldType="text"
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Teléfono:"
            fieldName="ti_phone"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={4}
            label="Teléfono fijo:"
            fieldName="ti_landline"
            fieldType="text"
            maxLength={10}
            validation={validation}
            mask="(999) 999-9999"
            unmask={/\D/g}
          />

          <NibbleInput
            md={4}
            label="País de nacimiento:"
            fieldName="ti_birth_country"
            fieldType="select"
            selectItems={PAISES}
            validation={validation}
          />

          <NibbleInput
            md={4}
            label="Estado de nacimiento:"
            fieldName="ti_birth_state"
            fieldType="select"
            selectItems={ESTADOS}
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Estado civil:"
            fieldName="ti_civil"
            fieldType="select"
            selectItems={ESTADO_CIVIL}
            validation={validation}
          />
          <NibbleInput
            md={4}
            label="Genero:"
            fieldName="ti_genere"
            fieldType="select"
            selectItems={GENEROS}
            validation={validation}
          />
          {[prospectStatus.APPROVED].includes(validation.values.status) && (
            <NibbleInput
              md={4}
              label="Nivel de contrato"
              fieldName="level"
              fieldType="select"
              selectItems={prospectLevels}
              validation={validation}
              selectOptionValue={(e) => e.id}
            />
          )}
        </div>

        <div className="row mt-5">
          <h5>Domicilio Fiscal</h5>
          <NibbleInput
            md={6}
            label="País:"
            fieldName="fiscal_country"
            fieldType="select"
            selectItems={PAISES}
            validation={validation}
          />
          <NibbleInput
            md={6}
            label="Estado:"
            fieldName="fiscal_state"
            fieldType="select"
            selectItems={ESTADOS}
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Calle:"
            fieldName="fiscal_address1"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Colonia:"
            fieldName="fiscal_address2"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Municipio:"
            fieldName="fiscal_address3"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Num ext:"
            fieldName="fiscal_ext"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Num int:"
            fieldName="fiscal_int"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="CP:"
            fieldName="fiscal_zipcode"
            fieldType="text"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Años de residencia:"
            fieldName="fiscal_years"
            fieldType="number"
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Tipo de vivienda:"
            fieldName="fiscal_type"
            fieldType="select"
            selectItems={TIPOS_VIVIENDA}
            validation={validation}
          />

          <NibbleInput
            md={6}
            label="Entre calles:"
            fieldName="fiscal_cross_streets"
            fieldType="text"
            validation={validation}
          />
        </div>

        <div className="row mt-5">
          <h5>Domicilio Comercial</h5>
          <NibbleInput
            md={6}
            label="¿Es el mismo que domicilio fiscal?"
            fieldName="commer_ask1"
            fieldType="select"
            selectItems={SINO}
            validation={validation}
            selectOptionValue={(e) => e.id}
            selectIsClearable={false}
          />
          {!validation.values.commer_ask1 && (
            <>
              <NibbleInput
                md={6}
                label="País:"
                fieldName="commer_country"
                fieldType="select"
                selectItems={[{ id: "México", name: "México" }]}
                validation={validation}
              />
              <NibbleInput
                md={6}
                label="Estado:"
                fieldName="commer_state"
                fieldType="select"
                selectItems={ESTADOS}
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="Calle:"
                fieldName="commer_address1"
                fieldType="text"
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="Colonia:"
                fieldName="commer_address2"
                fieldType="text"
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="Municipio:"
                fieldName="commer_address3"
                fieldType="text"
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="Num ext:"
                fieldName="commer_ext"
                fieldType="text"
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="Num int:"
                fieldName="commer_int"
                fieldType="text"
                validation={validation}
              />

              <NibbleInput
                md={6}
                label="CP:"
                fieldName="commer_zipcode"
                fieldType="text"
                validation={validation}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
