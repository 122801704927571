import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getStorageConfig, getStorageCompany, getUserLevel } from "../../helpers/api_helper";

import { Container } from "reactstrap";
import ContractDocuments from "./ContractDocuments";

const Dashboard = () => {
  //meta title
  const userLevel = getUserLevel();
  document.title = "Dashboard | Smart-Brokers";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          { userLevel === "Nivel 1" && <ContractDocuments /> }
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
