import React, { useState } from "react";
import { useFormik } from "formik";
import { Form, Button, Spinner, ModalBody, ModalFooter } from "reactstrap";

import { getComplementParsed } from "./helper";
import { complementaryDataBase } from "../ComplementaryData/base";
import { validationSchema } from "../ComplementaryData/form-validation";
import ProspectComplementaryData from "../ComplementaryData/ProspectComplementaryData";
import { postProspect, putProspect } from "../../../helpers/backend_helper";

const ComplementaryForm = ({ modal, item, realoadData, handleClose }) => {
  const parsedItem = getComplementParsed(item);

  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...complementaryDataBase,
      ...parsedItem,
    },
    validationSchema,
    onSubmit: (values) => {
      save(values);
    },
  });

  const save = async (formData) => {
    const { beneficiaries, economic_dependents, societies } = formData;

    const data = {
      ...item,
      complement: formData,
      societies,
      beneficiaries,
      economic_dependents,
    };

    setLoading(true);
    const response = modal.update
      ? await putProspect(data)
      : await postProspect(data);
    responseAction(response);
  };

  const responseAction = (response) => {
    if (response.status) {
      handleClose();
      validation.resetForm();
      realoadData && realoadData();
    }

    setLoading(false);
  };

  return (
    <>
      <ModalBody>
        <Form
          id="complementary-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ProspectComplementaryData validation={validation} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="text-end">
          <Button
            color="primary"
            form="complementary-form"
            type="submit"
            disabled={loading}
          >
            {loading && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Guardar
          </Button>{" "}
          <Button color="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Cancelar
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default ComplementaryForm;
