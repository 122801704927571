export const ESTADOS = [{ id: 1, name: "Aguascalientes" }];

export const transactionSubtype = {
  TRANSFER: "transferencia",
  DEPOSIT: "deposito",
};

export const transactionType = {
  INCOME: "ingreso",
  EXPENSE: "egreso",
};
