import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import Breadcrumbs
import { getProfiles, postFileProfile } from "../../helpers/backend_helper";
import {
  DestroyAlertHelper,
  ToastHelper,
} from "../../helpers/alerts/alertHelper";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { useParams } from "react-router-dom";
import ValidateFileHelper from "../../helpers/ValidateFileHelper";

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import PageLoader from "../../components/Common/PageLoader";
import Balances from "../Clients/detail/components/Balances";

const Profile = () => {
  const [isLoadingProfile, setisLoadingProfile] = useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(false);
  const [pending, setPending] = useState(false);
  const [file, setFile] = useState(null);
  const [user, setUser] = useState({
    name: "",
    email: "",
    profile_id: null,
    image: "",
    profile: {
      id: null,
      name: "",
    },
  });

  const { id } = useParams();

  useEffect(() => {
    getProfiles(id)
      .then((response) => {
        setUser(response.user);
      })
      .finally(() => setisLoadingProfile(false));
  }, []);

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      deleteUser(id).then((response) => {
        Alert(response.message, response.status);
        if (response.status) {
          setItems(items.filter((i) => i.id != id));
        }
      });
    });
  };

  const fileValidate = (file) => {
    setErrors({});
    const validate = ValidateFileHelper(
      file,
      { required: true, type: "image", size: 1 },
      "Imagen de perfil"
    );
    if (validate != undefined) {
      setErrors(validate);
      return;
    }
    setFile(file);
    setImage(true);
  };

  const fileUpload = () => {
    let auth = JSON.parse(localStorage.getItem("authUser"));
    setPending(true);
    postFileProfile(id, file).then((response) => {
      Alert(response.message, response.status);
      if (response.status) {
        setUser(response.user);

        if (auth.user.id == response.user.id) {
          auth.user.image = response.user.image;
          localStorage.setItem("authUser", JSON.stringify(auth));
        }
        setPending(false);
        setImage(false);
      }
    });
  };

  console.log({ user });

  document.title = "Perfil de usuario";
  if (isLoadingProfile) <PageLoader />;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{user.name}</h5>
                        <p className="mb-1">{user.email}</p>
                        <p className="mb-0">Id no: #{user.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h4 className="card-title mb-4">Información de usuario</h4>
          <Card>
            <CardBody>
              <div className="flex-grow-1 align-self-center ms-3">
                <div className="text-muted">
                  <p className="mb-1">Nombre de Empresa</p>
                  <p className="text-muted fw-bold">{user.company_name}</p>
                  <p className="mb-1">Nombre de Perfil</p>
                  <p className="text-muted fw-bold">{user.profile_name}</p>
                  <p className="mb-2">Cuenta verificada</p>
                  <p className="text-muted fw-bold">{user.verified}</p>
                  <p className="mb-2">Perfil de riesgo</p>
                  <p className="text-muted fw-bold">{user?.perfilRiesgo?.name}</p>
                  <p className="mb-3 d-none">Cambiar Foto de Perfil</p>
                  <input
                    className="d-none"
                    type="file"
                    id="anexoInput"
                    onChange={(e) => [fileValidate(e.target.files[0])]}
                  />
                  {errors && (
                    <div className="form-text text-danger">
                      {errors.file && errors.file[0]}
                    </div>
                  )}
                  {image && (
                    <Button variant="primary" onClick={fileUpload}>
                      {pending ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>Guardando...
                        </>
                      ) : (
                        <i className="fas fa-save">Guardar</i>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
          <h4 className="card-title mb-4">Saldos</h4>
          <Card>
            <CardBody>
              <Balances userId={id} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
