const RegexpNumeric = /^([0-9]*\.?[0-9]{1,2}|\.[0-9]+)/;
const RegexpEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/;
const RegexpText = /^[a-zA-Z0-9,./*+--ÁÀÉÈÍÌÓÒÚÙáàéèíìóòúùÑñ\s]+$/;
const RegexpInteger = /^[0-9]+$/;
const RegexpAlfanumeric = /^[a-zA-Z0-9]+$/;

export const InputInteger = (integer) => RegexpInteger.test(integer);

export const InputNumeric = (num) => RegexpNumeric.test(num);

export const InputEmail = (email) => RegexpEmail.test(email);

export const InputText = (text) => RegexpText.test(text);

export const InputAlfanumeric = (text) => RegexpAlfanumeric.test(text);