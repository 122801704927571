//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
export const POST_LOGIN_TOKEN = "/login";
export const POST_OTP_LOGIN = "/login/otp";
export const RESET_PASSW = "/resetPasword";
export const CHANGE_PASSW = "/changePasword";
export const SEND_CONFIRMATION = "/verify";
//LOGIN
//export const POST_FAKE_LOGIN = "/post-fake-login"
//export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
//export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
//export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
//export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
//export const POST_EDIT_PROFILE = "/post-fake-profile"
//catalogs
//Users Resource (Store, Update, Show, Index)
export const RESOURCE_USERS = "/users";
export const FILE_PROFILE_USER = "/user/file";
export const GET_PROFILES = "/user/profiles";

//Configuration Resource
export const RESOURCE_CONFIGURATIONS = "/configurations";
export const SELECTS_CONFIGURATIONS = "/configuration/selects";
export const UPDATE_CONFIGURATION_OPTIONS =
  "/configuration/update-configuration-options";
export const GET_ACTIVE_CONFIGURATION = "/configuration/showActive";

//Company Resource
export const RESOURCE_COMPANIES = "/companies";

export const RESOURCE_INSTRUMENTS = "/instruments";
export const RESOURCE_PERIODS = "/periods";
export const RESOURCE_PRODUCT_TYPES = "/product-types";
export const RESOURCE_PRODUCT_PERFORMANCES = "/product-performances";

export const RESOURCE_PROFILES_RISK = "/profiles-risk";
export const RESOURCE_PROSPECTS = "/prospects";
export const RESOURCE_PROSPECTS_REGISTRATION = "/prospects/registration";
export const RESOURCE_PROSPECTS_GENERALS = "/prospects/generals";
export const RESOURCE_PROSPECTS_DOCS = "/prospects/docs";
export const RESOURCE_PROSPECTS_IDENTITY = "/prospects/identity";
export const RESOURCE_PROSPECTS_REGISTRATION_END =
  "/prospects/registration-end";
export const RESOURCE_PROSPECTS_COMPLEMENTS = "/prospects/complements";
export const RESOURCE_PROSPECTS_CHECK_SELFIE = "/prospects/check-selfie";

export const RESOURCE_PROSPECTS_DOCUMENTS = "/prospects/documents";
export const RESOURCE_PROSPECTS_ANSWERS = "/prospects/answers";
export const RESOURCE_PROSPECTS_CHECK_EMAIL = "/prospects/check-email-exist";
export const RESOURCE_PROSPECTS_SEND_PIN_EMAIL = "/prospects/send-pin-email";
export const RESOURCE_PROSPECTS_SEND_PIN_SMS = "/prospects/send-pin-sms";
export const PROSPECTS_APPROVE_PROSPECT = "/prospects/approve-prospect";
export const PROSPECTS_REJECT_PROSPECT = "/prospects/reject-prospect";
export const PROSPECTS_UPDATE_STATUS_PROSPECT = "/prospects/update-status";
export const PROSPECTS_ACCEPT_TERMS = "/prospects/accept-terms";
export const PROSPECTS_SIGN_LEVEL2 = "/prospects/sign-contract";
export const PROSPECTS_DOCUMENT = "/prospects/document";
export const RESOURCE_CLIENTS = "/clients";
export const RESOURCE_PRODUCTS = "/products";
export const RESOURCE_PRODUCTS_UPLOAD_TEMPLATE = "/products/upload-template";
export const RESOURCE_PRODUCTS_DOWNLOAD_TEMPLATE =
  "/products/download-template";
export const RESOURCE_SURVEYS = "/surveys";
export const RESOURCE_WITHDRAWALS = "/withdrawals";
export const RESOURCE_SECTIONS = "/survey-section";
export const RESOURCE_ASK = "/survey-ask";
export const RESOURCE_SURVEY_ACTIVE = "/survey-active";
export const RESOURCE_FILES = "/files";
export const CLIENT_CONTRACTS = "/client-contracts";
export const CLIENT_TRANSACTIONS = "/transactions";
export const ADMIN_TRANSACTIONS = "/admin/transactions";
export const ADMIN_DEPOSITS = "/admin/deposits";
export const ADMIN_CONTRACTS_SUMMARY = "/admin/contracts-summary";
export const ADMIN_CONTRACTS = "/admin/contracts";

//PROFILES -PERMISSIONS
export const RESOURCE_PROFILES = "/profiles";
export const RESOURCE_PERMISSIONS = "/permissions";
export const PERMISSIONS_SELECT = "/permission/selects";

//NUBARIUM
export const NUBARIUM_TOKEN = "customer-processing/token";
export const NUBARIUM_VALIDATE_CURP = "customer-processing/validate-curp";
export const NUBARIUM_VALIDATE_RFC = "customer-processing/validate-rfc";
export const NUBARIUM_VALIDATE_INE = "customer-processing/validate-ine";
export const NUBARIUM_VALIDATE_FACE = "customer-processing/validate-face";
export const NUBARIUM_INE_INFORMATION = "customer-processing/ine-information";
export const NUBARIUM_PROOF_INFORMATION =
  "customer-processing/proof-information";
export const GET_ZIP_CODE_INFO = "customer-processing/zip-code-info";
export const GET_COLONIAS_BY_ZIP_CODE =
  "customer-processing/colonias-by-zip-code";

export const GENERATE_DOC = "generate";
