import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { getConfigurationsSelects } from "../../helpers/backend_helper";
import { configOptions } from "../Settings/static";
import { calculateInterest } from "./interest_calculator";

const getOptionValue = (id, optionsObject) => {
  const val = _.get(optionsObject, `${id}.value`, 0);

  return val;
};

const useCalculator = () => {
  const [values, setValues] = useState([10000]);
  const [isLoading, setIsLoading] = useState(true);
  const [benchmarkPercentage, setBenchmarkrPercentage] = useState(0);
  const [smartBrokerPercentage, setSmartBrokerPercentage] = useState(0);

  const [tableValues, setTableValues] = useState({
    smartbrokers: [],
    benchmark: [],
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      period: 5,
    },
    validationSchema: Yup.object({
      period: Yup.string().nullable().required(),
    }),
  });

  const fetchConfigOptions = () => {
    getConfigurationsSelects()
      .then((resp) => {
        if (resp?.status) {
          const optionsObject = _.keyBy(resp.options, "configuration_id");

          const tasaSmartBrokers = getOptionValue(
            configOptions.TASA_CALCULADORA_SMARTBROKERS,
            optionsObject
          );

          const tasaBenchmark = getOptionValue(
            configOptions.TASA_CALCULADORA_BENCHMARK,
            optionsObject
          );

          setSmartBrokerPercentage(Number(tasaSmartBrokers));
          setBenchmarkrPercentage(Number(tasaBenchmark));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const calculateAmounts = () => {
    const smartBrokersValues = calculateInterest({
      amount: values[0],
      percentage: smartBrokerPercentage / 100,
      period: validation.values.period,
    });

    const benchmarkValues = calculateInterest({
      amount: values[0],
      percentage: benchmarkPercentage / 100,
      period: validation.values.period,
    });

    setTableValues({
      smartbrokers: smartBrokersValues,
      benchmark: benchmarkValues,
    });
  };

  useEffect(fetchConfigOptions, []);
  useEffect(calculateAmounts, [
    values,
    benchmarkPercentage,
    smartBrokerPercentage,
    validation.values.period,
  ]);

  return {
    values,
    setValues,
    isLoading,
    validation,
    tableValues,
    smartBrokerPercentage,
    benchmarkPercentage,
  };
};

export default useCalculator;
