import React from "react";
import { Col, FormFeedback, Input, InputGroup, Label } from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import NibbleInputImage from "./NibbleInputImage";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import dayjs from "dayjs";
import { get } from "lodash";
import InputMask from "react-input-mask";

const NibbleInput = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  validation,
  fieldType = "text",
  placeHolder = "",
  selectItems = [],
  selectIsClearable = true,
  selectOptionValue = (option) => `${option.id}`,
  selectOptionLabel = (option) => `${option.name}`,
  selectIsSearchable = true,
  selectStringify = null,
  postFileFunction = () => {},
  itemFile = null,
  maxLength = null,
  readOnly = false,
  getOptionValue = (option) => option.id,
  mask = "",
  unmask = "",
  min = null,
  onChange = null,
  fileAccept = null,
  step = null,
}) => {
  const fieldValue = get(validation.values, fieldName, "");
  const fieldError = get(validation.errors, fieldName, "");
  const fieldTouched = get(validation.touched, fieldName, "");

  if (fieldType == "image")
    return (
      <NibbleInputImage
        md={md}
        label={label}
        fieldName={fieldName}
        validation={validation}
        postFileFunction={postFileFunction}
        itemFile={itemFile}
      />
    );

  if (fieldType == "textarea")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label>
        <Input
          type="textarea"
          className="form-control"
          name={fieldName}
          maxLength={maxLength}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[fieldName]}
          invalid={
            validation.touched[fieldName] && validation.errors[fieldName]
              ? true
              : false
          }
        />
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "select")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label>
        <Select
          placeholder="Seleccionar..."
          className={classNames({
            "select-control": true,
            "is-invalid": fieldTouched && fieldError,
          })}
          value={
            selectItems.filter((e) => {
              return selectOptionValue(e) == fieldValue;
            })[0] ?? null
          }
          stringify={selectStringify ?? selectOptionLabel}
          isClearable={selectIsClearable}
          isSearchable={selectIsSearchable}
          getOptionLabel={selectOptionLabel}
          getOptionValue={selectOptionValue}
          name={fieldName}
          options={selectItems}
          onChange={(option, meta) => {
            validation.setFieldValue(fieldName, option?.id ?? null, true);

            onChange && onChange(option);
          }}
        />
        <FormFeedback
          type="invalid"
          style={{
            display: fieldTouched && fieldError ? "block" : "none",
          }}
        >
          {fieldError}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "checkbox")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label> <br />
        {selectItems.map((e, idx) => (
          <div
            key={idx}
            className={classNames({
              "d-inline-block": true,
              "me-3": true,
            })}
          >
            <Input
              type="checkbox"
              id={fieldName + idx}
              name={fieldName}
              onClick={(e) => {
                console.log(e.target.checked);
                const newValue = [...validation.values[fieldName]];
                if (e.target.checked) {
                  newValue.push(e.target.value);
                } else {
                  const index = validation.values[fieldName].indexOf(
                    e.target.value
                  );
                  newValue.splice(index, 1);
                }
                console.log(newValue);
                validation.setFieldValue(fieldName, newValue, true);
              }}
              onBlur={validation.handleBlur}
              value={selectOptionValue(e)}
              invalid={
                validation.touched[fieldName] && validation.errors[fieldName]
              }
              checked={(validation.values[fieldName] ?? []).includes(
                selectOptionValue(e)
              )}
            />{" "}
            <Label for={fieldName + idx} className="form-label fw-normal">
              {selectOptionLabel(e)}
            </Label>
          </div>
        ))}
        <FormFeedback
          type="invalid"
          style={{
            display:
              validation.touched[fieldName] && validation.errors[fieldName]
                ? "block"
                : "none",
          }}
        >
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "time")
    return (
      <Col className="mt-2 form-group" md={md}>
        <Label className="form-label">{label}</Label>

        <InputGroup
          className={classNames({
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName],
          })}
        >
          <Flatpickr
            value={validation.values[fieldName] ?? ""}
            className={classNames({
              "form-control": true,
              "d-block": true,
              "is-invalid":
                validation.touched[fieldName] && validation.errors[fieldName],
            })}
            placeholder="Seleccionar..."
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
            onChange={([date]) => {
              console.log(date);
              validation.setFieldValue(
                fieldName,
                dayjs(date).format("HH:mm"),
                true
              );
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="mdi mdi-clock-outline" />
            </span>
          </div>
        </InputGroup>
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "mask")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label>
        <InputMask
          mask={mask}
          name={fieldName}
          value={fieldValue}
          className={`form-control input-color ${
            fieldTouched && fieldError ? "is-invalid" : ""
          }`}
          onChange={async (e) => {
            const { value } = e.target;

            if (!unmask) {
              validation.handleChange(e);
              return;
            }

            await validation.setFieldValue(
              fieldName,
              value.replace(unmask, "")
            );
            await validation.setTouched({ [fieldName]: true });
          }}
          alwaysShowMask
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    );

  return (
    <Col className="mt-2" md={md}>
      <Label className="form-label">{label}</Label>
      <Input
        className="form-control"
        placeholder={placeHolder}
        name={fieldName}
        type={fieldType}
        onChange={onChange || validation.handleChange}
        onBlur={validation.handleBlur}
        value={fieldValue}
        invalid={
          validation ? (fieldTouched && fieldError ? true : false) : false
        }
        maxLength={maxLength}
        readOnly={readOnly}
        min={min}
        accept={fileAccept}
        step={step}
      />
      <FormFeedback type="invalid">{fieldError}</FormFeedback>
    </Col>
  );
};

export default NibbleInput;
