export const thirdPartyScriptUrl =
  "//cdn.nubarium.io/nubSdk/nubSdk@latest/nubSdk-third.min.js";

export const libraryScriptUrl =
  "//cdn.nubarium.io/nubSdk/nubSdk@latest/nubSdk-biometrics.min.js";

export const idCaptureConfig = {
  rootElement: "id_nubarium",
  timeouts: {
    front: 120000,
    back: 120000,
  },
  captureMode: {
    front: {
      enabled: true,
      after: 7500,
    },
    back: {
      enabled: true,
      after: 7500,
    },
  },
  guide: {
  	front: { 
    	enabled: true //Enable guide on front capture. (By default is enabled)
    },
    back: {
    	enabled: true,  //Enable guide on back capture. (By default is enabled)
      until: 10000   // wait for 10 seconds to hide the image
    }
  },

};

export const faceCaptureConfig = {
  rootElement: "id_nubarium",
  maxValidations: 3,
  features: {
    disabled: ["glasses", "facemask"],
    enabled: [],
  },
  antispoofing: {
    enabled: true,
    level: 3,
  },
  ui: {
    colors: {
      messageScreenTextColor: "#ffffff",
    },
  },
};
