export const getComplementParsed = (prospect) => {
  const { complement, beneficiaries, economic_dependents, societies } =
    prospect || {};

  const economic_dependents_ask = economic_dependents?.length ? true : false;

  return {
    ...complement,
    societies,
    beneficiaries,
    economic_dependents,
    economic_dependents_ask,
  };
};
