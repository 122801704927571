import dayjs from "dayjs";
import React from "react";
import currencyjs from "currency.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Card,
  CardBody,
} from "reactstrap";

import Currency from "../../components/Common/Currency";
import DateFormat from "../../components/Common/DateFormat";

const getPercentageGenerated = (clientContract) => {
  if (!clientContract) return 0;

  const { amount, final_amount } = clientContract;

  const totalPercentage = ((final_amount - amount) / amount) * 100;

  return currencyjs(totalPercentage).value;
};

const DetailModal = ({ isModalOpen, setIsModalOpen, clientContract }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} style={{ maxWidth: "50%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Detalle de Inversión</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            {clientContract && (
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <div className="mb-4">
                          <img src="/images/logo_sb.png" alt="" height="60" />
                        </div>
                      </div>
                    </div>
                    <address>
                      <span className="d-block">
                        Monto invertido:{" "}
                        <strong>
                          <Currency amount={clientContract?.amount} />
                        </strong>
                      </span>
                      <span className="d-block">
                        Rendimiento generado:{" "}
                        <strong>
                          <Currency amount={clientContract?.final_amount} />
                        </strong>
                      </span>
                      <span className="d-block">
                        Porcentaje generado:{" "}
                        <strong>
                          % {getPercentageGenerated(clientContract)}
                        </strong>
                      </span>
                    </address>
                  </div>
                  <hr className="my-4" />
                  <div className="row">
                    <div className="col-sm-6">
                      <div>
                        <h5 className="font-size-15 mb-3">
                          Producto contratado:
                        </h5>
                        <span className="d-block">
                          {clientContract?.product?.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <div>
                          <h5 className="font-size-15">Fecha inicio:</h5>
                          <p>
                            <DateFormat
                              date={clientContract?.start_date}
                              format="DD/MM/YYYY"
                            />
                          </p>
                        </div>
                        <div>
                          <h5 className="font-size-15">Fecha fin:</h5>
                          <p>
                            <DateFormat
                              date={clientContract?.end_date}
                              format="DD/MM/YYYY"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cerrar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DetailModal;
