import React, { useState } from "react";
import {
  Spinner,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { getDocument } from "../../../../helpers/backend_helper";
import { downloadFile, openFile } from "../../../../utils/files";
import { toast } from "react-toastify";

export const documentItems = [
  {
    label: "Contrato",
    url: "commercial-commission-contract",
    filename: "contrato-de-comision-mercantil.pdf",
  },
  {
    label: "Anexo A (Cartera de productos )",
    url: "investment-portfolio",
    filename: "cartera-de-productos.pdf",
  },
  {
    label: "Anexo B (Solicitud)",
    url: "contract",
    filename: "solicitud.pdf",
  },
  {
    label: "Anexo C (Perfil de inversión)",
    url: "investment-profile",
    filename: "perfil-de-inversion.pdf",
  },
  {
    label: "Aviso de privacidad",
    url: "privacy-policy",
    filename: "aviso-de-privacidad.pdf",
  },
];

export const FormatsDropdown = ({ id }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadFormat = async (path, filename) => {
    setIsDownloading(true);
    try {
      const config = {
        url: `${path}/${id}`,
        responseType: "blob",
      };

      const file = await getDocument(config);

      openFile(file, filename);
    } catch (error) {
      toast.error(error?.message || "Error desconocido");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        type="button"
        color="primary"
        className="btn btn-primary btn-sm me-2"
        disabled={isDownloading}
      >
        {isDownloading ? (
          <Spinner size="sm"></Spinner>
        ) : (
          <i className="fas fa-file-pdf"></i>
        )}{" "}
        Formatos <i className="mdi mdi-chevron-down"></i>
      </DropdownToggle>
      <DropdownMenu>
        {documentItems.map((item) => (
          <DropdownItem
            to="#"
            key={item.label}
            onClick={() => handleDownloadFormat(item.url, item.filename)}
          >
            <i className="fas fa-file-pdf"></i> {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FormatsDropdown;
