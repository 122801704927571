import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns, { TYPES_STYLES } from "./Columns";
import { getAdminTransactions } from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import { getLoggedUser } from "../../helpers/login";

import "react-datepicker/dist/react-datepicker.css";

const INIT_FILTERS = {
  page: 1,
  perPage: 20,
  search: "",
  type: "",
  pagination: true,
  startDate: "",
  endDate: "",
};

const typeOptions = Object.entries(TYPES_STYLES);

const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const openFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

const Transactions = () => {
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState({ data: [] });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const loggedUser = getLoggedUser();

  const getTransactions = useCallback((filters) => {
    const config = { params: filters };

    setIsLoading(true);
    getAdminTransactions(config)
      .then(responseAction)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getTransactions(filters);
  }, [filters]);

  const responseAction = (response) => {
    if (response.status) {
      setTransactions(response.transactions);
    }
  };

  const downloadExcel = async () => {
    try {
      setIsDownloadingExcel(true);
      const response = await getAdminTransactions({
        responseType: "blob",
        params: {
          ...filters,
          pagination: false,
          reportType: "excel",
        },
      });

      if (response?.message) return;

      openFile(response, "transacciones.xlsx");
    } catch (err) {
      console.log("Err", err);
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const downloadPdf = async () => {
    try {
      setIsDownloadingPdf(true);
      const response = await getAdminTransactions({
        responseType: "blob",
        params: {
          ...filters,
          pagination: false,
          reportType: "pdf",
        },
      });

      if (response?.message) return;

      openFile(response, "transacciones.pdf");
    } catch (err) {
      console.log("Err", err);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  const columns = Columns();
  document.title = "Mis transacciones";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Transacciones"
              columns={columns}
              items={transactions.data}
              pending={isLoading}
              config={transactions}
              filters={filters}
              setFilters={setFilters}
              wrapperStyles={{ minHeight: "500px" }}
            >
              <div>
                <DatePicker
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);

                    const startDate = update[0];
                    const endDate = update[1];

                    if (!startDate) return;
                    if (!endDate) return;

                    setFilters((prev) => ({
                      ...prev,
                      startDate: formatDate(startDate),
                      endDate: formatDate(endDate),
                    }));
                  }}
                  placeholderText="Seleccionar fechas"
                  className="form-select form-select-sm"
                  wrapperClassName="w-auto"
                />
                <select
                  name="type"
                  value={filters.type}
                  onChange={(e) => {
                    const { value, name } = e.target;

                    setFilters((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                  }}
                  placeholder="Filtrar por tipo"
                  style={{
                    width: "150px",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                  className={`d-inline-block form-select form-select-sm ${
                    !filters.status && "text-muted"
                  }`}
                >
                  <option value="" className="text-muted">
                    Filtrar por tipo
                  </option>
                  {typeOptions.map(([key, value]) => (
                    <option key={key} value={key} className="text-dark">
                      {value.label}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-sm btn-success"
                  onClick={downloadExcel}
                  style={{ marginRight: "10px" }}
                  disabled={isDownloadingExcel}
                >
                  {isDownloadingExcel ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  ) : (
                    <i className="fas fa-file-excel"></i>
                  )}{" "}
                  Excel
                </button>
                <button
                  className="btn btn-sm btn-success"
                  onClick={downloadPdf}
                  style={{ marginRight: "10px" }}
                  disabled={isDownloadingPdf}
                >
                  {isDownloadingPdf ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  ) : (
                    <i className="fas fa-file-pdf"></i>
                  )}{" "}
                  Pdf
                </button>
              </div>
            </DataTableHelper>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
