import React from "react";
import { Link, withRouter } from "react-router-dom";

const Columns = (addUpd, destroy, policy, updTemplate, showFicha, showRendimientos) => {
  let columns = [
    {
      id: "name",
      name: "Nombre",
      selector: (row) => {
        return row.name + ( row.type_id==1 ? " (" + (row.clave_mutuo ?? '') + ")" : "");
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "type_id",
      name: "Tipo",
      selector: (row) => {
        return row.type?.name ?? "";
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "active",
      name: "Activo",
      cell: (row) => (
        <>
          {row.active ? (
            <i className="fas fa-check" />
          ) : (
            <i className="fas fa-times" />
          )}
        </>
      ),
      sortable: true,
      wrap: true,
    },

    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <button
              className="btn btn-primary btn-sm"
              title="Ficha"
              onClick={() => showFicha(row)}
            >
              <i className="fas fa-eye"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-success btn-sm"
              title="Subir template"
              onClick={() => updTemplate(row)}
            >
              <i className="mdi mdi-microsoft-excel"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-secondary btn-sm"
              title="Rendimientos del producto"
              onClick={() => showRendimientos(row)}
            >
              <i className="fas fa-money-bill-wave-alt"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-warning btn-sm"
              title="Editar"
              onClick={() => addUpd(row)}
            >
              <i className="fas fa-edit"></i>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-danger btn-sm"
              title="Eliminar"
              onClick={() => destroy(row.id)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ),
    },
  ];
  return columns;
};

export default Columns;
