import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";

import {
  downloadBackupFileChangeInvestmentProfile,
  getProfileRiskPermissions,
  postClientChangeInvestmentProfile,
  uploadFile,
} from "../../../helpers/backend_helper";

import NibbleInput from "../../Fields/NibbleInput";
import NibbleInputFile from "../../Fields/NibbleInputFile";
import { downloadFile } from "../../../utils/files";

const InvestmentProfileForm = ({ item, modal, handleClose, realoadData }) => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [filters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });

  const onDownloadFile = async () => {
    const config = {
      responseType: "blob",
    };

    setIsDownloading(true);
    try {
      const file = await downloadBackupFileChangeInvestmentProfile(
        item.id,
        config
      );

      downloadFile(file, "documento-respaldo.pdf");
    } catch (error) {
      console.log("downloadfileErr", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      perfil_inversion: item?.perfil_riesgo?.name,
      perfil_riesgo_id: "",
      file_id: "",
    },
    validationSchema: Yup.object({
      perfil_inversion: Yup.string().nullable(),
      perfil_riesgo_id: Yup.string().nullable().required("Requerido"),
      file_id: Yup.string().nullable().required("Requerido"),
    }),
    onSubmit: (values) => {
      save(values);
    },
  });

  const onUploadFile = (file) => {
    const formData = new FormData();
    formData.file = file;
    formData.name = "respaldoCambioPerfilInversionista";

    return uploadFile(formData);
  };

  const save = async (values) => {
    setLoading(true);
    try {
      const resp = await postClientChangeInvestmentProfile(item.id, values);
      if (resp?.status) {
        validation.setFieldValue(
          "perfil_inversion",
          resp.prospect?.perfil_riesgo?.name
        );

        validation.setFieldValue("file_id", "", false);
        validation.setFieldValue("perfil_riesgo_id", "", false);
      }
    } catch (error) {
      console.log("post", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProfileRisk = () => {
    const params = { params: filters };

    getProfileRiskPermissions(params).then((response) => {
      setProfiles(response.data);
    });
  };

  useEffect(fetchProfileRisk, []);

  return (
    <>
      <ModalBody>
        <Form
          id="complementary-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              <p>
                Cliente:{" "}
                <strong style={{ textTransform: "uppercase" }}>
                  {item?.full_name}
                </strong>
              </p>
              <p>
                Perfil de inversión:{" "}
                <strong>{validation.values.perfil_inversion}</strong>
              </p>
            </Col>
            <Col className="text-end align-content-center">
              {isDownloading ? (
                <Spinner size="sm" />
              ) : (
                <button
                  onClick={onDownloadFile}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  {" "}
                  <i className="fa fa-download"></i> Descargar documento
                </button>
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <NibbleInput
              md={6}
              label="Nuevo perfil de inversión:"
              fieldName="perfil_riesgo_id"
              fieldType="select"
              selectItems={profiles}
              validation={validation}
            />
            <NibbleInputFile
              md={12}
              label="Adjuntar documento de respaldo"
              fieldName="file_id"
              validation={validation}
              postFileFunction={onUploadFile}
              existFile={validation.values.file_id}
              acceptFiles="application/pdf"
            />
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="text-end">
          <Button
            color="primary"
            form="complementary-form"
            type="submit"
            disabled={loading}
          >
            {loading && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Guardar
          </Button>{" "}
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Cancelar
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default InvestmentProfileForm;
