export const prospectStatus = {
  REGISTRATION_BASE: "Registro base",
  REGISTRATION_GENERALS: "Registro generales",
  REGISTRATION_COMPLEMENTS: "Registro complementos",
  REGISTRATION_DOCUMENTS: "Registro documentos",
  RISK_EVALUATION: "Evaluación de riesgo",
  APPROVED: "Aprobado",
  REJECT: "Rechazado",
  BLOCKED: "Bloqueado",
};

export const prospectLevels = [
  { id: "Nivel 1", name: "Nivel 1" },
  { id: "Nivel 2", name: "Nivel 2" },
  { id: "Nivel 3", name: "Nivel 3" },
];
