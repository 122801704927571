import ValidateHelper from "../../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	const privilege_id = ValidateHelper({
		input: inputs.privilege_id,
		attribute: 'privilege_id',
		required: true, 
		max:30,
		type: 'text'
	});

	const profile_id = ValidateHelper({
		input: inputs.profile_id,
		attribute: 'profile_id',
		required: true, 
		type: 'integer'
	});
	const permission_id = ValidateHelper({
		input: inputs.permission_id,
		attribute: 'permission_id',
		required: true, 
		type: 'integer'
	});

	let errors = {
		privilege_id: [],
		profile_id: [],
		permission_id: [],
	};

	let flag = false;

	if (privilege_id != undefined){
		errors.privilege_id = [privilege_id];
		flag = true;
	}
	if (profile_id != undefined){
		errors.profile_id = [profile_id];
		flag = true;
	}
	if (permission_id != undefined){
		errors.permission_id = [permission_id];
		flag = true;
	}
	
	return flag ? errors : undefined;
}

export default Validate;