import { Container } from "reactstrap";
import React, { useEffect, useState } from "react";

import { getProduct, getProductsjList } from "../../helpers/backend_helper";
import DataTableBoxHelper from "../../helpers/tables/DataTableBoxHelper";
import MyProductDetailInner from "../MyProducts/Detail/MyProductDetailInner";

const ProductDetail = (props) => {
  const [item, setItem] = useState({});
  const [pending, setPending] = useState(false);
  const pid = props.match.params.id;

  useEffect(() => {
    setPending(true);
    getProduct(pid).then((response) => {
      setItem(response.item);
      setPending(false);
    });
  }, []);

  document.title = "Producto";
  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <Container fluid>
          <div className="col-12">
            <h4>Producto {item?.name}</h4>
            <MyProductDetailInner product={item} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;
