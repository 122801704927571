import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns, { TYPES_STYLES } from "./Columns";
import { getClientTransactions } from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import { getLoggedUser } from "../../helpers/login";

const INIT_FILTERS = {
  page: 1,
  perPage: 20,
  search: "",
  orderBy: "id",
  order: "asc",
  type: "",
};

const typeOptions = Object.entries(TYPES_STYLES);

const Mytransactions = () => {
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [isLoading, setIsLoading] = useState(true);
  const [myTransactions, setMyTransactions] = useState({ data: [] });

  const loggedUser = getLoggedUser();

  const getMyInvestings = useCallback((filters) => {
    const config = { params: filters };

    setIsLoading(true);
    getClientTransactions("", config)
      .then(responseAction)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getMyInvestings(filters);
  }, [filters]);

  const responseAction = (response) => {
    if (response.status) {
      setMyTransactions(response.transactions);
    }
  };

  const columns = Columns();
  document.title = "Mis transacciones";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Mis transacciones"
              columns={columns}
              items={myTransactions.data}
              pending={isLoading}
              config={myTransactions}
              filters={filters}
              setFilters={setFilters}
            >
              <div>
                <select
                  name="type"
                  value={filters.type}
                  onChange={(e) => {
                    const { value, name } = e.target;

                    setFilters((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                  }}
                  placeholder="Filtrar por tipo"
                  className={`form-select form-select-sm ${
                    !filters.status && "text-muted"
                  }`}
                >
                  <option value="" className="text-muted">
                    Filtrar por tipo
                  </option>
                  {typeOptions.map(([key, value]) => (
                    <option key={key} value={key} className="text-dark">
                      {value.label}
                    </option>
                  ))}
                </select>
              </div>
            </DataTableHelper>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Mytransactions;
