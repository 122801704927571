import React, { useEffect, useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getConfigurationsSelects, getLoggedInUser } from '../../helpers/backend_helper';
import { getUserLevel } from "../../helpers/api_helper";

const bank = process.env.REACT_APP_DEPOSIT_BANK;
const clabe = process.env.REACT_APP_DEPOSIT_CLABE;
const reference = process.env.REACT_APP_DEPOSIT_REF;

const Deposit = ({ showDepositModal, setShowDepositModal }) => {
  const [bancoDeposito, setBancoDeposito] = useState(null);
  const user = getLoggedInUser();
  const fetchConfigOptions = () => {
    getConfigurationsSelects().then((resp) => {
      if (resp?.status) {
        const optionsObject = _.keyBy(resp.options, "configuration_id");
        console.log('optionsObject',optionsObject)
        setBancoDeposito({
          titular: optionsObject?.[7]?.value,
          banco: optionsObject?.[8]?.value,
          cuenta: optionsObject?.[9]?.value,
        });
      }
    });
  };

  useEffect(fetchConfigOptions, []);
  const userLevel = getUserLevel();

  //console.log('bancoDeposito',bancoDeposito)
  return (
    <div>
      <Modal isOpen={showDepositModal}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Depositar</span>
        </ModalHeader>
        <ModalBody>
          <div style={{wordBreak:'break-all'}}>
          </div>
          
          <p>
            Para realizar un depósito a tu cuenta de SmartBrokers puedes
            realizar una transferencia bancaria SPEI a la siguiente cuenta:
          </p>

          { (userLevel==="Nivel 2" || userLevel==="Nivel 3") && bancoDeposito && <>
            <p className="mb-0">Titular: {bancoDeposito.titular}</p>
            <p className="mb-0">Banco: {bancoDeposito.banco}</p>
            <p className="mb-0">CLABE: {bancoDeposito.cuenta}</p>
            <p className="mb-0">Referencia: {user?.user?.prospect?.contract_number}</p>
          </>
          }
          {
            (userLevel==="Nivel 1") && 
            <>
              <Alert color={"warning"}>Debe firma el contrato en la pagina de inicio</Alert>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowDepositModal(false)}>
            <i className="fa fa-times"></i> Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Deposit;
