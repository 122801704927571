import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import { getAdminDeposits } from "../../helpers/backend_helper";
import { getPolicyUser } from "../../helpers/api_helper";
import { Container } from "reactstrap";
import { prospectGeneralBase } from "../../components/Prospect/General";
import { transactionType } from "../../constants/transactions";
import DetailModal from "./DetailModal";
import dayjs from "dayjs";

const itemBase = {
  type: transactionType.INCOME,
  prospect_id: "",
  amount: "",
  operation_date: dayjs().format("YYYY-MM-DD"),
  reference: "",
  description: "",
  subtype: "",
  file_id: "",
  pin: "",
};

const Deposits = () => {
  const [pending, setPending] = useState(true);
  const [modal, setModal] = useState({ show: false, update: false });
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(prospectGeneralBase);

  const fetchProspects = useCallback((filters) => {
    const config = { params: filters };

    setPending(true);
    getAdminDeposits(config)
      .then((response) => {
        setItems(response.deposits.data);
        setDataPag(response.deposits);
      })
      .finally(() => setPending(false));
  }, []);

  const realoadData = () => fetchProspects(filters);

  useEffect(() => {
    fetchProspects(filters);
  }, [filters]);

  const addUpd = (row = false) => {
    if (row.id) {
      setItem(row);
      setModal({ ...modal, show: true, update: true });
    } else {
      setItem(itemBase);
      setModal({ ...modal, show: true, update: false });
    }
  };

  const showDetail = (row) => {
    setItem(row);
    setModal({ ...modal, detail: true });
  };

  const columns = Columns(showDetail);
  document.title = "Depósitos";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Depósitos"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            >
              <button
                className="btn btn-sm btn-success"
                onClick={() => addUpd()}
              >
                <i className="fas fa-solid fa-plus"></i> Agregar
              </button>
            </DataTableHelper>
          </div>
        </Container>
        {modal.show && (
          <AddUpdModal
            modal={modal}
            setModal={setModal}
            items={items}
            setItems={setItems}
            item={item}
            setItem={setItem}
            realoadData={realoadData}
          />
        )}
        {modal.detail && (
          <DetailModal
            modal={modal}
            setModal={setModal}
            items={items}
            setItems={setItems}
            item={item}
            setItem={setItem}
            realoadData={realoadData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Deposits;
