import React from "react";
import { Link, withRouter } from "react-router-dom";
import Currency from "../../components/Common/Currency";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const Columns = (showDetail) => {
  let columns = [
    {
      id: "operation_date",
      name: "Fecha de operación",
      selector: (row) => row.operation_date,
      sortable: true,
      wrap: true,
    },
    {
      id: "client",
      name: "Cliente",
      selector: (row) => row?.prospect?.full_name,
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Importe",
      selector: (row) => <Currency amount={row?.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "file",
      name: "Baucher",
      selector: (row) =>
        row?.file?.id ? (
          <a
            download
            href={`${BASE_URL}/files/${row?.file?.id}`}
            className="fw-medium"
            title=""
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-file-download"></i> Descargar
          </a>
        ) : null,
      sortable: true,
      wrap: true,
    },
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <button
              className="btn btn-primary btn-sm"
              title="Eliminar"
              onClick={() => showDetail(row)}
            >
              <i className="fas fa-eye"></i>
            </button>
          </div>
        </div>
      ),
    },
  ];
  return columns;
};

export default Columns;
