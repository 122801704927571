import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { uploadInstruments } from "../../helpers/backend_helper";

const TEMPLATE_URL = `${process.env.REACT_APP_BACKEND_API_URL?.substring(0, process.env.REACT_APP_BACKEND_API_URL.length - 4)}/templates/instrumentos.xlsx`;

const ExcelModal = ({ open, handleClose, loadInstruments }) => {
  const [errors, setErrors] = useState("");
  const [errorDetail, setErrorDetail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const clearState = () => {
    setErrors("");
    setErrorDetail("");
    setSuccessMessage("");
  };

  const handleUploadFile = async ({ target }) => {
    const { files } = target;
    const file = files[0];

    if (!file) return;

    clearState();
    setLoading(true);
    uploadInstruments({ file }).then(responseAction);
  };

  const responseAction = (response) => {
    if (response.status) {
      setSuccessMessage(response?.message);

      loadInstruments && loadInstruments();
    } else {
      setErrors(response?.message);
      setErrorDetail(response?.error);
    }
    setLoading(false);
  };

  const onCloseModal = () => {
    clearState();
    handleClose();
  };

  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Cargar Instrumentos</span>
        </ModalHeader>
        <ModalBody>
          <Container>
            {successMessage && <Alert color="success">{successMessage}</Alert>}
            {errors && (
              <Alert color="danger">
                <p>{errors}</p>
                <p>{errorDetail}</p>
              </Alert>
            )}
            <Row md="12">
              <Col>
                <Label className="form-label">Cargar documento</Label>
                <Input
                  className="form-control"
                  type="file"
                  onChange={handleUploadFile}
                />
              </Col>
              {loading && (
                <Col md={12} className="text-center p-3">
                  <span>
                    <Spinner size="sm" color="primary" /> Cargando
                    instrumentos...
                  </span>
                </Col>
              )}
              <Col md={12} className="text-center p-3">
                <a
                  href={TEMPLATE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button size="sm" color="primary" outline>
                    <i className="fa fa-download"></i> Descargar plantilla
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCloseModal}>
            <i className="fa fa-times"></i> Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ExcelModal;
