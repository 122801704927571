import React from "react";
import * as _ from "lodash";
import dayjs from "dayjs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DateFormat from "../../../../../components/Common/DateFormat";
import Currency from "../../../../../components/Common/Currency";

export const CONTRACT_RETIRE = {
  NO_RETIRE: 0,
  RETIRE: 1,
};

export const CONTRACT_STATUS = {
  BLOCKED: "blocked",
  IN_COURSE: "in-course",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
};

export const CONTRACT_STATUS_STYLES = {
  [CONTRACT_STATUS.BLOCKED]: {
    label: "Bloqueado",
    color: "bg-warning",
  },
  [CONTRACT_STATUS.IN_COURSE]: {
    label: "En curso",
    color: "bg-info",
  },
  [CONTRACT_STATUS.CANCELLED]: {
    label: "Cancelado",
    color: "bg-danger",
  },
  [CONTRACT_STATUS.COMPLETED]: {
    label: "Completado",
    color: "bg-success",
  },
};

const Columns = (detailContract) => {
  let columns = [
    {
      id: "product",
      name: "Producto",
      selector: (row) => row.product?.name,
      sortable: true,
      wrap: true,
    },
    {
      id: "productType",
      name: "Tipo de producto",
      selector: (row) => row.product?.type?.name,
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Monto",
      selector: (row) => <Currency amount={row.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "status",
      name: "Estatus",
      selector: (row) => {
        const { color, label } = _.get(CONTRACT_STATUS_STYLES, row?.status, {});

        return <span className={`${color} badge bg-secondary`}>{label}</span>;
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "activation_date",
      name: "Fecha de activación",
      selector: ({ activation_date }) => (
        <DateFormat date={activation_date} format="DD/MM/YYYY" />
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: "created_at",
      name: "Fecha de solicitud",
      selector: ({ created_at }) => (
        <div>
          <DateFormat date={created_at} format="DD/MM/YYYY" />
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => {
        const { status, to_retire } = row;

        return (
          <div className="d-flex justify-content-between">
            <div className="p-2 d-flex flex-wrap gap-2">
              {status === CONTRACT_STATUS.COMPLETED && (
                <button
                  className="btn btn-outline-light waves-effect btn-sm"
                  title="Ver detalles de la inversión"
                  onClick={() => detailContract(row)}
                >
                  <i className="bx bx-bar-chart-alt-2"></i> Ver detalles
                </button>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  return columns;
};

export default Columns;
