import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTableHelper from "../../helpers/tables/DataTableHelper"
import Columns from './Columns'
import AddUpdModal from './AddUpdModal'
import { delInstrument, delPeriod, delPeriodRisk, delProductTypes, delProfileRisk, getInstrumentPermissions, getPeriodPermissions, getProductTypesPermissions, getProfileRiskPermissions } from '../../helpers/backend_helper';
import {DestroyAlertHelper,Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import { getPolicyUser } from '../../helpers/api_helper'; 
import {Container} from "reactstrap";
import { deleteProject } from '../../components/helpers/fakebackend_helper';

const ProductTypes = () => {
    const itemBase = useState({
        id: null,
        name: '',
    });
    const [pending, setPending] = useState(false);
    const [modal, setModal] = useState({show:false,update:false});
    const policy = getPolicyUser();
    const [dataPag, setDataPag] = useState({});
    const [filters,setFilters] = useState({page:1,perPage:20,search:'',orderBy:'id',order:'asc'});
    const [items, setItems] = useState([]);
    const [profile, setProfile] = useState(itemBase);
	const dispatch = useDispatch()
    useEffect(() => {
        //ToastHelper('mensaje','error')
        let params={params:filters}
        console.log('params', params)
        setPending(true)
        getProductTypesPermissions(params).then(response => {
            setItems(response.data)
            setDataPag(response.meta)
            //console.log(response)
            setPending(false)
        })
    }, [filters])

    const addUpd = (row=false) => {
        if(row.id){
            setProfile(row)
            setModal({...modal,show:true,update:true})
        }else{
            setProfile(itemBase)
            setModal({...modal,show:true,update:false})
        }
    }

    const destroy = (id) => {
        DestroyAlertHelper(async confirmed => {
            if (!confirmed) { return; }
            delProductTypes(id).then(response => {
                Alert(response.message,response.status)
                if (response.status) {
                    setItems(
                        items.filter(i => i.id != id)
                    );
                }           
            })
        });
    }
    
    const columns = Columns(addUpd, destroy, policy);
	document.title = "Tipos de Producto";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="col-12">
                        <DataTableHelper tittle="Tipos de producto" columns={columns} items={items} pending={pending} config={dataPag} filters={filters} setFilters={setFilters}>
                            <button className="btn btn-sm btn-success" onClick={() => addUpd()}><i className="fas fa-solid fa-plus"></i> Agregar</button>
                        </DataTableHelper>
                    </div>
                </Container>
                {modal.show &&
                    <AddUpdModal 
                        modal={modal}
                        setModal={setModal}
                        items={items}
                        setItems={setItems}
                        item={profile}
                        setItem={setProfile}
                    />
                }
            </div>
        </React.Fragment>
    );
};

export default ProductTypes;