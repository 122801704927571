import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input, Form,FormFeedback, Label} from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postProfile,putProfile,getCompanies, putProfileRisk, postProfileRisk, putClient, postClient, putProduct, postProduct } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from 'classnames';


const AddUpdModal = ({modal,setModal,items, setItems, setItem, item}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
    }

    const validation = useFormik({
		enableReinitialize: true,
		initialValues: {...item},
		validationSchema: Yup.object({
			name: Yup.string().required('Requerido').min(1),
			description: Yup.string().required('Requerido').min(1),
			bachmark: Yup.string(),
			composition: Yup.string(),
			operation: Yup.string(),
			risks: Yup.string(),
			administration: Yup.string(),
		}),
		onSubmit: async values => {
			await save(values)
			handleClose()
			validation.resetForm();
		},
	});

    const save = (data) => {
    	setErrors([])
    	setLoading(true)
    	if(modal.update){
    		putProduct(data).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postProduct(data).then(response => {
    			responseAction(response);
    		})
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.item;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.companies,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    	});
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show} style={{maxWidth: '50%'}}>
				<ModalHeader className='bg-primary'>
					<span className='text-light'>
						{modal.update ? 'Editar':'Agregar'} Producto
					</span>
				</ModalHeader>
				<Form
					onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
					}}
				>
				<ModalBody>
				<div className="row">
					<div className="col-sm-6">
						<Label>Nombre</Label>
					    <Input
							name="name"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.name || ""}
							invalid={
								validation.touched.name &&
								validation.errors.name
								? true
								: false
							}
						/>
					    {(validation.touched.name && validation.errors.name) && 
							<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>}
					</div>
					
					<div className="col-sm-12">
						<Label>Descripción</Label>
					    <textarea
							className={classNames({
								'form-control': true,
								'is-invalid': validation.touched.description && validation.errors.description
							})}
							name="description"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.description || ""}
						/>
					    {(validation.touched.description && validation.errors.description) && 
							<FormFeedback type="invalid">{validation.errors.description}</FormFeedback>}
					</div>
					<div className="col-sm-12">
						<Label>Benchmark</Label>
					    <textarea
							className='form-control'
							name="benchmark"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.benchmark || ""}
							invalid={
								validation.touched.benchmark &&
								validation.errors.benchmark
								? true
								: false
							}
						/>
					    {(validation.touched.benchmark && validation.errors.benchmark) && 
							<FormFeedback type="invalid">{validation.errors.benchmark}</FormFeedback>}
					</div>
					<div className="col-sm-12">
						<Label>Composición</Label>
					    <textarea
							className='form-control'
							name="composition"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.composition || ""}
							invalid={
								validation.touched.composition &&
								validation.errors.composition
								? true
								: false
							}
						/>
					    {(validation.touched.composition && validation.errors.composition) && 
							<FormFeedback type="invalid">{validation.errors.composition}</FormFeedback>}
					</div>
					<div className="col-sm-12">
						<Label>Operación</Label>
					    <textarea
							className='form-control'
							name="operation"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.operation || ""}
							invalid={
								validation.touched.operation &&
								validation.errors.operation
								? true
								: false
							}
						/>
					    {(validation.touched.operation && validation.errors.operation) && 
							<FormFeedback type="invalid">{validation.errors.operation}</FormFeedback>}
					</div>
					<div className="col-sm-12">
						<Label>Riesgos</Label>
					    <textarea
							className='form-control'
							name="risks"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.risks || ""}
							invalid={
								validation.touched.risks &&
								validation.errors.risks
								? true
								: false
							}
						/>
					    {(validation.touched.risks && validation.errors.risks) && 
							<FormFeedback type="invalid">{validation.errors.risks}</FormFeedback>}
					</div>
					<div className="col-sm-12">
						<Label>Administración</Label>
					    <textarea
							className='form-control'
							name="administration"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.administration || ""}
							invalid={
								validation.touched.administration &&
								validation.errors.administration
								? true
								: false
							}
						/>
					    {(validation.touched.administration && validation.errors.administration) && 
							<FormFeedback type="invalid">{validation.errors.administration}</FormFeedback>}
					</div>
					<div className="col-sm-6">
						<Label>Brochure</Label>
					    <Input
							name="brochure_id"
							type="file"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.brochure_id || ""}
							invalid={
								validation.touched.brochure_id &&
								validation.errors.brochure_id
								? true
								: false
							}
						/>
					    {(validation.touched.brochure_id && validation.errors.brochure_id) && 
							<FormFeedback type="invalid">{validation.errors.brochure_id}</FormFeedback>}
					</div>
					
				</div>	

				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } <i className='fa fa-save'></i> Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}><i className='fa fa-times'></i> Cancelar</Button>
				</ModalFooter>
				</Form>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;