import React from "react";
//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Pie from "./echart/piechart";
import Line from "./echart/linechart";
import TablesProductPerformace from "./TablesProductPerformace";
import { Row } from "reactstrap";

const MyProductDetailInner = ({ product }) => {
  return (
    <Row>
      <div className="col-lg-6">
        <h5>Descripción</h5>
        <p>{product?.description}</p>

        <h5>Benchmark</h5>
        <p>{product?.benchmark}</p>

        {product?.template_performance && (
          <Line data={product?.template_performance} type={product?.template_performance_type} />
        )}

        {product?.template_performance && (
          <div className="mt-4">
            <TablesProductPerformace data={product?.template_tables} type={product?.template_performance_type} />
          </div>
        )}

        {!product?.template_performance && (
          <div className="alert alert-warning">Sin datos de rendimiento</div>
        )}
      </div>
      <div className="col-lg-6">
        <h5>Composición</h5>
        <p>{product?.composition}</p>

        <Pie data={product?.instruments} />

        {(!product?.instruments || product?.instruments.length == 0) && (
          <div className="alert alert-warning">Sin datos de composición</div>
        )}

        <h5>Operación</h5>
        <p>{product?.operation}</p>

        <h5>Riesgos</h5>
        <p>{product?.risks}</p>

        <h5>Administración</h5>
        <p>{product?.administration}</p>
      </div>
    </Row>
  );
};

export default MyProductDetailInner;
