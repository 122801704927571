import React, { useState } from "react";

import {
  postProspectSendPinEmail,
} from "../../../helpers/backend_helper";

const useSendPinEmail = () => {
  const [isSendingPin, setIsSendingPin] = useState(false);

  const handleSendPin = async (validation) => {
    validation.setValues({
      ...validation.values,
      pin: "",
      pin_email_sent: false,
    });

    const data = {
      email: validation.values.email,
    };

    setIsSendingPin(true);
    try {
      const resp = await postProspectSendPinEmail(data);
      if (resp?.status) {
        validation.setFieldValue("pin_email_sent", true);
      }
    } catch (error) {
      console.log("PIN error", error);
    } finally {
      setIsSendingPin(false);
    }
  };

  const clearValidationOnChangeEmail = (validation) => {
    validation.setValues({
      ...validation.values,
      pin: "",
      pin_email_sent: false,
    });
  };

  return {
    isSendingPin,
    handleSendPin,
    clearValidationOnChangeEmail,
  };
};

export default useSendPinEmail;
