import React, { useState } from "react";

import { postProspectSendPinSms } from "../../../helpers/backend_helper";

const useSendPinSms = () => {
  const [isSendingPinSMS, setIsSendingPinSMS] = useState(false);

  const handleSendPinSMS = async (validation) => {
    validation.setValues({
      ...validation.values,
      pin: "",
      pin_phone_sent: false,
    });

    const data = {
      phone: validation.values.ti_phone,
    };

    setIsSendingPinSMS(true);
    try {
      const resp = await postProspectSendPinSms(data);
      if (resp?.status) {
        validation.setFieldValue("pin_phone_sent", true);
      }
    } catch (error) {
      console.log("PIN error", error);
    } finally {
      setIsSendingPinSMS(false);
    }
  };

  const clearValidationOnChangeSMS = (validation) => {
    validation.setValues({
      ...validation.values,
      pin: "",
      pin_phone_sent: false,
    });
  };

  return {
    isSendingPinSMS,
    handleSendPinSMS,
    clearValidationOnChangeSMS,
  };
};

export default useSendPinSms;
