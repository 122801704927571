import React from "react";
import * as _ from "lodash";
import Currency from "../../components/Common/Currency";
import DateFormat from "../../components/Common/DateFormat";

export const TYPES = {
  IN: "ingreso",
  OUT: "egreso",
};

export const TYPES_STYLES = {
  [TYPES.IN]: {
    label: "Ingreso",
    color: "bg-primary",
  },
  [TYPES.OUT]: {
    label: "Egreso",
    color: "bg-info",
  },
};

const Columns = () => {
  let columns = [
    {
      id: "status",
      name: "Tipo",
      selector: (row) => {
        const { color, label } = _.get(TYPES_STYLES, row?.type, {});
        /* return label; */
        return <span className={`${color} badge bg-secondary`}>{label}</span>;
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Monto",
      selector: (row) => <Currency amount={row.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "prospect.full_name",
      accessor: "prospect.full_name",
      selector: (row) => row?.prospect?.full_name,
      name: "Cliente",
      sortable: true,
      wrap: true,
    },
    {
      id: "created_at",
      name: "Fecha de operación",
      selector: ({ operation_date }) => (
        <div>
          <DateFormat date={operation_date} format="DD/MM/YYYY" />
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];
  return columns;
};

export default Columns;
