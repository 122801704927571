import React, { useEffect, useState } from "react";

import {
  faceCaptureConfig,
  idCaptureConfig,
  libraryScriptUrl,
  thirdPartyScriptUrl,
} from "./constants";

const DataCapture = ({
  token,
  captureType,
  onEvaluation,
  onSuccess,
  onError,
  onFail,
}) => {
  const isFaceCapture = captureType === "Face";
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);

  useEffect(() => {
    if (typeof NubSdkDeviceHandler === 'undefined') {
      const libraryScript = document.createElement("script");
      libraryScript.src = libraryScriptUrl;
      libraryScript.async = true;
      document.body.appendChild(libraryScript);

      libraryScript.onload = () => {
        setFirst(true);
      };
    } else {
      setFirst(true);
    }

    if (typeof RecordRTC === 'undefined') {
      const thirdPartyScript = document.createElement("script");
      thirdPartyScript.src = thirdPartyScriptUrl;
      thirdPartyScript.async = true;
      document.body.appendChild(thirdPartyScript);
      thirdPartyScript.onload = () => {
        setSecond(true);
      };
    } else {
      setSecond(true);
    }
  }, []);

  useEffect(() => {
    if (first && second) {
      const capture = isFaceCapture ? new FaceCapture() : new IdCapture();
      const config = isFaceCapture ? faceCaptureConfig : idCaptureConfig;

      capture.init(config);
      capture.setToken(token);

      capture.load(() => {
        capture.start();
      });

      if(isFaceCapture) {
        capture.onEvaluation(onEvaluation).onSuccess(onSuccess).onFail(onFail).onError(onError);
      } else {
        capture.onEvaluation(onEvaluation).onSuccess(onSuccess).onFail(onFail).onError(onError);
      }
      
    }
  }, [first, second]);

  return (
    <div>
      {first}{second}
      <div id="id_nubarium"></div>
    </div>
  );
};

export default DataCapture;
