import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Select from 'react-select';
import { getSelectsPermissions,postPermission,putPermission } from '../../../helpers/backend_helper';
import {Alert,ToastHelper} from '../../../helpers/alerts/alertHelper';
import Validate from './Validate'
import { useParams } from 'react-router-dom';

const AddUpdModal = ({modal,setModal,items, setItems, setPermission,permission}) => {
	const {id} = useParams();
	const [selects, setSelects] = useState({privileges:[],permissions:[]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setPermission( {...permission,
    		id: 0,
    		privilege_id:null,
    		privilege:{},
			permission_id:null,
			permission:{},
			profile_id:id
    	});	
    }

    const handlerOnChange = (value,name) => {
    	if(name=='privilege'){
    		setPermission({...permission, ['privilege_id']:value.value,[name]:value});	
    	}else{
    		setPermission({...permission, ['permission_id']:value.id,[name]:value});
    	}
    }

    const save = () => {
    	console.log('0ok')
    	setErrors([])
    	const validate = Validate(permission);
    	if(validate != undefined){
    		console.log(validate)
            setErrors(validate);
            setLoading(false)
            return;
        }
    	setLoading(true)
    	if(modal.update){
    		putPermission(permission).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postPermission(permission).then(response => {
    			responseAction(response);
    		})	
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.permission;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getSelectsPermissions(id).then(response => {
    		setSelects({...selects,permissions:response.permissions,privileges:response.privileges})
    	});
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>{modal.update ? 'Editar ':'Agregar '}Permiso</ModalHeader>
				<ModalBody>
				<div className="row">
					<div className="col-sm-6">
					    <label className="form-label">Modulo:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							defaultValue={permission.permission}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.permissions}
							onChange={ e => handlerOnChange(e, 'permission') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.permission_id && errors.permission_id[0]}</div>
					    }
					</div>
					<div className="col-sm-6">
					    <label className="form-label">Privilegio:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							name="color"
							options={selects.privileges}
							onChange={ e => handlerOnChange(e, 'privilege') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.privilege_id && errors.privilege_id[0]}</div>
					    }
					</div>
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;