import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import dayjs from "dayjs";
import "dayjs/locale/es";

import {
  delProductPerformances,
  getProductPerformances,
  postProductPerformances,
} from "../../helpers/backend_helper";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import { useFormik } from "formik";
import NibbleInput from "../../components/Fields/NibbleInput";
import { DestroyAlertHelper } from "../../helpers/alerts/alertHelper";

const today = dayjs();
const months = [
  { id: 1, name: "Enero" },
  { id: 2, name: "Febrero" },
  { id: 3, name: "Marzo" },
  { id: 4, name: "Abril" },
  { id: 5, name: "Mayo" },
  { id: 6, name: "Junio" },
  { id: 7, name: "Julio" },
  { id: 8, name: "Agosto" },
  { id: 9, name: "Septiembre" },
  { id: 10, name: "Octubre" },
  { id: 11, name: "Noviembre" },
  { id: 12, name: "Diciembre" },
];

const generateYears = () => {
  let years = [];
  for (let i = 0; i <= 50; i++) {
    years.push({ id: today.year()-i, name: today.year()-i });
  }
  return years;
}


const RendimientosModal = ({ item, setItem, modal, setModal }) => {
  const [years, setYears] = useState(generateYears());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      month: today.month() + 1,
      month_name: today.locale("es").format("MMMM"),
      year: today.year(),
      percentage: null,
      product_id: item.id,
    },
    validationSchema: Yup.object({
      month: Yup.string().required("Required"),
      year: Yup.string().required("Required"),
      percentage: Yup.string().nullable().required("Required"),
      product_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      save(values);
    },
  });

  const save = (values) => {
    const data = {
      ...values,
      product_id: item.id,
    };

    setLoading(true);
    postProductPerformances(data)
      .then(() => {
        fetchProductPerformances();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    setModal((prev) => ({ ...prev, rendimientos: false }));
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      try {
        await delProductPerformances(id);
        fetchProductPerformances();
      } catch (error) {
        console.log("delError", error);
      }
    });
  };

  const fetchProductPerformances = () => {
    const config = {
      params: {
        product_id: item.id,
      },
    };

    getProductPerformances(config).then((response) => {
      console.log({ response });
      setData(response);
    });
  };

  useEffect(fetchProductPerformances, []);

  return (
    <div>
      <Modal isOpen={modal.rendimientos} className="modal-lg">
        <ModalHeader>Rendimientos del producto</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              <NibbleInput
                md={3}
                label="Mes:"
                fieldName="month"
                fieldType="select"
                validation={validation}
                selectItems={months}
                readOnly
              />
              <NibbleInput
                md={3}
                label="Año:"
                fieldName="year"
                fieldType="select"
                validation={validation}
                selectItems={years}
                readOnly
              />
              <NibbleInput
                md={3}
                label="Rendimiento (%):"
                fieldName="percentage"
                fieldType="number"
                validation={validation}
              />
              <Col md={3} className="align-content-end">
                <Button color="primary" type="submit" disabled={loading}>
                  {loading && <Spinner size="sm"></Spinner>}{" "}
                  <i className="fa fa-save"></i> Guardar
                </Button>{" "}
              </Col>
            </Row>
            <Row></Row>
            <DataTableHelper
              columns={[
                {
                  id: "name",
                  name: "Mes",
                  selector: (row) => {
                    const monthName = dayjs(`${row.year}-${row.month}-01`)
                      .locale("es")
                      .format("MMMM");
                    return monthName;
                  },
                  sortable: true,
                  wrap: true,
                },
                {
                  id: "type_id",
                  name: "Año",
                  selector: (row) => {
                    return row?.year;
                  },
                  sortable: true,
                  wrap: true,
                },
                {
                  id: "rendimiento",
                  name: "Rendimiento (%)",
                  selector: (row) => {
                    return `${row?.percentage}%`;
                  },
                  sortable: true,
                  wrap: true,
                },
                {
                  id: "actions",
                  name: "Acciones",
                  width: "20%",
                  button: true,
                  cell: (row) => (
                    <div className="d-flex justify-content-between">
                      <div className="p-2">
                        <button
                          className="btn btn-danger btn-sm"
                          title="Eliminar"
                          onClick={() => destroy(row.id)}
                          type="button"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  ),
                },
              ]}
              items={data}
              pending={false}
              config={{}}
              filters={{}}
              setFilters={() => {}}
              showSearch={false}
              onlyTable={true}
              showPagination={false}
            />
          </ModalBody>
          <ModalFooter>
            {/* <Button
              color="primary"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              
              <i className="fa fa-save"></i> Guardar
            </Button> */}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cerrar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default RendimientosModal;
