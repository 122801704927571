import React from 'react';

const Columns = (destroy) => {
	let columns = [
	{
		id: 'config_name',
		name: 'Nombre',
		selector: row => row.config_name,
		sortable: true,
		wrap: true
	},
	{
		id: 'type',
		name: 'Tipo de configuración',
		selector: row => row.type,
		sortable: true,
	},
	{
		id: 'option_name',
		name: 'Configuracion asignada',
		selector: row => row.option_name,
		sortable: true,
	},
	{
		id: 'actions',
		name: 'Actions',
		button: true,
		cell: row => (
			<div className="d-flex justify-content-between">
				<div className="p-2">
					<button className="btn btn-danger btn-sm" title='Eliminar Configuration' onClick={() => destroy(row.id)}><i className="fas fa-trash"></i></button>
			</div>
		</div>
		),
	}
	];
	return columns;
}

export default Columns;