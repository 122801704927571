import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import Select from "react-select";
import {
  getProfilescat,
  getCompanies,
  putProduct,
  postProduct,
  postSection,
  getSections,
  putAsk,
  postAsk,
} from "../../../helpers/backend_helper";
import { getStorageUser } from "../../../helpers/api_helper";
import { Alert, ToastHelper } from "../../../helpers/alerts/alertHelper";
import * as Yup from "yup";
import { useFormik } from "formik";
import Creatable from "react-select/creatable";
import NibbleInput from "../../../components/Fields/NibbleInput";

const AddUpdModal = ({
  modal,
  setModal,
  items,
  setItems,
  setItem,
  item,
  survey,
  sections,
  fetchData,
}) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const authUser = getStorageUser();
  const handleClose = () => {
    setModal({ ...modal, show: false, update: false });
  };

  const fetchSections = async () => {
    const sections = await getSections({ params: { survey_id: survey?.id } });
    setSections(sections.data);
  };

  useEffect(() => {
    console.log("geeett");
    //if(survey?.id)
    //	fetchSections(survey?.id);
  }, []);

  const defaultValues = {
    ask: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    optionF: "",
    valueA: 0,
    valueAOther: false,
    valueB: 0,
    valueBOther: false,
    valueC: 0,
    valueCOther: false,
    valueD: 0,
    valueDOther: false,
    valueE: 0,
    valueEOther: false,
    valueF: 0,
    valueFOther: false,
    survey_section_id: null,
    allow_another_answer: false,
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...item },
    validationSchema: Yup.object({
      ask: Yup.string().required("Requerido").min(1),
      optionA: Yup.string().required("Requerido").min(1),
      optionB: Yup.string().required("Requerido").min(1),
      optionC: Yup.string().nullable(),
      optionD: Yup.string().nullable(),
      optionE: Yup.string().nullable(),
      optionF: Yup.string().nullable(),
      optionAOther: Yup.boolean().nullable(),
      optionBOther: Yup.boolean().nullable(),
      optionCOther: Yup.boolean().nullable(),
      optionDOther: Yup.boolean().nullable(),
      optionEOther: Yup.boolean().nullable(),
      optionFOther: Yup.boolean().nullable(),
      valueA: Yup.number().when("optionA", {
        is: (optionA) => {
          return optionA?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      valueB: Yup.number().when("optionB", {
        is: (optionB) => {
          return optionB?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      valueC: Yup.number().when("optionC", {
        is: (optionC) => {
          return optionC?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      valueD: Yup.number().when("optionD", {
        is: (optionD) => {
          return optionD?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      valueE: Yup.number().when("optionE", {
        is: (optionE) => {
          return optionE?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      valueF: Yup.number().when("optionF", {
        is: (optionF) => {
          return optionF?.length > 0 ?? false;
        },
        then: Yup.number().required("Requerido").min(1, "Valor Mínimo 1"),
      }),
      survey_section_id: Yup.number().nullable(),
    }),
    onSubmit: async (values) => {
      await save({ ...values, survey_id: survey?.id });
      handleClose();
      validation.resetForm();
    },
  });

  const saveSection = async (data) => {
    const response = await postSection({ ...data, survey_id: survey.id });
    return response;
  };

  const save = async (data) => {
    setErrors([]);
    setLoading(true);
    const response = modal.update ? await putAsk(data) : await postAsk(data);
    responseAction(response);
  };

  const responseAction = async (response) => {
    if (response.status) {
      await fetchData();
      handleClose();
      setLoading(false);
    } else {
      setLoading(false);
      setErrors(response.response.data.errors);
    }
  };

  const handleCreateSection = async (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    const section = await saveSection({ name: inputValue });
    await fetchData();
    validation.setFieldValue("survey_section_id", newOption.label);
  };

  console.log({ FormValues: validation.values });

  return (
    <div>
      <Modal isOpen={modal.show} style={{ maxWidth: "50%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            {modal.update ? "Editar" : "Agregar"} Pregunta
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.validateForm();
            validation.setTouched();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row mt-3">
              <div className="col-sm-6">
                <Label>Sección</Label>
                <Creatable
                  className={"is-invalid"}
                  name="section"
                  options={sections.map((e) => {
                    return { label: e.name, value: e.id };
                  })}
                  value={
                    sections
                      .map((e) => {
                        return { label: e.name, value: e.id };
                      })
                      .filter((e) => {
                        return e.value == validation.values.survey_section_id;
                      })[0] || null
                  }
                  onChange={(option, actionMeta) => {
                    validation.setFieldValue(
                      "survey_section_id",
                      option.value,
                      true
                    );
                  }}
                  onBlur={(option) => {
                    validation.setFieldTouched("survey_section_id", true, true);
                  }}
                  placeholder={"Seleccionar o crear..."}
                  onCreateOption={handleCreateSection}
                />
                {validation.touched.survey_section_id &&
                  validation.errors.survey_section_id && (
                    <FormFeedback type="invalid">
                      {validation.errors.section
                        ? validation.errors.survey_section_id
                        : "Error"}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Orden</Label>
                <Input
                  name="sort"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.sort || ""}
                  invalid={
                    validation.touched.sort && validation.errors.sort
                      ? true
                      : false
                  }
                />
                {validation.touched.sort && validation.errors.sort && (
                  <FormFeedback type="invalid">
                    {validation.errors.sort}
                  </FormFeedback>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Label>Pregunta</Label>
                <Input
                  name="ask"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.ask || ""}
                  invalid={
                    validation.touched.ask && validation.errors.ask
                      ? true
                      : false
                  }
                />
                {validation.touched.ask && validation.errors.ask && (
                  <FormFeedback type="invalid">
                    {validation.errors.ask}
                  </FormFeedback>
                )}
              </div>

              {/* <div className="col-sm-1 pt-3">
                <Label className="d-block">Permitir otra respuesta</Label>
                <Input
                  name="allow_another_answer"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue(
                      "allow_another_answer",
                      e.target.checked
                    );
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.allow_another_answer || false}
                  checked={validation.values.allow_another_answer || false}
                  invalid={
                    validation.touched.allow_another_answer &&
                    validation.errors.allow_another_answer
                      ? true
                      : false
                  }
                />
                {validation.touched.allow_another_answer &&
                  validation.errors.allow_another_answer && (
                    <FormFeedback type="invalid">
                      {validation.errors.allow_another_answer}
                    </FormFeedback>
                  )}
              </div> */}
            </div>
            <div className="row pt-3">
              <div className="col-sm-2">
                <Label className="d-block">Otra resp.</Label>
                <Input
                  name="allow_another_answer"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionAOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionAOther || false}
                  checked={validation.values.optionAOther || false}
                  invalid={
                    validation.touched.optionAOther &&
                    validation.errors.optionAOther
                      ? true
                      : false
                  }
                />
              </div>

              <div className="col-sm-6">
                <Label>Respuesta A</Label>
                <Input
                  name="optionA"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionA || ""}
                  invalid={
                    validation.touched.optionA && validation.errors.optionA
                      ? true
                      : false
                  }
                />
                {validation.touched.optionA && validation.errors.optionA && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionA}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos A</Label>
                <Input
                  name="valueA"
                  type="number"
                  step={1}
                  disabled={validation.values.optionA.length == 0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueA || ""}
                  invalid={
                    validation.touched.valueA && validation.errors.valueA
                      ? true
                      : false
                  }
                />
                {validation.touched.valueA && validation.errors.valueA && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueA}
                  </FormFeedback>
                )}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm-2 pt-3">
                {/* <Label className="d-block">Permitir otra respuesta</Label> */}
                <Input
                  name="optionBOther"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionBOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionBOther || false}
                  checked={validation.values.optionBOther || false}
                  invalid={
                    validation.touched.optionBOther &&
                    validation.errors.optionBOther
                      ? true
                      : false
                  }
                />
                {validation.touched.optionBOther &&
                  validation.errors.optionBOther && (
                    <FormFeedback type="invalid">
                      {validation.errors.optionBOther}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Respuesta B</Label>
                <Input
                  name="optionB"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionB || ""}
                  invalid={
                    validation.touched.optionB && validation.errors.optionB
                      ? true
                      : false
                  }
                />
                {validation.touched.optionB && validation.errors.optionB && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionB}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos B</Label>
                <Input
                  name="valueB"
                  type="number"
                  step={1}
                  disabled={validation.values.optionB.length == 0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueB || ""}
                  invalid={
                    validation.touched.valueB && validation.errors.valueB
                      ? true
                      : false
                  }
                />
                {validation.touched.valueB && validation.errors.valueB && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueB}
                  </FormFeedback>
                )}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-sm-2 pt-3">
                {/* <Label className="d-block">Permitir otra respuesta</Label> */}
                <Input
                  name="optionCOther"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionCOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionCOther || false}
                  checked={validation.values.optionCOther || false}
                  invalid={
                    validation.touched.optionCOther &&
                    validation.errors.optionCOther
                      ? true
                      : false
                  }
                />
                {validation.touched.optionCOther &&
                  validation.errors.optionCOther && (
                    <FormFeedback type="invalid">
                      {validation.errors.optionCOther}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Respuesta C</Label>
                <Input
                  name="optionC"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionC || ""}
                  invalid={
                    validation.touched.optionC && validation.errors.optionC
                      ? true
                      : false
                  }
                />
                {validation.touched.optionC && validation.errors.optionC && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionC}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos C</Label>
                <Input
                  name="valueC"
                  type="number"
                  step={1}
                  disabled={validation.values.optionC?.length == 0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueC || ""}
                  invalid={
                    validation.touched.valueC && validation.errors.valueC
                      ? true
                      : false
                  }
                />
                {validation.touched.valueC && validation.errors.valueC && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueC}
                  </FormFeedback>
                )}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-sm-2 pt-3">
                {/* <Label className="d-block">Permitir otra respuesta</Label> */}
                <Input
                  name="optionDOther"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionDOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionDOther || false}
                  checked={validation.values.optionDOther || false}
                  invalid={
                    validation.touched.optionDOther &&
                    validation.errors.optionDOther
                      ? true
                      : false
                  }
                />
                {validation.touched.optionDOther &&
                  validation.errors.optionDOther && (
                    <FormFeedback type="invalid">
                      {validation.errors.optionDOther}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Respuesta D</Label>
                <Input
                  name="optionD"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionD || ""}
                  invalid={
                    validation.touched.optionD && validation.errors.optionD
                      ? true
                      : false
                  }
                />
                {validation.touched.optionD && validation.errors.optionD && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionD}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos D</Label>
                <Input
                  name="valueD"
                  type="number"
                  step={1}
                  disabled={validation.values.optionD?.length == 0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueD || ""}
                  invalid={
                    validation.touched.valueD && validation.errors.valueD
                      ? true
                      : false
                  }
                />
                {validation.touched.valueD && validation.errors.valueD && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueD}
                  </FormFeedback>
                )}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-sm-2 pt-3">
                {/* <Label className="d-block">Permitir otra respuesta</Label> */}
                <Input
                  name="optionEOther"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionEOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionEOther || false}
                  checked={validation.values.optionEOther || false}
                  invalid={
                    validation.touched.optionEOther &&
                    validation.errors.optionEOther
                      ? true
                      : false
                  }
                />
                {validation.touched.optionEOther &&
                  validation.errors.optionEOther && (
                    <FormFeedback type="invalid">
                      {validation.errors.optionEOther}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Respuesta E</Label>
                <Input
                  name="optionE"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionE || ""}
                  invalid={
                    validation.touched.optionE && validation.errors.optionE
                      ? true
                      : false
                  }
                />
                {validation.touched.optionE && validation.errors.optionE && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionE}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos E</Label>
                <Input
                  name="valueE"
                  type="number"
                  step={1}
                  disabled={validation.values.optionE?.length == 0}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueE || ""}
                  invalid={
                    validation.touched.valueE && validation.errors.valueE
                      ? true
                      : false
                  }
                />
                {validation.touched.valueE && validation.errors.valueE && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueE}
                  </FormFeedback>
                )}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-sm-2 pt-3">
                {/* <Label className="d-block">Permitir otra respuesta</Label> */}
                <Input
                  name="optionFOther"
                  type="checkbox"
                  onChange={(e) => {
                    validation.setFieldValue("optionFOther", e.target.checked);
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionFOther || false}
                  checked={validation.values.optionFOther || false}
                  invalid={
                    validation.touched.optionFOther &&
                    validation.errors.optionFOther
                      ? true
                      : false
                  }
                />
                {validation.touched.optionFOther &&
                  validation.errors.optionFOther && (
                    <FormFeedback type="invalid">
                      {validation.errors.optionFOther}
                    </FormFeedback>
                  )}
              </div>
              <div className="col-sm-6">
                <Label>Respuesta F</Label>
                <Input
                  name="optionF"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optionF || ""}
                  invalid={
                    validation.touched.optionF && validation.errors.optionF
                      ? true
                      : false
                  }
                />
                {validation.touched.optionF && validation.errors.optionF && (
                  <FormFeedback type="invalid">
                    {validation.errors.optionF}
                  </FormFeedback>
                )}
              </div>

              <div className="col-sm-4">
                <Label>Puntos F</Label>
                <Input
                  name="valueF"
                  type="number"
                  disabled={validation.values.optionF?.length == 0}
                  step={1}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.valueF || ""}
                  invalid={
                    validation.touched.valueF && validation.errors.valueF
                      ? true
                      : false
                  }
                />
                {validation.touched.valueF && validation.errors.valueF && (
                  <FormFeedback type="invalid">
                    {validation.errors.valueF}
                  </FormFeedback>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUpdModal;
