import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ValidationTag from "../../ValidationTag/ValidationTag";

const responseStatus = {
  ok: "OK",
  error: "ERROR",
};

const GeneralDataTab = ({ prospect }) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Titular</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              Nombre: <ValidationTag status={prospect?.statusFullname} /> <br />{" "}
              {prospect?.ti_name}
            </Col>
            <Col md={4}>
              Apellido paterno: <br />
              {prospect?.ti_lname1}
            </Col>
            <Col md={4}>
              Apellido materno: <br />
              {prospect?.ti_lname2}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Fecha de nacimiento: <br />
              {prospect?.ti_birth}
            </Col>
            <Col md={4}>
              RFC: <ValidationTag status={prospect?.statusRfcSat} /> <br />
              {prospect?.ti_rfc}
            </Col>
            <Col md={4}>
              CURP: <ValidationTag status={prospect?.statusCurp} /> <br />
              {prospect?.ti_curp}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Teléfono: <br />
              {prospect?.ti_phone}
            </Col>
            <Col md={4}>
              Teléfono fijo: <br />
              {prospect?.ti_landline}
            </Col>
            <Col md={4}>
              País de nacimiento: <br />
              {prospect?.ti_birth_country}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Estado de nacimiento: <br />
              {prospect?.ti_birth_state}
            </Col>
            <Col md={4}>
              Estado civil: <br />
              {prospect?.ti_civil === "Otro"
                ? prospect?.ti_civil_other
                : prospect?.ti_civil}
            </Col>
            <Col md={4}>
              Genero: <br />
              {prospect?.ti_genere}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Contrato aceptado: <br />
              {prospect?.terms_accepted ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="mdi mdi-close-thick text-danger"></i>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Domicilio fiscal</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              País: <br /> {prospect?.fiscal_country}
            </Col>
            <Col md={4}>
              Estado: <br />
              {prospect?.fiscal_state_string}
            </Col>
            <Col md={4}>
              Calle: <br />
              {prospect?.fiscal_address1}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Colonia: <br />
              {prospect?.fiscal_address2}
            </Col>
            <Col md={4}>
              Municipio: <br />
              {prospect?.fiscal_address3}
            </Col>
            <Col md={4}>
              Num ext: <br />
              {prospect?.fiscal_ext}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Num int: <br />
              {prospect?.fiscal_int}
            </Col>
            <Col md={4}>
              CP: <br />
              {prospect?.fiscal_zipcode}
            </Col>
            <Col md={4}>
              Años de residencia: <br />
              {prospect?.fiscal_years}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Tipo de vivienda: <br />
              {prospect?.fiscal_type === "Otro"
                ? prospect?.fiscal_type_other
                : prospect?.fiscal_type}
            </Col>
            <Col md={4}>
              Entre calles: <br />
              {prospect?.fiscal_cross_streets}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Domicilio comercial</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              ¿Es el mismo que domicilio fiscal? <br />{" "}
              {prospect?.commer_ask1 ? "Sí" : "No"}
            </Col>
          </Row>
          {!prospect?.commer_ask1 && (
            <>
              <Row className="pb-3">
                <Col md={4}>
                  País: <br /> {prospect?.commer_country}
                </Col>
                <Col md={4}>
                  Estado: <br />
                  {prospect?.commer_state_string}
                </Col>
                <Col md={4}>
                  Calle: <br />
                  {prospect?.commer_address1}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Colonia: <br />
                  {prospect?.commer_address2}
                </Col>
                <Col md={4}>
                  Municipio: <br />
                  {prospect?.commer_address3}
                </Col>
                <Col md={4}>
                  Num ext: <br />
                  {prospect?.commer_ext}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Num int: <br />
                  {prospect?.commer_int}
                </Col>
                <Col md={4}>
                  CP: <br />
                  {prospect?.commer_zipcode}
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export const CustomerInfoStatus = ({ status }) => {
  if (status == responseStatus.ok)
    return <span className="bg-success badge me-2">{status}</span>;
  if (status == responseStatus.error)
    return <span className="badge bg-danger me-2">{status}</span>;

  return null;
};

export default GeneralDataTab;
