import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { getStorageUser, getUserLevel, getUserNeedsAcceptTerms } from "../../helpers/api_helper";
import { REGISTRATION_STATUS } from "../../helpers/login";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const authUser = getStorageUser();
      // const userLevel = getUserLevel();
      console.log("query authUser", authUser);

      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      
      if (isAuthProtected && REGISTRATION_STATUS.includes(authUser?.prospect?.status)) {
        return (
          <Redirect
            to={{ pathname: "/registration", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
