export const PAISES = [
  {
    name: "Afghanistan",
    id: "Afganistán",
  },
  {
    name: "Albania",
    id: "Albania",
  },
  {
    name: "Algeria",
    id: "Algeria",
  },
  {
    name: "American Samoa",
    id: "Samoa Americana",
  },
  {
    name: "Andorra",
    id: "Andorra",
  },
  {
    name: "Angola",
    id: "Angola",
  },
  {
    name: "Anguilla",
    id: "Anguilla",
  },
  {
    name: "Antarctica",
    id: "Antártida",
  },
  {
    name: "Antigua and Barbuda",
    id: "Antigua y Barbuda",
  },
  {
    name: "Argentina",
    id: "Argentina",
  },
  {
    name: "Armenia",
    id: "Armenia",
  },
  {
    name: "Aruba",
    id: "Aruba",
  },
  {
    name: "Australia",
    id: "Australia",
  },
  {
    name: "Austria",
    id: "Austria",
  },
  {
    name: "Azerbaijan",
    id: "Azerbaiyán",
  },
  {
    name: "Bahamas",
    id: "Bahamas",
  },
  {
    name: "Bahrain",
    id: "Bahrein",
  },
  {
    name: "Bangladesh",
    id: "Bangladesh",
  },
  {
    name: "Barbados",
    id: "Barbados",
  },
  {
    name: "Belarus",
    id: "Bielorrusia",
  },
  {
    name: "Belgium",
    id: "Bélgica",
  },
  {
    name: "Belize",
    id: "Belice",
  },
  {
    name: "Benin",
    id: "Benín",
  },
  {
    name: "Bermuda",
    id: "Bermuda",
  },
  {
    name: "Bhutan",
    id: "Bután",
  },
  {
    name: "Bolivia",
    id: "Bolivia",
  },
  {
    name: "Bosnia and Herzegovina",
    id: "Bosnia-Herzegovina",
  },
  {
    name: "Botswana",
    id: "Botswana",
  },
  {
    name: "Brazil",
    id: "Brasil",
  },
  {
    name: "Brunei Darussalam",
    id: "Brunei",
  },
  {
    name: "Bulgaria",
    id: "Bulgaria",
  },
  {
    name: "Burkina Faso",
    id: "Burkina Faso",
  },
  {
    name: "Burundi",
    id: "Burundi",
  },
  {
    name: "Cambodia",
    id: "Camboya",
  },
  {
    name: "Cameroon",
    id: "Camerún",
  },
  {
    name: "Canada",
    id: "Canadá",
  },
  {
    name: "Cape Verde",
    id: "Cabo Verde",
  },
  {
    name: "Cayman Islands",
    id: "Islas Caimán",
  },
  {
    name: "Central African Republic",
    id: "República Centroafricana",
  },
  {
    name: "Chad",
    id: "Chad",
  },
  {
    name: "Chile",
    id: "Chile",
  },
  {
    name: "China",
    id: "China",
  },
  {
    name: "Christmas Island",
    id: "Isla de Navidad",
  },
  {
    name: "Cocos (Keeling) Islands",
    id: "Islas Cocos",
  },
  {
    name: "Colombia",
    id: "Colombia",
  },
  {
    name: "Comoros",
    id: "Comores",
  },
  {
    name: "Congo",
    id: "República del Congo",
  },
  {
    name: "Congo, The Democratic Republic of the",
    id: "República Democrática del Congo",
  },
  {
    name: "Cook Islands",
    id: "Islas Cook",
  },
  {
    name: "Costa Rica",
    id: "Costa Rica",
  },
  {
    name: "Cote D'Ivoire",
    id: "Costa de Marfíl",
  },
  {
    name: "Croatia",
    id: "Croacia",
  },
  {
    name: "Cuba",
    id: "Cuba",
  },
  {
    name: "Cyprus",
    id: "Chipre",
  },
  {
    name: "Czech Republic",
    id: "República Checa",
  },
  {
    name: "Denmark",
    id: "Dinamarca",
  },
  {
    name: "Djibouti",
    id: "Djibouti",
  },
  {
    name: "Dominica",
    id: "Dominica",
  },
  {
    name: "Dominican Republic",
    id: "República Dominicana",
  },
  {
    name: "Ecuador",
    id: "Ecuador",
  },
  {
    name: "Egypt",
    id: "Egipto",
  },
  {
    name: "El Salvador",
    id: "El Salvador",
  },
  {
    name: "Equatorial Guinea",
    id: "Guinea Ecuatorial",
  },
  {
    name: "Eritrea",
    id: "Eritrea",
  },
  {
    name: "Estonia",
    id: "Estonia",
  },
  {
    name: "Ethiopia",
    id: "Etiopía",
  },
  {
    name: "Falkland Islands (Malvinas)",
    id: "Islas Malvinas",
  },
  {
    name: "Faroe Islands",
    id: "Islas Feroe",
  },
  {
    name: "Fiji",
    id: "Fiji",
  },
  {
    name: "Finland",
    id: "Finlandia",
  },
  {
    name: "France",
    id: "Francia",
  },
  {
    name: "French Guiana",
    id: "Guyana Francesa",
  },
  {
    name: "French Polynesia",
    id: "Polinesia Francesa",
  },
  {
    name: "French Southern Territories",
    id: "Tierras Australes y Antárticas Francesas",
  },
  {
    name: "Gabon",
    id: "Gabón",
  },
  {
    name: "Gambia",
    id: "Gambia",
  },
  {
    name: "Georgia",
    id: "Georgia",
  },
  {
    name: "Germany",
    id: "Alemania",
  },
  {
    name: "Ghana",
    id: "Ghana",
  },
  {
    name: "Gibraltar",
    id: "Gibraltar",
  },
  {
    name: "Greece",
    id: "Grecia",
  },
  {
    name: "Greenland",
    id: "Groenlandia",
  },
  {
    name: "Grenada",
    id: "Granada",
  },
  {
    name: "Guadeloupe",
    id: "Guadalupe",
  },
  {
    name: "Guam",
    id: "Guam",
  },
  {
    name: "Guatemala",
    id: "Guatemala",
  },
  {
    name: "Guinea",
    id: "Guinea",
  },
  {
    name: "Guinea-Bissau",
    id: "Guinea-Bissau",
  },
  {
    name: "Guyana",
    id: "Guyana",
  },
  {
    name: "Haiti",
    id: "Haití",
  },
  {
    name: "Holy See (Vatican City State)",
    id: "Vaticano",
  },
  {
    name: "Honduras",
    id: "Honduras",
  },
  {
    name: "Hong Kong",
    id: "Hong Kong",
  },
  {
    name: "Hungary",
    id: "Hungría",
  },
  {
    name: "Iceland",
    id: "Islandia",
  },
  {
    name: "India",
    id: "India",
  },
  {
    name: "Indonesia",
    id: "Indonesia",
  },
  {
    name: "Iran, Islamic Republic Of",
    id: "Irán",
  },
  {
    name: "Iraq",
    id: "Iraq",
  },
  {
    name: "Ireland",
    id: "Irlanda",
  },
  {
    name: "Israel",
    id: "Israel",
  },
  {
    name: "Italy",
    id: "Italia",
  },
  {
    name: "Jamaica",
    id: "Jamaica",
  },
  {
    name: "Japan",
    id: "Japón",
  },
  {
    name: "Jordan",
    id: "Jordania",
  },
  {
    name: "Kazakhstan",
    id: "Kazajstán",
  },
  {
    name: "Kenya",
    id: "Kenia",
  },
  {
    name: "Kiribati",
    id: "Kiribati",
  },
  {
    name: "Korea, Democratic People'S Republic of",
    id: "Corea del Norte",
  },
  {
    name: "Korea, Republic of",
    id: "Corea del Sur",
  },
  {
    name: "Kuwait",
    id: "Kuwait",
  },
  {
    name: "Kyrgyzstan",
    id: "Kirguistán",
  },
  {
    name: "Lao People's Democratic Republic",
    id: "Laos",
  },
  {
    name: "Latvia",
    id: "Letonia",
  },
  {
    name: "Lebanon",
    id: "Líbano",
  },
  {
    name: "Lesotho",
    id: "Lesotho",
  },
  {
    name: "Liberia",
    id: "Liberia",
  },
  {
    name: "Libyan Arab Jamahiriya",
    id: "Libia",
  },
  {
    name: "Liechtenstein",
    id: "Liechtenstein",
  },
  {
    name: "Lithuania",
    id: "Lituania",
  },
  {
    name: "Luxembourg",
    id: "Luxemburgo",
  },
  {
    name: "Macao",
    id: "Macao",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    id: "Macedonia",
  },
  {
    name: "Madagascar",
    id: "Madagascar",
  },
  {
    name: "Malawi",
    id: "Malawi",
  },
  {
    name: "Malaysia",
    id: "Malasia",
  },
  {
    name: "Maldives",
    id: "Maldivas",
  },
  {
    name: "Mali",
    id: "Mali",
  },
  {
    name: "Malta",
    id: "Malta",
  },
  {
    name: "Marshall Islands",
    id: "Islas Marshall",
  },
  {
    name: "Martinique",
    id: "Martinica",
  },
  {
    name: "Mauritania",
    id: "Mauritania",
  },
  {
    name: "Mauritius",
    id: "Mauricio",
  },
  {
    name: "Mayotte",
    id: "Mayotte",
  },
  {
    name: "Mexico",
    id: "México",
  },
  {
    name: "Micronesia, Federated States of",
    id: "Estados Federados de Micronesia",
  },
  {
    name: "Moldova, Republic of",
    id: "Moldavia",
  },
  {
    name: "Monaco",
    id: "Mónaco",
  },
  {
    name: "Mongolia",
    id: "Mongolia",
  },
  {
    name: "Montserrat",
    id: "Montserrat",
  },
  {
    name: "Morocco",
    id: "Marruecos",
  },
  {
    name: "Mozambique",
    id: "Mozambique",
  },
  {
    name: "Myanmar",
    id: "Myanmar",
  },
  {
    name: "Namibia",
    id: "Namibia",
  },
  {
    name: "Nauru",
    id: "Nauru",
  },
  {
    name: "Nepal",
    id: "Nepal",
  },
  {
    name: "Netherlands",
    id: "Holanda",
  },
  {
    name: "Netherlands Antilles",
    id: "Antillas Holandesas",
  },
  {
    name: "New Caledonia",
    id: "Nueva Caledonia",
  },
  {
    name: "New Zealand",
    id: "Nueva Zelanda",
  },
  {
    name: "Nicaragua",
    id: "Nicaragua",
  },
  {
    name: "Niger",
    id: "Niger",
  },
  {
    name: "Nigeria",
    id: "Nigeria",
  },
  {
    name: "Niue",
    id: "Niue",
  },
  {
    name: "Norfolk Island",
    id: "Islas Norfolk",
  },
  {
    name: "Northern Mariana Islands",
    id: "Islas Marianas del Norte",
  },
  {
    name: "Norway",
    id: "Noruega",
  },
  {
    name: "Oman",
    id: "Omán",
  },
  {
    name: "Pakistan",
    id: "Pakistán",
  },
  {
    name: "Palau",
    id: "Palau",
  },
  {
    name: "Palestinian Territory, Occupied",
    id: "Palestina",
  },
  {
    name: "Panama",
    id: "Panamá",
  },
  {
    name: "Papua New Guinea",
    id: "Papua Nueva Guinea",
  },
  {
    name: "Paraguay",
    id: "Paraguay",
  },
  {
    name: "Peru",
    id: "Perú",
  },
  {
    name: "Philippines",
    id: "Filipinas",
  },
  {
    name: "Pitcairn",
    id: "Pitcairn",
  },
  {
    name: "Poland",
    id: "Polonia",
  },
  {
    name: "Portugal",
    id: "Portugal",
  },
  {
    name: "Puerto Rico",
    id: "Puerto Rico",
  },
  {
    name: "Qatar",
    id: "Qatar",
  },
  {
    name: "Reunion",
    id: "Reunión",
  },
  {
    name: "Romania",
    id: "Rumanía",
  },
  {
    name: "Russian Federation",
    id: "Rusia",
  },
  {
    name: "Rwanda",
    id: "Ruanda",
  },
  {
    name: "Saint Helena",
    id: "Santa Helena",
  },
  {
    name: "Saint Kitts and Nevis",
    id: "San Kitts y Nevis",
  },
  {
    name: "Saint Lucia",
    id: "Santa Lucía",
  },
  {
    name: "Saint Vincent and the Grenadines",
    id: "San Vicente y Granadinas",
  },
  {
    name: "Samoa",
    id: "Samoa",
  },
  {
    name: "San Marino",
    id: "San Marino",
  },
  {
    name: "Sao Tome and Principe",
    id: "Santo Tomé y Príncipe",
  },
  {
    name: "Saudi Arabia",
    id: "Arabia Saudita",
  },
  {
    name: "Senegal",
    id: "Senegal",
  },
  {
    name: "Serbia and Montenegro",
    id: "Serbia",
  },
  {
    name: "Seychelles",
    id: "Seychelles",
  },
  {
    name: "Sierra Leone",
    id: "Sierra Leona",
  },
  {
    name: "Singapore",
    id: "Singapur",
  },
  {
    name: "Slovakia",
    id: "Eslovaquía",
  },
  {
    name: "Slovenia",
    id: "Eslovenia",
  },
  {
    name: "Solomon Islands",
    id: "Islas Salomón",
  },
  {
    name: "Somalia",
    id: "Somalia",
  },
  {
    name: "South Africa",
    id: "Sudáfrica",
  },
  {
    name: "Spain",
    id: "España",
  },
  {
    name: "Sri Lanka",
    id: "Sri Lanka",
  },
  {
    name: "Sudan",
    id: "Sudán",
  },
  {
    name: "Suriname",
    id: "Surinam",
  },
  {
    name: "Swaziland",
    id: "Swazilandia",
  },
  {
    name: "Sweden",
    id: "Suecia",
  },
  {
    name: "Switzerland",
    id: "Suiza",
  },
  {
    name: "Syrian Arab Republic",
    id: "Siria",
  },
  {
    name: "Taiwan, Province of China",
    id: "Taiwán",
  },
  {
    name: "Tajikistan",
    id: "Tadjikistan",
  },
  {
    name: "Tanzania, United Republic of",
    id: "Tanzania",
  },
  {
    name: "Thailand",
    id: "Tailandia",
  },
  {
    name: "Timor-Leste",
    id: "Timor Oriental",
  },
  {
    name: "Togo",
    id: "Togo",
  },
  {
    name: "Tokelau",
    id: "Tokelau",
  },
  {
    name: "Tonga",
    id: "Tonga",
  },
  {
    name: "Trinidad and Tobago",
    id: "Trinidad y Tobago",
  },
  {
    name: "Tunisia",
    id: "Túnez",
  },
  {
    name: "Turkey",
    id: "Turquía",
  },
  {
    name: "Turkmenistan",
    id: "Turkmenistan",
  },
  {
    name: "Turks and Caicos Islands",
    id: "Islas Turcas y Caicos",
  },
  {
    name: "Tuvalu",
    id: "Tuvalu",
  },
  {
    name: "Uganda",
    id: "Uganda",
  },
  {
    name: "Ukraine",
    id: "Ucrania",
  },
  {
    name: "United Arab Emirates",
    id: "Emiratos Árabes Unidos",
  },
  {
    name: "United Kingdom",
    id: "Reino Unido",
  },
  {
    name: "United States",
    id: "Estados Unidos",
  },
  {
    name: "Uruguay",
    id: "Uruguay",
  },
  {
    name: "Uzbekistan",
    id: "Uzbekistán",
  },
  {
    name: "Vanuatu",
    id: "Vanuatu",
  },
  {
    name: "Venezuela",
    id: "Venezuela",
  },
  {
    name: "Viet Nam",
    id: "Vietnam",
  },
  {
    name: "Virgin Islands, British",
    id: "Islas Vírgenes Británicas",
  },
  {
    name: "Virgin Islands, U.S.",
    id: "Islas Vírgenes Americanas",
  },
  {
    name: "Wallis and Futuna",
    id: "Wallis y Futuna",
  },
  {
    name: "Western Sahara",
    id: "Sáhara Occidental",
  },
  {
    name: "Yemen",
    id: "Yemen",
  },
  {
    name: "Zambia",
    id: "Zambia",
  },
  {
    name: "Zimbabwe",
    id: "Zimbabwe",
  },
];
