import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_OTP_SUCCESS,
  LOGIN_OTP_USER,
  RESENDING_OTP,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginOtpUser = (user, history) => {
  return {
    type: LOGIN_OTP_USER,
    payload: { user, history },
  };
};

export const loginIs2faSuccess = (otp_duration) => {
  return {
    type: LOGIN_OTP_SUCCESS,
    payload: otp_duration,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const resendOtp = ({user, callback}) => ({
  type: RESEND_OTP,
  payload: { user, callback },
});

export const resendOtpSuccess = (otp_duration) => ({
  type: RESEND_OTP_SUCCESS,
  payload: otp_duration,
});
