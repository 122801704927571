import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { postClientContract } from "../../../helpers/backend_helper";
import NibbleInput from "../../../components/Fields/NibbleInput";

const ContractProductModal = ({
  isOpen,
  productId,
  setIsContractModalOpen,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: null,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError("El valor debe ser numérico")
        .min(0.01, "El valor debe ser mayor a 0")
        .required("Requerido"),
    }),
    onSubmit: (formValues) => {
      saveContract(formValues);
    },
  });

  const saveContract = (formValues) => {
    setIsSaving(true);

    postClientContract({ ...formValues, productId })
      .then(responseAction)
      .finally(() => {
        setIsSaving(false);
      });
  };

  const responseAction = async (response) => {
    if (response.status) {
      validation.resetForm();
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsContractModalOpen(false);
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={{ maxWidth: "50%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            Contratación de producto de inversión
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.validateForm();
            validation.setTouched();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row d-flex justify-content-center">
              <NibbleInput
                md={6}
                label="Monto a invertir"
                fieldName="amount"
                fieldType="number"
                validation={validation}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={isSaving}>
              {isSaving && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleCloseModal}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ContractProductModal;
