import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Form, Input, FormFeedback, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginOtpUser, resendOtp } from "../../store/actions";
import dayjs from "dayjs";

const OtpForm = (props) => {
  const { validation, otp_duration } = props;

  const dispatch = useDispatch();

  const [timeLeft, setTimeLeft] = useState(otp_duration * 60);
  const [isTimeLeftActive, setIsTimeLeftActive] = useState(true);

  const { error, loading, is_resending_otp } = useSelector(
    (state) => state.Login
  );

  const formattedRemainingTime = dayjs()
    .startOf("D")
    .second(timeLeft)
    .format("mm:ss");

  const otpValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
      email: validation.values.email,
      password: validation.values.password,
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Requerido").label("Código"),
      email: Yup.string().required("Requerido").label("Email"),
    }),
    onSubmit: (values) => {
      dispatch(loginOtpUser(values, props.history));
    },
  });

  const onResetCode = () => {
    const callback = (otp_duration) => {
      setTimeLeft(otp_duration * 60);
      setIsTimeLeftActive(true);
    };

    dispatch(resendOtp({ user: otpValidation.values, callback }));
  };

  useEffect(() => {
    if (!setIsTimeLeftActive) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          setIsTimeLeftActive(false);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isTimeLeftActive]);

  return (
    <div className="auth-content my-auto">
      <div className="text-center">
        <img className="mb-5" src="/images/logo_sb.png" alt="" height="75" />
        <h5 className="mb-0">Verifica tu cuenta</h5>
        <p className="text-muted mt-2">
          Por favor verifica tu cuenta ingresando el código que enviamos a tu
          número telefónico.
        </p>        
      </div>
      {error ? <Alert color="danger">{error}</Alert> : null}
      {timeLeft ? (
        <Form
          className="custom-form mt-4 pt-2"
          onSubmit={(e) => {
            e.preventDefault();
            otpValidation.handleSubmit();
            return false;
          }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}
          <div className="mb-3">
            <Label className="form-label">Código</Label>
            <Input
              name="otp"
              className="form-control"
              type="text"
              onChange={otpValidation.handleChange}
              onBlur={otpValidation.handleBlur}
              value={otpValidation.values.otp || ""}
              invalid={
                otpValidation.touched.otp && otpValidation.errors.otp
                  ? true
                  : false
              }
              placeholder="Ingresa el codigo"
            />
            {otpValidation.touched.otp && otpValidation.errors.otp ? (
              <FormFeedback type="invalid">
                {otpValidation.errors.otp}
              </FormFeedback>
            ) : null}
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="mt-3 d-grid">
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Verificando..." : "Verificar"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <button
            className="btn btn-primary btn-sm"
            onClick={onResetCode}
            type="button"
            disabled={is_resending_otp}
          >
            {is_resending_otp ? "Reenviando código..." : "Reenviar código"}
          </button>
        </div>
      )}
      {timeLeft ? (
          <p className="text-muted">
            Tiempo restante para introducir el código <strong>{formattedRemainingTime}</strong>
          </p>
        ) : null}
    </div>
  );
};

export default withRouter(OtpForm);

OtpForm.propTypes = {
  history: PropTypes.object,
  validation: PropTypes.object,
};
