import React from "react";
import dayjs from "dayjs";

import { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ValidationTag from "../../ValidationTag/ValidationTag";
import { customerValidationType } from "../../../../constants/customerValidationType";
import ValidationResponseModal from "../Modals/ValidationResponseModal";

const inputFormat = "YYYY-MM-DD H:m";
const outputFormat = "DD/MM/YYYY";

const CustomerValidationsTab = ({ nubarium_history = [] }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [historyItem, setHistoryItem] = useState();

  const nubariumHistoryMapped = nubarium_history.map((item) => ({
    ...item,
    request_date: dayjs(item.request_date, inputFormat).format(outputFormat),
    validation_desc: customerValidationType[item?.validation_type] ?? "",
  }));

  const handleDetailClick = (item) => {
    setHistoryItem(item);
    setIsOpenModal(true);
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Validaciones del cliente</h5>
        </CardHeader>
        <CardBody>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fecha</th>
                <th scope="col">Tipo de validación</th>
                <th scope="col">Estatus</th>
                <th scope="col" className="text-center">
                  Ver detalle
                </th>
              </tr>
            </thead>
            <tbody>
              {nubariumHistoryMapped.map((item, index) => (
                <tr key={item.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{item?.request_date}</td>
                  <td>{item?.validation_desc}</td>
                  <td>
                    <ValidationTag
                      status={item?.status_response}
                      validation_type={item?.validation_type}
                    />
                  </td>
                  <td className="text-center">
                    <button
                      title="Detalle"
                      className="btn btn-primary btn-sm"
                      onClick={() => handleDetailClick(item)}
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
      {isOpenModal && (
        <ValidationResponseModal
          isOpenModal={isOpenModal}
          historyItem={historyItem}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </React.Fragment>
  );
};

export default CustomerValidationsTab;
