import React, { Component, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  TabPane,
  Form,
  Alert,
  Spinner,
  FormFeedback,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getActiveSurvey,
  postProspectCheckEmail,
  postProspectGenerals,
  postProspectRegistration,
} from "../../helpers/backend_helper";
import NibbleInput from "../../components/Fields/NibbleInput";
import registrationStorage from "./registrationStorage";
import Nubarium from "../../components/Nubarium/Nubarium";
import useSendPinEmail from "./hooks/useSendPinEmail";
import useSendPinSms from "./hooks/useSendPinSms";
import CpInput from "./components/CpInput";
import ColoniasModal from "./components/ColoniasModal";
import CpInputComercial from "./components/CpInputComercial";
import ColoniasCommercialModal from "./components/ColoniasCommercialModal";

import { PAISES } from "../../constants/countries";
import { NACIONALIDADES } from "../../constants/nationalities";

const RECURSOS_PROCEDENCIA = [
  { id: "1", name: "Ahorro" },
  { id: "2", name: "Préstamos" },
  { id: "3", name: "Herencia" },
  { id: "4", name: "Venta de activos" },
  { id: "5", name: "Premios" },
  { id: "6", name: "Sueldos" },
  { id: "7", name: "Otro" },
];

const SINO = [
  { id: true, name: "Si" },
  { id: false, name: "No" },
];
const PAISES_RESTRICTED = [{ id: "México", name: "México" }];

export const ESTADOS = [
  { id: "Aguascalientes", name: "Aguascalientes" },
  { id: "Baja California", name: "Baja California" },
  { id: "Baja California Sur", name: "Baja California Sur" },
  { id: "Campeche", name: "Campeche" },
  { id: "Coahuila", name: "Coahuila" },
  { id: "Colima", name: "Colima" },
  { id: "Chiapas", name: "Chiapas" },
  { id: "Chihuahua", name: "Chihuahua" },
  { id: "Ciudad de México", name: "Ciudad de México" },
  { id: "Durango", name: "Durango" },
  { id: "Guanajuato", name: "Guanajuato" },
  { id: "Guerrero", name: "Guerrero" },
  { id: "Hidalgo", name: "Hidalgo" },
  { id: "Jalisco", name: "Jalisco" },
  { id: "México", name: "México" },
  { id: "Michoacán", name: "Michoacán" },
  { id: "Morelos", name: "Morelos" },
  { id: "Nayarit", name: "Nayarit" },
  { id: "Nuevo León", name: "Nuevo León" },
  { id: "Oaxaca", name: "Oaxaca" },
  { id: "Puebla", name: "Puebla" },
  { id: "Querétaro", name: "Querétaro" },
  { id: "Quintana Roo", name: "Quintana Roo" },
  { id: "San Luis Potosí", name: "San Luis Potosí" },
  { id: "Sinaloa", name: "Sinaloa" },
  { id: "Sonora", name: "Sonora" },
  { id: "Tabasco", name: "Tabasco" },
  { id: "Tamaulipas", name: "Tamaulipas" },
  { id: "Tlaxcala", name: "Tlaxcala" },
  { id: "Veracruz", name: "Veracruz" },
  { id: "Yucatán", name: "Yucatán" },
  { id: "Zacatecas", name: "Zacatecas" },
];

const TIPOS_INGRESO = [
  { id: "Sueldos y salarios", name: "Sueldos y salarios" },
  { id: "Honorarios", name: "Honorarios" },
  { id: "Intereses y dividendos", name: "Intereses y dividendos" },
  { id: "Ingresos por arrendamientos", name: "Ingresos por arrendamientos" },
  { id: "Venta de bienes inmuebles", name: "Venta de bienes inmuebles" },
  { id: "Pensión o jubilaciones", name: "Pensión o jubilaciones" },
  { id: "Ganancia de premios", name: "Ganancia de premios" },
];

const REGIMEN_FISCAL = [
  { id: "601", name: "General de Ley Personas Morales" },
  { id: "603", name: "Personas Morales con Fines no Lucrativos" },
  { id: "605", name: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { id: "606", name: "Arrendamiento" },
  { id: "607", name: "Régimen de Enajenación o Adquisición de Bienes" },
  { id: "608", name: "Demás ingresos" },
  {
    id: "610",
    name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  { id: "611", name: "Ingresos por Dividendos (socios y accionistas)" },
  {
    id: "612",
    name: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { id: "614", name: "Ingresos por intereses" },
  { id: "615", name: "Régimen de los ingresos por obtención de premios" },
  { id: "616", name: "Sin obligaciones fiscales" },
  {
    id: "620",
    name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  { id: "621", name: "Incorporación Fiscal" },
  {
    id: "622",
    name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  { id: "623", name: "Opcional para Grupos de Sociedades" },
  { id: "624", name: "Coordinados" },
  {
    id: "625",
    name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  { id: "626", name: "Régimen Simplificado de Confianza" },
];

const RELATIONSHIP = [
  {
    id: "Padre",
    name: "Padre",
  },
  {
    id: "Madre",
    name: "Madre",
  },
  {
    id: "Hijo",
    name: "Hijo",
  },
  {
    id: "Hija",
    name: "Hija",
  },
  {
    id: "Otro",
    name: "Otro",
  },
];

const SECTORS_LIST = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y gas",
  "Construcción",
  "Industrias manufactureras",
  "Comercio",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Actividades legislativas, gubernamentales, de impartición de justicia",
];

const GIRO_MERCANTIL = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y gas.",
  "Construcción",
  "Industrias manufactureras",
  "Comercio",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Actividades legislativas, gubernamentales, de impartición de justicia",
];

const SECTORS = SECTORS_LIST.map((sector, index) => {
  return {
    id: index + 1,
    name: sector,
  };
});

const GIRO_MERCANTIL_LIST = GIRO_MERCANTIL.map((giro, index) => {
  return {
    id: giro,
    name: giro,
  };
});

const Beneficiary = {
  name: "",
  percent: "",
  relationship: "",
  relationship_other: "",
  birth: "",
  nation: "",
  nation_other: "",
  country: "",
  state: "",
  address: "",
};

const EconomicDependent = {
  name: "",
  relationship: "",
  relationship_other: "",
  birth: "",
  nation: "",
  nation_other: "",
  country: "",
  state: "",
  address: "",
};

const Society = {
  sociedad_name: "",
  sociedad_commercial: "",
  sociedad_nation: "",
  sociedad_nation_other: "",
  sociedad_apoderado: "",
  sociedad_tel: "",
  sociedad_percent: "",
  sociedad_counselor: null,
};

const extPersonValidation = Yup.string().when("peps_ext", {
  is: (value) => value === true,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

const relatedExtPersonValidation = Yup.string().when("peps_rel", {
  is: (value) => value === true,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

const ESTADO_CIVIL = [
  {
    id: 1,
    name: "Soltero",
  },
  {
    id: 2,
    name: "Casado",
  },
  {
    id: 3,
    name: "Viudo",
  },
  {
    id: 4,
    name: "Separado judicialmente",
  },
  {
    id: 5,
    name: "Divorciado",
  },
  {
    id: 6,
    name: "Unión libre",
  },
  {
    id: 7,
    name: "Otro",
  },
];
const GENEROS = [
  { id: "Femenino", name: "Femenino" },
  { id: "Masculino", name: "Masculino" },
];
const TIPOS_VIVIENDA = [
  { id: "Propia Hipotecada", name: "Propia Hipotecada" },
  { id: "Propia Pagada", name: "Propia Pagada" },
  { id: "Rentada", name: "Rentada" },
  { id: "Familiar", name: "Familiar" },
  { id: "Prestada", name: " Prestada" },
  { id: "Otro", name: " Otro" },
];

const commerAskValidation = Yup.string().when("commer_ask1", {
  is: (value) => value === false,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

const TabGenerales = ({
  tabId,
  toggleTabProgress,
  registrationData,
  setRegistrationData,
}) => {
  const [errors, setErrors] = useState("");
  const [survey, setSurvey] = useState(null);
  const [pending, setPending] = useState(null);
  const [isSavingComplements, setIsSavingComplements] = useState(false);

  const [isSavingProspect, setIsSavingProspect] = useState(false);
  const cancelTokenSource = useRef(null);

  const [modalId, setModalId] = useState(false);
  const [modalFace, setModalFace] = useState(false);

  const { handleValidatePinEntered, clearValidationOnChangeSMS } =
    useSendPinSms();

  const { handleSendPin, clearValidationOnChangeEmail } = useSendPinEmail();

  const fetchSurvey = async () => {
    setPending(true);
    const resp = await getActiveSurvey();
    setPending(false);
    setSurvey(resp.data);
    //console.log("resp", resp.data);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ti_name: registrationData.meta?.ti_name || "",
      ti_lname1: registrationData.meta?.ti_lname1 || "",
      ti_lname2: registrationData.meta?.ti_lname2 || "",
      ti_birth: registrationData.meta?.ti_birth || "",
      ti_rfc: registrationData.meta?.ti_rfc || "",
      ti_curp: registrationData.meta?.ti_curp || "",
      ti_landline: registrationData.meta?.ti_landline || "",
      ti_birth_country: registrationData.meta?.ti_birth_country || "",
      ti_birth_state: registrationData.meta?.ti_birth_state || "",
      ti_civil: registrationData.meta?.ti_civil || "",
      ti_civil_other: registrationData.meta?.ti_civil_other || "",
      ti_genere: registrationData.meta?.ti_genere || "",
      ti_genere: registrationData.meta?.ti_genere || "",
      fiscal_country: registrationData.meta?.fiscal_country || null,
      fiscal_state: registrationData.meta?.fiscal_state || null,
      fiscal_address1: registrationData.meta?.fiscal_address1 || "",
      fiscal_address2: registrationData.meta?.fiscal_address2 || "",
      fiscal_address3: registrationData.meta?.fiscal_address3 || "",
      fiscal_cross_streets: registrationData.meta?.fiscal_cross_streets || "",
      fiscal_ext: registrationData.meta?.fiscal_ext || "",
      fiscal_int: registrationData.meta?.fiscal_int || "",
      fiscal_zipcode: registrationData.meta?.fiscal_zipcode || "",
      fiscal_years: registrationData.meta?.fiscal_years || "",
      fiscal_type: registrationData.meta?.fiscal_type || "",
      fiscal_type_other: registrationData.meta?.fiscal_type_other || "",
      commer_ask1: registrationData.meta?.commer_ask1 || true,
      commer_country: registrationData.meta?.commer_country || null,
      commer_state: registrationData.meta?.commer_state || null,
      commer_address1: registrationData.meta?.commer_address1 || "",
      commer_address2: registrationData.meta?.commer_address2 || "",
      commer_address3: registrationData.meta?.commer_address3 || "",
      commer_ext: registrationData.meta?.commer_ext || "",
      commer_int: registrationData.meta?.commer_int || "",
      commer_zipcode: registrationData.meta?.commer_zipcode || "",
      //commer_years: "",
      // commer_type: "",

      ask1: "",
      ask2: null,
      ask3: null,
      ask4: null,
      facta_ask1: false,
      facta_ask2: false,
      facta_ask3: false,
      facta_tin: "",
      bank_person: "",
      bank_name: "",
      bank_account: "",
      bank_account_number: "",
      bank_branch: "",
      sociedades: false,
      /* sociedad_name: "",
      sociedad_commercial: "",
      sociedad_nation: "",
      sociedad_apoderado: "",
      sociedad_tel: "",
      sociedad_percent: "",
      sociedad_counselor: null, */
      ae_actividad: 1,
      ae_empresa: "",
      ae_puesto: "",
      ae_pagina: "",
      ae_tel: "",
      ae_sector: "",
      ae_giro: "",
      ae_profession: "",
      ae_type_income: "",
      ae_additional_income: "",
      ae_retired_company: "",
      ae_name_lender: "",
      ae_name_lender_answer: "",
      peps_peps: false,
      peps_ext: false,
      peps_ext_institucion: "",
      peps_rel: false,
      peps_rel_institucion: "",
      cuentas_carga: "",
      cuentas_procedencia: "",
      cuentas_procedencia_other: "",
      cuentas_intereses: "",
      movimientos_inicial: "",
      movimientos_depositos: "",
      movimientos_dep_prom: null,
      movimientos_retiros: "",
      movimientos_ret_prom: null,
      movimientos_otra_casa: null,
      movimientos_otro_instrumento: "",
      beneficiaries: [{ ...Beneficiary }],
      economic_dependents_ask: false,
      economic_dependents: [],
      societies: [],
      totalBeneficiariesPercent: 0,
      tax_information_regime: null,
      tax_information_country: null,
    },
    validationSchema: Yup.object({
      ti_name: Yup.string().nullable().required("Requerido"),
      ti_lname1: Yup.string().nullable().required("Requerido"),
      ti_lname2: Yup.string().nullable().required("Requerido"),
      ti_birth: Yup.string().nullable().required("Requerido"),
      ti_rfc: Yup.string()
        .nullable()
        .required("Requerido")
        .min(12, "El campo debe tener al menos 12 caracteres")
        .max(13, "El campo debe tener como máximo 13 caracteres"),
      ti_curp: Yup.string()
        .nullable()
        .required("Requerido")
        .length(18, "El campo debe tener 18 caracteres"),
      ti_landline: Yup.string().matches(
        /^\d{10}$/,
        "El número de teléfono debe tener 10 dígitos numéricos."
      ),
      ti_birth_country: Yup.string().nullable().required("Requerido"),
      ti_birth_state: Yup.string().nullable().required("Requerido"),
      ti_civil: Yup.string().nullable().required("Requerido"),
      ti_civil_other: Yup.string().when("ti_civil", {
        is: (value) => value == 7,
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      ti_genere: Yup.string().nullable().required("Requerido"),
      fiscal_country: Yup.string().nullable().required("Requerido"),
      fiscal_state: Yup.string().nullable().required("Requerido"),
      fiscal_address1: Yup.string().nullable().required("Requerido"),
      fiscal_address2: Yup.string().nullable().required("Requerido"),
      fiscal_address3: Yup.string().nullable().required("Requerido"),
      fiscal_cross_streets: Yup.string().nullable(),
      fiscal_ext: Yup.string().nullable().required("Requerido"),
      fiscal_int: Yup.string().nullable(),
      fiscal_zipcode: Yup.string()
        .matches(/^\d{5}$/, "El C.P. debe tener 5 dígitos numéricos.")
        .required("Requerido"),
      fiscal_years: Yup.string().nullable().required("Requerido"),
      fiscal_type: Yup.string().nullable().required("Requerido"),
      fiscal_type_other: Yup.string().when("fiscal_type", {
        is: (value) => value == "Otro",
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      commer_ask1: Yup.boolean().nullable().required("Requerido"),
      commer_country: commerAskValidation,
      commer_state: commerAskValidation,
      commer_address1: commerAskValidation,
      commer_address2: commerAskValidation,
      commer_address3: commerAskValidation,
      commer_ext: commerAskValidation,
      commer_int: Yup.string().nullable(),
      commer_zipcode: Yup.string().when("commer_ask1", {
        is: (value) => value == false,
        then: Yup.string()
          .nullable()
          .matches(/^\d{5}$/, "El C.P. debe tener 5 dígitos numéricos.")
          .required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),

      // commer_years: commerAskValidation,
      // commer_type: commerAskValidation,

      ask1: Yup.string().nullable().required("Requerido"),
      ask2: Yup.boolean().nullable().required("Requerido"),
      ask3: Yup.bool().nullable().required("Requerido"),
      ask4: Yup.bool().nullable().required("Requerido"),
      facta_ask1: Yup.bool().nullable().required("Requerido"),
      facta_ask2: Yup.bool().nullable().required("Requerido"),
      facta_ask3: Yup.bool().nullable().required("Requerido"),
      facta_tin: Yup.string().when("facta_ask3", {
        is: true,
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      bank_person: Yup.string().nullable().required("Requerido"),
      bank_name: Yup.string().nullable().required("Requerido"),
      bank_account: Yup.string()
        .nullable()
        .required("Requerido")
        .matches(/^\d{18}$/, "El campo debe contener 18 dígitos"),
      bank_account_number: Yup.string()
        .nullable()
        .required("Requerido")
        .matches(/^\d+$/, "El campo debe contener solo números"),
      bank_branch: Yup.string().nullable(),
      sociedades: Yup.boolean().nullable().required("Requerido"),
      /* sociedad_name: sociedadesValidation,
      sociedad_commercial: sociedadesValidation,
      sociedad_nation: sociedadesValidation,
      sociedad_apoderado: sociedadesValidation,
      sociedad_tel: sociedadesValidation,
      sociedad_percent: Yup.number().when("sociedades", {
        is: true,
        then: Yup.number().min(1, "Debe ser mayor a 0").required("Requerido"),
        otherwise: Yup.number().nullable(),
      }),
      sociedad_counselor: Yup.boolean().when("sociedades", {
        is: true,
        then: Yup.boolean().nullable().required("Requerido"),
        otherwise: Yup.boolean().nullable(),
      }), */
      ae_actividad: Yup.string().nullable().required("Requerido"),
      ae_empresa: Yup.string()
        .nullable()
        .when("ae_actividad", {
          is: "1",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      ae_puesto: Yup.string()
        .nullable()
        .when("ae_actividad", {
          is: "1",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      ae_pagina: Yup.string().nullable().when("ae_actividad", {
        is: "1",
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable(),
      }),
      ae_tel: Yup.string()
        .nullable()
        .when("ae_actividad", {
          is: "1",
          then: Yup.string()
            .matches(
              /^\d{10}$/,
              "El número de teléfono debe tener 10 dígitos numéricos."
            )
            .nullable()
            .required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      ae_sector: Yup.string()
        .nullable()
        .when("ae_actividad", {
          is: "1",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      ae_giro: Yup.string()
        .nullable()
        .when("ae_actividad", {
          is: "1",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      ae_profession: Yup.string().nullable().required("Requerido"),
      ae_type_income: Yup.string().nullable().required("Requerido"),
      ae_additional_income: Yup.string().nullable(),
      ae_retired_company: Yup.string().nullable(),
      ae_name_lender: Yup.string().nullable().required("Requerido"),
      ae_name_lender_answer: Yup.string()
        .nullable()
        .when("ae_name_lender", {
          is: "Invierto recursos de un tercero en su representación",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      peps_peps: Yup.boolean().nullable().required("Requerido"),
      peps_ext: Yup.boolean().nullable().required("Requerido"),
      peps_ext_institucion: extPersonValidation,
      peps_rel: Yup.boolean().nullable().required("Requerido"),
      peps_rel_institucion: relatedExtPersonValidation,
      cuentas_carga: Yup.string()
        .nullable()
        .required("Requerido")
        .test({
          message: "El valor debe ser 100",
          test: function (value) {
            return value == 100;
          },
        }),
      cuentas_procedencia: Yup.string().nullable().required("Requerido"),
      cuentas_procedencia_other: Yup.string()
        .nullable()
        .when("cuentas_procedencia", {
          is: "7",
          then: Yup.string().nullable().required("Requerido"),
          otherwise: Yup.string().nullable(),
        }),
      cuentas_intereses: Yup.number()
        //.min(1, "Porcentaje mínimo 1")
        //.max(100, "Porcentaje máximo 100")
        .required("Requerido")
        .test({
          message: "El valor debe ser 100",
          test: function (value) {
            return value == 100;
          },
        }),
      movimientos_inicial: Yup.number()
        .min(5000, "Monto mínimo 5,000")
        .max(100000, "Monto máximo 100,000")
        .required("Requerido"),
      movimientos_depositos: Yup.number()
        .min(1, "Debe ser mayor a 0")
        .required("Requerido"),
      movimientos_dep_prom: Yup.string().nullable().required("Requerido"),
      movimientos_retiros: Yup.number()
        .min(1, "Debe ser mayor a 0")
        .required("Requerido"),
      movimientos_ret_prom: Yup.string().nullable().required("Requerido"),
      movimientos_otra_casa: Yup.boolean().nullable().required("Requerido"),
      movimientos_otro_instrumento: Yup.string().when("movimientos_otra_casa", {
        is: true,
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      beneficiaries: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Requerido"),
          percent: Yup.number()
            .min(1, "Porcentaje mínimo 1")
            .max(100, "Porcentaje máximo 100")
            .required("Requerido"),
          relationship: Yup.string().required("Requerido"),
          relationship_other: Yup.string().when("relationship", {
            is: "Otro",
            then: () => Yup.string().required("Requerido"),
            otherwise: () => Yup.string().nullable(),
          }),
          birth: Yup.string().required("Requerido"),
          nation: Yup.string().required("Requerido"),
          nation_other: Yup.string().when("nation", {
            is: "Otra",
            then: () => Yup.string().required("Requerido"),
            otherwise: () => Yup.string().nullable(),
          }),
          country: Yup.string().nullable().required("Requerido"),
          /*
          state_tmp: Yup.string().when("country", {
            is: (country) => country === "México",
            then: Yup.string().nullable().required("Requerido"),
            otherwise: Yup.string().nullable(),
          }),
          */
          state: Yup.string().when("country", {
            is: (country) => country !== "México",
            then: Yup.string().nullable().required("Requerido"),
            otherwise: Yup.string().nullable(),
          }),
          address: Yup.string().required("Requerido"),
        })
      ),
      economic_dependents_ask: Yup.boolean().nullable(),
      economic_dependents: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Requerido"),
          relationship: Yup.string().required("Requerido"),
          relationship_other: Yup.string().when("relationship", {
            is: "Otro",
            then: () => Yup.string().required("Requerido"),
            otherwise: () => Yup.string().nullable(),
          }),
          birth: Yup.string().required("Requerido"),
          nation: Yup.string().required("Requerido"),
          nation_other: Yup.string().when("nation", {
            is: "Otra",
            then: () => Yup.string().required("Requerido"),
            otherwise: () => Yup.string().nullable(),
          }),
          country: Yup.string().required("Requerido"),
          state: Yup.string().required("Requerido"),
          address: Yup.string().required("Requerido"),
        })
      ),
      societies: Yup.array().of(
        Yup.object().shape({
          sociedad_name: Yup.string().required("Requerido"),
          sociedad_commercial: Yup.string().required("Requerido"),
          sociedad_nation: Yup.string().required("Requerido"),
          sociedad_nation_other: Yup.string().when("sociedad_nation", {
            is: "Otra",
            then: () => Yup.string().required("Requerido"),
            otherwise: () => Yup.string().nullable(),
          }),
          sociedad_apoderado: Yup.string().required("Requerido"),
          sociedad_tel: Yup.string()
            .matches(
              /^\d{10}$/,
              "El número de teléfono debe tener 10 dígitos numéricos."
            )
            .required("Requerido"),
          sociedad_percent: Yup.number()
            .min(1, "Debe ser mayor a 0")
            .max(100, "Debe ser menor o igual a 100")
            .required("Requerido"),
          sociedad_counselor: Yup.boolean().nullable().required("Requerido"),
        })
      ),
      totalBeneficiariesPercent: Yup.number().test(
        "total-percent",
        "La suma de los porcentajes debe ser 100",
        function () {
          const beneficiaries = this.parent.beneficiaries;

          const totalPercent = beneficiaries.reduce(
            (total, beneficiary) => total + Number(beneficiary.percent),
            0
          );

          return totalPercent === 100;
        }
      ),
      tax_information_regime: Yup.string().nullable().required("Requerido"),
      tax_information_country: Yup.string().nullable().required("Requerido"),
    }),
    onSubmit: (values) => {
      console.log({ values });
      // dispatch(loginUser(values, props.history));
      save(values);
    },
  });

  console.log({
    generalesValues: validation.values,
    generalesErrors: validation.errors,
  });

  const email = validation.values.email;
  const emailErr = validation.errors.email;

  const phone = validation.values.ti_phone;
  const phoneErr = validation.errors.ti_phone;
  const isValidPhone = phone && !phoneErr;

  const save = async (formValues) => {
    setErrors("");
    setIsSavingProspect(true);

    const data = {
      ...formValues,
      prospect_id: registrationData.prospect_id,
    };

    console.log({ data });
    const resp = await postProspectGenerals(data);

    responseAction(resp);
  };

  const responseAction = (response) => {
    if (response.status) {
      toggleTabProgress(3);
      //setRegistrationData(prospectData);
      //registrationStorage.saveProspect(prospectData);
    } else {
      setErrors(response?.message);
    }
    setIsSavingProspect(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!email || emailErr) return;

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Se canceló la petición");
    }
    cancelTokenSource.current = axios.CancelToken.source();

    async function checkEmail() {
      const resp = await postProspectCheckEmail(
        { email },
        {
          cancelToken: cancelTokenSource.current.token,
        }
      );
      if (resp.exist) {
        validation.setErrors({
          ...validation.errors,
          email: "El email ya existe en el sistema",
        });
      }
    }
    checkEmail();
  }, [email, emailErr]);

  /*
  useEffect(() => {
    clearValidationOnChangeEmail(validation);
  }, [validation.values.email]);

  useEffect(() => {
    clearValidationOnChangeSMS(validation);
  }, [validation.values.ti_phone]);
*/

  const toggleId = () => {
    setModalId(!modalId);
  };
  const toggleFace = () => {
    setModalFace(!modalFace);
  };

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <div className="text-center mb-4">
          <h5>Apertura de cuenta cliente</h5>
          <p className="card-title-desc">
            Registra tus datos personales para crear tu cuenta con Smart Brokers
            o si ya te has registrado puedes ingresar{" "}
            <Link to="/login">aquí</Link>
          </p>
        </div>
        {errors && <Alert color="danger">{errors}</Alert>}
        <Row>
          <Col md={12} className="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="d-flex flex-column ">
                <div className="auth-content my-auto">
                  <div className="row mt-5">
                    <h5>Datos generales</h5>
                    <NibbleInput
                      md={6}
                      label="¿Cómo supo de Smart Brokers?"
                      fieldName="ask1"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="¿Es empleado de Smart Brokers?"
                      fieldName="ask2"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    <NibbleInput
                      md={6}
                      label="¿Es familiar de empleado de Smart Brokers?"
                      fieldName="ask3"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    <NibbleInput
                      md={6}
                      fieldName="ask4"
                      label="¿Es empleado de algún intermediario financiero?"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                  </div>
                  <div className="row mt-5">
                    <h5>Titular</h5>

                    <NibbleInput
                      md={6}
                      label="Nombre:"
                      fieldName="ti_name"
                      fieldType="text"
                      validation={validation}
                    />
                    <Col md={6} />
                    <NibbleInput
                      md={6}
                      label="Apellido paterno:"
                      fieldName="ti_lname1"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Apellido materno:"
                      fieldName="ti_lname2"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Fecha de nacimiento:"
                      fieldName="ti_birth"
                      fieldType="date"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="RFC:"
                      fieldName="ti_rfc"
                      fieldType="text"
                      validation={validation}
                      onChange={(e) => {
                        validation.setFieldValue(
                          "ti_rfc",
                          e.target.value?.toUpperCase()
                        );
                      }}
                    />
                    <NibbleInput
                      md={6}
                      label="CURP:"
                      fieldName="ti_curp"
                      fieldType="text"
                      validation={validation}
                      onChange={(e) => {
                        validation.setFieldValue(
                          "ti_curp",
                          e.target.value?.toUpperCase()
                        );
                      }}
                    />
                    {/* <NibbleInput
                      md={6}
                      label="Teléfono:"
                      fieldName="ti_phone"
                      fieldType="text"
                      maxLength={10}
                      validation={validation}
                    /> */}
                    {/* <NibbleInput
                      md={6}
                      label="Teléfono:"
                      fieldName="ti_phone"
                      fieldType="mask"
                      maxLength={10}
                      validation={validation}
                      mask="(999) 999-9999"
                      unmask={/\D/g}
                    /> */}
                    <NibbleInput
                      md={6}
                      label="Teléfono fijo:"
                      fieldName="ti_landline"
                      fieldType="mask"
                      maxLength={10}
                      validation={validation}
                      mask="(999) 999-9999"
                      unmask={/\D/g}
                    />
                    <NibbleInput
                      md={6}
                      label="País de nacimiento:"
                      fieldName="ti_birth_country"
                      fieldType="select"
                      selectItems={PAISES}
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Estado de nacimiento:"
                      fieldName="ti_birth_state"
                      fieldType="select"
                      selectItems={ESTADOS}
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Estado civil:"
                      fieldName="ti_civil"
                      fieldType="select"
                      selectItems={ESTADO_CIVIL}
                      validation={validation}
                    />
                    {validation.values.ti_civil == 7 ? (
                      <NibbleInput
                        md={6}
                        label="Estado civil:"
                        fieldName="ti_civil_other"
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}
                    <NibbleInput
                      md={6}
                      label="Género:"
                      fieldName="ti_genere"
                      fieldType="select"
                      selectItems={GENEROS}
                      validation={validation}
                    />
                  </div>

                  <div className="row mt-5">
                    <h5>Domicilio Fiscal</h5>
                    <CpInput validation={validation} />

                    <NibbleInput
                      md={6}
                      label="País:"
                      fieldName="fiscal_country"
                      fieldType="select"
                      selectItems={PAISES}
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Estado:"
                      fieldName="fiscal_state"
                      fieldType="select"
                      selectItems={ESTADOS}
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Calle:"
                      fieldName="fiscal_address1"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Entre que calles:"
                      fieldName="fiscal_cross_streets"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Colonia:"
                      fieldName="fiscal_address2"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Municipio:"
                      fieldName="fiscal_address3"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Num ext:"
                      fieldName="fiscal_ext"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Num int:"
                      fieldName="fiscal_int"
                      fieldType="text"
                      validation={validation}
                    />

                    {/* <NibbleInput
                      md={6}
                      label="CP:"
                      fieldName="fiscal_zipcode"
                      fieldType="text"
                      validation={validation}
                    /> */}

                    <NibbleInput
                      md={6}
                      label="Años de residencia:"
                      fieldName="fiscal_years"
                      fieldType="number"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Tipo de vivienda:"
                      fieldName="fiscal_type"
                      fieldType="select"
                      selectItems={TIPOS_VIVIENDA}
                      validation={validation}
                    />
                    {validation.values.fiscal_type === "Otro" ? (
                      <NibbleInput
                        md={6}
                        label="Tipo de vivienda:"
                        fieldName="fiscal_type_other"
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}
                  </div>

                  <div className="row mt-5">
                    <h5>Domicilio Actual/Comercial</h5>
                    <NibbleInput
                      md={6}
                      label="¿Es el mismo que domicilio fiscal?"
                      fieldName="commer_ask1"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                      selectIsClearable={false}
                    />
                    {!validation.values.commer_ask1 && (
                      <>
                        <CpInputComercial validation={validation} />
                        <NibbleInput
                          md={6}
                          label="País:"
                          fieldName="commer_country"
                          fieldType="select"
                          selectItems={[{ id: "México", name: "México" }]}
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Estado:"
                          fieldName="commer_state"
                          fieldType="select"
                          selectItems={ESTADOS}
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Calle:"
                          fieldName="commer_address1"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Colonia:"
                          fieldName="commer_address2"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Municipio:"
                          fieldName="commer_address3"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Num ext:"
                          fieldName="commer_ext"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Num int:"
                          fieldName="commer_int"
                          fieldType="text"
                          validation={validation}
                        />
                      </>
                    )}
                  </div>

                  <div className="row mt-5">
                    <h5>FACTA</h5>
                    <NibbleInput
                      md={6}
                      label="¿Cuenta con nacionalidad estadounidense?"
                      fieldName="facta_ask1"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    <NibbleInput
                      md={6}
                      label="¿Es residente en los Estados Unidos de América?"
                      fieldName="facta_ask2"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    <NibbleInput
                      md={6}
                      label="¿Es contribuyente o residente fiscal de los Estados Unidos de América?"
                      fieldName="facta_ask3"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    {validation.values.facta_ask3 ? (
                      <NibbleInput
                        md={6}
                        label="TIN (Tax Identification Number)"
                        fieldName="facta_tin"
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}
                  </div>
                  <div className="row mt-5">
                    <h5>Información fiscal</h5>
                    <NibbleInput
                      md={6}
                      label="Régimen Fiscal"
                      fieldName="tax_information_regime"
                      fieldType="select"
                      selectItems={REGIMEN_FISCAL}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    <NibbleInput
                      md={6}
                      label="Pais de residencia"
                      fieldName="tax_information_country"
                      fieldType="select"
                      selectItems={PAISES_RESTRICTED}
                      validation={validation}
                    />
                  </div>
                  <div className="row mt-5">
                    <h5>Datos bancarios para liquidación</h5>
                    <NibbleInput
                      md={6}
                      label="Titular de la cuenta"
                      fieldName="bank_person"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Banco"
                      fieldName="bank_name"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Cuenta CLABE"
                      fieldName="bank_account"
                      fieldType="text"
                      validation={validation}
                      maxLength={18}
                    />
                    <NibbleInput
                      md={6}
                      label="Cuenta bancaria"
                      fieldName="bank_account_number"
                      fieldType="text"
                      validation={validation}
                      maxLength={18}
                    />
                    <NibbleInput
                      md={6}
                      label="Sucursal"
                      fieldName="bank_branch"
                      fieldType="text"
                      validation={validation}
                      maxLength={18}
                    />
                  </div>
                  <div className="row mt-5">
                    <h5>Vinculo Patrimonial</h5>
                    <NibbleInput
                      md={12}
                      label="Sociedades y asociaciones con las que tiene relación o vínculos patrimoniales, o en su caso ocupe algún cargo de consejero:"
                      fieldName="sociedades"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                      onChange={(option) => {
                        if (!option?.id) {
                          validation.setFieldValue("societies", []);
                          return;
                        }

                        validation.setFieldValue("societies", [{ ...Society }]);
                      }}
                    />
                    {validation.values.societies.map((society, index) => (
                      <div key={index} className={`row mt-4`}>
                        <div
                          className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                            validation.values.societies.length === 1 && "d-none"
                          }`}
                        >
                          <p className="p-2 border-radius-4 mb-0">
                            Sociedad {index + 1}
                          </p>
                          <button
                            disabled={isSavingComplements}
                            className="btn btn-primary btn-sm"
                            type="button"
                            hidden={index === 0}
                            onClick={(e) => {
                              const beneficiariesFiltered =
                                validation.values.societies.filter(
                                  (_, i) => i !== index
                                );

                              validation.setFieldValue(
                                "societies",
                                beneficiariesFiltered
                              );
                            }}
                          >
                            <i className="fas fa-trash"></i> Remover
                          </button>
                        </div>
                        <NibbleInput
                          md={6}
                          label="Denominación social:"
                          fieldName={`societies[${index}].sociedad_name`}
                          fieldType="text"
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Giro mercantil:"
                          fieldName={`societies[${index}].sociedad_commercial`}
                          fieldType="select"
                          selectItems={GIRO_MERCANTIL_LIST}
                          selectOptionValue={(e) => e.id}
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Nacionalidad:"
                          fieldName={`societies[${index}].sociedad_nation`}
                          fieldType="select"
                          selectItems={NACIONALIDADES.map((item) => ({
                            id: item,
                            name: item,
                          }))}
                          validation={validation}
                        />
                        {validation.values.societies[index].sociedad_nation ===
                        "Otra" ? (
                          <NibbleInput
                            md={6}
                            label="Otra nacionalidad:"
                            fieldName={`societies[${index}].sociedad_nation_other`}
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}
                        <NibbleInput
                          md={6}
                          label="Nombre del Gerente o apoderado Legal:"
                          fieldName={`societies[${index}].sociedad_apoderado`}
                          fieldType="text"
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Teléfono:"
                          fieldName={`societies[${index}].sociedad_tel`}
                          fieldType="text"
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Porcentaje de vínculo patrimonial:"
                          fieldName={`societies[${index}].sociedad_percent`}
                          fieldType="number"
                          validation={validation}
                          min={1}
                        />
                        <NibbleInput
                          md={6}
                          label="Es consejero:"
                          fieldName={`societies[${index}].sociedad_counselor`}
                          fieldType="select"
                          selectItems={SINO}
                          validation={validation}
                          selectOptionValue={(e) => e.id}
                        />
                      </div>
                    ))}
                  </div>

                  {validation.values.sociedades && (
                    <Row className="mb-4">
                      <Col md={12} className="text-center pt-2 mt-3">
                        <span
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={(e) => {
                            validation.setFieldValue("societies", [
                              ...validation.values.societies,
                              { ...Society },
                            ]);
                          }}
                        >
                          <i className="fas fa-plus"></i> Agregar sociedad
                        </span>
                      </Col>
                    </Row>
                  )}

                  <div className="row mt-5">
                    <h5>Datos de actividad económica</h5>

                    <NibbleInput
                      md={6}
                      label="Actividad:"
                      fieldName="ae_actividad"
                      fieldType="select"
                      selectItems={[
                        { id: 1, name: "Empleado" },
                        { id: 2, name: "Prestador de servicios" },
                        { id: 3, name: "Jubilado" },
                      ]}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    {validation.values.ae_actividad === 1 && (
                      <>
                        <NibbleInput
                          md={6}
                          label="Nombre de la empresa donde presta sus servicios:"
                          fieldName="ae_empresa"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Puesto específico:"
                          fieldName="ae_puesto"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Actividad/giro de la empresa donde presta sus servicios:"
                          fieldName="ae_giro"
                          fieldType="text"
                          validation={validation}
                        />
                      </>
                    )}

                    {[1, 2].includes(validation.values.ae_actividad) && (
                      <>
                        <NibbleInput
                          md={6}
                          label="Página de internet:"
                          fieldName="ae_pagina"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Teléfono de oficina:"
                          fieldName="ae_tel"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Sector de la empresa donde presta sus servicios:"
                          fieldName="ae_sector"
                          fieldType="select"
                          selectItems={SECTORS}
                          validation={validation}
                          selectOptionValue={(e) => e.id}
                        />
                      </>
                    )}

                    {[1, 2, 3].includes(validation.values.ae_actividad) && (
                      <>
                        <NibbleInput
                          md={6}
                          label="Profesión:"
                          fieldName="ae_profession"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="Tipo de ingreso:"
                          fieldName="ae_type_income"
                          fieldType="select"
                          selectItems={TIPOS_INGRESO}
                          validation={validation}
                          selectOptionValue={(e) => e.id}
                        />

                        <NibbleInput
                          md={6}
                          label="Indique de donde son sus ingresos adicionales:"
                          fieldName="ae_additional_income"
                          fieldType="text"
                          validation={validation}
                        />

                        <NibbleInput
                          md={6}
                          label="¿De que empresa se jubiló?"
                          fieldName="ae_retired_company"
                          fieldType="text"
                          validation={validation}
                        />

                        {/* <NibbleInput
                          md={6}
                          label="Indique si actúa a nombre de un tercero o los recursos a invertir son de un tercero:"
                          fieldName="ae_name_lender"
                          fieldType="text"
                          validation={validation}
                        /> */}

                        <NibbleInput
                          md={6}
                          label="Indique si actúa a nombre de un tercero o los recursos a invertir son de un tercero:"
                          fieldName="ae_name_lender"
                          fieldType="select"
                          selectItems={[
                            {
                              id: "Solo recursos propios, no represento a nadie",
                              name: "Solo recursos propios, no represento a nadie",
                            },
                            {
                              id: "Invierto recursos de un tercero en su representación",
                              name: "Invierto recursos de un tercero en su representación",
                            },
                          ]}
                          validation={validation}
                          selectOptionValue={(e) => e.id}
                        />
                        {validation.values.ae_name_lender ===
                        "Invierto recursos de un tercero en su representación" ? (
                          <NibbleInput
                            md={6}
                            label="Indique el nombre del tercero:"
                            fieldName="ae_name_lender_answer"
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}
                      </>
                    )}
                  </div>

                  <div className="row mt-5">
                    <h5>PEPS</h5>
                    <NibbleInput
                      md={12}
                      label="¿Desempeña o ha desempeñado en la administración vigente o en la anterior algún cargo público?"
                      fieldName="peps_peps"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    <NibbleInput
                      md={6}
                      label="Si usted es extranjero, ¿Desempeña o ha desempeñado en la administración vigente o en la anterior algún cargo público?"
                      fieldName="peps_ext"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    {validation.values.peps_ext && (
                      <NibbleInput
                        md={6}
                        label="Institución o dependencia donde la persona presta (prestó) sus servicios:"
                        fieldName="peps_ext_institucion"
                        fieldType="text"
                        validation={validation}
                      />
                    )}

                    <NibbleInput
                      md={6}
                      label="Si usted está relacionado con una persona nacional o extrajera que desempeñe o haya desempañado algún cargo público en algún país extranjero:"
                      fieldName="peps_rel"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    {validation.values.peps_rel && (
                      <NibbleInput
                        md={6}
                        label="Institución o dependencia donde la persona presta (prestó) sus servicios:"
                        fieldName="peps_rel_institucion"
                        fieldType="text"
                        validation={validation}
                      />
                    )}
                  </div>

                  <div className="row mt-5">
                    <h5>Cuentas discrecionales</h5>

                    <NibbleInput
                      md={6}
                      label="Acumulación/carga fiscal de intereses:"
                      fieldName="cuentas_carga"
                      fieldType="number"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Procedencia de los recursos con los que apertura la cuenta:"
                      fieldName="cuentas_procedencia"
                      fieldType="select"
                      selectItems={RECURSOS_PROCEDENCIA}
                      validation={validation}
                    />

                    {validation.values.cuentas_procedencia === "7" && (
                      <NibbleInput
                        md={6}
                        label="Procedencia de los recursos con los que apertura la cuenta detalle:"
                        fieldName="cuentas_procedencia_other"
                        fieldType="text"
                        validation={validation}
                      />
                    )}

                    <NibbleInput
                      md={6}
                      label="Carga de intereses:"
                      fieldName="cuentas_intereses"
                      fieldType="number"
                      validation={validation}
                    />
                  </div>

                  <div className="row mt-5">
                    <h5>Movimientos de la cuenta</h5>

                    <NibbleInput
                      md={12}
                      label="Depósito inicial (Monto):"
                      fieldName="movimientos_inicial"
                      fieldType="number"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Número de depósitos aproximados al mes:"
                      fieldName="movimientos_depositos"
                      fieldType="number"
                      validation={validation}
                      min={1}
                    />
                    <NibbleInput
                      md={6}
                      label="Monto mensual promedio de los depósitos:"
                      fieldName="movimientos_dep_prom"
                      fieldType="select"
                      selectItems={[
                        { id: "$0 - $50,000", name: "$0-$50,000" },
                        {
                          id: "$50,000 - $250,000",
                          name: "$50,000 - $250,000",
                        },
                        {
                          id: "$250,000 - $1,250,000",
                          name: "$250,000 - $1,250,000",
                        },
                        {
                          id: "Más de $1,250,000",
                          name: "Más de $1,250,000",
                        },
                      ]}
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Número de retiros aproximados al mes:"
                      fieldName="movimientos_retiros"
                      fieldType="number"
                      validation={validation}
                      min={1}
                    />
                    <NibbleInput
                      md={6}
                      label="Monto mensual promedio de los retiros:"
                      fieldName="movimientos_ret_prom"
                      fieldType="select"
                      selectItems={[
                        { id: "$0 - $50,000", name: "$0-$50,000" },
                        {
                          id: "$50,000 - $250,000",
                          name: "$50,000 - $250,000",
                        },
                        {
                          id: "$250,000 - $1,250,000",
                          name: "$250,000 - $1,250,000",
                        },
                        {
                          id: "Más de $1,250,000",
                          name: "Más de $1,250,000",
                        },
                      ]}
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="¿Maneja contratos con otra casa de bolsa o instituación de inversión? ¿Con cual?:"
                      fieldName="movimientos_otra_casa"
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    {validation.values.movimientos_otra_casa ? (
                      <NibbleInput
                        md={6}
                        label="¿En qué instrumento ha invertido en el mercado financiero?"
                        fieldName="movimientos_otro_instrumento"
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}
                  </div>

                  <div className="row mt-5">
                    <h5>Beneficiarios</h5>

                    {validation.values.beneficiaries.map((nameObj, index) => (
                      <div
                        key={index}
                        className={`row ${index !== 0 && "mt-4"}`}
                      >
                        <div
                          className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                            validation.values.beneficiaries.length === 1 &&
                            "d-none"
                          }`}
                        >
                          <p className="p-2 border-radius-4 mb-0">
                            Beneficiario {index + 1}
                          </p>
                          <button
                            disabled={isSavingComplements}
                            className="btn btn-primary btn-sm"
                            type="button"
                            hidden={index === 0}
                            onClick={(e) => {
                              const beneficiariesFiltered =
                                validation.values.beneficiaries.filter(
                                  (_, i) => i !== index
                                );
                              validation.setFieldValue(
                                "beneficiaries",
                                beneficiariesFiltered
                              );
                            }}
                          >
                            <i className="fas fa-trash"></i> Remover
                          </button>
                        </div>
                        <NibbleInput
                          md={6}
                          label="Nombre Completo:"
                          fieldName={`beneficiaries[${index}].name`}
                          fieldType="text"
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Porcentaje:"
                          fieldName={`beneficiaries[${index}].percent`}
                          fieldType="number"
                          validation={validation}
                          min={1}
                        />
                        <NibbleInput
                          md={6}
                          label="Parentesco:"
                          fieldName={`beneficiaries[${index}].relationship`}
                          fieldType="select"
                          selectItems={RELATIONSHIP}
                          validation={validation}
                          selectOptionValue={(e) => e.id}
                        />
                        {validation.values.beneficiaries[index].relationship ===
                        "Otro" ? (
                          <NibbleInput
                            md={6}
                            label="Otro parentesco:"
                            fieldName={`beneficiaries[${index}].relationship_other`}
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}
                        <NibbleInput
                          md={6}
                          label="Fecha de nacimiento:"
                          fieldName={`beneficiaries[${index}].birth`}
                          fieldType="date"
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Nacionalidad:"
                          fieldName={`beneficiaries[${index}].nation`}
                          fieldType="select"
                          selectItems={NACIONALIDADES.map((item) => ({
                            id: item,
                            name: item,
                          }))}
                          validation={validation}
                        />
                        {validation.values.beneficiaries[index].nation ===
                        "Otra" ? (
                          <NibbleInput
                            md={6}
                            label="Otra nacionalidad:"
                            fieldName={`beneficiaries[${index}].nation_other`}
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}
                        <NibbleInput
                          md={6}
                          label="País de nacimiento:"
                          fieldName={`beneficiaries[${index}].country`}
                          fieldType="select"
                          selectItems={PAISES}
                          validation={validation}
                          selectOptionLabel={(option) => `${option.id}`}
                          selectOptionValue={(option) => `${option.id}`}
                        />
                        {validation.values.beneficiaries[index].country ===
                          "México" && (
                          <NibbleInput
                            md={6}
                            label="Estado de nacimiento:"
                            fieldName={`beneficiaries[${index}].state`}
                            fieldType="select"
                            selectItems={ESTADOS}
                            validation={validation}
                            selectOptionLabel={(option) => `${option.id}`}
                            selectOptionValue={(option) => `${option.id}`}
                          />
                        )}

                        {validation.values.beneficiaries[index].country !==
                          "México" && (
                          <NibbleInput
                            md={6}
                            label="Estado de nacimiento:"
                            fieldName={`beneficiaries[${index}].state`}
                            fieldType="text"
                            validation={validation}
                          />
                        )}
                        <NibbleInput
                          md={6}
                          label="Dirección:"
                          fieldName={`beneficiaries[${index}].address`}
                          fieldType="text"
                          validation={validation}
                        />
                      </div>
                    ))}
                  </div>
                  <Row>
                    <Col md={12} className="text-center pt-2 mt-3">
                      <FormFeedback
                        type="invalid"
                        className="d-block text-center mb-3"
                        style={{
                          display: validation.errors.totalBeneficiariesPercent
                            ? "inline"
                            : "none",
                        }}
                      >
                        {validation.errors.totalBeneficiariesPercent}
                      </FormFeedback>
                      <span
                        className="btn btn-sm btn-info"
                        type="button"
                        onClick={(e) => {
                          validation.setFieldValue("beneficiaries", [
                            ...validation.values.beneficiaries,
                            { ...Beneficiary },
                          ]);
                        }}
                      >
                        <i className="fas fa-plus"></i> Agregar beneficiario
                      </span>
                    </Col>
                  </Row>

                  <div className="row mt-5 pb-4">
                    <h5>Dependientes económicos</h5>

                    <div className="row">
                      <NibbleInput
                        md={12}
                        label="¿Tiene dependientes económicos?"
                        fieldName="economic_dependents_ask"
                        fieldType="select"
                        selectItems={SINO}
                        validation={validation}
                        selectOptionValue={(e) => e.id}
                        onChange={(option) => {
                          if (!option?.id) {
                            validation.setFieldValue("economic_dependents", []);
                            return;
                          }

                          validation.setFieldValue("economic_dependents", [
                            { ...EconomicDependent },
                          ]);
                        }}
                      />
                    </div>

                    {validation.values.economic_dependents.map((_, index) => (
                      <div key={index} className={`row mt-4`}>
                        <div
                          className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                            validation.values.economic_dependents.length ===
                              -1 && "d-none"
                          }`}
                        >
                          <p className="p-2 border-radius-4 mb-0">
                            Dependiente económico {index + 1}
                          </p>
                          <button
                            disabled={isSavingComplements}
                            className="btn btn-primary btn-sm"
                            type="button"
                            hidden={index === 0}
                            onClick={(e) => {
                              const economicDependentsFiltered =
                                validation.values.economic_dependents.filter(
                                  (_, i) => i !== index
                                );
                              validation.setFieldValue("economic_dependents", {
                                ...economicDependentsFiltered,
                              });
                            }}
                          >
                            <i className="fas fa-trash"></i> Remover
                          </button>
                        </div>
                        <NibbleInput
                          md={6}
                          label="Nombre Completo:"
                          fieldName={`economic_dependents[${index}].name`}
                          fieldType="text"
                          validation={validation}
                        />

                        <Col md={6} />

                        <NibbleInput
                          md={6}
                          label="Parentesco:"
                          fieldName={`economic_dependents[${index}].relationship`}
                          fieldType="select"
                          selectItems={RELATIONSHIP}
                          validation={validation}
                        />

                        {validation.values.economic_dependents[index]
                          .relationship === "Otro" ? (
                          <NibbleInput
                            md={6}
                            label="Parentesco:"
                            fieldName={`economic_dependents[${index}].relationship_other`}
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}

                        <NibbleInput
                          md={6}
                          label="Fecha de nacimiento:"
                          fieldName={`economic_dependents[${index}].birth`}
                          fieldType="date"
                          validation={validation}
                        />
                        {/* <NibbleInput
                          md={6}
                          label="Nacionalidad:"
                          fieldName={`economic_dependents[${index}].nation`}
                          fieldType="text"
                          validation={validation}
                        /> */}
                        <NibbleInput
                          md={6}
                          label="Nacionalidad:"
                          fieldName={`economic_dependents[${index}].nation`}
                          fieldType="select"
                          selectItems={NACIONALIDADES.map((item) => ({
                            id: item,
                            name: item,
                          }))}
                          validation={validation}
                        />

                        {validation.values.economic_dependents[index].nation ===
                        "Otra" ? (
                          <NibbleInput
                            md={6}
                            label="Otra nacionalidad:"
                            fieldName={`economic_dependents[${index}].nation_other`}
                            fieldType="text"
                            validation={validation}
                          />
                        ) : null}

                        <NibbleInput
                          md={6}
                          label="País de nacimiento:"
                          fieldName={`economic_dependents[${index}].country`}
                          fieldType="select"
                          selectItems={PAISES_RESTRICTED}
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Estado de nacimiento:"
                          fieldName={`economic_dependents[${index}].state`}
                          fieldType="select"
                          selectItems={ESTADOS}
                          validation={validation}
                        />
                        <NibbleInput
                          md={6}
                          label="Dirección:"
                          fieldName={`economic_dependents[${index}].address`}
                          fieldType="text"
                          validation={validation}
                        />
                      </div>
                    ))}
                  </div>
                  {validation.values.economic_dependents_ask ? (
                    <Row className="mb-4">
                      <Col md={12} className="text-center pt-2 mt-3">
                        <span
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={(e) => {
                            validation.setFieldValue("economic_dependents", [
                              ...validation.values.economic_dependents,
                              { ...EconomicDependent },
                            ]);
                          }}
                        >
                          <i className="fas fa-plus"></i> Agregar dependiente
                          económico
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </div>
              <Col md={12} className="text-center mt-3">
                <button
                  disabled={isSavingProspect}
                  className="btn btn-primary"
                  type="submit"
                >
                  {isSavingProspect ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="fas fa-save"></i>
                  )}{" "}
                  Guardar
                </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </TabPane>
      <Nubarium
        captureType={"Id"}
        show={modalId}
        toggle={toggleId}
        setIdInfo={() => {}}
      ></Nubarium>
      <Nubarium
        captureType={"Face"}
        show={modalFace}
        oggle={toggleFace}
      ></Nubarium>
      <ColoniasModal validation={validation} />
      <ColoniasCommercialModal validation={validation} />
    </React.Fragment>
  );
};

export default TabGenerales;
