import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const ComplementsTab = ({ prospect }) => {
  const { complement, beneficiaries = [], economic_dependents = [] } = prospect;

  if (!complement)
    return (
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Complementos</h5>
        </CardHeader>
        <CardBody>
          <p>Aún no hay información para mostrar</p>
        </CardBody>
      </Card>
    );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Servicios de inversión</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              ¿Cómo supo de Smart Brokers? <br /> {complement?.ask1}
            </Col>
            <Col md={4}>
              ¿Es empleado de Smart Brokers? <br />
              {complement?.ask2 ? "Sí" : "No"}
            </Col>
            <Col md={4}>
              ¿Es familiar de empleado de Smart Brokers? <br />
              {complement?.ask3 ? "Sí" : "No"}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              ¿Es empleado de algún intermediario financiaro? <br />
              {complement?.ask4 ? "Sí" : "No"}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Facta</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              ¿Cuenta con nacionalidad estadounidense?
              <br /> {complement?.facta_ask1 ? "Sí" : "No"}
            </Col>
            <Col md={4}>
              ¿Es residente en los Estados Unidos de América? <br />
              {complement?.facta_ask2 ? "Sí" : "No"}
            </Col>
            <Col md={4}>
              ¿Es contribuyente o residente fiscal de los Estados Unidos de
              América? <br />
              {complement?.facta_ask3 ? "Sí" : "No"}
            </Col>
          </Row>
          {complement?.facta_ask3 ? (
            <Row>
              <Col md={4}>
                TIN (Tax Identification Number)
                <br />
                {complement?.facta_tin}
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Información fiscal</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              Régimen Fiscal:
              <br /> {complement?.tax_information_regime_string}
            </Col>
            <Col md={4}>
              Pais de residencia:
              <br /> {complement?.tax_information_country}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Instrucciones de liquidación</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              Titular de la cuenta:
              <br /> {complement?.bank_person}
            </Col>
            <Col md={4}>
              Banco:
              <br /> {complement?.bank_name}
            </Col>
            <Col md={4}>
              Cuenta CLABE:
              <br /> {complement?.bank_account}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Cuenta bancaria:
              <br /> {complement?.bank_account_number}
            </Col>
            <Col md={4}>
              Sucursal:
              <br /> {complement?.bank_branch}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Vinculo Patrimonial</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={12}>
              Sociedades y asociaciones con las que tiene relación o vínculos
              patrimoniales, o en su caso ocupe algún cargo de consejero:
              <br /> {complement?.sociedades ? "Sí" : "No"}
            </Col>
          </Row>
          {prospect?.societies?.map((society, index) => (
            <div key={society?.id}>
              <p>
                <strong>Sociedad {index + 1}</strong>
              </p>
              <Row className="pb-3">
                <Col md={4}>
                  Denominación social: <br /> {society?.sociedad_name}
                </Col>
                <Col md={4}>
                  Giro mercantil: <br /> {society?.sociedad_commercial}
                </Col>
                <Col md={4}>
                  Nacionalidad: <br /> {society?.sociedad_nation}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Nombre del Gerente o apoderado Legal: <br />{" "}
                  {society?.sociedad_apoderado}
                </Col>
                <Col md={4}>
                  Teléfono: <br /> {society?.sociedad_tel}
                </Col>
                <Col md={4}>
                  Porcentaje de vínculo patrimonial: <br />{" "}
                  {society?.sociedad_percent}%
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Es consejero: <br />{" "}
                  {society?.sociedad_counselor ? "Sí" : "No"}
                </Col>
              </Row>
            </div>
          ))}
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Datos de actividad económica</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={12}>
              Actividad:
              <br /> {complement?.ae_actividad_string}
            </Col>
          </Row>
          {prospect?.is_employee && (
            <>
              <Row className="pb-3">
                <Col md={4}>
                  Nombre de la empresa donde presta sus servicios:
                  <br /> {complement?.ae_empresa}
                </Col>
                <Col md={4}>
                  Puesto específico:
                  <br /> {complement?.ae_puesto}
                </Col>
                <Col md={4}>
                  Página de internet:
                  <br /> {complement?.ae_pagina}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Teléfono de oficina:
                  <br /> {complement?.ae_tel}
                </Col>
                <Col md={4}>
                  Sector de la empresa donde presta sus servicios:
                  <br /> {complement?.ae_sector_string}
                </Col>
                <Col md={4}>
                  Actividad/giro de la empresa donde presta sus servicios:
                  <br /> {complement?.ae_giro}
                </Col>
              </Row>
            </>
          )}
          <Row className="pb-3">
            <Col md={4}>
              Profesión:
              <br /> {complement?.ae_profession}
            </Col>
            <Col md={4}>
              Tipo de ingreso:
              <br /> {complement?.ae_type_income}
            </Col>
            <Col md={4}>
              Indique de donde son sus ingresos adicionales:
              <br /> {complement?.ae_additional_income}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              ¿De que empresa se jubiló?
              <br /> {complement?.ae_retired_company}
            </Col>
            <Col md={4}>
              Indique si actúa a nombre de un tercero o los recursos a invertir
              son de un tercero:
              <br /> {complement?.ae_name_lender}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">PEPS</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              ¿Desempeña o ha desempeñado en la administración vigente o en la
              anterior algún cargo público?
              <br /> {complement?.peps_peps ? "Sí" : "No"}
            </Col>
            <Col md={4}>
              Si usted es extranjero, ¿Desempeña o ha desempeñado en la
              administración vigente o en la anterior algún cargo público?:
              <br /> {complement?.peps_ext ? "Sí" : "No"}
            </Col>
            {complement?.peps_ext ? (
              <Col md={4}>
                Institución o dependencia donde la persona presta (prestó) sus
                servicios:
                <br /> {complement?.peps_ext_institucion}
              </Col>
            ) : null}
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Si usted está relacionado con una persona nacional o extrajera que
              desempeñe o haya desempañado algún cargo público en algún país
              extranjero:
              <br /> {complement?.peps_rel ? "Sí" : "No"}
            </Col>
          </Row>
          {complement?.peps_rel ? (
            <Row className="pb-3">
              <Col md={4}>
                Institución o dependencia donde la persona presta (prestó) sus
                servicios:
                <br /> {complement?.peps_rel_institucion}
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Cuentas discrecionales</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              Acumulación/carga fiscal de intereses:
              <br /> {complement?.cuentas_carga}
            </Col>
            <Col md={4}>
              Procedencia de los recursos con los que apertura la cuenta:
              <br /> {complement?.cuentas_procedencia_string}
            </Col>
            <Col md={4}>
              Carga de
              intereses:
              <br /> {complement?.cuentas_intereses}%
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Movimientos de la cuenta</h5>
        </CardHeader>
        <CardBody>
          <Row className="pb-3">
            <Col md={4}>
              Depósito inicial (Monto):
              <br /> {complement?.movimientos_inicial}
            </Col>
            <Col md={4}>
              Número de depósitos aproximados al mes:
              <br /> {complement?.movimientos_depositos}
            </Col>
            <Col md={4}>
              Monto mensual promedio de los depósitos:
              <br /> {complement?.movimientos_dep_prom}
            </Col>
          </Row>
          <Row className="pb-3">
            <Col md={4}>
              Número de retiros aproximados al mes:
              <br /> {complement?.movimientos_retiros}
            </Col>
            <Col md={4}>
              Monto mensual promedio de los retiros:
              <br /> {complement?.movimientos_ret_prom}
            </Col>
            <Col md={4}>
              ¿Maneja contratos con otra casa de bolsa o instituación de
              inversión? ¿Con cual?:
              <br /> {complement?.movimientos_otra_casa ? "Sí" : "No"}
            </Col>
          </Row>
          {complement?.movimientos_otra_casa ? (
            <Row className="pb-3">
              <Col md={4}>
                ¿En qué instrumento ha invertido en el mercado financiero?
                <br /> {complement?.movimientos_otro_instrumento}
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Beneficiarios</h5>
        </CardHeader>
        <CardBody>
          {beneficiaries.length === 0 && (
            <p>Aún no hay beneficiarios para mostrar</p>
          )}
          {beneficiaries?.map((bene, index) => (
            <div key={bene?.id}>
              <p>
                <strong>Beneficiario {index + 1}</strong>
              </p>
              <Row className="pb-3">
                <Col md={4}>
                  Nombre Completo:
                  <br /> {bene?.name}
                </Col>
                <Col md={4}>
                  Porcentaje:
                  <br /> {bene?.percent}%
                </Col>
                <Col md={4}>
                  Parentesco:
                  <br /> {bene?.relationship}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Fecha de nacimiento:
                  <br /> {bene?.birth}
                </Col>
                <Col md={4}>
                  Nacionalidad:
                  <br /> {bene?.nation}
                </Col>
                <Col md={4}>
                  País de nacimiento:
                  <br /> {bene?.country}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Estado de nacimiento:
                  <br /> {bene?.state_string}
                </Col>
                <Col md={4}>
                  Dirección:
                  <br /> {bene?.address}
                </Col>
              </Row>
            </div>
          ))}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Dependientes económicos</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              ¿Tiene dependientes económicos?
              <br /> {economic_dependents.length ? "Sí" : "No"}
            </Col>
          </Row>
          {economic_dependents?.map((eco, index) => (
            <div key={eco?.id} className="pt-3">
              <p>
                <strong>Dependiente económico {index + 1}</strong>
              </p>
              <Row className="pb-3">
                <Col md={4}>
                  Nombre Completo:
                  <br /> {eco?.name}
                </Col>
                <Col md={4}>
                  Parentesco:
                  <br /> {eco?.relationship}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Fecha de nacimiento:
                  <br /> {eco?.birth}
                </Col>
                <Col md={4}>
                  Nacionalidad:
                  <br /> {eco?.nation}
                </Col>
                <Col md={4}>
                  País de nacimiento:
                  <br /> {eco?.country}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col md={4}>
                  Estado de nacimiento:
                  <br /> {eco?.state_string}
                </Col>
                <Col md={4}>
                  Dirección:
                  <br /> {eco?.address}
                </Col>
              </Row>
            </div>
          ))}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ComplementsTab;
