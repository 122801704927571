import React, { useEffect, useRef, useState } from "react";
import { Alert, Spinner, FormFeedback } from "reactstrap";

import { getActiveSurvey, uploadFile } from "../../helpers/backend_helper";
import { file } from "../../helpers/api_helper";

const successIcon = <i className="fas fa-check-circle text-success"></i>;

const SeccionArchivosDocs = ({ user_id, setArchivos, validation }) => {
  const fileDomicilio = useRef(null);
  const fileBanco = useRef(null);

  const [survey, setSurvey] = useState(null);
  const [pending, setPending] = useState(null);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [errors, setErrors] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploaded, setFileUploaded] = useState({
    comprobanteDomicilio: false,
    estadoCuenta: false,
  });

  const isValid = uploadedFiles.length !== 2;

  const fetchSurvey = async () => {
    setPending(true);
    const resp = await getActiveSurvey();
    setPending(false);
    setSurvey(resp.data);
    console.log("resp", resp.data);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const name = e.target.id;
    const fileIndex = uploadedFiles.findIndex(
      (file) => "ineAnverso" === file.name
    );
    const front = uploadedFiles[fileIndex]?.id ?? null;

    if (!file) return;

    setErrors("");
    setIsUploadingFile(true);
    uploadFile({ file, user_id, name, front }).then((resp) =>
      responseAction(resp, name)
    );
  };

  const responseAction = (response, name) => {
    if (response.status) {
      const { item } = response;

      const fileIndex = uploadedFiles.findIndex((file) => file.name === name);

      if (fileIndex !== -1) {
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles[fileIndex] = item;
        setUploadedFiles(updatedUploadedFiles);
      } else {
        setUploadedFiles((prev) => [...prev, item]);
      }

      setFileUploaded((prev) => ({
        ...prev,
        [name]: true,
      }));
    } else {
      setErrors(response?.message);
    }
    setIsUploadingFile(false);
  };

  useEffect(() => {
    setArchivos([...uploadedFiles]);
  }, [uploadedFiles]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  console.log({ fileUploaded });
  return (
    <React.Fragment>
      {errors && <Alert color="danger">{errors}</Alert>}
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="progresspill-firstname-input">
              Comprobante de domicilio (formato PDF, JPG o PNG){" "}
              {fileUploaded.comprobanteDomicilio && successIcon}
            </label>
            <input
              ref={fileDomicilio}
              type="file"
              className="form-control d-none"
              id="comprobanteDomicilio"
              onChange={handleUploadFile}
              disabled={isUploadingFile}
              accept="image/png, image/jpeg, application/pdf"
            />
            <div>
              <button
                className="btn btn-secondary btn-sm"
                disabled={isUploadingFile}
                onClick={() => {
                  fileDomicilio.current.setAttribute("capture", false);
                  fileDomicilio.current.click();
                }}
              >
                <i className="fas fa-upload"></i> Subir Archivo
              </button>
              {isMobile && (
                <button
                  className="ms-3 btn btn-secondary btn-sm"
                  disabled={isUploadingFile}
                  onClick={() => {
                    fileDomicilio.current.setAttribute(
                      "capture",
                      "environment"
                    );
                    fileDomicilio.current.click();
                  }}
                >
                  <i className="fas fa-camera"></i> Tomar foto
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="progresspill-firstname-input">
              Estado de cuenta Bancaria de Débito (formato PDF){" "}
              {fileUploaded.estadoCuenta && successIcon}
            </label>
            <input
              ref={fileBanco}
              type="file"
              className="form-control d-none"
              id="estadoCuenta"
              onChange={handleUploadFile}
              disabled={isUploadingFile}
              accept="application/pdf"
            />
            <div>
              <button
                className="btn btn-secondary btn-sm"
                disabled={isUploadingFile}
                onClick={() => {
                  fileBanco.current.click();
                }}
              >
                <i className="fas fa-upload"></i> Subir Archivo
              </button>
            </div>
          </div>
        </div>
      </div>
      {validation.touched.files && validation.errors.files && (
        <FormFeedback
          type="invalid"
          style={{
            display:
              validation.touched.files && validation.errors.files
                ? "block"
                : "none",
          }}
        >
          {validation.errors.files}
        </FormFeedback>
      )}

      <div className="text-center">
        {isUploadingFile && (
          <Alert color="light">
            Subiendo archivo <Spinner size="sm" />
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default SeccionArchivosDocs;
