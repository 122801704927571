import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import {
  delProspect,
  getProspectPermissions,
} from "../../helpers/backend_helper";
import { DestroyAlertHelper, Alert } from "../../helpers/alerts/alertHelper";
import { getPolicyUser } from "../../helpers/api_helper";
import { Container } from "reactstrap";
import { prospectGeneralBase } from "../../components/Prospect/General";
import AddUpdProspectModal from "../../components/Prospect/AddUpdModal/AddUpdProspectModal";

const Prospects = () => {
  const [pending, setPending] = useState(true);
  const [modal, setModal] = useState({ show: false, update: false });
  const policy = getPolicyUser();
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(prospectGeneralBase);

  const fetchProspects = useCallback((filters) => {
    const config = { params: filters };

    setPending(true);
    getProspectPermissions(config).then((response) => {
      setItems(response.data);
      setDataPag(response.meta);
      setPending(false);
    });
  }, []);

  const realoadData = () => fetchProspects(filters);

  useEffect(() => {
    fetchProspects(filters);
  }, [filters]);

  const addUpd = (row = false) => {
    console.log({ row });
    if (row.id) {
      setItem(row);
      setModal({ ...modal, show: true, update: true });
    } else {
      setItem(itemBase);
      setModal({ ...modal, show: true, update: false });
    }
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      let response = await delProspect(id);
      Alert(response.message, response.status);
      if (response.status) {
        setItems(items.filter((i) => i.id != id));
      }
    });
  };

  const showDocs = (id) => {};

  const columns = Columns(addUpd, destroy, policy, showDocs);
  document.title = "Prospectos";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Prospectos"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            ></DataTableHelper>
          </div>
        </Container>
        {/* {modal.show && (
          <AddUpdModal
            modal={modal}
            setModal={setModal}
            items={items}
            setItems={setItems}
            item={item}
            setItem={setItem}
            realoadData={realoadData}
          />
        )} */}
        {modal.show && (
          <AddUpdProspectModal
            item={item}
            modal={modal}
            title="Prospecto"
            setModal={setModal}
            realoadData={realoadData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Prospects;
