import React, { useState } from "react";
import { Col, FormFeedback, Label } from "reactstrap";
import classNames from "classnames";
import Dropzone from "react-dropzone";

const NibbleInputFile = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  itemFile = null,
  validation,
  postFileFunction = async () => {},
  existFile = null,
  acceptFiles = null
}) => {
  const [newImage, setNewImage] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleAcceptedFiles = async (files) => {
    setUploadingFile(true);
    try {
      const response = await postFileFunction(files[0]);
      setNewImage(response);
      validation.setFieldValue(fieldName, response.item.id, true);
    } catch (error) {}

    setUploadingFile(false);
  };

  return (
    <Col md={md}>
      {existFile && (
        <div
          className={classNames({
            "pt-2": true,
            "mb-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <p className="pt-3">
            <i className="fas fa-file-alt" style={{ fontSize: "40px" }}></i>
          </p>
          <button
            type="button"
            onClick={() => {
              setNewImage(null);
              validation.setFieldValue(fieldName, itemFile?.id ?? null);
            }}
            className="btn btn-primary btn-sm save-user mt-1"
          >
            <i className="fas fa-sync"></i> Cambiar
          </button>
        </div>
      )}
      {!existFile && (
        <div
          className={classNames({
            "d-none": false,
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName]
                ? true
                : false,
            "mt-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <br />
          {!existFile && (
            <Dropzone
            accept={acceptFiles}
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
              onFileDialogOpen={() => {
                validation.setFieldTouched(fieldName, true, true);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" style={{ minHeight: "120px" }}>
                  <div
                    className="text-center needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-1">
                      <i className="display-5 text-muted bx bx-cloud-upload" />
                    </div>
                    <h5>Arrastra archivos aquí o da clic en cargar</h5>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      )}
      {validation.touched[fieldName] && validation.errors[fieldName] ? (
        <FormFeedback type="invalid" className="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      ) : null}
    </Col>
  );
};

export default NibbleInputFile;
