import axios from "axios"
import { del, get, post, postFile, put, postData, postFileV2} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
//reset pasword
export const userResetPasw = user => post(url.RESET_PASSW, user)
export const changePassw = data => post(url.CHANGE_PASSW, data)
export const sendConfirmation = data => post(url.SEND_CONFIRMATION, data)

// Login Method

//export const postLoginToken = data => post(url.POST_LOGIN_TOKEN, data)

export const postLoginToken = (data) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      var message = ''
      if (err.response && err.response.status) {
        console.log({ err });
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = err.response?.data?.message || err.message || 'Error desconocido'
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      throw message
    })
}

export const postLoginOtp = (data) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_OTP_LOGIN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      var message = ''
      if (err.response && err.response.status) {
        message = err.response?.data?.message || err.message
      } else {
        message = err.message
      }
      throw message
    })
}

// get Users
export const GET_ME = "/user";
export const getUsers = (config) => get(url.RESOURCE_USERS,config);
export const postUser = user => post(url.RESOURCE_USERS, user)
export const putUser = user => put(`${url.RESOURCE_USERS}/${user.id}`, user)
export const deleteUser = id => del(`${url.RESOURCE_USERS}/${id}`)
export const getProfiles = (id) => get(`${url.RESOURCE_USERS}/${id}`);
export const postFileProfile = (id,data) => postFile(`${url.FILE_PROFILE_USER}/${id}`, data)

//catalogs
export const getProfilescat = ()  => get(url.GET_PROFILES);

//condiguration
export const getConfigurations = (config) => get(url.RESOURCE_CONFIGURATIONS,config);
export const postFileFileConfiguration = configuration => postData(url.RESOURCE_CONFIGURATIONS, configuration)
export const getConfigurationsSelects = () => get(url.SELECTS_CONFIGURATIONS);
export const updateConfigurationOptions = (data) => post(url.UPDATE_CONFIGURATION_OPTIONS, data);
export const getConfiguration = () => get(url.GET_ACTIVE_CONFIGURATION);
export const deleteConfiguration = id => del(`${url.RESOURCE_CONFIGURATIONS}/${id}`)
//company
export const getCompanies = config => get(url.RESOURCE_COMPANIES,config);
export const postCompany = company => post(url.RESOURCE_COMPANIES,company);
export const putCompany = company => put(`${url.RESOURCE_COMPANIES}/${company.id}`,company);
//profiles - permissions
export const getProfilesPermissions = (config) => get(url.RESOURCE_PROFILES,config);
export const postProfile = profile => post(url.RESOURCE_PROFILES,profile);
export const putProfile = profile => put(`${url.RESOURCE_PROFILES}/${profile.id}`,profile);
export const getPermissions = (id,config) => get(`${url.RESOURCE_PERMISSIONS}/${id}`,config);
export const getSelectsPermissions = (id) => get(`${url.PERMISSIONS_SELECT}/${id}`);
export const postPermission = permission => post(url.RESOURCE_PERMISSIONS,permission);
export const putPermission = permission => put(`${url.RESOURCE_PERMISSIONS}/${permission.id}`,permission);
export const deletePermission = id => del(`${url.RESOURCE_PERMISSIONS}/${id}`)

export const getProfileRiskPermissions = (config) => get(url.RESOURCE_PROFILES_RISK,config);
export const getProfileRisk = id => get(`${url.RESOURCE_PROFILES_RISK}/${id}`);
export const postProfileRisk = profile => post(url.RESOURCE_PROFILES_RISK,profile);
export const putProfileRisk = profile => put(`${url.RESOURCE_PROFILES_RISK}/${profile.id}`,profile);
export const delProfileRisk = id => del(`${url.RESOURCE_PROFILES_RISK}/${id}`)
export const getProfileRiskSelect = id => get(`${url.RESOURCE_PROFILES_RISK}/select`);

export const getInstrumentPermissions = (config) => get(url.RESOURCE_INSTRUMENTS,config);
export const getInstrument = id => get(`${url.RESOURCE_INSTRUMENTS}/${id}`);
export const postInstrument = profile => post(url.RESOURCE_INSTRUMENTS,profile);
export const putInstrument = profile => put(`${url.RESOURCE_INSTRUMENTS}/${profile.id}`,profile);
export const delInstrument = id => del(`${url.RESOURCE_INSTRUMENTS}/${id}`)
export const getInstrumentSelect = id => get(`${url.RESOURCE_INSTRUMENTS}/select`);
export const uploadInstruments = data => postFileV2(`${url.RESOURCE_INSTRUMENTS}/import`, data);

export const getPeriodPermissions = (config) => get(url.RESOURCE_PERIODS,config);
export const getPeriod = id => get(`${url.RESOURCE_PERIODS}/${id}`);
export const postPeriod = profile => post(url.RESOURCE_PERIODS,profile);
export const putPeriod = profile => put(`${url.RESOURCE_PERIODS}/${profile.id}`,profile);
export const delPeriod = id => del(`${url.RESOURCE_PERIODS}/${id}`)
export const getPeriodSelect = id => get(`${url.RESOURCE_PERIODS}/select`);

export const getProductTypesPermissions = (config) => get(url.RESOURCE_PRODUCT_TYPES,config);
export const getProductTypes = id => get(`${url.RESOURCE_PRODUCT_TYPES}/${id}`);
export const postProductTypes = profile => post(url.RESOURCE_PRODUCT_TYPES,profile);
export const putProductTypes = profile => put(`${url.RESOURCE_PRODUCT_TYPES}/${profile.id}`,profile);
export const delProductTypes = id => del(`${url.RESOURCE_PRODUCT_TYPES}/${id}`)
export const getProductTypesSelect = id => get(`${url.RESOURCE_PRODUCT_TYPES}/select`);

export const getProductPerformances = config => get(`${url.RESOURCE_PRODUCT_PERFORMANCES}`, config);
export const postProductPerformances = data => post(`${url.RESOURCE_PRODUCT_PERFORMANCES}`, data);
export const delProductPerformances = id => del(`${url.RESOURCE_PRODUCT_PERFORMANCES}/${id}`);

export const getSections = (config) => get(url.RESOURCE_SECTIONS,config);
export const postSection = item => post(url.RESOURCE_SECTIONS,item);

export const postAsk = item => post(url.RESOURCE_ASK,item);
export const putAsk = item => put(`${url.RESOURCE_ASK}/${item.id}`,item);
export const delAsk = id => del(`${url.RESOURCE_ASK}/${id}`)

export const getClientsPermissions = (config) => get(url.RESOURCE_CLIENTS,config);
export const postClient = item => post(url.RESOURCE_CLIENTS,item);
export const putClient = item => put(`${url.RESOURCE_CLIENTS}/${item.id}`,item);
export const delClient = id => del(`${url.RESOURCE_CLIENTS}/${id}`)
export const getClient = id => get(`${url.RESOURCE_CLIENTS}/${id}`)
export const postClientChangeInvestmentProfile = (id, data) => post(`${url.RESOURCE_CLIENTS}/change-investment-profile/${id}`, data)
export const downloadBackupFileChangeInvestmentProfile = (id, config) => post(`${url.RESOURCE_CLIENTS}/download-backup-file-change-investment-profile/${id}`, {}, config)

export const getProspectPermissions = (config) => get(url.RESOURCE_PROSPECTS,config);
export const postProspect = item => post(url.RESOURCE_PROSPECTS,item);
export const postProspectRegistration = item => post(url.RESOURCE_PROSPECTS_REGISTRATION, item);
export const postProspectGenerals = item => post(url.RESOURCE_PROSPECTS_GENERALS, item);
export const postProspectDocs = item => post(url.RESOURCE_PROSPECTS_DOCS, item);
export const postProspectIdentity = item => post(url.RESOURCE_PROSPECTS_IDENTITY, item);
export const postProspectRegistrationEnd = item => post(url.RESOURCE_PROSPECTS_REGISTRATION_END, item);
export const getProspectCheckSelfie = (id) => get(url.RESOURCE_PROSPECTS_CHECK_SELFIE + `/${id}`);
export const postProspectSignContract = data => postFileV2(url.PROSPECTS_SIGN_LEVEL2, data)
export const postProspectUpdateDocument = data => post(url.PROSPECTS_DOCUMENT+ `/${data.id}`, data)

export const postProspectComplements = item => post(url.RESOURCE_PROSPECTS_COMPLEMENTS, item);
export const postProspectDocuments = item => post(url.RESOURCE_PROSPECTS_DOCUMENTS, item);
export const postProspectAnswers = item => post(url.RESOURCE_PROSPECTS_ANSWERS, item);
export const postProspectCheckEmail = (item, config = {}) => post(url.RESOURCE_PROSPECTS_CHECK_EMAIL, item, config);
export const postProspectSendPinEmail = (item, config = {}) => post(url.RESOURCE_PROSPECTS_SEND_PIN_EMAIL, item, config);
export const postProspectSendPinSms = (item, config = {}) => post(url.RESOURCE_PROSPECTS_SEND_PIN_SMS, item, config);
export const putProspect = item => put(`${url.RESOURCE_PROSPECTS}/${item.id}`,item);
export const delProspect = id => del(`${url.RESOURCE_PROSPECTS}/${id}`)
export const getProspect = id => get(`${url.RESOURCE_PROSPECTS}/${id}`)
export const postProspectApprove = data => post(`${url.PROSPECTS_APPROVE_PROSPECT}`, data)
export const postProspectReject = data => post(`${url.PROSPECTS_REJECT_PROSPECT}`, data)
export const putProspectUpdateStatus = (id, data) => put(`${url.PROSPECTS_UPDATE_STATUS_PROSPECT}/${id}`, data)
export const prospectAcceptTerms = (id) => get(`${url.PROSPECTS_ACCEPT_TERMS}/${id}`)

export const getProductsPermissions = (config) => get(url.RESOURCE_PRODUCTS,config);
export const getProductsjList = (config) => get(`${url.RESOURCE_PRODUCTS}/jList`,config);
export const getProduct = id => get(`${url.RESOURCE_PRODUCTS}/${id}`);
export const postProduct = item => post(url.RESOURCE_PRODUCTS,item);
export const putProduct = item => put(`${url.RESOURCE_PRODUCTS}/${item.id}`,item);
export const delProduct = id => del(`${url.RESOURCE_PRODUCTS}/${id}`)
export const uploadProductTemplate = (id, data) => post(`${url.RESOURCE_PRODUCTS_UPLOAD_TEMPLATE}/${id}`, data)
export const downloadProductTemplate = (id, config) => get(`${url.RESOURCE_PRODUCTS_DOWNLOAD_TEMPLATE}/${id}`, config)

export const getSurveysPermissions = (config) => get(url.RESOURCE_SURVEYS,config);
export const getSurvey = id => get(`${url.RESOURCE_SURVEYS}/${id}`);
export const getActiveSurvey = () => get(`${url.RESOURCE_SURVEY_ACTIVE}`);
export const postSurvey = item => post(url.RESOURCE_SURVEYS,item);
export const putSurvey = item => put(`${url.RESOURCE_SURVEYS}/${item.id}`,item);
export const delSurvey = id => del(`${url.RESOURCE_SURVEYS}/${id}`);

export const uploadFile = data => postFileV2(url.RESOURCE_FILES, data);
export const postUploadFile = data => post(url.RESOURCE_FILES, data);

export const getClientContracts = (id, config) => get(`${url.CLIENT_CONTRACTS}/${id}`, config);
export const postClientContract = data => post(`${url.CLIENT_CONTRACTS}`, data);
export const getClientBalances = (id) => get(`${url.CLIENT_CONTRACTS}/client-balances/${id}`);
export const cancelClientContract = (id) => get(`${url.CLIENT_CONTRACTS}/cancel/${id}`);
export const retireClientContract = (id) => get(`${url.CLIENT_CONTRACTS}/retire/${id}`);

export const getClientTransactions = (id = '', config) => get(`${url.CLIENT_TRANSACTIONS}/${id}`, config);

export const getWithdrawals = (config) => get(`${url.RESOURCE_WITHDRAWALS}`, config);
export const postWithdrawals = (config) => post(`${url.RESOURCE_WITHDRAWALS}`, config);
export const postRefundWithdrawal = (data) => post(`${url.RESOURCE_WITHDRAWALS}/refund/${data.id}`, data);
export const postCompleteWithdrawalPin = (data) => post(`${url.RESOURCE_WITHDRAWALS}/complete/pin`);
export const postCompleteWithdrawal = (data) => post(`${url.RESOURCE_WITHDRAWALS}/complete/${data.id}`, data);

export const postAdminDeposit = (config) => post(`${url.ADMIN_DEPOSITS}`, config);
export const postAdminPinDeposit = () => post(`${url.ADMIN_DEPOSITS}/pin`);
export const getAdminDeposits = (config) => get(`${url.ADMIN_DEPOSITS}`, config);
export const getAdminTransactions = (config) => get(`${url.ADMIN_TRANSACTIONS}`, config);
export const getAdminSummaryContracts = () => get(`${url.ADMIN_CONTRACTS_SUMMARY}`);
export const getAdminContracts = (config) => get(`${url.ADMIN_CONTRACTS}`, config);

//Nubarium
export const getNubariumToken = () => get(url.NUBARIUM_TOKEN);
export const getNubariumValidateCurp = (config) => get(`${url.NUBARIUM_VALIDATE_CURP}`, config);
export const getNubariumValidateRfc = (config) => get(`${url.NUBARIUM_VALIDATE_RFC}`, config);
export const getNubariumValidateIne = (config) => post(`${url.NUBARIUM_VALIDATE_INE}`, config);
export const getNubariumIneInformation = (config) => post(`${url.NUBARIUM_INE_INFORMATION}`, config);
export const getNubariumProofInformation = (config) => post(`${url.NUBARIUM_PROOF_INFORMATION}`, config);
export const getNubariumValidateFace = (config) => post(`${url.NUBARIUM_VALIDATE_FACE}`, config);

export const getZipCodeInfo = (config) => get(`${url.GET_ZIP_CODE_INFO}`, config);
export const getColoniasByZipCode = (config) => get(`${url.GET_COLONIAS_BY_ZIP_CODE}`, config);


//Download pdf
export const getDocument = (config) => get(`${url.GENERATE_DOC}/${config.url}`, config);

export {
  getLoggedInUser,
  isUserAuthenticated,
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}
