import React, { useEffect } from "react";
import { useState } from "react";

import DataCapture from "./Capture";
import {
  getNubariumIneInformation,
  getNubariumToken,
  getNubariumValidateIne,
  uploadFile,
} from "../../helpers/backend_helper";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const Nubarium = ({
  captureType = "Face",
  show = false,
  toggle,
  setIdInfo,
  archivos = [],
  setArchivos = null,
  prospect_id = null,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [verifing, setVerifing] = useState(false);
  const [token, setToken] = useState();
  const [evaluation, setEvaluation] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [errorSimilitud, setErrorSimilitud] = useState("");
  const [completed, setCompleted] = useState(false);

  // Capture Face
  const onEvaluation = (evaluation, result) => {
    console.log({ evaluation, result });

    /*
    if(result?.result?.evaluation?.toLowerCase() !== "pass") {
      setError(true);
      setEvaluation(evaluation);
      setResult(result);
    } else {
      setError(false);
      setIdInfo(result);
      setCompleted(true);
      toggle();
    }
    */
  };

  // Capture Id
  const onSuccess = async (data) => {
    if (captureType == "Face") {
      let id = data.id;
      let face = data.face;

      setResult(data);
      console.log({ onSuccess: data });

      // if (true || data.result?.evaluation?.toLowerCase() == "pass") {
      if (
        data.result?.evaluation?.toLowerCase() == "pass" 
        //data.result?.evaluation?.toLowerCase() == "warning"
      ) {
        console.log(face);
        const user_id = "undefined";
        const file = b64toBlob(face, "image/jpeg");
        const name = "selfie";
        const fileIndex = archivos.findIndex(
          (file) => "ineAnverso" === file.name
        );
        const front = archivos[fileIndex]?.id ?? null;

        setVerifing(true);
        try {
          const response = await uploadFile({ file, prospect_id, name, front });

          if (response.status == true) {
            setIdInfo(response);
            setError(false);
            setCompleted(true);
            if (response?.item) setArchivos([...archivos, response?.item]);
            toggle();
          } else {
            setError(true);
            setErrorSimilitud(response?.mensaje ?? "Fallo al subir la imagen");
          }
        } catch (error) {
          console.log(error);
        }
        setVerifing(false);
      } else {
        setError(true);
      }
    } else {
      let id = data.id;
      let front = data.front;
      let back = data.back;
      let result = data.result;

      setResult(data);
      console.log({ onSuccess: data });

      if (data.result?.evaluation?.toLowerCase() == "pass") {
        const response = await getNubariumIneInformation({ front, back });
        setIdInfo(response.ine);
      }
    }
  };

  const onFail = (fail) => {
    let id = data.id;
    let reason = fail.reason;
    let result = fail.result;
    setResult(fail);
    console.log({ onFail: fail });
  };

  const onError = (error) => {
    let errorCode = error.code;
    let errorMsg = error.msg;
    setResult(error);
    console.log({ onError: error });
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const resp = await getNubariumToken();
        setToken(resp.token.bearer_token);
        console.log(resp);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    if (show) {
      setResult(null);
      setEvaluation(null);
    }
  }, [show]);

  useEffect(() => {
    if (error) {
      setResult(null);
      setEvaluation(null);
    }
  }, [error]);

  if (isLoading) return "";
  if (!token) return "";

  return (
    <Modal isOpen={show}>
      <ModalHeader className="bg-primary">
        <span className="text-light">Capturar</span>
      </ModalHeader>

      <ModalBody className="p-0">
        <Card>
          <CardBody className="p-0">
            <div className="p-2">
              <p>
                Tomar selfie para verificar su identificación, asegurese de
                tener buena iluminación y que su rostro sea visible.
              </p>
              {captureType == "Face" && error && (
                <Alert color="warning">
                  No se pudo capturar la información, por favor intente de
                  nuevo. {errorSimilitud}
                </Alert>
              )}

              {verifing && (
                <div className="text-center">
                  <Spinner
                    color="primary"
                    style={{ width: "2rem", height: "2rem" }}
                  />{" "}
                  Verificando...
                </div>
              )}
            </div>
            {token && result == null && (
              <DataCapture
                captureType={captureType}
                token={token}
                onEvaluation={onEvaluation}
                onSuccess={onSuccess}
                onError={onError}
                onFail={onFail}
              />
            )}
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <i className="fa fa-times"></i> Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Nubarium;
