import React, { Component, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  FormFeedback,
  Button,
  Form,
  Alert,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postProspectIdentity, postProspectRegistration, postProspectRegistrationEnd } from "../../helpers/backend_helper";
import registrationStorage from "./registrationStorage";
import Nubarium from "../../components/Nubarium/Nubarium";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const RegistrationFace = (props) => {
  const [errors, setErrors] = useState("");
  const [validSelfi, setValidSelfi] = useState(null);
  const [isSavingProspect, setIsSavingProspect] = useState(false);

  const [archivos, setArchivos] = useState([]);
  const [modalFace, setModalFace] = useState(false);
  
  const prospect_id = props.match.params.id;

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      face: true,
      prospect_id: prospect_id,
    },
    validationSchema: Yup.object({
      face: Yup.bool().required("Requerido").isTrue("Requerido"),      
    }),
    onSubmit: (values) => {
      console.log({ values });
      // dispatch(loginUser(values, props.history));
      save(values);
    },
  });

  useEffect(() => {
    if (validSelfi) {
      validation.setFieldValue("face", true);
    }
  }, [validSelfi]);

  const save = async (formValues) => {
    setErrors("");
    setIsSavingProspect(true);

    const data = {
      ...formValues,
      files: archivos,
    };

    console.log({ data });
    const resp = await postProspectIdentity(data);

    responseAction(resp);
  };

  const responseAction = (response, name) => {
    if (response.status) {
      registrationStorage.clean();

      ToastAlert(
        "Registro completado. Su cuenta esta en proceso de activación, hasta que este activada no se podrán realizar operaciones.",
        true
      );

      history.push("/login");
    } else {
      setErrors(response?.message);
    }
    setIsSavingAnswers(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleFace = () => {
    setModalFace(!modalFace);
  };

  useEffect(() => {
    if(archivos.length > 0)
      validation.handleSubmit();
  }, [archivos]);

  /*
  if(!isMobile){
    return (
      <>
        <Alert color={'warning'}>Debe registrarse en un teléfono móvil</Alert>
      </>
    )
  }
  */

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container className="p-0">
          <Row className="g-0">
            <Col lg={12}>
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column">
                    <div className="mb-4 mb-md-5 text-center">
                      <img src="/images/logo_sb.png" alt="" height="80" />
                    </div>
                    {!isMobile && (
                      <Alert color={"warning"}>
                        Debe registrarse en un teléfono móvil
                      </Alert>
                    )}
                    {isMobile && (
                      <div className="auth-content my-auto">
                        <div>
                          <div className="text-center mb-4">
                            <h5>Valida tu identidad</h5>
                            <p className="card-title-desc">
                              Valida tu identidad con una selfie.
                            </p>
                          </div>
                          {errors && <Alert color="danger">{errors}</Alert>}
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col md={12} className="text-center mt-1 mb-2">
                                {validSelfi && (
                                  <p className="fw-bold">
                                    <i className="fas fa-check-circle text-success fs-5"></i>{" "}
                                    Reconocimiento facial aprobado.
                                  </p>
                                )}
                                {!validSelfi && (
                                  <>
                                    <Button
                                      className="mt-4"
                                      type="button"
                                      color="primary"
                                      onClick={toggleFace}
                                    >
                                      <i className="fas fa-camera"></i> Tomar
                                      selfie
                                    </Button>
                                    {validation.touched.face &&
                                      validation.errors.face && (
                                        <FormFeedback
                                          type="invalid"
                                          style={{
                                            display:
                                              validation.touched.face &&
                                              validation.errors.face
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          {validation.errors.face}
                                        </FormFeedback>
                                      )}
                                  </>
                                )}
                              </Col>

                            </Row>
                          </Form>
                        </div>
                      </div>
                    )}
                    <div className="mt-5 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Smart-Brokers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {modalFace && (
        <Nubarium
          captureType={"Face"}
          show={modalFace}
          toggle={toggleFace}
          setIdInfo={setValidSelfi}
          archivos={archivos}
          setArchivos={setArchivos}
          prospect_id={prospect_id}
        ></Nubarium>
      )}
    </React.Fragment>
  );
};

export default RegistrationFace;
