import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DataTableHelper from "../../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import {
  delAsk,
  getProduct,
  getProfileRisk,
  getProfileRiskPermissions,
  getSurvey,
} from "../../../helpers/backend_helper";
import {
  DestroyAlertHelper,
  Alert,
  ToastHelper,
} from "../../../helpers/alerts/alertHelper";
import { Button, Container, Row, Table } from "reactstrap";
import { Tbody, Thead } from "react-super-responsive-table";
import Pie from "./echart/piechart";
import Line from "./echart/linechart";
import ContractProductModal from "./ContractProductModal";
import { Link } from "react-router-dom/cjs/react-router-dom";
import TablesProductPerformace from "./TablesProductPerformace";
import MyProductDetailInner from "./MyProductDetailInner";
import { getUserLevel } from "../../../helpers/api_helper";

const MyProductDetail = ({ match }) => {
  const productId = match.params.id;
  const backLink = "/my-products";
  const itemBase = {
    id: 0,
    name: "",
    description: "",
    bechmark: "",
    composition: "",
    operation: "",
    risks: "",
    administration: "",
  };
  const [product, setProduct] = useState(null);
  const [pending, setPending] = useState(false);
  const [modal, setModal] = useState({ show: false, update: false });
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(itemBase);

  const [isContractModalOpen, setIsContractModalOpen] = useState(false);

  const userLevel = getUserLevel();

  const fetchProduct = async () => {
    setPending(true);
    const resp = await getProduct(productId);
    setPending(false);
    setProduct(resp.item);
    console.log("resp", resp.item);
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const addUpd = (row = false) => {
    if (row.id) {
      setItem(row);
      setModal({ ...modal, show: true, update: true });
    } else {
      setItem({ ...itemBase, survey_id: product.id });
      setModal({ ...modal, show: true, update: false });
    }
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      const response = await delAsk(id);
      Alert(response.message, response.status);
      if (response.status) fetchData();
    });
  };

  const columns = Columns(addUpd, destroy);
  document.title = "Producto";
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-body pb-0">
            <Breadcrumbs title="Producto" breadcrumbItem="Producto" />
            <h4>Producto {product?.name}</h4>

            <MyProductDetailInner product={product} />

            <div className="col-lg-12 text-center mt-3 mb-5">
              {(userLevel === "Nivel 2" || userLevel === "Nivel 3") && (
                <Button
                  color="primary"
                  onClick={() => setIsContractModalOpen(true)}
                  className=""
                >
                  <i className="fa fa-buy"></i> Invertir
                </Button>
              )}

              <Link to={backLink} className="btn btn-secondary ms-2">
                Regresar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ContractProductModal
        productId={product?.id}
        isOpen={isContractModalOpen}
        setIsContractModalOpen={setIsContractModalOpen}
      />
    </React.Fragment>
  );
};

export default MyProductDetail;
