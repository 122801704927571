import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import Currency from "../../components/Common/Currency";
import {
  postCompleteWithdrawal,
  postCompleteWithdrawalPin,
  postRefundWithdrawal,
  uploadFile,
} from "../../helpers/backend_helper";
import NibbleInput from "../../components/Fields/NibbleInput";
import NibbleInputFile from "../../components/Fields/NibbleInputFile";

const CompleteModal = ({ modal, setModal, setItem, item, realoadData }) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setModal({ ...modal, complete: false, pin: false });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: item.id,
      reference: "",
      file_id: "",
      pin: "",
    },
    validationSchema: Yup.object({
      id: Yup.string().nullable().required(),
      reference: Yup.string().nullable(),
      file_id: Yup.string().nullable(),
      pin: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      console.log({ values });
      save(values);
    },
  });

  const pinValidation = useFormik({
    enableReinitialize: true,
    initialValues: { pin: "" },
    validationSchema: Yup.object({
      pin: Yup.number().nullable().required("Requerido"),
    }),
    onSubmit: async (values) => {
      validation.setFieldValue("pin", values.pin);

      await validation.submitForm();
    },
  });

  const save = async (data) => {
    if (!validation.values.pin) {
      await sendPin();
      return;
    }

    setLoading(true);
    const response = await postCompleteWithdrawal(data);
    responseAction(response);
  };

  const responseAction = (response) => {
    if (response.status) {
      handleClose();
      validation.resetForm();
      realoadData && realoadData();
    } else {
      validation.setFieldValue("pin", "");
    }

    setLoading(false);
  };

  const sendPin = async () => {
    setLoading(true);
    const response = await postCompleteWithdrawalPin();

    if (response.status) {
      setModal((prev) => ({ ...prev, pin: true }));
    }

    setLoading(false);

    return response;
  };

  const onUploadFile = (file) => {
    const formData = new FormData();
    formData.file = file;
    formData.name = "baucher-deposito";

    return uploadFile(formData);
  };

  const onClosePinModal = () => {
    setModal((prev) => ({ ...prev, pin: false }));
    pinValidation.resetForm();
  };

  return (
    <div>
      <Modal isOpen={modal.complete} size="md">
        <ModalHeader className="bg-primary">
          <span className="text-light">Completar</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <p>Cliente: {item?.prospect?.full_name}</p>
            <p>
              Importe: <Currency amount={item?.amount} />
            </p>
            <p>
              <strong>Datos bancarios</strong>
            </p>
            <p>
              Titular de la cuenta: {item?.prospect?.complement?.bank_person}
              <br />
              Banco: {item?.prospect?.complement?.bank_name}
              <br />
              Cuenta CLABE: {item?.prospect?.complement?.bank_account}
              <br />
              Cuenta bancaria: {item?.prospect?.complement?.bank_account_number}
              <br />
              Sucursal: {item?.prospect?.complement?.bank_branch}
            </p>

            <NibbleInput
              md={12}
              label="Refencia"
              fieldName="reference"
              fieldType="text"
              validation={validation}
            />
            <NibbleInputFile
              md={12}
              label="Adjuntar documento de respaldo"
              fieldName="file_id"
              validation={validation}
              postFileFunction={onUploadFile}
              existFile={validation.values.file_id}
              /* acceptFiles="application/pdf" */
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modal.pin}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            Ingresa el PIN enviado a tu número teléfonico
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            pinValidation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row justify-content-center">
              <NibbleInput
                md={6}
                label="PIN"
                fieldName="pin"
                fieldType="text"
                validation={pinValidation}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={onClosePinModal}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default CompleteModal;
