import React, { Component, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  TabPane,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getProspectCheckSelfie,
  postProspectRegistration,
  postProspectRegistrationEnd,
} from "../../helpers/backend_helper";

import registrationStorage from "./registrationStorage";
import Nubarium from "../../components/Nubarium/Nubarium";
import QRCode from "react-qr-code";
import { Alert as ToastAlert } from "../../helpers/alerts/alertHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TabFace = ({
  tabId,
  toggleTabProgress,
  setRegistrationData,
  prospect_id,
}) => {
  const [errors, setErrors] = useState("");
  const [validSelfi, setValidSelfi] = useState(null);
  const [isSavingProspect, setIsSavingProspect] = useState(false);

  const [archivos, setArchivos] = useState([]);
  const [modalFace, setModalFace] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  const history = useHistory();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      face: false,
      accept: false,
    },
    validationSchema: Yup.object({
      face: Yup.bool().required("Requerido").isTrue("Requerido"),
      accept: Yup.bool()
        .required("Requerido")
        .isTrue("Debe aceptar los términos y condiciones"),
    }),
    onSubmit: (values) => {
      console.log({ values });
      // dispatch(loginUser(values, props.history));
      save(values);
    },
  });

  useEffect(() => {
    if (validSelfi) {
      validation.setFieldValue("face", true);
    }
  }, [validSelfi]);

  const save = async (formValues) => {
    setErrors("");
    setIsSavingProspect(true);

    const data = {
      ...formValues,
      prospect_id: prospect_id,
    };

    console.log({ data });
    const resp = await postProspectRegistrationEnd(data);

    responseAction(resp);
    //setIsSavingProspect(false);
  };

  const responseAction = (response, name) => {
    if (response.status) {
      registrationStorage.clean();

      ToastAlert (
        "Registro completado. Su cuenta esta en proceso de activación, hasta que este activada no se podrán realizar operaciones. Espera el correo electróncio de activación",
        true
      );

      setTimeout(() => {
        history.push("/login");
      }, 25000);
    } else {
      setErrors(response?.message);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleFace = () => {
    setModalFace(!modalFace);
  };

  const checkSelfie = async () => {
    const response = await getProspectCheckSelfie(prospect_id);
    if(response.status)
      validation.setFieldValue("face", true);
  };

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <div className="text-center mb-4">
          <h5>Valida tu identidad</h5>
          <p className="card-title-desc">
            Valida tu identidad y acepta los términos y condiciones para generar
            tu contrato.
          </p>
        </div>
        {errors && <Alert color="danger">{errors}</Alert>}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={12} className="text-center mt-1 mb-2">
              {validSelfi && (
                <p className="fw-bold">
                  <i className="fas fa-check-circle text-success fs-5"></i>{" "}
                  Reconocimiento facial aprobado.
                </p>
              )}

              {!isMobile && (
                <div>
                  {!validation.values?.face && (
                    <>
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 150,
                          width: "100%",
                        }}
                      >
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={
                            process.env.REACT_APP_URL +
                            "/registration-face/" +
                            prospect_id
                          }
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      <p className="mt-3">
                        Leer código QR con tu teléfono móvil para tomar la
                        selfie y <br />
                        presiona el boton continuar una vez que hayas tomado la
                        selfie.
                      </p>
                      <Button
                        className="mt-4"
                        type="button"
                        color="primary"
                        onClick={checkSelfie}
                      >
                        <i className="fas fa-check"></i> Continuar
                      </Button>
                    </>
                  )}

                  {validation.values?.face && (
                    <p className="fw-bold">
                      <i className="fas fa-check-circle text-success fs-5"></i>{" "}
                      Reconocimiento facial aprobado.
                    </p>
                  )}
                </div>
              )}

              {isMobile && !validSelfi && (
                <>
                  <Button
                    className="mt-4"
                    type="button"
                    color="primary"
                    onClick={toggleFace}
                  >
                    <i className="fas fa-camera"></i> Tomar selfie
                  </Button>
                  {validation.touched.face && validation.errors.face && (
                    <FormFeedback
                      type="invalid"
                      style={{
                        display:
                          validation.touched.face && validation.errors.face
                            ? "block"
                            : "none",
                      }}
                    >
                      {validation.errors.face}
                    </FormFeedback>
                  )}
                </>
              )}
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="mt-5 text-center">
              <Label for="active">
                <Link
                  to={{
                    pathname: "/terminos-y-condiciones.html",
                  }}
                  target="_blank"
                >
                  Aceptar términos y condiciones
                </Link>
              </Label>{" "}
              <Input
                className="ms-2"
                name="active"
                type="checkbox"
                onChange={(evt) => {
                  validation.setFieldValue("accept", evt.target.checked);
                }}
                checked={validation.values.accept}
              />
              {validation.touched.accept && validation.errors.accept && (
                <FormFeedback
                  type="invalid"
                  style={{
                    display:
                      validation.touched.accept && validation.errors.accept
                        ? "block"
                        : "none",
                  }}
                >
                  {validation.errors.accept}
                </FormFeedback>
              )}
            </Col>

            <Col md={12} className="text-center mt-5">
              <button
                disabled={
                  !validation.values.face ||
                  !validation.values.accept ||
                  isSavingProspect
                }
                className="btn btn-primary"
                type="submit"
              >
                {isSavingProspect ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="fas fa-save"></i>
                )}{" "}
                Registrar
              </button>
            </Col>
          </Row>
        </Form>
      </TabPane>

      {modalFace && (
        <Nubarium
          captureType={"Face"}
          show={modalFace}
          toggle={toggleFace}
          setIdInfo={setValidSelfi}
          archivos={archivos}
          setArchivos={setArchivos}
        ></Nubarium>
      )}
      {modalMessage && (
        <Modal isOpen={modalMessage} className="modal-lg">
          <ModalHeader>Alerta</ModalHeader>
          <ModalBody>
            <div>
              <div className="mt-4 mb-4">
                Su cuenta está en proceso de validación. En breve recibirá un
                correo con el resultado de la validación.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModalMessage(false);
                toggleTabProgress(2);
              }}
            >
              <i className="fa fa-times"></i> Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TabFace;
