export const RECURSOS_PROCEDENCIA = [
  { id: "1", name: "Ahorro" },
  { id: "2", name: "Préstamos" },
  { id: "3", name: "Herencia" },
  { id: "4", name: "Venta de activos" },
  { id: "5", name: "Premios" },
  { id: "6", name: "Sueldos" },
  { id: "7", name: "Otro" },
];

export const SINO = [
  { id: true, name: "Si" },
  { id: false, name: "No" },
];

export const PAISES = [{ id: "México", name: "México" }];

export const ESTADOS = [
  { id: 1, name: "Aguascalientes" },
  { id: 2, name: "Baja California" },
  { id: 3, name: "Baja California Sur" },
  { id: 4, name: "Campeche" },
  { id: 5, name: "Coahuila" },
  { id: 6, name: "Colima" },
  { id: 7, name: "Chiapas" },
  { id: 8, name: "Chihuahua" },
  { id: 9, name: "Distrito Federal" },
  { id: 10, name: "Durango" },
  { id: 11, name: "Guanajuato" },
  { id: 12, name: "Guerrero" },
  { id: 13, name: "Hidalgo" },
  { id: 14, name: "Jalisco" },
  { id: 15, name: "México" },
  { id: 16, name: "Michoacán" },
  { id: 17, name: "Morelos" },
  { id: 18, name: "Nayarit" },
  { id: 19, name: "Nuevo León" },
  { id: 20, name: "Oaxaca" },
  { id: 21, name: "Puebla" },
  { id: 22, name: "Querétaro" },
  { id: 23, name: "Quintana Roo" },
  { id: 24, name: "San Luis Potosí" },
  { id: 25, name: "Sinaloa" },
  { id: 26, name: "Sonora" },
  { id: 27, name: "Tabasco" },
  { id: 28, name: "Tamaulipas" },
  { id: 29, name: "Tlaxcala" },
  { id: 30, name: "Veracruz" },
  { id: 31, name: "Yucatán" },
  { id: 32, name: "Zacatecas" },
];

export const TIPOS_INGRESO = [
  { id: "Sueldos y salarios", name: "Sueldos y salarios" },
  { id: "Honorarios", name: "Honorarios" },
  { id: "Intereses y dividendos", name: "Intereses y dividendos" },
  { id: "Ingresos por arrendamientos", name: "Ingresos por arrendamientos" },
  { id: "Venta de bienes inmuebles", name: "Venta de bienes inmuebles" },
  { id: "Pensión o jubilaciones", name: "Pensión o jubilaciones" },
  { id: "Ganancia de premios", name: "Ganancia de premios" },
];

export const REGIMEN_FISCAL = [
  { id: "601", name: "General de Ley Personas Morales" },
  { id: "603", name: "Personas Morales con Fines no Lucrativos" },
  { id: "605", name: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { id: "606", name: "Arrendamiento" },
  { id: "607", name: "Régimen de Enajenación o Adquisición de Bienes" },
  { id: "608", name: "Demás ingresos" },
  {
    id: "610",
    name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  { id: "611", name: "Ingresos por Dividendos (socios y accionistas)" },
  {
    id: "612",
    name: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { id: "614", name: "Ingresos por intereses" },
  { id: "615", name: "Régimen de los ingresos por obtención de premios" },
  { id: "616", name: "Sin obligaciones fiscales" },
  {
    id: "620",
    name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  { id: "621", name: "Incorporación Fiscal" },
  {
    id: "622",
    name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  { id: "623", name: "Opcional para Grupos de Sociedades" },
  { id: "624", name: "Coordinados" },
  {
    id: "625",
    name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  { id: "626", name: "Régimen Simplificado de Confianza" },
];

export const RELATIONSHIP = [
  {
    id: "Padre",
    name: "Padre",
  },
  {
    id: "Madre",
    name: "Madre",
  },
  {
    id: "Hijo",
    name: "Hijo",
  },
  {
    id: "Hija",
    name: "Hija",
  },
  {
    id: "Otro",
    name: "Otro",
  },
];

export const SECTORS_LIST = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y gas",
  "Construcción",
  "Industrias manufactureras",
  "Comercio",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Actividades legislativas, gubernamentales, de impartición de justicia",
];

export const SECTORS = SECTORS_LIST.map((sector, index) => {
  return {
    id: index + 1,
    name: sector,
  };
});

export const Beneficiary = {
  name: "",
  percent: "",
  relationship: "",
  birth: "",
  nation: "",
  country: "",
  state: "",
  address: "",
};

export const EconomicDependent = {
  name: "",
  relationship: "",
  relationship_other: "",
  birth: "",
  nation: "",
  country: "",
  state: "",
  address: "",
};

export const Society = {
  sociedad_name: "",
  sociedad_commercial: "",
  sociedad_nation: "",
  sociedad_apoderado: "",
  sociedad_tel: "",
  sociedad_percent: "",
  sociedad_counselor: null,
};

export const ACTIVIDAD_ECONOMICA = [
  { id: 1, name: "Empleado" },
  { id: 2, name: "Prestador de servicios" },
  { id: 3, name: "Jubilado" },
]
