import React from "react";
import * as _ from "lodash";
import dayjs from "dayjs";
import DateFormat from "../../../../../components/Common/DateFormat";
import Currency from "../../../../../components/Common/Currency";

export const CONTRACT_RETIRE = {
  NO_RETIRE: 0,
  RETIRE: 1,
};

export const TYPES = {
  IN: "ingreso",
  OUT: "egreso",
};

export const TYPES_STYLES = {
  [TYPES.IN]: {
    label: "Ingreso",
    color: "bg-primary",
  },
  [TYPES.OUT]: {
    label: "Egreso",
    color: "bg-info",
  },
};

const Columns = (cancelContract, retireContract, detailContract) => {
  let columns = [
    {
      id: "status",
      name: "Tipo",
      selector: (row) => {
        const { color, label } = _.get(TYPES_STYLES, row?.type, {});
        /* return label; */
        return <span className={`${color} badge bg-secondary`}>{label}</span>;
      },
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Monto",
      selector: (row) => <Currency amount={row.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "created_at",
      name: "Fecha de operación",
      selector: ({ operation_date }) => (
        <div>
          <DateFormat date={operation_date} format="DD/MM/YYYY" />
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];
  return columns;
};

export default Columns;
