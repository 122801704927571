import { Card, CardBody, Container, Spinner } from "reactstrap";
import React from "react";
import { Range } from "react-range";
import ReactEcharts from "echarts-for-react";

import NibbleInput from "../../components/Fields/NibbleInput";
import Currency from "../../components/Common/Currency";
import useCalculator from "./useCalculator";

const Calculator = () => {
  const {
    values,
    setValues,
    isLoading,
    validation,
    tableValues,
    benchmarkPercentage,
    smartBrokerPercentage,
  } = useCalculator();

  const getGraphOptions = () => {
    return {
      grid: {
        zlevel: 0,
        x: 80,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      color: ["#3a64ae", "#90a1d4"],
      legend: {
        data: ["Smart Brokers", "Benchmark"],
        textStyle: { color: "#858d98" },
      },
      xAxis: [
        {
          type: "category",
          data: tableValues.smartbrokers.map(
            (item, index) => `${index + 1} año`
          ),
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            lineStyle: {
              color: "#858d98",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          min: 0,
          //max: 250,
          //interval: 50,
          axisLine: {
            lineStyle: {
              color: "#858d98",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(133, 141, 152, 0.1)",
            },
          },
          axisLabel: {
            formatter: "${value}",
          },
        },
      ],
      series: [
        {
          name: "Smart Brokers",
          type: "bar",
          data: tableValues.smartbrokers.map((item) => item.monto),
        },
        {
          name: "Benchmark",
          type: "bar",
          data: tableValues.benchmark.map((item) => item.monto),
        },
      ],
    };
  };

  document.title = "Calculadora";

  if (isLoading)
    return (
      <React.Fragment>
        <div className="page-content pt-4">
          <Container fluid>
            <Card>
              <CardBody className="text-center">
                <Spinner />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <Container fluid>
          <Card>
            <CardBody>
              <h2 className="text-center">Calculadora de rendimientos</h2>
              <div className="row justify-content-center align-items-center py-4">
                <div className="col-md-4">
                  <label className="form-label form-label">
                    Capital a invertir: <Currency amount={values[0]} />
                  </label>
                  <div className="d-flex justify-content-between pb-2">
                    <span>$1,000</span>
                    <span>$1,000,000.00</span>
                  </div>
                  <Range
                    defaultValue={[10000]}
                    step={100}
                    min={1000}
                    max={1000000}
                    values={values}
                    onChange={(values) => setValues(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "6px",
                          width: "100%",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        key={props.key}
                        style={{
                          ...props.style,
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#0E3386",
                        }}
                      />
                    )}
                  />
                </div>
                <NibbleInput
                  md={4}
                  label="Periodo:"
                  fieldName="period"
                  fieldType="select"
                  selectItems={[
                    {
                      id: 1,
                      name: "12 meses",
                    },
                    {
                      id: 2,
                      name: "24 meses",
                    },
                    {
                      id: 3,
                      name: "36 meses",
                    },
                    {
                      id: 4,
                      name: "48 meses",
                    },
                    {
                      id: 5,
                      name: "60 meses",
                    },
                  ]}
                  validation={validation}
                  defaultValue={5}
                />
              </div>
            </CardBody>
          </Card>
          <div className="row pt-4 d-none">
            <div className="col-md-12">
              <h4>Smart Brokers</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Tasa de rendimiento</th>
                    <th scope="col">{smartBrokerPercentage}%</th>
                    {tableValues.smartbrokers.map((item) => (
                      <th key={item.meses} scope="col"></th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Intereses</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>
                        <Currency amount={item.interes} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Monto</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>
                        <Currency amount={item.monto} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Plazo</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>{item.meses} meses</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-12 pt-4">
              <h4>Benchmark</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Tasa de rendimiento</th>
                    <th scope="col">{benchmarkPercentage}%</th>
                    {tableValues.benchmark.map((item) => (
                      <th key={item.meses} scope="col"></th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Intereses</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>
                        <Currency amount={item.interes} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Monto</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>
                        <Currency amount={item.monto} />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Plazo</th>
                    <td></td>
                    {tableValues.smartbrokers.map((item) => (
                      <td key={item.meses}>{item.meses} meses</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12  pt-4">
              <h4>Comparativo</h4>
            </div>
            <div className="col-md-6">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Periodo</th>
                    <th scope="col">Smart Brokers</th>
                    <th scope="col">Benchmark</th>
                  </tr>
                </thead>
                <tbody>
                  {tableValues.smartbrokers.map((item, index) => (
                    <tr key={item.meses}>
                      <td>{index + 1} año</td>
                      <td>
                        <Currency amount={item.monto} />
                      </td>
                      <td>
                        <Currency
                          amount={tableValues.benchmark[index]?.monto}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <ReactEcharts
                style={{ height: "350px" }}
                option={getGraphOptions()}
              />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Calculator;
