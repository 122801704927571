import React, { useCallback, useEffect, useState } from "react";
import Columns, { CONTRACT_STATUS_STYLES } from "./Columns";

import DetailModal from "./DetailModal";
import { getClientContracts } from "../../../../../helpers/backend_helper";
import DataTableHelper from "../../../../../helpers/tables/DataTableHelper";

const INIT_FILTERS = {
  page: 1,
  perPage: 20,
  search: "",
  orderBy: "id",
  order: "asc",
  status: "",
};

const statusOptiones = Object.entries(CONTRACT_STATUS_STYLES);

const InvestingsTab = ({ userId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientContract, setClientContract] = useState();
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [isLoading, setIsLoading] = useState(true);
  const [myInvestings, setMyInvestings] = useState({ data: [] });

  console.log({ filters });

  const getMyInvestings = useCallback((filters) => {
    const config = { params: filters };

    console.log("Se redefinio!");

    setIsLoading(true);
    getClientContracts(userId, config)
      .then(responseAction)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getMyInvestings(filters);
  }, [filters]);

  const responseAction = (response) => {
    if (response.status) {
      setMyInvestings(response.clientContracts);
    }
  };

  const detailContract = (row) => {
    setIsModalOpen(true);
    setClientContract(row);
  };

  const columns = Columns(detailContract);
  document.title = "Mis inversiones";
  return (
    <React.Fragment>
      <div className="col-12">
        <DataTableHelper
          tittle="Inversiones"
          columns={columns}
          items={myInvestings.data}
          pending={isLoading}
          config={myInvestings}
          filters={filters}
          setFilters={setFilters}
        >
          <div>
            <select
              name="status"
              value={filters.status}
              onChange={(e) => {
                const { value, name } = e.target;

                setFilters((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              placeholder="Filtrar por estatus"
              className={`form-select form-select-sm ${
                !filters.status && "text-muted"
              }`}
            >
              <option value="" className="text-muted">
                Filtrar por estatus
              </option>
              {statusOptiones.map(([key, value]) => (
                <option key={key} value={key} className="text-dark">
                  {value.label}
                </option>
              ))}
            </select>
          </div>
        </DataTableHelper>
        <DetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          clientContract={clientContract}
        />
      </div>
    </React.Fragment>
  );
};

export default InvestingsTab;
