import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { getAdminSummaryContracts } from "../../../helpers/backend_helper";

const Summary = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [summaryData, setSummaryData] = useState({});

  useEffect(() => {
    getAdminSummaryContracts()
      .then((resp) => {
        if (resp.status) {
          setSummaryData(resp.summary);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading)
    return (
      <div>
        <p className="card-text placeholder-glow pb-3">
          <span className="placeholder col-7"></span>{" "}
          <span className="placeholder col-4"></span>{" "}
          <span className="placeholder col-4"></span>{" "}
          <span className="placeholder col-6"></span>{" "}
          <span className="placeholder col-8"></span>{" "}
        </p>
      </div>
    );

  return (
    <div>
      <Row>
        <Col xl={3} md={6}>
          <Card className="card-h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12} className="text-center">
                  <span className="text-muted mb-3 lh-1 d-block text-truncate">
                    Inversiones Activas
                  </span>
                  <h4 className="mb-3">{summaryData?.activeContracts ?? '-'}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="card-h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12} className="text-center">
                  <span className="text-muted mb-3 lh-1 d-block text-truncate">
                    Inversiones Activas Por Retirar
                  </span>
                  <h4 className="mb-3">{summaryData?.activeContractsPerRetire ?? '-'}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="card-h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12} className="text-center">
                  <span className="text-muted mb-3 lh-1 d-block text-truncate">
                    Inversiones Comprometidas
                  </span>
                  <h4 className="mb-3">{summaryData?.committedContracts}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} md={6}>
          <Card className="card-h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs={12} className="text-center">
                  <span className="text-muted mb-3 lh-1 d-block text-truncate">
                    Inversiones Completadas
                  </span>
                  <h4 className="mb-3">{summaryData?.completeContracts}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
