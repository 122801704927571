import React, { useState } from "react";
import Dropzone from "react-dropzone";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import {
  downloadProductTemplate,
  uploadProductTemplate,
} from "../../helpers/backend_helper";
import { downloadFile } from "../../utils/files";

const UpdTemplateModal = ({ item, setItem, modal, setModal }) => {
  const [template_performance_type, setTemplate_performance_type] =
    useState("%");
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleClose = () => {
    setModal((prev) => ({ ...prev, template: false }));
  };

  const handleUploadTemplate = (files) => {
    const formData = new FormData();
    formData.append("excel", files[0]);
    formData.append("template_performance_type", template_performance_type);

    setLoading(true);
    uploadProductTemplate(item.id, formData)
      .then((resp) => {
        if (resp?.status) {
          setItem(resp.item);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDownloadTemplate = async () => {
    setIsDownloading(true);
    try {
      const config = {
        responseType: "blob",
      };

      const file = await downloadProductTemplate(item.id, config);

      downloadFile(file, "plantilla-producto.pdf");
    } catch (error) {
      console.log("plantilla", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div>
      <Modal isOpen={modal.template} className="modal-lg">
        <ModalHeader>Cargar plantilla de rendimientos</ModalHeader>
        <ModalBody>
          <div>
            <div className="mt-4 mb-4">
              <h5 className="font-size-14 mb-4">Tipo de interés</h5>
              <div className="d-flex mb-1">
                <div
                  className="form-check me-4"
                  onClick={() => setTemplate_performance_type("%")}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="template_performance_type"
                    value="%"
                    checked={template_performance_type === "%"}
                  />
                  <label className="form-check-label">Porcentaje (%)</label>
                </div>
                <div
                  className="form-check"
                  onClick={() => setTemplate_performance_type("$")}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="template_performance_type"
                    value="$"
                    checked={template_performance_type === "$"}
                  />
                  <label className="form-check-label">Cantidad ($)</label>
                </div>
              </div>
            </div>
          </div>
          <Dropzone
            accept=".xlsx,.xls"
            onDrop={handleUploadTemplate}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone mb-4" style={{ minHeight: "120px" }}>
                <div
                  className="text-center needsclick mt-2"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-1">
                    <i className="display-5 text-muted bx bx-cloud-upload" />
                  </div>
                  <h5>Arrastra archivos aquí o da clic en cargar</h5>
                  {loading && <Spinner className="my-2" size="sm" />}
                </div>
              </div>
            )}
          </Dropzone>
          <div>
            {item?.template_performance ? (
              <div>
                <h6>Rendimientos</h6>
                <div className="table-responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <td scope="col">Periodo</td>
                        {item?.template_performance?.periodos?.map(
                          (periodo, index) => (
                            <td className="text-center" key={index} scope="col">
                              <small>{periodo}</small>
                            </td>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.template_performance?.instrumentos?.map(
                        (periodo) => (
                          <tr key={periodo?.nombre}>
                            <td key={periodo} scope="col">
                              <small>{periodo?.nombre}</small>
                            </td>
                            {periodo?.rendimientos?.map(
                              (rendimiento, index) => (
                                <td className="text-center" key={index}>
                                  <small>
                                  {template_performance_type === '%' ? Number(rendimiento*100).toFixed(2) : rendimiento}
                                  {item?.template_performance_type}
                                  </small>
                                </td>
                              )
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}

            {item?.template_tables ? (
              <>
                {Object.entries(item?.template_tables)?.map((entry) => {
                  const [tableName, item] = entry;
                  return (
                    <div key={tableName}>
                      <h6>{tableName}</h6>
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            {item?.periodos?.map((periodo, index) => (
                              <td key={index} scope="col">
                                {periodo}
                              </td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {item?.cantidades?.map((cantidad, index) => (
                              <td key={index} scope="col">
                                <small>
                                {Number(cantidad*100).toFixed(2)}%
                                </small>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
          <div className="py-3 text-center">
            {isDownloading ? (
              <Spinner />
            ) : (
              <a
                className="btn btn-success"
                title="Descargar template"
                href="./plantilla_rendimientos.xlsx"
              >
                <i className="fas fa-file-download"></i> Descargar plantilla
              </a>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="d-none"
            color="primary"
            type="button"
            disabled={loading}
            onClick={handleClose}
          >
            {/* {loading && <Spinner size="sm"></Spinner>}{" "} */}
            <i className="fa fa-save"></i> Guardar
          </Button>{" "}
          <Button color="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdTemplateModal;
