import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./DataTable.css";
import { Col, Progress, Row } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Currency from "../../components/Common/Currency";
import Countdown from "../../pages/MyProducts/Countdown";

const DataTableBoxHelper = ({
  tittle,
  columns,
  items,
  pending,
  filters,
  setFilters,
  config,
  children = false,
  wrapperStyles = {},
  isPublicProduct = false,
}) => {
  const [cols, setCols] = useState(4);
  const [orderByData, setOrderByData] = useState(true);
  const [newItems, setNewItems] = useState(items);
  const [filterText, setFilterText] = useState("");
  let filterColumns = columns.filter((col) => {
    if (!col.button) {
      return col;
    }
  });
  filterColumns = filterColumns.map((col) => col.id);

  useEffect(() => {
    const search = () => {
      setFilters({ ...filters, search: filterText });
    };
    //if(filterText.length>0)
    search();
  }, [filterText]);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const handlePageChange = (page) => {
    console.log("okd");
    setFilters({ ...filters, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("okd");
    setFilters({ ...filters, perPage: newPerPage, page: page });
  };

  const orderBy = async (cols) => {
    if (orderByData) {
      setOrderByData(false);
      setFilters({ ...filters, orderBy: cols.id, order: "asc" });
    } else {
      setOrderByData(true);
      setFilters({ ...filters, orderBy: cols.id, order: "desc" });
    }
  };

  useEffect(() => {
    setCols(items.length == 3 || items.length == 4 ? 6 : 4);
  }, [items.length]);

  const TIPOS = {
    1: {
      name: "Mutuo Amortizable con garantía",
      img: "/moneta.png",
    },
    2: {
      name: "Portafolio deuda discrecional",
      img: "fortuna.png",
    },
    3: {
      name: "Portafolio de renta variable",
      img: "sneed.png",
    },
  };

  console.log(items);

  return (
    <div className="table-responsive" style={{ ...wrapperStyles }}>
      <Row>
        {(items || []).map((e, idx) => {
          let percentage = 0;

          if (e?.mutual_amount) {
            percentage =
              (e?.client_contracts_sum_amount / e.mutual_amount) * 100;
          }

          return (
            <div key={idx} className={"col-md-" + cols + " mb-2"}>
              <div className="border p-4 text-center">
                <img className="w-75" src={TIPOS[e.type_id]?.img}></img>
                <h2
                  className="mb-1 mt-0 fw-normal text-black"
                  style={{ lineHeight: "1em" }}
                >
                  {e.name} - {TIPOS[e.type_id]?.name}
                </h2>
                {e.type_id == 1 && (
                  <p className="mb-1 mt-2 fw-bold text-black fs-4">
                    Clave {e.clave_mutuo}
                  </p>
                )}
                <p
                  style={{ fontSize: "65px" }}
                  className="text-primary fw-bold mt-0 mb-0"
                >
                  <b>{e.tasa}%</b>
                </p>
                <p className="text-primary fw-bold fs-4 mb-5">
                  Taza de rendimiento{" "}
                </p>

                {e.type_id == 1 && (
                  <>
                    {e.start_date ? (
                      <Row>
                        <Col>
                          <Countdown startDate={e.mutual_start_date} />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className="fw-bold mb-4">
                      <Col md={12} className="text-start">
                        <Progress
                          style={{ height: "18px" }}
                          value={percentage.toFixed(2)}
                        >
                          Invertido actual {percentage.toFixed(2)}%
                        </Progress>
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Nivel de riesgo
                      </Col>
                      <Col md={6} className="text-end">
                        {e.risk_level == 1 ? (
                          <>
                            <i className="fa fa-star"></i>
                          </>
                        ) : (
                          ""
                        )}
                        {e.risk_level == 2 ? (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </>
                        ) : (
                          ""
                        )}

                        {e.risk_level == 3 ? (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </>
                        ) : (
                          ""
                        )}

                        {e.risk_level == 4 ? (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </>
                        ) : (
                          ""
                        )}

                        {e.risk_level == 5 ? (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Inversión mínima
                      </Col>
                      <Col md={6} className="text-end">
                        <Currency amount={e.min_investing} />
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Captia objetivo
                      </Col>
                      <Col md={6} className="text-end">
                        <Currency amount={e.mutual_amount} />
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Capital mínimo
                      </Col>
                      <Col md={6} className="text-end">
                        <Currency amount={e.import} />
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Monto mínimo sugerido
                      </Col>
                      <Col md={6} className="text-end">
                        <Currency amount={e.min_investing} />
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Días para levantar recurso
                      </Col>
                      <Col md={6} className="text-end">
                        {e.lead_capture_days} días
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Días de activación
                      </Col>
                      <Col md={6} className="text-end">
                        {e.activation_days} días
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Plazo
                      </Col>
                      <Col md={6} className="text-end">
                        {e.plazo}
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Retorno de capital
                      </Col>
                      <Col md={6} className="text-end">
                        {e.capital_return}
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Pago de ganancias
                      </Col>
                      <Col md={6} className="text-end">
                        {e.earning_return}
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Garantía
                      </Col>
                      <Col md={6} className="text-end">
                        {e.warranty}
                      </Col>
                    </Row>
                  </>
                )}

                {e.type_id != 1 && (
                  <>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Monto mínimo sugerido
                      </Col>
                      <Col md={6} className="text-end">
                        <Currency amount={e.min_amount} />
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Permanencia mínima
                      </Col>
                      <Col md={6} className="text-end">
                        {e.min_time} meses
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Fecha de liquidación
                      </Col>
                      <Col md={6} className="text-end">
                        {e.fec_liquidacion}
                      </Col>
                    </Row>
                    <Row className="fw-bold mb-4">
                      <Col md={6} className="text-start">
                        Tiempo de retiro del recursos
                      </Col>
                      <Col md={6} className="text-end">
                        {e.time_liquidacion}
                      </Col>
                    </Row>
                  </>
                )}

                {isPublicProduct && e?.external_url ? (
                  <p>
                    <a
                      href={e.external_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Más información
                    </a>
                  </p>
                ) : null}

                {!isPublicProduct ? (
                  <p>
                    <Link
                      to={`/my-product/${e.id}`}
                      className="btn btn-primary"
                    >
                      Más información
                    </Link>
                  </p>
                ) : null}
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
};

export default DataTableBoxHelper;
