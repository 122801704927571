import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";

class Pie extends Component {
  getOption = () => {
    const data = this.props.data ?? [];
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      /*
      legend: {
        orient: "vertical",
        left: "left",
        data: data.map(e=>e.name),
        textStyle: {
          color: ['#fd625e', '#2ab57d', '#4ba6ef', '#ffbf53', '#5156be', '#AAA'],
        },
      },
      */
      color: [
        "#0066CC",
        "#4CB140",
        "#009596",
        "#5752D1",
        "#F4C145",
        "#EC7A08",
        "#7D1007",
        "#8BC1F7",
        "#BDE2B9",
        "#A2D9D9",
        "#B2B0EA",
        "#F9E0A2",
        "#F4B678",
        "#C9190B",
      ],
      series: [
        {
          name: "Composición",
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          data: data.map((e) => {
            return { name: e.name, value: e.pivot.percent };
          }),
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.getOption()} />
      </React.Fragment>
    );
  }
}
export default Pie;
