import * as _ from "lodash";

export const configOptions = {
  TITULAR_CUENTA: 7,
  BANCO: 8,
  CUENTA: 9,
  REF_INVERSIONISTA: 10,
  REF_ACREDITADOS: 11,
  COMISION_PLATAFORMA: 12,
  TASA_CALCULADORA_SMARTBROKERS: 13,
  TASA_CALCULADORA_BENCHMARK: 14,
  FIRMA_SMART_BROKERS: 15,
  NO_CONTRATO: 16,
};

const getOptionValue = (id, optionsObject) => {
  const val = _.get(optionsObject, `${id}.value`, "");

  return val;
};

export const getFormValues = (optionsObject) => ({
  [configOptions.TITULAR_CUENTA]: getOptionValue(
    configOptions.TITULAR_CUENTA,
    optionsObject
  ),
  [configOptions.BANCO]: getOptionValue(configOptions.BANCO, optionsObject),
  [configOptions.CUENTA]: getOptionValue(configOptions.CUENTA, optionsObject),
  [configOptions.REF_INVERSIONISTA]: getOptionValue(
    configOptions.REF_INVERSIONISTA,
    optionsObject
  ),
  [configOptions.REF_ACREDITADOS]: getOptionValue(
    configOptions.REF_ACREDITADOS,
    optionsObject
  ),
  [configOptions.COMISION_PLATAFORMA]: getOptionValue(
    configOptions.COMISION_PLATAFORMA,
    optionsObject
  ),
  [configOptions.TASA_CALCULADORA_SMARTBROKERS]: getOptionValue(
    configOptions.TASA_CALCULADORA_SMARTBROKERS,
    optionsObject
  ),
  [configOptions.TASA_CALCULADORA_BENCHMARK]: getOptionValue(
    configOptions.TASA_CALCULADORA_BENCHMARK,
    optionsObject
  ),
  [configOptions.NO_CONTRATO]: getOptionValue(
    configOptions.NO_CONTRATO,
    optionsObject
  ),
});
