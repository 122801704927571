import React, { useState } from "react";
import { Table } from "reactstrap";

const TablesProductPerformace = ({ data, type = "%" }) => {
  if (!data) return <></>;

  const [tables, setTables] = useState(Object.keys(data));

  return (
    <>
      {tables.map((table, index) => {
        return (
          <div key={table} className="table-responsive">
            <Table
              className="table table-bordered table-condensed table-sm"
            >
              <thead className="bg-light">
                <tr>
                  <th
                    colSpan={data[table]?.periodos.length}
                    className="text-center"
                  >
                    {table}
                  </th>
                </tr>
                <tr key={index}>
                  {data[table]?.periodos.map((item, index) => {
                    return (
                      <th className="text-center" key={index}>
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr key={index}>
                  {data[table]?.cantidades.map((item, index) => {
                    return (
                      <td className="text-center" key={index}>
                        {type === '%' ? Number(item*100).toFixed(2) : item}
                        {type}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
};

export default TablesProductPerformace;
