import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

const calculateTimeLeft = (startDate) => {
  const now = dayjs();
  const target = dayjs(startDate);
  const difference = target.diff(now);

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
};

const Countdown = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(startDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate]);

  return (
    <div>
      <p className="text-primary fw-bold fs-5 mb-0">{timeLeft.days} días</p>
      <p className="text-primary fw-bold fs-6 mb-5">
        {timeLeft.hours} horas {timeLeft.minutes} minutos {timeLeft.seconds}{" "}
        segundos
      </p>
    </div>
  );
};

export default Countdown;
