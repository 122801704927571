import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input, Form,FormFeedback, Label} from 'reactstrap';
import Select from 'react-select';
import { getProfilescat, postProfile, putProfile, getCompanies, putProfileRisk, postProfileRisk, putClient, postClient, putProduct, postProduct, putSurvey, postSurvey } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from 'classnames';


const AddUpdModal = ({modal,setModal,items, setItems, setItem, item}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
    }

    const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			...item
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Requerido').min(1),
			active: Yup.boolean(),
		}),
		onSubmit: async values => {
			await save(values)
			handleClose()
			validation.resetForm();
		},
	});

    const save = async (data) => {
    	setErrors([])
    	setLoading(true)
		const response = modal.update ? await putSurvey(data) : await postSurvey(data);
		responseAction(response);
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.item;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.companies,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    	});
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader className='bg-primary'>
					<span className='text-light'>
						{modal.update ? 'Editar':'Agregar'} Encuesta
					</span>
				</ModalHeader>
				<Form
					onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
					}}
				>
				<ModalBody>
				<div className="row">
					<div className="col-sm-12">
						
						<Label>Nombre</Label>
					    <Input
							name="name"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.name || ""}
							invalid={
								validation.touched.name &&
								validation.errors.name
								? true
								: false
							}
						/>
					    {(validation.touched.name && validation.errors.name) && 
							<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>}
					</div>

					<div className="col-sm-12 mt-3">
						<Label for='active' >Activo</Label> <Input
							name="active"
							type="checkbox"
							onChange={(evt) => { validation.setFieldValue('active', evt.target.checked)}}
							onBlur={validation.handleBlur}
							value={validation.values.active || false}
						/>
					    
					</div>
				</div>	

				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } <i className='fa fa-save'></i> Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}><i className='fa fa-times'></i> Cancelar</Button>
				</ModalFooter>
				</Form>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;