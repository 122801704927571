import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input, Form,FormFeedback, Label, Row} from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postProfile,putProfile,getCompanies, putProfileRisk, postProfileRisk, putInstrument, postInstrument, putPeriod, postPeriod, putProductTypes, postProductTypes } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import * as Yup from "yup";
import { useFormik } from "formik";
import NibbleInput from '../../components/Fields/NibbleInput';


const AddUpdModal = ({modal,setModal,items, setItems, setItem,item}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
    }
	console.log('tem', item)
    const validation = useFormik({
		enableReinitialize: true,
		initialValues: {...item},
		validationSchema: Yup.object({
			name: Yup.string().nullable().required('Required').min(1),
		}),
		onSubmit: async values => {
			await save(values)
			handleClose()
			validation.resetForm();
		},
	});

	const save = async (data) => {
    	setErrors([])
    	setLoading(true)
		let response = modal.update ? await putProductTypes(data) : await postProductTypes(data)
		responseAction(response);
    }

    const responseAction = (response) => {
    	if(response?.status){
    		const newItem = response.instrument;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response?.response?.data?.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.companies,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    	});
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader className='bg-primary'>
					<span className='text-light'>
						{modal.update ? 'Editar':'Agregar'} Tipo de Producto
					</span>
				</ModalHeader>
				<Form
					onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
					}}
				>
				<ModalBody>
					<Row>
						<NibbleInput
							md={12}
							maxLength={50}
							label="Nombre"
							fieldName="name"
							fieldType="text"
							validation={validation}
						/>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } <i className='fa fa-save'></i> Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}><i className='fa fa-times'></i> Cancelar</Button>
				</ModalFooter>
				</Form>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;