import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import {
  delInstrument,
  delProfileRisk,
  getInstrumentPermissions,
  getProfileRiskPermissions,
} from "../../helpers/backend_helper";
import {
  DestroyAlertHelper,
  Alert,
  ToastHelper,
} from "../../helpers/alerts/alertHelper";
import { getPolicyUser } from "../../helpers/api_helper";
import { Container } from "reactstrap";
import { deleteProject } from "../../components/helpers/fakebackend_helper";
import ExcelModal from "./ExcelModal";

const Instruments = () => {
  const itemBase = useState({
    id: null,
    name: "",
    short: "",
  });
  const [pending, setPending] = useState(false);
  const [modal, setModal] = useState({ show: false, update: false });
  const policy = getPolicyUser();
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);
  const [profile, setProfile] = useState(itemBase);
  const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);

  const dispatch = useDispatch();

  const loadInstruments = useCallback(() => {
    //ToastHelper('mensaje','error')
    let params = { params: filters };
    console.log("params", params);
    setPending(true);
    getInstrumentPermissions(params).then((response) => {
      setItems(response.data);
      setDataPag(response.meta);
      //console.log(response)
      setPending(false);
    });
  }, [filters]);

  useEffect(() => {
    loadInstruments();
  }, [loadInstruments]);

  const addUpd = (row = false) => {
    if (row.id) {
      setProfile(row);
      setModal({ ...modal, show: true, update: true });
    } else {
      setProfile(itemBase);
      setModal({ ...modal, show: true, update: false });
    }
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      delInstrument(id).then((response) => {
        Alert(response.message, response.status);
        if (response.status) {
          setItems(items.filter((i) => i.id != id));
        }
      });
    });
  };

  const columns = Columns(addUpd, destroy, policy);
  document.title = "Instrumentos";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Instrumentos"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            >
              <div>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => setIsExcelModalOpen(true)}
                  style={{ marginRight: "10px" }}
                >
                  <i className="fas fa-file-excel"></i> Cargar
                </button>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => addUpd()}
                >
                  <i className="fas fa-solid fa-plus"></i> Agregar
                </button>
              </div>
            </DataTableHelper>
          </div>
        </Container>
        {modal.show && (
          <AddUpdModal
            modal={modal}
            setModal={setModal}
            items={items}
            setItems={setItems}
            item={profile}
            setItem={setProfile}
          />
        )}
        <ExcelModal
          open={isExcelModalOpen}
          handleClose={() => setIsExcelModalOpen(false)}
          loadInstruments={loadInstruments}
        />
      </div>
    </React.Fragment>
  );
};

export default Instruments;
