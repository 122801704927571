import ValidateHelper from "../../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	console.log(inputs)
	if (inputs.configuration_id!=1 && inputs.configuration_id!=4) {
		const option_id = ValidateHelper({
			input: inputs.option_id,
			attribute: 'option_id',
			required: true, 
			type: 'integer'
		});	
		if (option_id != undefined){
			errors.option_id = [option_id];
			flag = true;
		}
	}
	
	const company_id = ValidateHelper({
		input: inputs.company_id,
		attribute: 'company_id',
		required: true, 
		type: 'integer'
	});
	let errors = {
		company_id: [],
		option_id: []
	};

	let flag = false;

	if (company_id != undefined){
		errors.company_id = [company_id];
		flag = true;
	}
	

	return flag ? errors : undefined;
}
export default Validate;