import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form, Button, Spinner, ModalBody, ModalFooter } from "reactstrap";

import {
  ProspectGeneralForm,
  prospectGeneralValidation,
} from "../General";
import { postProspect, putProspect } from "../../../helpers/backend_helper";

const GeneralForm = ({ modal, item, realoadData, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object(prospectGeneralValidation),
    onSubmit: (values) => {
      save(values);
    },
  });

  const save = async (data) => {
    setLoading(true);
    const response = modal.update
      ? await putProspect(data)
      : await postProspect(data);
    responseAction(response);
  };

  const responseAction = (response) => {
    if (response.status) {
      handleClose();
      validation.resetForm();
      realoadData && realoadData();
    }

    setLoading(false);
  };

  return (
    <>
      <ModalBody>
        <Form
          id="general-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ProspectGeneralForm validation={validation} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="text-end">
          <Button
            color="primary"
            form="general-form"
            type="submit"
            disabled={loading}
          >
            {loading && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Guardar
          </Button>{" "}
          <Button color="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Cancelar
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default GeneralForm;
