import React, { useEffect } from "react";

import NibbleInput from "../../../components/Fields/NibbleInput";

import { ESTADOS } from "../TabGenerales";
import { getZipCodeInfo } from "../../../helpers/backend_helper";
import useCancelableRequest from "../../../hooks/useCancelableRequest";
import { Spinner } from "reactstrap";
import { useState } from "react";

const CpInput = ({ validation }) => {
  const getCancelTokenSource = useCancelableRequest();

  const [isLoading, setIsLoading] = useState(false);

  const formValues = validation.values;
  const zipCode = validation.values?.fiscal_zipcode;

  const fillGeneralData = (data) => {
    const zipInfo = data[0];
    if (!zipCode) return;

    const estado = ESTADOS.find((item) => item.name == zipInfo?.estado);

    const newGeneralData = {
      //ti_birth_country: zipInfo?.pais || "",
      //ti_birth_state: estado?.id || "",
      fiscal_country: zipInfo?.pais || "",
      fiscal_state: estado?.id || "",
      fiscal_address3: zipInfo?.municipio || "",
    };

    validation.setValues({ ...formValues, ...newGeneralData });
  };

  const fetchZipCodeInfo = () => {
    if (!zipCode) return;

    validation.validateForm().then((errors) => {
      if (errors?.fiscal_zipcode) return;

      const config = {
        params: { zipCode },
        cancelToken: getCancelTokenSource(),
      };

      setIsLoading(true);

      getZipCodeInfo(config)
        .then((resp) => {
          console.log({
            CpInfoResp: resp,
          });
          if (resp.status && resp?.data) {
            fillGeneralData(resp.data);
          }
        })
        .finally(() => setIsLoading(false));
    });
  };

  useEffect(fetchZipCodeInfo, [zipCode]);

  return (
    <NibbleInput
      md={6}
      label={
        <>
          <span>CP:</span> {isLoading && <Spinner size="sm" />}
        </>
      }
      fieldName="fiscal_zipcode"
      fieldType="text"
      validation={validation}
    />
  );
};

export default CpInput;
