import React from "react";
import * as _ from "lodash";

import Currency from "../../../components/Common/Currency";
import DateFormat from "../../../components/Common/DateFormat";
import { CONTRACT_STATUS_STYLES } from "../../Clients/detail/tabs/investings/Columns";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";

const Columns = (status) => {
  let columns = [
    {
      id: "client",
      name: "Cliente",
      selector: (row) => row?.prospect?.full_name,
      sortable: true,
      wrap: true,
    },
    {
      id: "product",
      name: "Producto",
      selector: (row) => row?.product?.name,
      sortable: true,
      wrap: true,
    },
    {
      id: "productType",
      selector: (row) => row?.product?.type?.name,
      name: "Tipo de producto",
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Monto",
      selector: (row) => <Currency amount={row.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "created_at",
      name: "Fecha de activación",
      selector: ({ operation_date }) => (
        <div>
          <DateFormat date={operation_date} format="DD/MM/YYYY" />
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      id: "status",
      name: "Estatus",
      selector: (row) => {
        const { color, label } = _.get(CONTRACT_STATUS_STYLES, row?.status, {});

        return <span className={`${color} badge bg-secondary`}>{label}</span>;
      },
      sortable: true,
      wrap: true,
    },
  ];

  if (status != "blocked") {
    columns.push({
      id: "to_retire",
      name: "Para retirar",
      selector: ({ to_retire }) => <div>{to_retire ? "Sí" : "No"}</div>,
      sortable: true,
      wrap: true,
    });
  }

  if (status == "completed") {
    columns.push({
      id: "final_amount",
      name: "Ganancia Neta",
      selector: ({ final_amount }) => <Currency amount={final_amount} />,
      sortable: true,
      wrap: true,
    });
  }

  return columns;
};

export default Columns;
