import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import {
  delClient,
  delProduct,
  delProfileRisk,
  getClientsPermissions,
  getInstrumentSelect,
  getPeriodSelect,
  getProductTypesSelect,
  getProductsPermissions,
  getProfileRiskPermissions,
  getProfileRiskSelect,
} from "../../helpers/backend_helper";
import {
  DestroyAlertHelper,
  Alert,
  ToastHelper,
} from "../../helpers/alerts/alertHelper";
import { getPolicyUser } from "../../helpers/api_helper";
import { Container } from "reactstrap";
import UpdTemplateModal from "./UpdTemplateModal";
import dayjs from "dayjs";
import FichaModal from "./FichaModal";
import RendimientosModal from "./RendimientosModal";

const Products = () => {
  const itemBase = {
    id: null,
    name: "",
    type: null,
    profilesRisks: [],
    periods: [],
    active: true,
    min_investing: 100,
    description: "",
    bechmark: "",
    composition: "",
    operation: "",
    risks: "",
    administration: "",
    plazos: [],
    profile_risks: [],
    instruments: [{}],
    mutual_start_date: null,
    mutual_end_date: null,
    withdrawal_day_month: null,
    withdrawal_day_quarter: null,
    plazo: "",
    commission: 0,
    hour_processing: "",
    days: [],
    mutual_amount: null,
    start_date: dayjs().format("YYYY-MM-DD"),
    lead_capture_days: 90,
    activation_days: 30,
    import: "",
    serie: "",
    time_limit: "",
    tasa: null,
    min_amount: null,
    min_time: null,
    fec_liquidacion: null,
    time_liquidacion: null,
    capital_return: null,
    earning_return: null,
    warranty: null,
    external_url: null,
    activation_day_month: null
  };

  const [pending, setPending] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    update: false,
    template: false,
    rendimientos: false,
  });
  const policy = getPolicyUser();
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(itemBase);

  const [profilesRisks, setProfilesRisks] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [instruments, setInstruments] = useState([]);

  useEffect(() => {
    //ToastHelper('mensaje','error')
    let params = { params: filters };
    console.log("params", params);
    setPending(true);
    getProductsPermissions(params).then((response) => {
      setItems(response.data);
      setDataPag(response.meta);
      //console.log(response)
      setPending(false);
    });
  }, [filters]);

  const addUpd = (row = false) => {
    if (row.id) {
      setItem({
        ...row,
        profile_risks: row.profile_risks.map((e) => `${e.id}`),
        periods: row.periods.map((e) => `${e.id}`),
        instruments: row.instruments.map((e) => e.pivot),
      });
      setModal({ ...modal, show: true, update: true });
    } else {
      setItem(itemBase);
      setModal({ ...modal, show: true, update: false });
    }
  };

  const showFicha = (row) => {
    setItem(row);

    setModal((prev) => ({ ...prev, ficha: true }));
  };

  const updTemplate = (row) => {
    setItem(row);

    setModal((prev) => ({ ...prev, template: true }));
  };

  const showRendimientos = (row) => {
    setItem(row);

    setModal((prev) => ({ ...prev, rendimientos: true }));
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      delProduct(id).then((response) => {
        Alert(response.message, response.status);
        if (response.status) {
          setItems(items.filter((i) => i.id != id));
        }
      });
    });
  };

  useEffect(() => {
    getProfileRiskSelect().then((response) => {
      setProfilesRisks(response.data);
    });
    getProductTypesSelect().then((response) => {
      setProductTypes(response.data);
    });
    getPeriodSelect().then((response) => {
      setPeriods(response.data);
    });
    getInstrumentSelect().then((response) => {
      setInstruments(response.data);
    });
  }, []);

  const columns = Columns(
    addUpd,
    destroy,
    policy,
    updTemplate,
    showFicha,
    showRendimientos
  );
  document.title = "Productos";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Productos"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            >
              <button
                className="btn btn-sm btn-success"
                onClick={() => addUpd()}
              >
                <i className="fas fa-solid fa-plus"></i> Agregar
              </button>
            </DataTableHelper>
          </div>
        </Container>
        {modal.show && (
          <AddUpdModal
            modal={modal}
            setModal={setModal}
            items={items}
            setItems={setItems}
            item={item}
            setItem={setItem}
            selects={{
              instruments,
              profilesRisks,
              productTypes,
              periods,
            }}
          />
        )}
        {modal.template && (
          <UpdTemplateModal
            item={item}
            setItem={setItem}
            modal={modal}
            setModal={setModal}
          />
        )}

        {modal.ficha && (
          <FichaModal item={item} modal={modal} setModal={setModal} />
        )}
        {modal.rendimientos && (
          <RendimientosModal item={item} modal={modal} setModal={setModal} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Products;
