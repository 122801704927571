import * as Yup from "yup";

const commerAskValidation = Yup.string().when("commer_ask1", {
  is: (value) => !value,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

export const prospectGeneralValidation = {
  ti_name: Yup.string().nullable().required("Requerido"),
  ti_lname1: Yup.string().nullable().required("Requerido"),
  ti_lname2: Yup.string().nullable().required("Requerido"),
  ti_birth: Yup.string().nullable().required("Requerido"),
  ti_rfc: Yup.string()
    .nullable()
    .required("Requerido")
    .min(12, "El campo debe tener al menos 12 caracteres")
    .max(13, "El campo debe tener como máximo 13 caracteres"),
  ti_curp: Yup.string()
    .nullable()
    .required("Requerido")
    .length(18, "El campo debe tener 18 caracteres"),
  ti_phone: Yup.string()
    .matches(
      /^\d{10}$/,
      "El número de teléfono debe tener 10 dígitos numéricos."
    )
    .required("Requerido"),
  ti_landline: Yup.string().matches(
    /^\d{10}$/,
    "El número de teléfono debe tener 10 dígitos numéricos."
  ),
  ti_birth_country: Yup.string().nullable().required("Requerido"),
  ti_birth_state: Yup.string().nullable().required("Requerido"),
  ti_civil: Yup.string().nullable().required("Requerido"),
  ti_genere: Yup.string().nullable().required("Requerido"),
  fiscal_country: Yup.string().nullable().required("Requerido"),
  fiscal_state: Yup.string().nullable().required("Requerido"),
  fiscal_address1: Yup.string().nullable().required("Requerido"),
  fiscal_address2: Yup.string().nullable().required("Requerido"),
  fiscal_address3: Yup.string().nullable().required("Requerido"),
  fiscal_cross_streets: Yup.string().nullable(),
  fiscal_ext: Yup.string().nullable().required("Requerido"),
  fiscal_int: Yup.string().nullable(),
  fiscal_zipcode: Yup.string().nullable().required("Requerido"),
  fiscal_years: Yup.string().nullable().required("Requerido"),
  fiscal_type: Yup.string().nullable().required("Requerido"),
  commer_ask1: Yup.boolean().nullable().required("Requerido"),
  commer_country: commerAskValidation,
  commer_state: commerAskValidation,
  commer_address1: commerAskValidation,
  commer_address2: commerAskValidation,
  commer_address3: commerAskValidation,
  commer_ext: commerAskValidation,
  commer_int: Yup.string().nullable(),
  commer_zipcode: commerAskValidation,
};
