import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';
import Select from 'react-select';
import ValidateFileHelper from '../../../helpers/ValidateFileHelper'
import Validate from './Validate'
import {Alert,ToastHelper} from '../../../helpers/alerts/alertHelper';
import { postFileFileConfiguration,getConfigurationsSelects } from '../../../helpers/backend_helper';

const AddUpdModal = ({modal,setModal,items, setItems, setConfiguration,configuration,company}) => {
	const [ errors, setErrors ] = useState([]);
	const [ configurations, setConfigurations ] = useState([]);
	const [ options, setOptions ] = useState([]);
	const [file, setFile] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setConfiguration( {...configuration,
			option_id:'',
	        company_id:company.id,
	        value:'',
	        configuration:{
	            id:null,
	            name:''
	        }
		});
	}

	const handlerOnChange = (value,name) => {
    	if(name=='configuration' || name=='option'){
    		setConfiguration({...configuration, [name=='configuration' ? 'configuration_id':'option_id']:value.id,[name]:value});
    	}
    }

    const save = () => {
    	ToastHelper('mensaje','error')
    	setErrors([])
    	const validate = Validate(configuration);
    	if(validate != undefined){
    		console.log(validate)
            setErrors(validate);
            setLoading(false)
            return;
        }
    	setLoading(true)

    	const formData = new FormData();
  		formData.append('file', file);
  		formData.append('configuration_id', configuration.configuration_id);
  		formData.append('company_id', company.id);
  		formData.append('value', configuration.value);
  		if(configuration.configuration_id!=1){
  			formData.append('option_id', configuration.option_id);	
  		}
    	postFileFileConfiguration(formData).then(response => {
    		responseAction(response);    			
    	})	
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.configuration;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			let storage = JSON.parse(localStorage.getItem("authUser"));
				storage.company=response.company
				localStorage.setItem('authUser',JSON.stringify(storage));
				location. reload()

    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    const fileValidate = (file) => {
        setErrors({})
        const validate = ValidateFileHelper(file, {required: true, type: 'image', size: 1}, 'Imagen de Logo');
        if(validate != undefined){
            setErrors(validate);
            return;
        }
        setFile(file);
    }

    useEffect(() => {
        getConfigurationsSelects().then(response => {
            setConfigurations(response.configuration)
            setOptions(response.options)
        })
    }, [])

	return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>{modal.update ? 'Editar Configuración':'Agregar Configuración'}</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-sm-6">
						    <label className="form-label">Configuración:</label>
						    <Select
								className="basic-single"
								classNamePrefix="select"
								isSearchable={true}
								getOptionLabel={(option) => `${option.name}`}
								getOptionValue={(option) => `${option.id}`}
								name="color"
								options={configurations}
								onChange={ e => handlerOnChange(e, 'configuration') }
							/>
							{
								errors && <div className="form-text text-danger">{errors.configuration_id && errors.configuration_id[0]}</div>
							}
						</div>
						{configuration.configuration_id == 1 ?
						<div className="col-sm-6">
						    <label className="form-label">Imagen:</label>
						    <input type="file" id='file' onChange={ e => [fileValidate(e.target.files[0])] } />
						    {
						    	errors && <div className="form-text text-danger">{errors.image && errors.image[0]}</div>
						    }
						</div>
						:

						<div className="col-sm-6">
						    <label className="form-label">Nombre:</label>
						    <input className="form-control" name="name" type="text" onChange={ e => setConfiguration({...configuration,value:e.target.value}) } value={configuration.value}/>
						    {
						    	errors && <div className="form-text text-danger">{errors.name && errors.name[0]}</div>
						    }
							
						</div>
						}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;


