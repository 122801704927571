import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Spinner, Form, Input } from "reactstrap";
import dayjs from "dayjs";
import { postWithdrawals } from "../../helpers/backend_helper";

const request_date = dayjs().format("YYYY-MM-DD HH:mm");

const WithdrawalForm = ({ realoadData }) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      request_date,
      amount,
    };

    setLoading(true);
    postWithdrawals(data)
      .then(() => {
        realoadData && realoadData();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <div className="d-flex">
          <Input
            className="form-control me-2 flex-1"
            placeholder="Monto a retirar"
            type="number"
            size="sm"
            style={{ maxWidth: "200px" }}
            required
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
          <Button
            color="primary"
            type="submit"
            disabled={loading}
            size="sm"
            className="w-auto"
            style={{ flexShrink: "0" }}
          >
            {loading && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Retirar
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default WithdrawalForm;
