export const NACIONALIDADES = [
  "Afgana",
  "Alemana",
  "Árabe",
  "Argentina",
  "Australiana",
  "Belga",
  "Boliviana",
  "Brasileña",
  "Camboyana",
  "Canadiense",
  "Chilena",
  "China",
  "Colombiana",
  "Coreana",
  "Costarricense",
  "Cubana",
  "Danesa",
  "Ecuatoriana",
  "Egipcia",
  "Salvadoreña",
  "Escocesa",
  "Española",
  "Estadounidense",
  "Estonia",
  "Etiope",
  "Filipina",
  "Finlandesa",
  "Francesa",
  "Galesa",
  "Griega",
  "Guatemalteca",
  "Haitiana",
  "Holandesa",
  "Hondureña",
  "Indonesia",
  "Inglesa",
  "Iraquí",
  "Iraní",
  "Irlandesa",
  "Israelí",
  "Italiana",
  "Japonesa",
  "Jordana",
  "Laosiana",
  "Letona",
  "Letonesa",
  "Malaya",
  "Marroquí",
  "Mexicana",
  "Nicaragüense",
  "Noruega",
  "Neozelandesa",
  "Panameña",
  "Paraguaya",
  "Peruana",
  "Portuguesa",
  "Puertorriqueño",
  "Dominicana",
  "Rumana",
  "Rusa",
  "Sueca",
  "Suiza",
  "Tailandesa",
  "Taiwanesa",
  "Turca",
  "Ucraniana",
  "Uruguaya",
  "Venezolana",
  "Vietnamita",
  "Otra",
];
