import React from 'react';
import { Link, withRouter } from "react-router-dom"

const Columns = ( addUpd, destroy, policy ) => {
		let columns = [
			{
				id: 'name',
				name: 'Nombre',
				selector: row => row.name,
				sortable: true,
				wrap: true,
			},
			{
				id: 'description',
				name: 'Descripción',
				selector: row => row.description,
				sortable: true,
				wrap: true,
			},
			{
				id: 'actions',
				name: 'Acciones',
				width:'20%',
				button: true,
				cell: row => (
					<div className="d-flex justify-content-between">
						<div className="p-2">
							<Link to={"/my-product/" + row.id} className="btn btn-primary btn-sm" title="Detalle de producto"><i className="fas fa-eye"></i></Link>
                        </div>
					</div>
				),
			}
		];
	return columns;
}

export default Columns;