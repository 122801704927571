export const downloadFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const openFile = (data, filename) => {
  console.log(data, filename);
  const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' } ));
  let tab = window.open();
  tab.location.href = url;
};
