import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import { getWithdrawals } from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import RefundBalanceModal from "./RefundBalanceModal";
import CompleteModal from "./CompleteModal";

const Withdrawals = () => {
  const [item, setItem] = useState();
  const [modal, setModal] = useState({ refund: false, complete: false });

  const [items, setItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });

  const refundBalance = (row) => {
    setModal((prev) => ({ ...prev, refund: true }));
    setItem(row);
  };

  const completeWithdrawal = (row) => {
    setModal((prev) => ({ ...prev, complete: true }));
    setItem(row);
  };

  const fetchProspects = useCallback((filters) => {
    const config = { params: filters };

    setPending(true);
    getWithdrawals(config)
      .then((response) => {
        setItems(response.withdrawals.data);
        setDataPag(response.withdrawals);
      })
      .finally(() => setPending(false));
  }, []);

  const realoadData = () => fetchProspects(filters);

  useEffect(() => {
    fetchProspects(filters);
  }, [filters]);

  const columns = Columns(refundBalance, completeWithdrawal);
  document.title = "Retiros";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Retiros"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </Container>
      </div>
      {modal.refund ? (
        <RefundBalanceModal
          item={item}
          modal={modal}
          setItem={setItem}
          setModal={setModal}
          realoadData={realoadData}
        />
      ) : null}
      {modal.complete ? (
        <CompleteModal
          item={item}
          modal={modal}
          setItem={setItem}
          setModal={setModal}
          realoadData={realoadData}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Withdrawals;
