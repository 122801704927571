import React, { useEffect } from "react";
import { Row, Col, FormFeedback } from "reactstrap";

import NibbleInput from "../../Fields/NibbleInput";

import {
  SINO,
  PAISES,
  SECTORS,
  TIPOS_INGRESO,
  REGIMEN_FISCAL,
  RECURSOS_PROCEDENCIA,
  RELATIONSHIP,
  ACTIVIDAD_ECONOMICA,
  Society,
  Beneficiary,
  EconomicDependent,
} from "./constants";

const ESTADOS = [
  { id: "Aguascalientes" },
  { id: "Baja California" },
  { id: "Baja California Sur" },
  { id: "Campeche" },
  { id: "Coahuila" },
  { id: "Colima" },
  { id: "Chiapas" },
  { id: "Chihuahua" },
  { id: "Distrito Federal" },
  { id: "Durango" },
  { id: "Guanajuato" },
  { id: "Guerrero" },
  { id: "Hidalgo" },
  { id: "Jalisco" },
  { id: "México" },
  { id: "Michoacán" },
  { id: "Morelos" },
  { id: "Nayarit" },
  { id: "Nuevo León" },
  { id: "Oaxaca" },
  { id: "Puebla" },
  { id: "Querétaro" },
  { id: "Quintana Roo" },
  { id: "San Luis Potosí" },
  { id: "Sinaloa" },
  { id: "Sonora" },
  { id: "Tabasco" },
  { id: "Tamaulipas" },
  { id: "Tlaxcala" },
  { id: "Veracruz" },
  { id: "Yucatán" },
  { id: "Zacatecas" },
];

const GIRO_MERCANTIL = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y gas.",
  "Construcción",
  "Industrias manufactureras",
  "Comercio",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Actividades legislativas, gubernamentales, de impartición de justicia",
];

const GIRO_MERCANTIL_LIST = GIRO_MERCANTIL.map((giro, index) => {
  return {
    id: giro,
    name: giro,
  };
});

const ProspectComplementaryData = ({ validation, isSavingComplements }) => {
  useEffect(() => {
    if (validation.values.beneficiaries) {
      validation.values.beneficiaries.forEach((beneficiary, index) => {
        if (validation.values.beneficiaries[index].country === "México")
          validation.setFieldValue(
            `beneficiaries[${index}].state`,
            beneficiary.state_tmp
          );
      });
    }
  }, [validation.values.beneficiaries]);

  return (
    <React.Fragment>
      <Row>
        <Col md={12} className="">
          <div className="d-flex flex-column ">
            <div className="auth-content my-auto">
              <div className="row">
                <h5>Servicios de inversión</h5>
                <NibbleInput
                  md={6}
                  label="¿Cómo supo de Smart Brokers?"
                  fieldName="ask1"
                  fieldType="text"
                  validation={validation}
                />
                <NibbleInput
                  md={6}
                  label="¿Es empleado de Smart Brokers?"
                  fieldName="ask2"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                <NibbleInput
                  md={6}
                  label="¿Es familiar de empleado de Smart Brokers?"
                  fieldName="ask3"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                <NibbleInput
                  md={6}
                  fieldName="ask4"
                  label="¿Es empleado de algún intermediario financiero?"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
              </div>
              <div className="row mt-5">
                <h5>FACTA</h5>
                <NibbleInput
                  md={6}
                  label="¿Cuenta con nacionalidad estadounidense?"
                  fieldName="facta_ask1"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
                <NibbleInput
                  md={6}
                  label="¿Es residente en los Estados Unidos de América?"
                  fieldName="facta_ask2"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
                <NibbleInput
                  md={6}
                  label="¿Es contribuyente o residente fiscal de los Estados Unidos de América?"
                  fieldName="facta_ask3"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
                {validation.values.facta_ask3 ? (
                  <NibbleInput
                    md={6}
                    label="TIN (Tax Identification Number)"
                    fieldName="facta_tin"
                    fieldType="text"
                    validation={validation}
                  />
                ) : null}
              </div>
              <div className="row mt-5">
                <h5>Información fiscal</h5>
                <NibbleInput
                  md={6}
                  label="Régimen Fiscal"
                  fieldName="tax_information_regime"
                  fieldType="select"
                  selectItems={REGIMEN_FISCAL}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
                <NibbleInput
                  md={6}
                  label="Pais de residencia"
                  fieldName="tax_information_country"
                  fieldType="select"
                  selectItems={PAISES}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
              </div>
              <div className="row mt-5">
                <h5>Instrucciones de liquidación</h5>
                <NibbleInput
                  md={6}
                  label="Titular de la cuenta"
                  fieldName="bank_person"
                  fieldType="text"
                  validation={validation}
                />

                <NibbleInput
                  md={6}
                  label="Banco"
                  fieldName="bank_name"
                  fieldType="text"
                  validation={validation}
                />
                <NibbleInput
                  md={6}
                  label="Cuenta CLABE"
                  fieldName="bank_account"
                  fieldType="text"
                  validation={validation}
                  maxLength={18}
                />
                <NibbleInput
                  md={6}
                  label="Cuenta bancaria"
                  fieldName="bank_account_number"
                  fieldType="text"
                  validation={validation}
                  maxLength={18}
                />
                <NibbleInput
                  md={6}
                  label="Sucursal"
                  fieldName="bank_branch"
                  fieldType="text"
                  validation={validation}
                  maxLength={18}
                />
              </div>
              <div className="row mt-5">
                <h5>Vinculo Patrimonial</h5>
                <NibbleInput
                  md={12}
                  label="Sociedades y asociaciones con las que tiene relación o vínculos patrimoniales, o en su caso ocupe algún cargo de consejero:"
                  fieldName="sociedades"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                  onChange={(option) => {
                    if (!option?.id) {
                      validation.setFieldValue("societies", []);
                      return;
                    }

                    validation.setFieldValue("societies", [{ ...Society }]);
                  }}
                />
                {validation.values.societies.map((society, index) => (
                  <div key={index} className={`row mt-4`}>
                    <div
                      className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                        validation.values.societies.length === 1 && "d-none"
                      }`}
                    >
                      <p className="p-2 border-radius-4 mb-0">
                        Sociedad {index + 1}
                      </p>
                      <button
                        disabled={isSavingComplements}
                        className="btn btn-primary btn-sm"
                        type="button"
                        hidden={index === 0}
                        onClick={(e) => {
                          const beneficiariesFiltered =
                            validation.values.societies.filter(
                              (_, i) => i !== index
                            );

                          validation.setFieldValue(
                            "societies",
                            beneficiariesFiltered
                          );
                        }}
                      >
                        <i className="fas fa-trash"></i> Remover
                      </button>
                    </div>
                    <NibbleInput
                      md={6}
                      label="Denominación social:"
                      fieldName={`societies[${index}].sociedad_name`}
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Giro mercantil:"
                      fieldName={`societies[${index}].sociedad_commercial`}
                      fieldType="select"
                      selectItems={GIRO_MERCANTIL_LIST}
                      selectOptionValue={(e) => e.id}
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Nacionalidad:"
                      fieldName={`societies[${index}].sociedad_nation`}
                      fieldType="select"
                      selectItems={PAISES}
                      validation={validation}
                      selectOptionValue={(e) => e.name_es}
                    />

                    <NibbleInput
                      md={6}
                      label="Nombre del Gerente o apoderado Legal:"
                      fieldName={`societies[${index}].sociedad_apoderado`}
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Teléfono:"
                      fieldName={`societies[${index}].sociedad_tel`}
                      fieldType="number"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Porcentaje de vínculo patrimonial:"
                      fieldName={`societies[${index}].sociedad_percent`}
                      fieldType="number"
                      validation={validation}
                      min={1}
                    />

                    <NibbleInput
                      md={6}
                      label="Es consejero:"
                      fieldName={`societies[${index}].sociedad_counselor`}
                      fieldType="select"
                      selectItems={SINO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                  </div>
                ))}
              </div>

              {validation.values.sociedades ? (
                <Row className="mb-4">
                  <Col md={12} className="text-center pt-2 mt-3">
                    <span
                      className="btn btn-sm btn-info"
                      type="button"
                      onClick={(e) => {
                        validation.setFieldValue("societies", [
                          ...validation.values.societies,
                          { ...Society },
                        ]);
                      }}
                    >
                      <i className="fas fa-plus"></i> Agregar sociedad
                    </span>
                  </Col>
                </Row>
              ) : null}

              <div className="row mt-5">
                <h5>Datos de actividad económica</h5>

                <NibbleInput
                  md={6}
                  label="Actividad:"
                  fieldName="ae_actividad"
                  fieldType="select"
                  selectItems={ACTIVIDAD_ECONOMICA}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                {validation.values.ae_actividad == 1 ? (
                  <>
                    <NibbleInput
                      md={6}
                      label="Nombre de la empresa donde presta sus servicios:"
                      fieldName="ae_empresa"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Puesto específico:"
                      fieldName="ae_puesto"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Actividad/giro de la empresa donde presta sus servicios:"
                      fieldName="ae_giro"
                      fieldType="text"
                      validation={validation}
                    />
                  </>
                ) : null}

                {validation.values.ae_actividad == 1 ||
                validation.values.ae_actividad == 2 ? (
                  <>
                    <NibbleInput
                      md={6}
                      label="Página de internet:"
                      fieldName="ae_pagina"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Teléfono de oficina:"
                      fieldName="ae_tel"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Sector de la empresa donde presta sus servicios:"
                      fieldName="ae_sector"
                      fieldType="select"
                      selectItems={SECTORS}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                  </>
                ) : null}

                {validation.values.ae_actividad == 1 ||
                validation.values.ae_actividad == 2 ||
                validation.values.ae_actividad == 3 ? (
                  <>
                    <NibbleInput
                      md={6}
                      label="Profesión:"
                      fieldName="ae_profession"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="Tipo de ingreso:"
                      fieldName="ae_type_income"
                      fieldType="select"
                      selectItems={TIPOS_INGRESO}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />

                    <NibbleInput
                      md={6}
                      label="Indique de donde son sus ingresos adicionales:"
                      fieldName="ae_additional_income"
                      fieldType="text"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      label="¿De que empresa se jubiló?"
                      fieldName="ae_retired_company"
                      fieldType="text"
                      validation={validation}
                    />

                    {/* <NibbleInput
                          md={6}
                          label="Indique si actúa a nombre de un tercero o los recursos a invertir son de un tercero:"
                          fieldName="ae_name_lender"
                          fieldType="text"
                          validation={validation}
                        /> */}

                    <NibbleInput
                      md={6}
                      label="Indique si actúa a nombre de un tercero o los recursos a invertir son de un tercero:"
                      fieldName="ae_name_lender"
                      fieldType="select"
                      selectItems={[
                        {
                          id: "Solo recursos propios, no represento a nadie",
                          name: "Solo recursos propios, no represento a nadie",
                        },
                        {
                          id: "Invierto recursos de un tercero en su representación",
                          name: "Invierto recursos de un tercero en su representación",
                        },
                      ]}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                  </>
                ) : null}
              </div>

              <div className="row mt-5">
                <h5>PEPS</h5>
                <NibbleInput
                  md={12}
                  label="¿Desempeña o ha desempeñado en la administración vigente o en la anterior algún cargo público?"
                  fieldName="peps_peps"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                <NibbleInput
                  md={6}
                  label="Si usted es extranjero, ¿Desempeña o ha desempeñado en la administración vigente o en la anterior algún cargo público?"
                  fieldName="peps_ext"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />
                {validation.values.peps_ext ? (
                  <NibbleInput
                    md={6}
                    label="Institución o dependencia donde la persona presta (prestó) sus servicios:"
                    fieldName="peps_ext_institucion"
                    fieldType="text"
                    validation={validation}
                  />
                ) : null}

                <NibbleInput
                  md={6}
                  label="Si usted está relacionado con una persona nacional o extrajera que desempeñe o haya desempañado algún cargo público en algún país extranjero:"
                  fieldName="peps_rel"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                {validation.values.peps_rel ? (
                  <NibbleInput
                    md={6}
                    label="Institución o dependencia donde la persona presta (prestó) sus servicios:"
                    fieldName="peps_rel_institucion"
                    fieldType="text"
                    validation={validation}
                  />
                ) : null}
              </div>

              <div className="row mt-5">
                <h5>Cuentas discrecionales</h5>

                <NibbleInput
                  md={6}
                  label="Acumulación/carga fiscal de intereses:"
                  fieldName="cuentas_carga"
                  fieldType="number"
                  validation={validation}
                />

                <NibbleInput
                  md={6}
                  label="Procedencia de los recursos con los que apertura la cuenta:"
                  fieldName="cuentas_procedencia"
                  fieldType="select"
                  selectItems={RECURSOS_PROCEDENCIA}
                  validation={validation}
                />

                {validation.values.cuentas_procedencia === "7" && (
                  <NibbleInput
                    md={6}
                    label="Procedencia de los recursos con los que apertura la cuenta detalle:"
                    fieldName="cuentas_procedencia_other"
                    fieldType="text"
                    validation={validation}
                  />
                )}

                <NibbleInput
                  md={6}
                  label="Carga de intereses:"
                  fieldName="cuentas_intereses"
                  fieldType="number"
                  validation={validation}
                />
              </div>

              <div className="row mt-5">
                <h5>Movimientos de la cuenta</h5>

                <NibbleInput
                  md={12}
                  label="Depósito inicial (Monto):"
                  fieldName="movimientos_inicial"
                  fieldType="number"
                  validation={validation}
                />

                <NibbleInput
                  md={6}
                  label="Número de depósitos aproximados al mes:"
                  fieldName="movimientos_depositos"
                  fieldType="number"
                  validation={validation}
                  min={1}
                />
                <NibbleInput
                  md={6}
                  label="Monto mensual promedio de los depósitos:"
                  fieldName="movimientos_dep_prom"
                  fieldType="select"
                  selectItems={[
                    { id: "$0 - $50,000", name: "$0-$50,000" },
                    {
                      id: "$50,000 - $250,000",
                      name: "$50,000 - $250,000",
                    },
                    {
                      id: "$250,000 - $1,250,000",
                      name: "$250,000 - $1,250,000",
                    },
                    {
                      id: "Más de $1,250,000",
                      name: "Más de $1,250,000",
                    },
                  ]}
                  validation={validation}
                />

                <NibbleInput
                  md={6}
                  label="Número de retiros aproximados al mes:"
                  fieldName="movimientos_retiros"
                  fieldType="number"
                  validation={validation}
                  min={1}
                />
                <NibbleInput
                  md={6}
                  label="Monto mensual promedio de los retiros:"
                  fieldName="movimientos_ret_prom"
                  fieldType="select"
                  selectItems={[
                    { id: "$0 - $50,000", name: "$0-$50,000" },
                    {
                      id: "$50,000 - $250,000",
                      name: "$50,000 - $250,000",
                    },
                    {
                      id: "$250,000 - $1,250,000",
                      name: "$250,000 - $1,250,000",
                    },
                    {
                      id: "Más de $1,250,000",
                      name: "Más de $1,250,000",
                    },
                  ]}
                  validation={validation}
                />

                <NibbleInput
                  md={6}
                  label="¿Maneja contratos con otra casa de bolsa o instituación de inversión? ¿Con cual?:"
                  fieldName="movimientos_otra_casa"
                  fieldType="select"
                  selectItems={SINO}
                  validation={validation}
                  selectOptionValue={(e) => e.id}
                />

                {validation.values.movimientos_otra_casa ? (
                  <NibbleInput
                    md={6}
                    label="¿En qué instrumento ha invertido en el mercado financiero?"
                    fieldName="movimientos_otro_instrumento"
                    fieldType="text"
                    validation={validation}
                  />
                ) : null}
              </div>

              <div className="row mt-5">
                <h5>Beneficiarios</h5>

                {validation.values.beneficiaries.map((nameObj, index) => (
                  <div key={index} className={`row ${index !== 0 && "mt-4"}`}>
                    <div
                      className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                        validation.values.beneficiaries.length === 1 && "d-none"
                      }`}
                    >
                      <p className="p-2 border-radius-4 mb-0">
                        Beneficiario {index + 1}
                      </p>
                      <button
                        disabled={isSavingComplements}
                        className="btn btn-primary btn-sm"
                        type="button"
                        hidden={index === 0}
                        onClick={(e) => {
                          const beneficiariesFiltered =
                            validation.values.beneficiaries.filter(
                              (_, i) => i !== index
                            );
                          validation.setFieldValue(
                            "beneficiaries",
                            beneficiariesFiltered
                          );
                        }}
                      >
                        <i className="fas fa-trash"></i> Remover
                      </button>
                    </div>
                    <NibbleInput
                      md={6}
                      label="Nombre Completo:"
                      fieldName={`beneficiaries[${index}].name`}
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Porcentaje:"
                      fieldName={`beneficiaries[${index}].percent`}
                      fieldType="number"
                      validation={validation}
                      min={1}
                    />
                    <NibbleInput
                      md={6}
                      label="Parentesco:"
                      fieldName={`beneficiaries[${index}].relationship`}
                      fieldType="select"
                      selectItems={RELATIONSHIP}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                    {validation.values.beneficiaries[index].relationship ===
                    "Otro" ? (
                      <NibbleInput
                        md={6}
                        label="Parentesco:"
                        fieldName={`beneficiaries[${index}].relationship_other`}
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}
                    <NibbleInput
                      md={6}
                      label="Fecha de nacimiento:"
                      fieldName={`beneficiaries[${index}].birth`}
                      fieldType="date"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Nacionalidad:"
                      fieldName={`beneficiaries[${index}].nation`}
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="País de nacimiento:"
                      fieldName={`beneficiaries[${index}].country`}
                      fieldType="select"
                      selectItems={PAISES}
                      validation={validation}
                    />
                    {validation.values.beneficiaries[index].country ===
                      "México" && (
                      <NibbleInput
                        md={6}  
                        label="Estado de nacimiento:"
                        fieldName={`beneficiaries[${index}].state_tmp`}
                        fieldType="select"
                        selectItems={ESTADOS}
                        validation={validation}
                        selectOptionLabel={(option) => `${option.id}`}
                      />
                    )}
                    {validation.values.beneficiaries[index].country !==
                      "México" && (
                      <NibbleInput
                        md={6}
                        label="Estado de nacimiento:"
                        fieldName={`beneficiaries[${index}].state`}
                        fieldType="text"
                        validation={validation}
                      />
                    )}
                    <NibbleInput
                      md={6}
                      label="Dirección:"
                      fieldName={`beneficiaries[${index}].address`}
                      fieldType="text"
                      validation={validation}
                    />
                  </div>
                ))}
              </div>
              <Row>
                <Col md={12} className="text-center pt-2 mt-3">
                  <FormFeedback
                    type="invalid"
                    className="d-block text-center mb-3"
                    style={{
                      display: validation.errors.totalBeneficiariesPercent
                        ? "inline"
                        : "none",
                    }}
                  >
                    {validation.errors.totalBeneficiariesPercent}
                  </FormFeedback>
                  <span
                    className="btn btn-sm btn-info"
                    type="button"
                    onClick={(e) => {
                      validation.setFieldValue("beneficiaries", [
                        ...validation.values.beneficiaries,
                        { ...Beneficiary },
                      ]);
                    }}
                  >
                    <i className="fas fa-plus"></i> Agregar beneficiario
                  </span>
                </Col>
              </Row>

              <div className="row mt-5 pb-4">
                <h5>Dependientes económicos</h5>

                <div className="row">
                  <NibbleInput
                    md={12}
                    label="¿Tiene dependientes económicos?"
                    fieldName="economic_dependents_ask"
                    fieldType="select"
                    selectItems={SINO}
                    validation={validation}
                    selectOptionValue={(e) => e.id}
                    onChange={(option) => {
                      if (!option?.id) {
                        validation.setFieldValue("economic_dependents", []);
                        return;
                      }

                      validation.setFieldValue("economic_dependents", [
                        { ...Society },
                      ]);
                    }}
                  />
                </div>

                {validation.values.economic_dependents.map((_, index) => (
                  <div key={index} className={`row mt-4`}>
                    <div
                      className={`col-12 py-2 bg-light rounded d-flex justify-content-between ${
                        validation.values.economic_dependents.length === -1 &&
                        "d-none"
                      }`}
                    >
                      <p className="p-2 border-radius-4 mb-0">
                        Dependiente económico {index + 1}
                      </p>
                      <button
                        disabled={isSavingComplements}
                        className="btn btn-primary btn-sm"
                        type="button"
                        hidden={index === 0}
                        onClick={(e) => {
                          const economicDependentsFiltered =
                            validation.values.economic_dependents.filter(
                              (_, i) => i !== index
                            );
                          validation.setFieldValue(
                            "economic_dependents",
                            economicDependentsFiltered
                          );
                        }}
                      >
                        <i className="fas fa-trash"></i> Remover
                      </button>
                    </div>
                    <NibbleInput
                      md={6}
                      label="Nombre Completo:"
                      fieldName={`economic_dependents[${index}].name`}
                      fieldType="text"
                      validation={validation}
                    />

                    <Col md={6} />

                    <NibbleInput
                      md={6}
                      label="Parentesco:"
                      fieldName={`economic_dependents[${index}].relationship`}
                      fieldType="select"
                      selectItems={RELATIONSHIP}
                      validation={validation}
                    />

                    {validation.values.economic_dependents[index]
                      .relationship === "Otro" ? (
                      <NibbleInput
                        md={6}
                        label="Parentesco:"
                        fieldName={`economic_dependents[${index}].relationship_other`}
                        fieldType="text"
                        validation={validation}
                      />
                    ) : null}

                    <NibbleInput
                      md={6}
                      label="Fecha de nacimiento:"
                      fieldName={`economic_dependents[${index}].birth`}
                      fieldType="date"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Nacionalidad:"
                      fieldName={`economic_dependents[${index}].nation`}
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="País de nacimiento:"
                      fieldName={`economic_dependents[${index}].country`}
                      fieldType="select"
                      selectItems={PAISES}
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Estado de nacimiento:"
                      fieldName={`economic_dependents[${index}].state`}
                      fieldType="select"
                      selectItems={ESTADOS}
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Dirección:"
                      fieldName={`economic_dependents[${index}].address`}
                      fieldType="text"
                      validation={validation}
                    />
                  </div>
                ))}
              </div>
              {validation.values.economic_dependents_ask ? (
                <Row className="mb-4">
                  <Col md={12} className="text-center pt-2 mt-3">
                    <span
                      className="btn btn-sm btn-info"
                      type="button"
                      onClick={(e) => {
                        validation.setFieldValue("economic_dependents", [
                          ...validation.values.economic_dependents,
                          { ...EconomicDependent },
                        ]);
                      }}
                    >
                      <i className="fas fa-plus"></i> Agregar dependiente
                      económico
                    </span>
                  </Col>
                </Row>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProspectComplementaryData;
