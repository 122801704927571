import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import useCancelableRequest from "../../../hooks/useCancelableRequest";
import { getColoniasByZipCode } from "../../../helpers/backend_helper";

const ColoniasCommercialModal = ({ validation }) => {
  const getCancelTokenSource = useCancelableRequest();

  const [showModal, setShowModal] = useState(false);
  const [colonias, setColonias] = useState([]);

  const zipCode = validation.values?.commer_zipcode;

  const fetchZipCodeInfo = () => {
    if (!zipCode) return;

    validation.validateForm().then((errors) => {
      if (errors?.commer_zipcode) return;

      const config = {
        params: { zipCode },
        cancelToken: getCancelTokenSource(),
      };

      getColoniasByZipCode(config).then((resp) => {
        if (resp.status && resp?.data && resp.data?.length) {
          setColonias(resp.data);
          setShowModal(true);
        }
      });
    });
  };

  const toggleModal = () => setShowModal(!showModal);

  const onSelectColonia = (colonia) => {
    validation.setFieldValue("commer_address2", colonia);

    toggleModal();
  };

  useEffect(fetchZipCodeInfo, [zipCode]);

  return (
    <Modal isOpen={showModal} size="sm">
      <ModalHeader className="bg-primary">
        <span className="text-light">Selecciona tu colonia</span>
      </ModalHeader>
      <ModalBody>
        {colonias?.map((colonia) => (
          <div
            key={colonia}
            onClick={() => onSelectColonia(colonia)}
            role="button"
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-start p-2 border-bottom"
          >
            <i className="font-size-18 bx bx-right-arrow-circle me-3"></i>
            <h5 className="font-size-14">{colonia}</h5>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          <i className="fa fa-times"></i> Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ColoniasCommercialModal;
