import React, { useEffect, useState } from "react";
import { Row, Col, TabContent, NavItem, NavLink, Progress } from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getActiveSurvey } from "../../helpers/backend_helper";
import TabGenerales from "./TabGenerales";
import TabSurvey from "./TabSurvey";
import registrationStorage from "./registrationStorage";
import TabRegistro from "./TabRegistro";
import { ToastContainerHelper } from "../../helpers/alerts/alertHelper";
import TabDocs from "./TabDocs";
import TabFace from "./TabFace";

const initRegistrationDataState = {
  user_id: null,
  prospect_id: null,
};

const initialTabsState = {
  breadcrumbItems: [
    { title: "Forms", link: "#" },
    { title: "Form Wizard", link: "#" },
  ],
  activeTabProgress: 1,
  progressValue: 20,
};

const getInitTabsState = () => {
  return registrationStorage.getTabs() || initialTabsState;
};

const getInitProspectState = () => {
  return registrationStorage.getProspect() || initRegistrationDataState;
};

const ProgressBarWizard = () => {
  const [survey, setSurvey] = useState(null);
  const [pending, setPending] = useState(null);
  const [state, setState] = useState(getInitTabsState());
  const [registrationData, setRegistrationData] = useState(
    getInitProspectState()
  );

  const toggleTabProgress = (tab) => {
    if (state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 5) {
        const progressValues = {
          1: 20,
          2: 40,
          3: 60,
          4: 80,
          5: 100,
        };

        const newTabsState = {
          ...state,
          progressValue: progressValues[tab],
          activeTabProgress: tab,
        };

        setState(newTabsState);
        registrationStorage.saveTabs(newTabsState);
      }
    }
  };

  const fetchSurvey = async () => {
    setPending(true);
    const resp = await getActiveSurvey();
    setPending(false);
    setSurvey(resp.data);
    console.log("resp", resp.data);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "user@smart-brokers.com" || "",
      password: "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  return (
    <React.Fragment>
      <ToastContainerHelper />
      <Row>
        <Col md={12} lg="12">
          <h3 className="mb-4 text-center">Proceso de Registro</h3>

          <div id="progrss-wizard" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTabProgress === 1,
                  })}
                  onClick={() => {
                    toggleTabProgress(1);
                  }}
                >
                  <div
                    className="step-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Registro"
                  >
                    <i className="far fa-user"></i>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTabProgress === 2,
                  })}
                  onClick={() => {
                    toggleTabProgress(2);
                  }}
                >
                  <div
                    className="step-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Apertura de cuenta cliente"
                  >
                    <i className="fas fa-house-user"></i>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTabProgress === 3,
                  })}
                  onClick={() => {
                    toggleTabProgress(3);
                  }}
                >
                  <div
                    className="step-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Perfil de inversión"
                  >
                    <i className="fas fa-list"></i>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTabProgress === 4,
                  })}
                  onClick={() => {
                    toggleTabProgress(4);
                  }}
                >
                  <div
                    className="step-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Documentos adicionales"
                  >
                    <i className="fas fa-file"></i>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: state.activeTabProgress === 5,
                  })}
                  onClick={() => {
                    toggleTabProgress(5);
                  }}
                >
                  <div
                    className="step-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Valida tu identidad"
                  >
                    <i className="fas fa-user-check"></i>
                  </div>
                </NavLink>
              </NavItem>
            </ul>

            <div id="bar" className="mt-4">
              <Progress
                color="primary"
                striped
                animated
                value={state.progressValue}
              />
            </div>

            <TabContent
              activeTab={state.activeTabProgress}
              className="twitter-bs-wizard-tab-content mt-4"
            >
              {state.activeTabProgress === 1 && (
                <TabRegistro
                  tabId={1}
                  toggleTabProgress={toggleTabProgress}
                  setRegistrationData={setRegistrationData}
                />
              )}

              {state.activeTabProgress === 2 && (
                <TabGenerales
                  tabId={2}
                  toggleTabProgress={toggleTabProgress}
                  setRegistrationData={setRegistrationData}
                  registrationData={registrationData}
                />
              )}

              {state.activeTabProgress === 3 && (
                <TabSurvey
                  tabId={3}
                  toggleTabProgress={toggleTabProgress}
                  prospect_id={registrationData.prospect_id}
                />
              )}

              {state.activeTabProgress === 4 && (
                <TabDocs
                  tabId={4}
                  toggleTabProgress={toggleTabProgress}
                  prospect_id={registrationData.prospect_id}
                />
              )}

              {state.activeTabProgress === 5 && (
                <TabFace
                  tabId={5}
                  toggleTabProgress={toggleTabProgress}
                  prospect_id={registrationData.prospect_id}
                />
              )}
            </TabContent>
            <div className="d-none text-center mt-4">
              <Link
                to="#"
                className={
                  state.activeTabProgress === 1
                    ? "btn btn-primary"
                    : "btn btn-primary"
                }
                onClick={() => {
                  toggleTabProgress(state.activeTabProgress + 1);
                }}
              >
                {state.activeTabProgress != 2 ? "Siguiente" : "Guardar"}
              </Link>
            </div>

            <ul className="d-none pager wizard twitter-bs-wizard-pager-link">
              <li
                className={
                  state.activeTabProgress === 1
                    ? "previous disabled"
                    : "previous"
                }
              >
                <Link
                  to="#"
                  className={
                    state.activeTabProgress === 1
                      ? "btn btn-primary disabled"
                      : "btn btn-primary"
                  }
                  onClick={() => {
                    toggleTabProgress(state.activeTabProgress - 1);
                  }}
                >
                  <i className="bx bx-chevron-left me-1"></i> Guardar
                </Link>
              </li>

              <li
                className={
                  state.activeTabProgress === 3 ? "next disabled" : "next"
                }
              >
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={() => {
                    toggleTabProgress(state.activeTabProgress + 1);
                  }}
                >
                  Next <i className="bx bx-chevron-right ms-1"></i>
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProgressBarWizard;
