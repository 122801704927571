import React, { useEffect, useState } from "react";
import { getClientBalances } from "../../../../helpers/backend_helper";

const Balances = ({ userId }) => {
  const [balances, setBalances] = useState();
  const [isLoadingBalances, setIsLoadingBalances] = useState(true);

  useEffect(() => {
    getClientBalances(userId)
      .then((resp) => {
        setBalances(resp.balances);
      })
      .finally(() => {
        setIsLoadingBalances(false);
      });
  }, []);

  if (isLoadingBalances)
    return (
      <p style={{ minWidth: "150px" }} className="card-text placeholder-glow">
        <span className="placeholder col-12"></span>{" "}
        <span className="placeholder col-12"></span>{" "}
      </p>
    );

  return (
    <div className="text-right">
      <span className="d-block text-muted card-title-desc">
        Saldo comprometido: <strong>{balances?.saldoComprometido}</strong>
      </span>
      <span className="d-block text-muted card-title-desc">
        Saldo disponible: <strong>{balances?.saldoDisponible}</strong>
      </span>
      <span className="d-block text-muted card-title-desc">
        Saldo en inversiones: <strong>{balances?.saldoEnInversiones}</strong>
      </span>
    </div>
  );
};

export default Balances;
