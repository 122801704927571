import PropTypes from "prop-types";
import React from "react";
import { Alert, Form, Input, FormFeedback, Label } from "reactstrap";
import { useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const LoginForm = (props) => {
  const {validation} = props

  const { error, loading } = useSelector((state) => state.Login);

  return (
    <div className="auth-content my-auto">
      <div className="text-center">
        <img className="mb-5" src="/images/logo_sb.png" alt="" height="75" />
        <h5 className="mb-0">¡Bienvenido!</h5>
        <p className="text-muted mt-2">
          Introduce tus credenciales para ingresar.
        </p>
      </div>
      <Form
        className="custom-form mt-4 pt-2"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <div className="mb-3">
          <Label className="form-label">Email</Label>
          <Input
            name="email"
            className="form-control"
            type="email"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">
              {validation.errors.email}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label className="form-label">Constraseña</Label>
          <Input
            name="password"
            value={validation.values.password || ""}
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.password && validation.errors.password
                ? true
                : false
            }
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">
              {validation.errors.password}
            </FormFeedback>
          ) : null}
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-check">
              <Link to="/forgot-password" className="">
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            <div className="mt-3 d-grid">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={loading}
              >
                {loading ? "Enviando..." : "Ingresar"}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withRouter(LoginForm);

LoginForm.propTypes = {
  history: PropTypes.object,
  validation: PropTypes.object,
};
