import React, { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  getClient,
  putProspectUpdateStatus,
} from "../../../../helpers/backend_helper";

import classnames from "classnames";
import InvestingsTab from "../tabs/investings";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Balances from "./Balances";
import TransactionsTab from "../tabs/transactions/Transactions";
import FormatsDropdown from "../../../Prospects/detail/components/FormatsDropdown";
import GeneralDataTab from "../../../../components/Prospect/Detail/Tabs/GeneralDataTab";
import CustomerValidationsTab from "../../../../components/Prospect/Detail/Tabs/CustomerValidationsTab";
import ComplementsTab from "../../../../components/Prospect/Detail/Tabs/ComplementsTab";
import DocumentsTab from "../../../../components/Prospect/Detail/Tabs/DocumentsTab";

const ProspectStatus = {
  REGISTRATION_BASE: "Registro base",
  REGISTRATION_COMPLEMENTS: "Registro complementos",
  REGISTRATION_DOCUMENTS: "Registro documentos",
  RISK_EVALUATION: "Evaluación de riesgo",
  APPROVED: "Aprobado",
  REJECT: "Rechazado",
  BLOCKED: "Bloqueado",
};

const initModal = { show: false, status: "" };

const ProfileTab = () => {
  const { id } = useParams();

  const [activeTab, toggleTab] = useState("1");
  const [client, setClient] = useState();
  const [modal, setModal] = useState(initModal);
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const fetchClient = useCallback(() => {
    //setIsLoadingClient(true);

    getClient(id)
      .then((resp) => {
        if (!resp.status) return;

        setClient(resp.client);
      })
      .finally(() => {
        setIsLoadingClient(false);
      });
  }, [id]);

  const handleOpenModal = (status) => {
    setModal({ show: true, status });
  };

  const handleCloseModal = () => {
    setModal(initModal);
  };

  const handleUpdateProspectStatus = () => {
    setIsUpdatingStatus(true);

    const { status } = modal;

    putProspectUpdateStatus(id, { status })
      .then(() => {
        fetchClient();
        handleCloseModal();
      })
      .finally(() => {
        setIsUpdatingStatus(false);
      });
  };

  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  if (isLoadingClient)
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );

  if (!client) return <p>No se encontro información del cliente</p>;

  return (
    <React.Fragment>
      <Row>
        <Col xl={24} lg={24} md={24}>
          <Card>
            <CardBody>
              <Row>
                <div className="col-sm order-2 order-sm-1">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-shrink-0">
                      <div className="avatar-xl mx-auto mb-4">
                        <span
                          style={{ backgroundColor: "#ffffff" }}
                          className="avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle"
                        >
                          <i className="bx bxs-user-circle"></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-16 mb-1 text-uppercase">
                          {client?.ti_name} {client?.ti_lname1}{" "}
                          {client?.ti_lname2}
                        </h5>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          Estatus: {client?.status}
                        </p>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          Nivel: {client?.level}
                        </p>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          RFC: {client?.ti_rfc}
                        </p>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          CURP: {client?.ti_curp}
                        </p>
                        <p className="text-muted font-size-13 text-uppercase">
                          Correo: {client?.user?.email}
                        </p>
                        <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                          <div title="Fecha de nacimiento">
                            <i className="fas fa-calendar-alt me-1 text-muted align-middle"></i>
                            {client?.ti_birth}
                          </div>
                          <div title="Teléfono">
                            <i className="fas fa-phone-alt me-1 text-muted align-middle"></i>
                            {client?.ti_phone}
                          </div>
                          <div className="text-uppercase">
                            <i className="far fa-address-card me-1 text-muted align-middle"></i>
                            {client?.fiscal_address1} {client?.fiscal_ext}
                            {", "}
                            {client?.fiscal_address2} {", "}{" "}
                            {client?.fiscal_address3} {", CP "}{" "}
                            {client?.fiscal_zipcode}
                          </div>
                          {/* <div>
                            <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                            phyllisgatlin@minia.com
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-auto order-1 order-sm-2">
                  <div className="pb-2 d-flex justify-content-end">
                    <FormatsDropdown id={id} />
                    {client?.status === ProspectStatus.APPROVED && (
                      <Button
                        color="danger"
                        size="sm"
                        disabled={isUpdatingStatus}
                        onClick={() => handleOpenModal(ProspectStatus.BLOCKED)}
                      >
                        {isUpdatingStatus ? (
                          <Spinner size="sm" className="me-2" />
                        ) : (
                          <i className="bx bx-lock-alt font-size-16 align-middle me-2"></i>
                        )}
                        Bloquear
                      </Button>
                    )}
                    {client?.status === ProspectStatus.BLOCKED && (
                      <Button
                        color="success"
                        size="sm"
                        disabled={isUpdatingStatus}
                        onClick={() => handleOpenModal(ProspectStatus.APPROVED)}
                      >
                        {isUpdatingStatus ? (
                          <Spinner size="sm" className="me-2" />
                        ) : (
                          <i className="bx bx-lock-open-alt font-size-16 align-middle me-2"></i>
                        )}
                        Desbloquear
                      </Button>
                    )}
                  </div>
                  <Balances userId={client?.user_id} />
                </div>
              </Row>

              <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "1",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Generales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "2",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Complementos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "3",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Documentación
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "4",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Inversiones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "5",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Transacciones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "6",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    Validaciones
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {/* <GeneralTab prospect={client} /> */}
              <GeneralDataTab prospect={client} />
            </TabPane>
            <TabPane tabId="2">
              <ComplementsTab prospect={client} />
            </TabPane>
            <TabPane tabId="3">
              <DocumentsTab
                prospect={client}
                files={client?.files}
                fetchClient={fetchClient}
              />
            </TabPane>
            <TabPane tabId="4">
              <InvestingsTab userId={client?.user_id} />
            </TabPane>
            <TabPane tabId="5">
              <TransactionsTab clientId={client?.id} />
            </TabPane>
            <TabPane tabId="6">
              <CustomerValidationsTab
                nubarium_history={client?.nubarium_history}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Modal isOpen={modal.show} style={{ maxWidth: "400px" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Cambiar el estatus del cliente</span>
        </ModalHeader>
        <ModalBody>
          <p>¿Esta seguro que desea cambiar el estatus del registro?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            disabled={isUpdatingStatus}
            onClick={handleUpdateProspectStatus}
          >
            {isUpdatingStatus && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Guardar cambios
          </Button>
          <Button
            color="secondary"
            disabled={isUpdatingStatus}
            onClick={handleCloseModal}
          >
            <i className="fa fa-times"></i> Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileTab;
