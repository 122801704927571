export const calculateInterest = ({ amount, percentage, period }) => {
  let meses = 12;
  let interesAcumulado = 0;
  let montoAcumulado = 0;

  let tableValues = [];

  for (let index = 1; index <= period; index++) {
    if (index === 1) {
      interesAcumulado = amount * percentage;
      montoAcumulado = interesAcumulado + amount;

      tableValues.push({
        interes: interesAcumulado,
        monto: montoAcumulado,
        meses,
      });
    } else {
      interesAcumulado = montoAcumulado * percentage;
      montoAcumulado += interesAcumulado;

      tableValues.push({
        interes: interesAcumulado,
        monto: montoAcumulado,
        meses: meses * index,
      });
    }
  }

  return tableValues;
};
