import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  Row,
} from "reactstrap";
import Currency from "../../components/Common/Currency";
import NibbleInput from "../../components/Fields/NibbleInput";
import NibbleInputFile from "../../components/Fields/NibbleInputFile";
import { postRefundWithdrawal } from "../../helpers/backend_helper";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const RefundBalanceModal = ({
  modal,
  setModal,
  setItem,
  item,
  realoadData,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setModal({ ...modal, refund: false });
  };

  const handleSubmit = () => {
    setLoading(true);
    postRefundWithdrawal(item)
      .then(() => {
        handleClose();
        realoadData();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Modal isOpen={modal.refund} size="sm">
        <ModalHeader className="bg-primary">
          <span className="text-light">Reintegrar a la cuenta del cliente</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <p>Cliente: {item?.prospect?.full_name}</p>
            <p>
              Importe: <Currency amount={item?.amount} />
            </p>
            <p>¿Desea reintegrar el importe a la cuenta del cliente?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default RefundBalanceModal;
