import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const jsonStyles = {
  border: "1px solid #c2c2c2",
  padding: "10px",
  borderRadius: "5px",
  backgroundColor: "#dbdbdb",
};

const PayloadResponseModal = ({ isOpenModal, setIsOpenModal, data }) => {
  const responsePayloadStr = data
    ? JSON.stringify(JSON.parse(data), null, 4)
    : "";

  return (
    <Modal isOpen={isOpenModal}>
      <ModalHeader className="bg-primary">
        <span className="text-light">Detalle de respuesta</span>
      </ModalHeader>
      <ModalBody>
        <div style={jsonStyles}>
          <pre className="mb-0">
            <code>{responsePayloadStr}</code>
          </pre>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setIsOpenModal(false)}>
          <i className="fa fa-times"></i> Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayloadResponseModal;
