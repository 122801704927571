export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_OTP_USER = "LOGIN_OTP_USER"
export const LOGIN_OTP_SUCCESS = "LOGIN_OTP_SUCCESS"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const RESEND_OTP = "RESEND_OTP"
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS"
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR"