import {
	InputNumeric,
	InputInteger,
	InputEmail,
	InputText,
	InputAlfanumeric,
} from './InputRegexHelper';

const ValidateHelper = (options = {input: '', attribute: '', required: false, min:0, max:0, type:'text', exists: []}) => {
	const input = options.input || '';
	const attribute = options.attribute || '';
	const required = options.required || false;
	const min = options.min || 0;
	const max = options.max || 0;
	const type = options.type || 'text';
	const exists = options.exists || [];
	if(required && input == ''){
		return "El campo "+attribute+" es obligatorio."
	}else if(input.length > 0 && min > 0 && input.length < min){
		return "El campo "+attribute+" debe contener al menos "+min+" caracteres."
	}else if(input.length > 0 && max > 0 && input.length > max){
		return "El campo "+attribute+" debe contener al maximo "+max+" caracteres."
	}else if(input.length > 0 && type == 'email' && !InputEmail(input)){
		return "El campo "+attribute+" debe ser de tipo email."
	}else if(input.length > 0 && type == 'text' && !InputText(input)){
		return "El campo "+attribute+" debe ser de tipo texto."
	}else if(input.length > 0 && type == 'numeric' && !InputNumeric(input)){
		return "El campo "+attribute+" debe ser de tipo numerico con dos decimales."
	}else if(input.length > 0 && type == 'integer' && !InputInteger(input)){
		return "El campo "+attribute+" debe ser de tipo entero."
	}else if(input.length > 0 && type == 'alfanumeric' && !InputAlfanumeric(input)){
		return "El campo "+attribute+" debe contener letras y números."
	}else if(required && type == 'exists' && exists.find(item => item.id == input) == undefined){
		return "El campo "+attribute+" seleccionado no existe."
	}else{
		return undefined;
	}
}
export default ValidateHelper;