import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//Utility
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import Configuration from "../pages/Utility/Configuration/Configuration";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageConfirm from "../pages/Authentication/PageRegister";
//import userProfile from "../pages/Authentication/user-profile";
import Users from "../pages/Users/Users";
import Profile from "../pages/Users/Profile";

//company
import Company from "../pages/Companies/Company";

import Profiles from "../pages/Profiles/Profiles";
import Permissions from "../pages/Profiles/Permissions/Permissions";
import Products from "../pages/Products/Products";
import Clients from "../pages/Clients/Clients";
import ClientDetail from "../pages/Clients/detail";
import PerfilesRiesgo from "../pages/PerfilesRiesgo/PerfilesRiesgo";
import Registration from "../pages/Registration/Registration";
//import SurveyApply from "../pages/Registration/SurveyApply";
import Surveys from "../pages/Survey/Surveys";
import Survey from "../pages/Survey/Detail/Survey";
import MyProducts from "../pages/MyProducts/MyProducts";
import ProductList from "../pages/ProductList/ProductList";
import MyProductDetail from "../pages/MyProducts/Detail/MyProductDetail";
import Prospects from "../pages/Prospects/Prospects";
import ProspectDetail from "../pages/Prospects/detail";
import Instruments from "../pages/Instruments/Instruments";
import Periods from "../pages/Periods/Periods";
import ProductTypes from "../pages/ProductTypes/ProductTypes";
import MyInvestings from "../pages/MyInvestings/MyInvestings";
import MyTransactions from "../pages/MyTransactions/MyTransactions";
import Transactions from "../pages/Transactions/Transactions";
import ClientContracts from "../pages/ClientContracts";
import Settings from "../pages/Settings/Settings";
import RegistrationFace from "../pages/Registration/RegistrationFace";
import ProductDetail from "../pages/ProductList/ProductDetail";
import Deposits from "../pages/Deposits/Deposits";
import MyWithdrawals from "../pages/MyWithdrawals/MyWithdrawals";
import Withdrawals from "../pages/Withdrawals/Withdrawals";
import Calculator from "../pages/Calculator/Calculator";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/products", component: Products },
  { path: "/my-products", component: MyProducts },
  { path: "/my-product/:id", component: MyProductDetail },
  { path: "/my-investings", component: MyInvestings },
  { path: "/my-transactions", component: MyTransactions },

  { path: "/clients", component: Clients },
  { path: "/clients/detail/:id", component: ClientDetail },
  { path: "/prospects", component: Prospects },
  { path: "/prospects/detail/:id", component: ProspectDetail },
  { path: "/catalogs/instruments", component: Instruments },
  { path: "/catalogs/periods", component: Periods },
  { path: "/catalogs/product-types", component: ProductTypes },
  { path: "/transactions", component: Transactions },
  { path: "/deposits", component: Deposits },
  { path: "/my-withdrawals", component: MyWithdrawals },
  { path: "/withdrawals", component: Withdrawals },

  { path: "/profiles-risk", component: PerfilesRiesgo },

  { path: "/surveys", component: Surveys },
  { path: "/surveys/:id", component: Survey },

  { path: "/perfiles", component: Profiles },

  { path: "/client-contracts", component: ClientContracts },

  { path: "/settings", component: Settings },

  //List_Users Routes
  { path: "/users", component: Users },
  { path: "/users/profile/:id", component: Profile },

  //configurations
  { path: "/configuration/:id?", component: Configuration },

  //Company
  { path: "/companies", component: Company },

  //ProfilesPremissons
  { path: "/profiles", component: Profiles },
  { path: "/profile/:id", component: Permissions },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  //AuthenticationInner pages
  { path: "/page-login", component: PageLogin },
  { path: "/verifyMail/:id/:token/:reset?", component: PageConfirm },

  //Utility page
  { path: "/pages-maintenance", component: PageMaintenance },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
];

const publicRoutes = [
  //authencation page
  { path: "/registration", component: Registration },
  { path: "/registration-face/:id", component: RegistrationFace },
  { path: "/product-list", component: ProductList },
  { path: "/product/:id", component: ProductDetail },
  { path: "/calculator", component: Calculator },
  //{ path: "/survey-apply", component: SurveyApply },
];

export { userRoutes, authRoutes, publicRoutes };
