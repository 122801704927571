import { Container } from "reactstrap";
import React, { useEffect, useState } from "react";

import { getProductsjList } from "../../helpers/backend_helper";
import DataTableBoxHelper from "../../helpers/tables/DataTableBoxHelper";

const ProductList = () => {
  const [items, setItems] = useState([]);
  const [pending, setPending] = useState(false);
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });

  useEffect(() => {
    const params = { params: filters };

    setPending(true);
    getProductsjList(params).then((response) => {
      setItems(response.data);
      setDataPag(response.meta);
      setPending(false);
    });
  }, [filters]);

  document.title = "Productos";
  return (
    <React.Fragment>
      <div className="page-content pt-4">
        <Container fluid>
          <div className="col-12">
            <DataTableBoxHelper
              tittle="Productos"
              columns={[]}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
              isPublicProduct
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductList;
