import { Alert } from "./alerts/alertHelper";

import registrationStorage from "../pages/Registration/registrationStorage";

export const REGISTRATION_STATUS = [
  "Registro base",
  "Registro cuenta",
  "Registro perfil inversión",
  "Registro documentos",
];

export const redirectUserToHome = (user, history) => {
  registrationStorage.clean();

  if (user.prospect) {
    const { id: prospect_id, status, user_id } = user.prospect;

    if (REGISTRATION_STATUS.includes(status)) {
      const tabsState = {
        activeTabProgress: 1,
        progressValue: 5,
      };

      switch (status) {
        case "Registro base":
          tabsState.activeTabProgress = 2;
          tabsState.progressValue = 20;
          break;
        case "Registro cuenta":
          tabsState.activeTabProgress = 3;
          tabsState.progressValue = 40;
          break;
        case "Registro perfil inversión":
          tabsState.activeTabProgress = 4;
          tabsState.progressValue = 60;
          break;
        case "Registro documentos":
          tabsState.activeTabProgress = 5;
          tabsState.progressValue = 80;
          break;
        default:
          break;
      }

      const prospectData = { user_id, prospect_id, meta: user?.prospect };
      registrationStorage.saveTabs(tabsState).saveProspect(prospectData);

      Alert("Por favor completa tu registro", true);
      history.push(`/registration`);

      return;
    }

    if (status === "Registro identidad") {
      Alert("Su cuenta se encuentra en revisión", true);
      return;
    }
  }

  console.log(user);
  const userType = user.user?.type || user.type;
  history.push(
    userType == 2
      ? "/provider/daily"
      : userType == 3
      ? "/client/daily"
      : "dashboard"
  );
};

export const getLoggedUser = () => {
  const authUser = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {};
  const loggedUser = authUser?.user;
  console.log({ userObj: loggedUser });
  return loggedUser;
};
