import React from "react";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const RiskProfileTab = ({ prospect }) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h5 className="card-title mb-0">Preguntas</h5>
            <p>
              <strong>Perfil de riesgo:</strong> {prospect?.perfil_riesgo?.name}
            </p>
          </div>
        </CardHeader>
        <CardBody>
          {prospect?.quiz_questions?.map((quiz) => {
            return (
              <>
                <Row key={quiz.section} className="py-2">
                  <h5 className="pb-3">{quiz?.section}</h5>
                  {quiz?.questions?.map((question, index) => (
                    <Col sm={6} key={question?.questionId} className="pb-3">
                      <p className="fw-700 mb-1">
                        {index + 1}.- {question?.question}
                      </p>
                      <em>
                        <strong>R.</strong>
                        {question?.anotherAnswer
                          ? `Otra respuesta: ${question.anotherAnswer}`
                          : question?.answer}
                      </em>
                    </Col>
                  ))}
                </Row>
                <hr />
              </>
            );
          })}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RiskProfileTab;
