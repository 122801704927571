import { Container } from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";

import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import AddUpdModal from "./AddUpdModal";
import { delClient, getClientsPermissions } from "../../helpers/backend_helper";
import { DestroyAlertHelper, Alert } from "../../helpers/alerts/alertHelper";
import { getPolicyUser } from "../../helpers/api_helper";
import { prospectGeneralBase } from "../../components/Prospect/General";
import AddUpdProspectModal from "../../components/Prospect/AddUpdModal/AddUpdProspectModal";

const initFilters = {
  page: 1,
  perPage: 20,
  search: "",
  orderBy: "id",
  order: "asc",
};

const Clients = () => {
  const policy = getPolicyUser();

  const [items, setItems] = useState([]);
  const [dataPag, setDataPag] = useState({});
  const [pending, setPending] = useState(false);
  const [filters, setFilters] = useState(initFilters);
  const [item, setItem] = useState(prospectGeneralBase);
  const [modal, setModal] = useState({ show: false, update: false });

  const fetchClients = useCallback((filters) => {
    const config = { params: filters };

    setPending(true);
    getClientsPermissions(config).then((response) => {
      setItems(response.data);
      setDataPag(response.meta);
      setPending(false);
    });
  }, []);

  const realoadData = () => fetchClients(filters);

  useEffect(() => {
    fetchClients(filters);
  }, [filters]);

  const addUpd = (row = false) => {
    if (row.id) {
      setItem(row);
      setModal({ ...modal, show: true, update: true });
    } else {
      setItem(itemBase);
      setModal({ ...modal, show: true, update: false });
    }
  };

  const destroy = (id) => {
    DestroyAlertHelper(async (confirmed) => {
      if (!confirmed) {
        return;
      }
      let response = await delClient(id);
      Alert(response.message, response.status);
      if (response.status) {
        setItems(items.filter((i) => i.id != id));
      }
    });
  };

  const columns = Columns(addUpd, destroy, policy);

  document.title = "Clientes";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Clientes"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </Container>
        {/* {modal.show && (
          <AddUpdModal
            modal={modal}
            setModal={setModal}
            item={item}
            setItem={setItem}
            realoadData={realoadData}
          />
        )} */}
        {modal.show && (
          <AddUpdProspectModal
            item={item}
            modal={modal}
            title="Cliente"
            setModal={setModal}
            realoadData={realoadData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Clients;
