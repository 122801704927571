import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./DataTable.css";

const DataTableHelper = ({
  tittle,
  columns,
  items,
  pending,
  filters,
  setFilters,
  config,
  children = false,
  wrapperStyles = {},
  showSearch = true,
  showPagination = true,
  onlyTable = false,
  beforeSearch = null,
}) => {
  const [orderByData, setOrderByData] = useState(true);
  const [newItems, setNewItems] = useState(items);
  const [filterText, setFilterText] = useState("");
  let filterColumns = columns.filter((col) => {
    if (!col.button) {
      return col;
    }
  });
  filterColumns = filterColumns.map((col) => col.id);

  useEffect(() => {
    const search = () => {
      setFilters({ ...filters, search: filterText });
    };
    //if(filterText.length>0)
    search();
  }, [filterText]);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const handlePageChange = (page) => {
    console.log("okd");
    setFilters({ ...filters, page: page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("okd");
    setFilters({ ...filters, perPage: newPerPage, page: page });
  };

  const orderBy = async (cols) => {
    if (orderByData) {
      setOrderByData(false);
      setFilters({ ...filters, orderBy: cols.id, order: "asc" });
    } else {
      setOrderByData(true);
      setFilters({ ...filters, orderBy: cols.id, order: "desc" });
    }
  };

  return (
    <div className="table-responsive" style={{ ...wrapperStyles }}>
      <div className={onlyTable ? "" : "card"}>
        <div className={`pb-0 ${onlyTable ? "" : "card-header"}`}>
          {tittle && <Breadcrumbs title={tittle} breadcrumbItem={tittle} />}
          {beforeSearch}
          <div className="d-flex flex-sm-row flex-column justify-content-lg-between">
            <div className="d-flex flex-fill flex-lg-row flex-column justify-content-md-end">
              {children.length != undefined &&
                children.filter((child) => (
                  <div
                    className={`flex-fill ${child.props.className}`}
                    key={child.props.className}
                  >
                    {child}
                  </div>
                ))}

              {showSearch && (
                <div className="p-2 flex-sm-row flex-column order-sm-2 order-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Busqueda..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button
                      className="btn btn-secondary"
                      type="button"
                      id="button-addon2"
                      onClick={() => setFilterText("")}
                    >
                      X
                    </button>
                  </div>
                </div>
              )}
              {children != false && children.length != undefined ? (
                ""
              ) : (
                <div className="p-2 flex-sm-row flex-column order-sm-2 order-1">
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-body px-0 pt-0 pb-2">
          {/*<div className="d-none d-md-block">*/}
          <DataTable
            pagination={showPagination}
            paginationPerPage={filters?.perPage || 20}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            data={items}
            paginationComponentOptions={paginationComponentOptions}
            progressPending={pending}
            progressComponent={
              <div>
                <i className="fas fa-solid fa-spinner fa-spin"></i> Cargando...
              </div>
            }
            noDataComponent={
              <div>
                <i className="fas fa-solid fa-info text-primary"></i> No hay
                registros para mostrar
              </div>
            }
            highlightOnHover={true}
            paginationServer
            paginationTotalRows={config.total}
            paginationDefaultPage={1}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeaderWrap={false}
            onSort={(cols) => orderBy(cols)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTableHelper;
