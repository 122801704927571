import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../../helpers/tables/DataTableHelper";
import {
  getAdminContracts,
  getProductTypesPermissions,
  getProductsPermissions,
} from "../../../helpers/backend_helper";
import Columns from "./Columns";

const CONTRACT_FILTERS = {
  page: 1,
  perPage: 20,
  search: "",
  status: "",
  productId: "",
  productTypeId: "",
  pagination: true,
};

const EMPTY_FILTER = {
  page: 1,
  perPage: 20,
  search: "",
  orderBy: "id",
  order: "asc",
};

const defaultConfig = { params: EMPTY_FILTER };

const Contracts = ({ status }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(CONTRACT_FILTERS);
  const [productTypes, setProductTypes] = useState([]);
  const [transactions, setTransactions] = useState({ data: [] });

  const columns = Columns(status);

  const fetchContracts = useCallback((filters) => {
    setIsLoading(true);

    const config = {
      params: {
        ...filters,
        status,
      },
    };

    getAdminContracts(config)
      .then(responseAction)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const responseAction = (response) => {
    if (response.status) {
      setTransactions(response.contracts);
    }
  };

  useEffect(() => {
    fetchContracts(filters);
  }, [filters]);

  useEffect(() => {
    getProductsPermissions(defaultConfig).then((response) => {
      setProducts(response.data);
    });

    getProductTypesPermissions(defaultConfig).then((response) => {
      setProductTypes(response.data);
    });
  }, []);

  return (
    <div>
      <DataTableHelper
        tittle="Inversiones activas"
        columns={columns}
        items={transactions.data}
        pending={isLoading}
        config={transactions}
        filters={filters}
        setFilters={setFilters}
        wrapperStyles={{ minHeight: "500px" }}
      >
        <div>
          <select
            name="productId"
            value={filters.productId}
            onChange={(e) => {
              const { value, name } = e.target;

              setFilters((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
            placeholder="Filtrar por producto"
            style={{
              width: "150px",
              marginLeft: "15px",
              marginRight: "15px",
            }}
            className={`d-inline-block form-select form-select-sm ${
              !filters.productId && "text-muted"
            }`}
          >
            <option value="" className="text-muted">
              Seleccionar producto
            </option>
            {products.map((product) => (
              <option key={product.id} value={product.id} className="text-dark">
                {product.name}
              </option>
            ))}
          </select>
          <select
            name="productTypeId"
            value={filters.productTypeId}
            onChange={(e) => {
              const { value, name } = e.target;

              setFilters((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
            placeholder="Filtrar por tipo"
            style={{
              width: "150px",
              marginLeft: "15px",
              marginRight: "15px",
            }}
            className={`d-inline-block form-select form-select-sm ${
              !filters.productTypeId && "text-muted"
            }`}
          >
            <option value="" className="text-muted">
              Seleccionar tipo de producto
            </option>
            {productTypes.map((product) => (
              <option key={product.id} value={product.id} className="text-dark">
                {product.name}
              </option>
            ))}
          </select>
        </div>
      </DataTableHelper>
    </div>
  );
};

export default Contracts;
