import React, { useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import Contracts from "./Contracts";

const ContractTabs = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Card className="card-h-100">
        <CardBody>
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Inversiones activas</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">
                  Inversiones comprometidas
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">
                  Inversiones completadas
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardBody>
      </Card>

      <TabContent activeTab={customActiveTab} className="pt-0 text-muted">
        <TabPane tabId="1">
          <Contracts status="in-course" />
        </TabPane>
        <TabPane tabId="2">
          <Contracts status="blocked" />
        </TabPane>
        <TabPane tabId="3">
          <Contracts status="completed" />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

export default ContractTabs;
