import React from "react";

const responseStatus = {
  ok: "OK",
  error: "ERROR",
};

/*
    const RENAPO_CURP = 'renapo_curp';
    const RFC_SAT = 'rfc_sat';
    const RFC_SAT_69 = 'rfc_sat_69';
    const RFC_SAT_69B = 'rfc_sat_69b';
    const BLOCK_LIST = 'person_appears_block_list';
    const INE_VALIDATION = 'ine_validation';

    {
        "estatus": "ERROR",
        "mensaje": "No se encontro el RFC en la lista",
        "claveMensaje": "1",
        "codigoValidacion": "sb1716406987.2759945"
    }
*/

const ValidationTag = ({ status, validation_type = "" }) => {
  const isRFCSatType = ["rfc_sat_69", "rfc_sat_69b"].includes(validation_type);
  const isValid = status === responseStatus.ok;
  const isError = status === responseStatus.error;

  if (isRFCSatType) {
    return isValid ? (
      <span className="badge bg-danger me-2">Inválido</span>
    ) : isError ? (
      <span className="bg-success badge me-2">Válido</span>
    ) : null;
  }

  return isValid ? (
    <span className="bg-success badge me-2">Válido</span>
  ) : isError ? (
    <span className="badge bg-danger me-2">Inválido</span>
  ) : null;
};

export default ValidationTag;
