import React from "react";

import Summary from "./components/Summary";
import ContractTabs from "./components/ContractTabs";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ClientContracts = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={"Inversiones"} breadcrumbItem={"Inversiones"} />
        <Summary />
        <ContractTabs />
      </div>
    </React.Fragment>
  );
};

export default ClientContracts;
