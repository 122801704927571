import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import MyProductDetailInner from '../MyProducts/Detail/MyProductDetailInner';

const FichaModal = ({ item, modal, setModal }) => {
  return (
    <div>
      <Modal isOpen={modal.ficha} className="modal-lg">
        <ModalHeader>Ficha de Producto {item?.name}</ModalHeader>
        <ModalBody>
          <div>
            <div className="mt-4 mb-4">
              <MyProductDetailInner product={item} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { setModal({...modal, ficha: false})}}>
            <i className="fa fa-times"></i> Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FichaModal;
