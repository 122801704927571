import React, { useState } from "react";
import ReactSelect from "react-select";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";
import RowComposition from "./RowComposition";

const Composition = ({instruments=[], validation, fieldName}) => {
  let list = validation.values[fieldName]
  console.log('list',list)

  const onDelete = (idx) => {
    console.log('delete',idx, validation.values[fieldName])

    let newValue = [...validation.values[fieldName]];
    newValue.splice(idx, 1)
    validation.setFieldValue(fieldName,(newValue))
  }
  return (
    <Col md={12} className="pt-2">
      <Label>Composición </Label>
      {instruments.length > 0 &&
        validation.values[fieldName].map((e, idx) => (
          <>
            <RowComposition
              fieldName={fieldName}
              idx={idx}
              instruments={instruments}
              value={e}
              onChange={item => {
                const newComposition = [...list]
                newComposition[idx] = {...item}
                validation.setFieldValue(fieldName, newComposition)
              }}
              onDelete={e => onDelete(idx)}
              list={validation.values[fieldName]}
              validation={validation}
            ></RowComposition>
          </>
      ))}
      <Row className="pt-2">
        <Col md={1}></Col>
        <Col md={3} className="text-end fw-bold">{list.reduce((a, b) => {return Number((a ?? 0)) + Number((b?.percent ?? 0))}, 0)}%</Col>
        <Col md={8}>
          <FormFeedback type="invalid" style={{display: validation.touched[fieldName] && validation.errors[fieldName] ? 'inline':'none'}}>
            {validation.errors[fieldName]}
          </FormFeedback>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-center pt-2">
          <Button className="btn btn-sm btn-primary" onClick={e=>{validation.setFieldValue(fieldName,([...list, {}]))}}><i className="fas fa-plus"></i> Agregar instrumento</Button>
        </Col>
      </Row>
      
    </Col>
  );
};

export default Composition;
