import React, { useRef, useState } from "react";
import {
  postProspectUpdateDocument,
  uploadFile,
} from "../../../../helpers/backend_helper";
import { Alert, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

export const UpdateFile = ({ id, files, filename, prospect, fetchClient }) => {
  const fileRef = useRef(null);

  const [errors, setErrors] = useState();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const onSelectFile = () => {
    fileRef.current.click();
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const name = e.target.id;

    //e.target.value = "";

    if (!file) return;

    const fileFront = files.find((file) => "ineAnverso" === file.name);

    const data = {
      file,
      user_id: prospect?.user_id,
      name,
      front: fileFront?.id,
    };

    setErrors("");
    setIsUploadingFile(true);
    uploadFile(data).then((resp) => responseAction(resp, name));
  };

  const responseAction = (response, name) => {
    if (response.status) {
      updateProspectDocument(response.item.id);
    } else {
      const message = response?.message || "Ocurrió un error al subir archivo";

      setErrors(message);
      setIsUploadingFile(false);
    }
  };

  const updateProspectDocument = (file_id) => {
    const data = {
      id: prospect.id,
      file_id,
      old_file_id: id,
    };

    postProspectUpdateDocument(data)
      .then((response) => {
        if (response.status) {
          toast.success("Archivo actualizado correctamente", {
            theme: "light",
          });

          fetchClient && fetchClient();
        } else {
          const message =
            response?.message || "Ocurrió un error al subir archivo";

          setErrors(message);
        }
      })
      .finally(() => {
        setIsUploadingFile(false);
      });
  };

  return (
    <div
      className="d-flex flex-column flex-shrink-0"
      title="Actualizar archivo"
      style={{ cursor: "pointer" }}
      onClick={onSelectFile}
    >
      {isUploadingFile ? (
        <Spinner size="sm" />
      ) : (
        <i className="fas fa-upload fs-4 align-self-end text-success"></i>
      )}

      {errors && (
        <Alert color="danger" className="mt-2">
          {errors}
        </Alert>
      )}

      <input
        ref={fileRef}
        type="file"
        className="form-control d-none"
        id={filename}
        onChange={handleUploadFile}
        accept="image/png, image/jpeg,application/pdf"
        capture={"environment"}
      />
      <ToastContainer />
    </div>
  );
};
