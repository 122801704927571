import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import PayloadResponseModal from "../Modals/PayloadResponseModal";
import { REGISTRATION_FILES } from "../../../../constants/registration";
import DateFormat from "../../../Common/DateFormat";
import { UpdateFile } from "../components/UpdateFile";
import { prospectStatus } from "../../../../constants/prospectStatus";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
const BASE_URL_STORAGE = process.env.REACT_APP_BACKEND_URL;

const DocumentsTab = ({ files = [], prospect = null, fetchClient = null }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isOpenPayloadResponseModal, setIsOpenPayloadResponseModal] =
    useState(false);

  const handleShowPayloadResponse = (file) => {
    setSelectedFile(file);
    setIsOpenPayloadResponseModal(true);
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">Documentación</h5>
        </CardHeader>
        <CardBody>
          <Row>
            {files.length === 0 && <p>Aún no hay documentos para mostrar</p>}
            {files.map((file) => {
              const { id, name, created_at, path } = file;
              const label = REGISTRATION_FILES[name];

              return (
                <Col key={id} md={6}>
                  <div className="border shadow-none card p-4">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-15 text-truncate text-primary">
                          {label}
                        </h5>
                        <p className="mb-0 text-truncate font-size-13">
                          <DateFormat date={created_at} format="D MMM YYYY" />{" "}
                          <span className="">
                            / <DateFormat date={created_at} format="hh:m a" />
                          </span>
                        </p>
                      </div>
                      {[prospectStatus.APPROVED].includes(prospect?.status) ? (
                        <UpdateFile
                          id={id}
                          files={files}
                          filename={name}
                          prospect={prospect}
                          fetchClient={fetchClient}
                        />
                      ) : (
                        <div className="fs-1">
                          <i className="fas fa-file-alt"></i>
                        </div>
                      )}
                    </div>

                    <div className="p-4 text-center">
                      <a
                        download
                        href={`${BASE_URL}/files/${id}`}
                        className="fw-medium"
                        title={label}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fas fa-file-download"></i> Descargar
                      </a>
                    </div>
                    <div className="p-4 pt-0 text-center">
                      <a
                        href={`${BASE_URL_STORAGE}/storage/${path}`}
                        className="fw-medium"
                        title={label}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fas fa-eye"></i> Ver archivo
                      </a>
                    </div>
                    <div className="text-end">
                      <button
                        onClick={() => handleShowPayloadResponse(file)}
                        className="d-inline-flex focus-ring focus-ring-light py-1 px-2 text-decoration-none border rounded-2"
                      >
                        <i className="mdi mdi-code-json"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
      {isOpenPayloadResponseModal && (
        <PayloadResponseModal
          data={selectedFile?.metadata}
          isOpenModal={isOpenPayloadResponseModal}
          setIsOpenModal={setIsOpenPayloadResponseModal}
        />
      )}
    </React.Fragment>
  );
};

export default DocumentsTab;
