import React from 'react';
import Swal from 'sweetalert2'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DestroyAlertHelper = (callback) => {
	Swal.fire({
		title: '¿Estas seguro de eliminar?',
		text: 'Una vez eliminado no podrás revertir cambios.',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#337ab7',
		cancelButtonColor: '#d9534f',
		confirmButtonText: 'Eliminar',
		cancelButtonText: 'Cancelar',
		reverseButtons: true
	}).then((confirmed) => {
		callback(confirmed && confirmed.value == true);
	})
}

export const SendRequisicionAlertHelper = (callback) => {
	Swal.fire({
		title: '¿Estas seguro de enviar la requisición?',
		text: 'Una vez enviado no podrás revertir cambios.',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#337ab7',
		cancelButtonColor: '#d9534f',
		confirmButtonText: 'Enviar',
		cancelButtonText: 'Cancelar',
		reverseButtons: true
	}).then((confirmed) => {
		callback(confirmed && confirmed.value == true);
	})
}

export const ConfirmAsistencia = (callback,status) => {
	Swal.fire({
		title: '¿Estas seguro de realizar esta acción?',
		text: status ? 'Se registrará la asistencia del operador':'Se eliminará el registro de asistencia del operador',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#337ab7',
		cancelButtonColor: '#d9534f',
		confirmButtonText: 'Guardar',
		cancelButtonText: 'Cancelar',
		reverseButtons: true
	}).then((confirmed) => {
		callback(confirmed && confirmed.value == true);
	})
}

export const SendWellcomeAlertHelper = (callback) => {
	Swal.fire({
		title: '¿Estas seguro de enviar correo electrónico de bienvenida?',
		text: 'Se enviaria un correo para asigar una contraseña.',
		icon: 'info',
		showCancelButton: true,
		confirmButtonColor: '#337ab7',
		cancelButtonColor: '#d9534f',
		confirmButtonText: 'Enviar',
		cancelButtonText: 'Cancelar',
		reverseButtons: true
	}).then((confirmed) => {
		callback(confirmed && confirmed.value == true);
	})
}

export const Alert = (text,  status=true) => {
    const icon=status ? 'success':'error'
	Swal.fire({
		title: '',
		text: text,
		icon: icon,
		confirmButtonColor: '#337ab7',
		confirmButtonText: 'Continuar'
	});
}

export const ToastHelper = (title, theme='') => {
    switch (theme) {
        case 'info':
            toast.info(title, { theme: "light" });
            break;
        case 'success':
            toast.success(title, { theme: "light" });
            break;
        case 'warning':
            toast.warn(title, { theme: "light" });
            break;
        case 'error':
            toast.error(title, { theme: "light" });
            break;
        default:
            toast(title, { theme: "light" });
            break;
    }
}

export const ToastContainerHelper = () => <ToastContainer/>