import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns from "./Columns";
import { getWithdrawals } from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import WithdrawalForm from "./WithdrawalForm";

const MyWithdrawals = () => {
  const [pending, setPending] = useState(true);
  const [dataPag, setDataPag] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 20,
    search: "",
    orderBy: "id",
    order: "asc",
  });
  const [items, setItems] = useState([]);

  const fetchProspects = useCallback((filters) => {
    const config = { params: filters };

    setPending(true);
    getWithdrawals(config)
      .then((response) => {
        setItems(response.withdrawals.data);
        setDataPag(response.withdrawals);
      })
      .finally(() => setPending(false));
  }, []);

  const realoadData = () => fetchProspects(filters);

  useEffect(() => {
    fetchProspects(filters);
  }, [filters]);

  const columns = Columns();
  document.title = "Mis retiros";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Mis retiros"
              columns={columns}
              items={items}
              pending={pending}
              config={dataPag}
              filters={filters}
              setFilters={setFilters}
              beforeSearch={<WithdrawalForm realoadData={realoadData} />}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyWithdrawals;
