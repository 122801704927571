export const PAISES = [{ id: "México", name: "México" }];

export const ESTADOS = [
  { id: 1, name: "Aguascalientes" },
  { id: 2, name: "Baja California" },
  { id: 3, name: "Baja California Sur" },
  { id: 4, name: "Campeche" },
  { id: 5, name: "Coahuila" },
  { id: 6, name: "Colima" },
  { id: 7, name: "Chiapas" },
  { id: 8, name: "Chihuahua" },
  { id: 9, name: "Distrito Federal" },
  { id: 10, name: "Durango" },
  { id: 11, name: "Guanajuato" },
  { id: 12, name: "Guerrero" },
  { id: 13, name: "Hidalgo" },
  { id: 14, name: "Jalisco" },
  { id: 15, name: "México" },
  { id: 16, name: "Michoacán" },
  { id: 17, name: "Morelos" },
  { id: 18, name: "Nayarit" },
  { id: 19, name: "Nuevo León" },
  { id: 20, name: "Oaxaca" },
  { id: 21, name: "Puebla" },
  { id: 22, name: "Querétaro" },
  { id: 23, name: "Quintana Roo" },
  { id: 24, name: "San Luis Potosí" },
  { id: 25, name: "Sinaloa" },
  { id: 26, name: "Sonora" },
  { id: 27, name: "Tabasco" },
  { id: 28, name: "Tamaulipas" },
  { id: 29, name: "Tlaxcala" },
  { id: 30, name: "Veracruz" },
  { id: 31, name: "Yucatán" },
  { id: 32, name: "Zacatecas" },
];

export const ESTADO_CIVIL = [
  {
    id: 1,
    name: "Soltero",
  },
  {
    id: 2,
    name: "Casado",
  },
  {
    id: 3,
    name: "Viudo",
  },
  {
    id: 4,
    name: "Separado judicialmente",
  },
  {
    id: 5,
    name: "Divorciado",
  },
  {
    id: 6,
    name: "Unión libre",
  },
  {
    id: 7,
    name: "Otro",
  },
];

export const GENEROS = [
  { id: "Femenino", name: "Femenino" },
  { id: "Masculino", name: "Masculino" },
];

export const SINO = [
  { id: true, name: "Si" },
  { id: false, name: "No" },
];

export const TIPOS_VIVIENDA = [
  { id: "Propia Hipotecada", name: "Propia Hipotecada" },
  { id: "Propia Pagada", name: "Propia Pagada" },
  { id: "Rentada", name: "Rentada" },
  { id: "Familiar", name: "Familiar" },
  { id: "Prestada", name: " Prestada" },
];
