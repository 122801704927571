import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, TabContent, TabPane } from "reactstrap";

import ModalTabs from "./ModalTabs";
import GeneralForm from "./GeneralForm";
import ComplementaryForm from "./ComplementaryForm";
import InvestmentProfileForm from "./InvestmentProfileForm";

const AddUpdProspectModal = ({
  title = "",
  item,
  modal,
  setModal,
  realoadData,
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => setActiveTab(tab);

  const handleClose = () => {
    setModal({ ...modal, show: false, update: false });
  };

  return (
    <div>
      <Modal isOpen={modal.show} style={{ maxWidth: "60%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            {modal.update ? "Editar" : "Agregar"} {title}
          </span>
        </ModalHeader>
        <ModalBody>
          <ModalTabs item={item} activeTab={activeTab} toggleTab={toggleTab} />
        </ModalBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {activeTab == "1" ? (
              <GeneralForm
                item={item}
                modal={modal}
                handleClose={handleClose}
                realoadData={realoadData}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="2">
            {activeTab == "2" ? (
              <ComplementaryForm
                item={item}
                modal={modal}
                handleClose={handleClose}
                realoadData={realoadData}
              />
            ) : null}
          </TabPane>
          <TabPane tabId="3">
            {activeTab == "3" ? (
              <InvestmentProfileForm
                item={item}
                modal={modal}
                handleClose={handleClose}
                realoadData={realoadData}
              />
            ) : null}
          </TabPane>
        </TabContent>
      </Modal>
    </div>
  );
};

export default AddUpdProspectModal;
