import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import OtpForm from "./OtpForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginUser } from "../../store/actions";

const Login = (props) => {
  const dispatch = useDispatch();

  const [configuration] = useState({
    image: "",
    name: "",
    url: "",
  });

  const { is2fa, otp_duration } = useSelector((state) => state.Login);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Requerido"),
      password: Yup.string().required("Requerido"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  document.title = "Login";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col md={4}></Col>
            <Col md={4} className="">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={configuration.url} alt="" height="28" />{" "}
                        <span className="logo-txt">{configuration.name}</span>
                      </Link>
                    </div>
                    {is2fa ? (
                      <OtpForm validation={validation} otp_duration={otp_duration} />
                    ) : (
                      <LoginForm validation={validation} />
                    )}
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {configuration.name}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
