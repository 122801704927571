import React from "react";
import { Button, Form, Row, Spinner } from "reactstrap";

import useSettings from "./useSettings";
import { configOptions } from "./static";
import NibbleInput from "../../components/Fields/NibbleInput";
import Signature from "./Signature";

const Settings = () => {
  const { validation, isLoading } = useSettings();

  return (
    <React.Fragment>
      <div className="page-content">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">Configuraciones</h4>
          </div>

          <Row>
            <NibbleInput
              md={6}
              label="Titular de cuenta"
              fieldName={configOptions.TITULAR_CUENTA}
              fieldType="text"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="Banco"
              fieldName={configOptions.BANCO}
              fieldType="text"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="Cuenta Clabe"
              fieldName={configOptions.CUENTA}
              fieldType="text"
              validation={validation}
            />

            <NibbleInput
              md={6}
              label="Referencia inversionista"
              fieldName={configOptions.REF_INVERSIONISTA}
              fieldType="text"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="Referencia acreditados"
              fieldName={configOptions.REF_ACREDITADOS}
              fieldType="text"
              validation={validation}
            />

            <NibbleInput
              md={6}
              label="Comisión de plataforma"
              fieldName={configOptions.COMISION_PLATAFORMA}
              fieldType="number"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="Tasa calculadora smartbrokers"
              fieldName={configOptions.TASA_CALCULADORA_SMARTBROKERS}
              fieldType="number"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="Tasa calculadora benchmark"
              fieldName={configOptions.TASA_CALCULADORA_BENCHMARK}
              fieldType="number"
              validation={validation}
            />
            <NibbleInput
              md={6}
              label="No de contrato"
              fieldName={configOptions.NO_CONTRATO}
              fieldType="number"
              validation={validation}
            />
          </Row>
          <div className="py-4 text-center">
            <Button color="primary" type="submit" disabled={isLoading}>
              {isLoading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>
          </div>
        </Form>
        <hr />
        <Signature />
      </div>
    </React.Fragment>
  );
};

export default Settings;
