import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getConfigurationsSelects,
  updateConfigurationOptions,
} from "../../helpers/backend_helper";
import { configOptions, getFormValues } from "./static";

const useSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    const configs = _.map(_.toPairs(values), ([configuration_id, value]) => ({
      configuration_id,
      value,
    }));

    const data = { configs };

    setIsLoading(true);

    updateConfigurationOptions(data).finally(() => setIsLoading(false));
    console.log({ values });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      [configOptions.TITULAR_CUENTA]: "",
      [configOptions.BANCO]: "",
      [configOptions.CUENTA]: "",
      [configOptions.REF_INVERSIONISTA]: "",
      [configOptions.REF_ACREDITADOS]: "",
      [configOptions.COMISION_PLATAFORMA]: "",
      [configOptions.TASA_CALCULADORA_BENCHMARK]: "",
      [configOptions.TASA_CALCULADORA_SMARTBROKERS]: "",
      [configOptions.NO_CONTRATO]: "",
    },
    validationSchema: Yup.object({
      [configOptions.TITULAR_CUENTA]: Yup.string()
        .nullable()
        .required("Requerido"),
      [configOptions.BANCO]: Yup.string().nullable().required("Requerido"),
      [configOptions.CUENTA]: Yup.number()
        .typeError("Debe ser numérico")
        .nullable()
        .required("Requerido"),
      [configOptions.REF_INVERSIONISTA]: Yup.string()
        .nullable()
        .required("Requerido"),
      [configOptions.REF_ACREDITADOS]: Yup.string()
        .nullable()
        .required("Requerido"),
      [configOptions.COMISION_PLATAFORMA]: Yup.number()
        .nullable()
        .required("Requerido"),
      [configOptions.TASA_CALCULADORA_BENCHMARK]: Yup.number()
        .nullable()
        .required("Requerido"),
      [configOptions.TASA_CALCULADORA_SMARTBROKERS]: Yup.number()
        .nullable()
        .required("Requerido"),
      [configOptions.NO_CONTRATO]: Yup.number()
        .nullable()
        .required("Requerido"),
    }),
    onSubmit,
  });

  console.log({ validation });

  const fetchConfigOptions = () => {
    getConfigurationsSelects().then((resp) => {
      if (resp?.status) {
        const optionsObject = _.keyBy(resp.options, "configuration_id");
        const newFormData = getFormValues(optionsObject);

        validation.setValues(newFormData);

        console.log({ newFormData, optionsObject });
      }
    });
  };

  useEffect(fetchConfigOptions, []);

  return {
    isLoading,
    validation,
  };
};

export default useSettings;
