import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  TabPane,
  Label,
  Input,
  FormFeedback,
  Button,
  Alert,
  Spinner,
} from "reactstrap";

import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getActiveSurvey,
  postProspectAnswers,
} from "../../helpers/backend_helper";
import NibbleInput from "../../components/Fields/NibbleInput";
import registrationStorage from "./registrationStorage";
import { Alert as ToastAlert } from "../../helpers/alerts/alertHelper";

const getOptionsArr = (survey_ask) => [
  {
    option: survey_ask?.optionA,
    value: survey_ask?.valueA,
  },
  {
    option: survey_ask?.optionB,
    value: survey_ask?.valueB,
  },
  {
    option: survey_ask?.optionC,
    value: survey_ask?.valueC,
  },
  {
    option: survey_ask?.optionD,
    value: survey_ask?.valueD,
  },
  {
    option: survey_ask?.optionE,
    value: survey_ask?.valueE,
  },
  {
    option: survey_ask?.optionF,
    value: survey_ask?.valueF,
  },
];

const TabSurvey = ({ tabId, prospect_id, toggleTabProgress }) => {
  const [survey, setSurvey] = useState(null);
  const [pending, setPending] = useState(null);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState("");
  const [answersSelected, setAnswersSelected] = useState({});
  const [isSavingAnswers, setIsSavingAnswers] = useState(false);

  const fetchSurvey = async () => {
    setPending(true);
    const resp = await getActiveSurvey();
    setPending(false);
    setSurvey(resp.data);
    console.log("resp", resp.data);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "user@smart-brokers.com" || "",
      password: "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  const onChangeAnswer = (
    e,
    question,
    answer,
    section,
    survey_ask,
    anotherAnswer = ""
  ) => {
    console.log({
      e,
      question,
      answer,
      section,
      survey_ask,
    });

    const { name, value } = e.target;

    console.log({ name, value });

    setAnswers((prev) => ({
      ...prev,
      [name]: {
        score: value,
        question,
        answer,
        section,
        options: getOptionsArr(survey_ask),
        sort: survey_ask?.sort,
        anotherAnswer,
      },
    }));
  };

  console.log({ answers });

  const onSubmit = (e) => {
    e.preventDefault();

    const answersParsed = Object.entries(answers).map(([questionId, item]) => ({
      ...item,
      questionId: parseInt(questionId),
      score: parseInt(item.score),
    }));

    setErrors("");
    setIsSavingAnswers(true);
    postProspectAnswers({ answers: answersParsed, prospect_id }).then(
      responseAction
    );
  };

  const responseAction = (response) => {
    if (response.status) {
      toggleTabProgress(4);
    } else {
      setErrors(response?.message);
    }
    setIsSavingProspect(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log({ answersSelected });

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <div>
          <div className="text-center mb-4">
            <h5>Perfil de inversión</h5>
            <p className="card-title-desc">
              Completa la encuesta para para determinar tu perfil de inversión
            </p>
          </div>
          {errors && <Alert color="danger">{errors}</Alert>}
          <form onSubmit={onSubmit}>
            <div className="row">
              {(survey?.sections || []).map((f) => (
                <div className="row" key={f.id}>
                  {(
                    survey?.asks.filter((e) => {
                      return e.survey_section_id == f.id;
                    }) || []
                  ).length > 0 && <h4 className="mt-5">{f.name}</h4>}
                  {(
                    survey?.asks.filter((e) => {
                      return e.survey_section_id == f.id;
                    }) || []
                  ).map((e, idx) => (
                    <div className="col-lg-6 mb-4" key={idx}>
                      <div className="blockquote">{e.ask}</div>

                      <div>
                        <Input
                          type="radio"
                          className="me-2"
                          name={e.id}
                          value={e.valueA}
                          onChange={(event) => {
                            setAnswersSelected((prev) => ({
                              ...prev,
                              [e.id]: e.optionA,
                            }));

                            onChangeAnswer(event, e.ask, e.optionA, f.name, e);
                          }}
                          required
                        />{" "}
                        A. {e.optionA}
                        {e.optionAOther ? (
                          <Input
                            type="text"
                            className="d-block mb-3 mt-1"
                            placeholder="Escribir otra respuesta"
                            name={e.id}
                            onChange={(event) => {
                              onChangeAnswer(
                                {
                                  target: {
                                    name: e.id,
                                    value: e.valueA,
                                  },
                                },
                                e.ask,
                                e.optionA,
                                f.name,
                                e,
                                event.target.value
                              );
                            }}
                            disabled={
                              answersSelected[e.id] === e.optionA ? false : true
                            }
                          />
                        ) : null}
                      </div>
                      <div>
                        <Input
                          type="radio"
                          className="me-2"
                          name={e.id}
                          value={e.valueB}
                          onChange={(event) => {
                            setAnswersSelected((prev) => ({
                              ...prev,
                              [e.id]: e.optionB,
                            }));

                            onChangeAnswer(event, e.ask, e.optionB, f.name, e);
                          }}
                          required
                        />{" "}
                        B. {e.optionB}
                        {e.optionBOther ? (
                          <Input
                            type="text"
                            className="d-block mb-3 mt-1"
                            placeholder="Escribir otra respuesta"
                            name={e.id}
                            onChange={(event) => {
                              onChangeAnswer(
                                {
                                  target: {
                                    name: e.id,
                                    value: e.valueB,
                                  },
                                },
                                e.ask,
                                e.optionB,
                                f.name,
                                e,
                                event.target.value
                              );
                            }}
                            disabled={
                              answersSelected[e.id] === e.optionB ? false : true
                            }
                          />
                        ) : null}
                      </div>
                      {e.optionC?.length > 0 && (
                        <div>
                          <Input
                            type="radio"
                            className="me-2"
                            name={e.id}
                            value={e.valueC}
                            onChange={(event) => {
                              setAnswersSelected((prev) => ({
                                ...prev,
                                [e.id]: e.optionC,
                              }));

                              onChangeAnswer(
                                event,
                                e.ask,
                                e.optionC,
                                f.name,
                                e
                              );
                            }}
                            required
                          />{" "}
                          C. {e.optionC}
                          {e.optionCOther ? (
                            <Input
                              type="text"
                              className="d-block mb-3 mt-1"
                              placeholder="Escribir otra respuesta"
                              name={e.id}
                              onChange={(event) => {
                                onChangeAnswer(
                                  {
                                    target: {
                                      name: e.id,
                                      value: e.valueC,
                                    },
                                  },
                                  e.ask,
                                  e.optionC,
                                  f.name,
                                  e,
                                  event.target.value
                                );
                              }}
                              disabled={
                                answersSelected[e.id] === e.optionC
                                  ? false
                                  : true
                              }
                            />
                          ) : null}
                        </div>
                      )}
                      {e.optionD?.length > 0 && (
                        <div>
                          <Input
                            type="radio"
                            className="me-2"
                            name={e.id}
                            value={e.valueD}
                            onChange={(event) => {
                              setAnswersSelected((prev) => ({
                                ...prev,
                                [e.id]: e.optionD,
                              }));

                              onChangeAnswer(
                                event,
                                e.ask,
                                e.optionD,
                                f.name,
                                e
                              );
                            }}
                            required
                          />{" "}
                          D. {e.optionD}
                          {e.optionDOther ? (
                            <Input
                              type="text"
                              className="d-block mb-3 mt-1"
                              placeholder="Escribir otra respuesta"
                              name={e.id}
                              onChange={(event) => {
                                onChangeAnswer(
                                  {
                                    target: {
                                      name: e.id,
                                      value: e.valueD,
                                    },
                                  },
                                  e.ask,
                                  e.optionD,
                                  f.name,
                                  e,
                                  event.target.value
                                );
                              }}
                              disabled={
                                answersSelected[e.id] === e.optionD
                                  ? false
                                  : true
                              }
                            />
                          ) : null}
                        </div>
                      )}
                      {e.optionE?.length > 0 && (
                        <div>
                          <Input
                            type="radio"
                            className="me-2"
                            name={e.id}
                            value={e.valueE}
                            onChange={(event) => {
                              setAnswersSelected((prev) => ({
                                ...prev,
                                [e.id]: e.optionE,
                              }));

                              onChangeAnswer(
                                event,
                                e.ask,
                                e.optionE,
                                f.name,
                                e
                              );
                            }}
                            required
                          />{" "}
                          E. {e.optionE}
                          {e.optionEOther ? (
                            <Input
                              type="text"
                              className="d-block mb-3 mt-1"
                              placeholder="Escribir otra respuesta"
                              name={e.id}
                              onChange={(event) => {
                                onChangeAnswer(
                                  {
                                    target: {
                                      name: e.id,
                                      value: e.valueE,
                                    },
                                  },
                                  e.ask,
                                  e.optionE,
                                  f.name,
                                  e,
                                  event.target.value
                                );
                              }}
                              disabled={
                                answersSelected[e.id] === e.optionE
                                  ? false
                                  : true
                              }
                            />
                          ) : null}
                        </div>
                      )}
                      {e.optionF?.length > 0 && (
                        <div>
                          <Input
                            type="radio"
                            className="me-2"
                            name={e.id}
                            value={e.valueF}
                            onChange={(event) => {
                              setAnswersSelected((prev) => ({
                                ...prev,
                                [e.id]: e.optionF,
                              }));

                              onChangeAnswer(
                                event,
                                e.ask,
                                e.optionF,
                                f.name,
                                e
                              );
                            }}
                            required
                          />{" "}
                          F. {e.optionF}
                          {e.optionFOther ? (
                            <Input
                              type="text"
                              className="d-block mb-3 mt-1"
                              placeholder="Escribir otra respuesta"
                              name={e.id}
                              onChange={(event) => {
                                onChangeAnswer(
                                  {
                                    target: {
                                      name: e.id,
                                      value: e.valueF,
                                    },
                                  },
                                  e.ask,
                                  e.optionF,
                                  f.name,
                                  e,
                                  event.target.value
                                );
                              }}
                              disabled={
                                answersSelected[e.id] === e.optionF
                                  ? false
                                  : true
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <Col md={12} className="text-center mt-5">
              <button
                disabled={isSavingAnswers}
                className="btn btn-primary"
                type="submit"
              >
                {isSavingAnswers ? (
                  <Spinner size="sm" />
                ) : (
                  <i className="fas fa-save"></i>
                )}{" "}
                Guardar
              </button>
            </Col>
          </form>
        </div>
      </TabPane>
    </React.Fragment>
  );
};

export default TabSurvey;
