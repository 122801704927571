import React from "react";
import Currency from "../../components/Common/Currency";
const status = {
  PENDING: "PENDIENTE",
  COMPLETED: "COMPLETADO",
  REFUNDED: "REINTEGRADO A SALDO",
};

const Columns = (refundBalance, completeWithdrawal) => {
  let columns = [
    {
      id: "operation_date",
      name: "Fecha de solicitud",
      selector: (row) => row.request_date,
      sortable: true,
      wrap: true,
    },
    {
      id: "client",
      name: "Cliente",
      selector: (row) => row?.prospect?.full_name,
      sortable: true,
      wrap: true,
    },
    {
      id: "amount",
      name: "Importe",
      selector: (row) => <Currency amount={row?.amount} />,
      sortable: true,
      wrap: true,
    },
    /* {
      id: "operation_date",
      name: "Fecha de retiro efectiva",
      selector: (row) => row.withdrawal_date,
      sortable: true,
      wrap: true,
    }, */
    {
      id: "status",
      name: "Estatus",
      selector: (row) => {
        if (row.status == status.COMPLETED)
          return <span className="bg-success badge me-2">{row.status}</span>;
        if (row.status == status.PENDING)
          return <span className="badge bg-info me-2">{row.status}</span>;
        if (row.status == status.REFUNDED)
          return <span className="badge bg-warning me-2">{row.status}</span>;

        return null;
      },
      sortable: true,
      wrap: true,
    },
    /* {
      id: "file",
      name: "Baucher",
      selector: (row) =>
        row?.file?.id ? (
          <a
            download
            href={`${BASE_URL}/files/${row?.file?.id}`}
            className="fw-medium"
            title=""
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-file-download"></i> Descargar
          </a>
        ) : null,
      sortable: true,
      wrap: true,
    },*/
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      left: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          {row.status == status.PENDING ? (
            <div className="p-2">
              <button
                className="btn btn-primary btn-sm"
                title="Realizar retiro"
                onClick={() => completeWithdrawal(row)}
              >
                <i className="fas fa-money-bill-wave"></i>
              </button>
            </div>
          ) : null}
          {row.status == status.PENDING ? (
            <div className="p-2">
              <button
                className="btn btn-success btn-sm"
                title="Reintegrar a la cuenta del cliente"
                onClick={() => refundBalance(row)}
              >
                <i className="fas fa-wallet"></i>
              </button>
            </div>
          ) : null}
        </div>
      ),
    },
  ];
  return columns;
};

export default Columns;
