import React from "react";
import dayjs from "dayjs";

const DateFormat = ({ date, format }) => {
  if (!dayjs(date).isValid()) return "";

  return <span>{dayjs(date).format(format)}</span>;
};

export default DateFormat;
