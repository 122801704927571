import React, { useEffect } from "react"
import { Row, Col, Container, } from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo-sm.svg"
import ProgressBarWizard from "./ProgressBarWizard";

const Registration = props => {

  //meta title
  document.title = "Registro de cliente";

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      
    }
  });

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container className="p-0">
          <Row className="g-0">
            <Col lg={12}>
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <img src="/images/logo_sb.png" alt="" height="80" />
                    </div>
                    <div className="auth-content my-auto">
                      <ProgressBarWizard></ProgressBarWizard>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} Smart-Brokers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Registration