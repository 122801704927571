import React, { Component, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  TabPane,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getActiveSurvey,
  postProspectCheckEmail,
  postProspectRegistration,
} from "../../helpers/backend_helper";
import NibbleInput from "../../components/Fields/NibbleInput";
import registrationStorage from "./registrationStorage";
import Nubarium from "../../components/Nubarium/Nubarium";
import SeccionArchivos from "./SeccionArchivos";
import useSendPinEmail from "./hooks/useSendPinEmail";
import InputMask from "react-input-mask";
import useSendPinSms from "./hooks/useSendPinSms";

const PAISES = [{ id: "México", name: "México" }];
const ESTADOS = [
  { id: 1, name: "Aguascalientes" },
  { id: 2, name: "Baja California" },
  { id: 3, name: "Baja California Sur" },
  { id: 4, name: "Campeche" },
  { id: 5, name: "Coahuila" },
  { id: 6, name: "Colima" },
  { id: 7, name: "Chiapas" },
  { id: 8, name: "Chihuahua" },
  { id: 9, name: "Ciudad de México" },
  { id: 10, name: "Durango" },
  { id: 11, name: "Guanajuato" },
  { id: 12, name: "Guerrero" },
  { id: 13, name: "Hidalgo" },
  { id: 14, name: "Jalisco" },
  { id: 15, name: "México" },
  { id: 16, name: "Michoacán" },
  { id: 17, name: "Morelos" },
  { id: 18, name: "Nayarit" },
  { id: 19, name: "Nuevo León" },
  { id: 20, name: "Oaxaca" },
  { id: 21, name: "Puebla" },
  { id: 22, name: "Querétaro" },
  { id: 23, name: "Quintana Roo" },
  { id: 24, name: "San Luis Potosí" },
  { id: 25, name: "Sinaloa" },
  { id: 26, name: "Sonora" },
  { id: 27, name: "Tabasco" },
  { id: 28, name: "Tamaulipas" },
  { id: 29, name: "Tlaxcala" },
  { id: 30, name: "Veracruz" },
  { id: 31, name: "Yucatán" },
  { id: 32, name: "Zacatecas" },
];
const ESTADO_CIVIL = [
  {
    id: 1,
    name: "Soltero",
  },
  {
    id: 2,
    name: "Casado",
  },
  {
    id: 3,
    name: "Viudo",
  },
  {
    id: 4,
    name: "Separado judicialmente",
  },
  {
    id: 5,
    name: "Divorciado",
  },
  {
    id: 6,
    name: "Unión libre",
  },
  {
    id: 7,
    name: "Otro",
  },
];
const GENEROS = [
  { id: "Femenino", name: "Femenino" },
  { id: "Masculino", name: "Masculino" },
];
const SINO = [
  { id: true, name: "Si" },
  { id: false, name: "No" },
];
const TIPOS_VIVIENDA = [
  { id: "Propia Hipotecada", name: "Propia Hipotecada" },
  { id: "Propia Pagada", name: "Propia Pagada" },
  { id: "Rentada", name: "Rentada" },
  { id: "Familiar", name: "Familiar" },
  { id: "Prestada", name: " Prestada" },
  { id: "Otro", name: " Otro" },
];

const commerAskValidation = Yup.string().when("commer_ask1", {
  is: (value) => value === false,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

//const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TabRegistro = ({ tabId, toggleTabProgress, setRegistrationData }) => {
  const [errors, setErrors] = useState("");
  const [survey, setSurvey] = useState(null);
  const [pending, setPending] = useState(null);
  const [validSelfi, setValidSelfi] = useState(null);
  const [isSavingProspect, setIsSavingProspect] = useState(false);
  const cancelTokenSource = useRef(null);

  const [archivos, setArchivos] = useState([]);
  const [modalId, setModalId] = useState(false);
  const [modalFace, setModalFace] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  const {
    isSendingPinSMS,
    handleSendPinSMS,
    clearValidationOnChangeSMS
  } = useSendPinSms();

  const {
    isSendingPin,
    handleSendPin,
    clearValidationOnChangeEmail,
  } = useSendPinEmail();


  const fetchSurvey = async () => {
    setPending(true);
    const resp = await getActiveSurvey();
    setPending(false);
    setSurvey(resp.data);
    //console.log("resp", resp.data);
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      ti_phone: "",
      pin: "",
      pin_phone: "",
      password: "",
      password2: "",
      face: true,
      files: false,
      pin_email_sent: false,
      pin_phone_sent: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email no valído").required("Requerido"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z\d]).{10,}$/,
          "Minimo 10 caracteres: 1 mayuscula, 1 numerico y uno alfanumerico."
        )
        .required("Requerido"),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Las contraseñas no son iguales")
        .required("Requerido"),
      face: Yup.bool().required("Requerido").isTrue("Requerido"),
      files: Yup.bool().isTrue("Los documentos son requeridos"),
      pin: Yup.string().required("Requerido"),
      pin_email_sent: Yup.bool().isTrue(
        "El correo electrónico no ha sido verificado"
      ),
      ti_phone: Yup.string()
        .matches(
          /^\d{10}$/,
          "El número de teléfono debe tener 10 dígitos numéricos."
        )
        .required("Requerido"),
      pin_phone: Yup.string().required("Requerido"),
      validated_phone: Yup.bool().isTrue(
        "El teléfono no ha sido verificado, da clic en el botón enviar PIN de validación."
      ),
      pin_phone_sent: Yup.bool().isTrue(
        "El teléfono no ha sido verificado, da clic en el botón enviar PIN de validación."
      ),
    }),
    onSubmit: (values) => {
      console.log({ values });
      // dispatch(loginUser(values, props.history));
      save(values);
    },
  });

  const isValidEmail = validation.values.email && !validation.errors.email;

  useEffect(() => {
    clearValidationOnChangeEmail(validation);
  }, [validation.values.email]);

  useEffect(() => {
    clearValidationOnChangeSMS(validation);
  }, [validation.values.ti_phone]);
  
  useEffect(() => {
    if (validSelfi) {
      validation.setFieldValue("face", true);
    }
  }, [validSelfi]);

  const email = validation.values.email;
  const emailErr = validation.errors.email;

  const save = async (formValues) => {
    setErrors("");
    setIsSavingProspect(true);

    const data = {
      ...formValues,
      files: archivos,
    };

    console.log({ data });

    const resp = await postProspectRegistration(data).finally(() =>
      setIsSavingProspect(false)
    );

    responseAction(resp);
  };

  const responseAction = (response) => {
    if (response.status) {
      const { user_id, prospect_id, meta } = response;

      const prospectData = { user_id, prospect_id, meta };

      setRegistrationData(prospectData);
      setModalMessage(true);

      registrationStorage.saveProspect(prospectData);
    } else {
      setErrors(response?.message);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!email || emailErr) return;

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Se canceló la petición");
    }
    cancelTokenSource.current = axios.CancelToken.source();

    async function checkEmail() {
      const resp = await postProspectCheckEmail(
        { email },
        {
          cancelToken: cancelTokenSource.current.token,
        }
      );
      if (resp.exist) {
        validation.setErrors({
          ...validation.errors,
          email: "El email ya existe en el sistema",
        });
      }
    }
    checkEmail();
  }, [email, emailErr]);

  useEffect(() => {
    if (archivos.length == 2) {
      validation.setFieldValue("files", true);
    }
  }, [archivos]);

  /*
  useEffect(() => {
    if (validation.values.pin) {
      handleValidatePinEntered(validation);
    }
  }, [validation.values.pin]);

  useEffect(() => {
    if (validation.values.pin_phone) {
      handleValidatePinPhoneEntered(validation);
    }
  }, [validation.values.pin_phone]);
  */

  useEffect(() => {
    clearValidationOnChangeEmail(validation);
  }, [validation.values.email]);

  const toggleId = () => {
    setModalId(!modalId);
  };
  const toggleFace = () => {
    setModalFace(!modalFace);
  };

  const phone = validation.values.ti_phone;
  const phoneErr = validation.errors.ti_phone;
  const isValidPhone = phone && !phoneErr;
  const isValidatedPhone = validation.values.validated_phone;

  /*
  if(!isMobile){
    return (
      <>
        <Alert color={'warning'}>Debe registrarse en un teléfono móvil</Alert>
      </>
    )
  }
  */

  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <div className="text-center mb-4">
          <h5>Registro</h5>
          <p className="card-title-desc">
            Registra tu cuenta con Smart Brokers o si ya te has registrado
            puedes ingresar <Link to="/login">aquí</Link>
          </p>
        </div>
        {errors && <Alert color="danger">{errors}</Alert>}
        <Row>
          <Col md={12} className="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="d-flex flex-column ">
                <div className="auth-content my-auto">
                  <div className="mt-5">
                    <h5>Cuenta</h5>
                    <p className="card-title-desc mb-3">
                      Para crear tu cuenta requieres un correo electrónico y una
                      contraseña con mínimo 10 caracteres: 1 mayúscula, 1
                      númerico y uno alfanumérico.
                    </p>
                    <Row>
                      <Col className="mt-2" md={6}>
                        <Col className="mt-2" md={12}>
                          <Label className="form-label">Correo:</Label>
                          <div className="input-group mb-3">
                            <Input
                              className="form-control"
                              name="email"
                              type={"text"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email}
                              invalid={
                                validation
                                  ? validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                  : false
                              }
                            />
                            {isValidEmail && !isSendingPin && (
                              <span
                                role="button"
                                title="Enviar PIN de validación"
                                className="input-group-text"
                                onClick={() => handleSendPin(validation)}
                              >
                                {validation.values.pin_email_sent ? (
                                  <i className="mdi mdi-email-check text-success"></i>
                                ) : (
                                  <>
                                    <i className="bx bx-mail-send me-2"></i>{" "}
                                    Enviar PIN
                                  </>
                                )}
                              </span>
                            )}
                            {isSendingPin && (
                              <span className="input-group-text">
                                <i className="bx bx-loader-circle bx-spin"></i>
                              </span>
                            )}
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          </div>
                          {validation.submitCount && !validation.values.pin ? (
                            <div className="col-md-12 text-danger invalid-feedback d-block">
                              Necesitas verificar tu correo electrónico
                            </div>
                          ) : (
                            <></>
                          )}

                          {validation.values.pin_email_sent ? (
                            <NibbleInput
                              md={12}
                              label="Email PIN"
                              fieldName="pin"
                              fieldType="text"
                              placeHolder="Ingresa el PIN recibido"
                              validation={validation}
                            />
                          ) : (
                            <div className="col-md-12" />
                          )}
                        </Col>
                      </Col>
                      <Col className="mt-2" md={6}>
                        <Col className="mt-2" md={12}>
                          <Label className="form-label">Teléfono móvil:</Label>
                          <div className="input-group mb-3">
                            <InputMask
                              mask="(999) 999-9999"
                              name="ti_phone"
                              value={validation.values.ti_phone}
                              className={`form-control input-color ${
                                validation.touched.ti_phone &&
                                validation.errors.ti_phone
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={async (e) => {
                                const unmask = /\D/g;

                                const { value } = e.target;

                                if (!unmask) {
                                  validation.handleChange(e);
                                  return;
                                }

                                await validation.setFieldValue(
                                  "ti_phone",
                                  value.replace(unmask, ""),
                                  true
                                );
                                await validation.setTouched({
                                  ["ti_phone"]: true,
                                });
                              }}
                              alwaysShowMask
                            />

                            {!isSendingPin && isValidPhone && (
                              <span
                                role="button"
                                title="Enviar PIN de validación por SMS"
                                className="input-group-text"
                                onClick={() => handleSendPinSMS(validation)}
                              >
                                {isValidatedPhone ? (
                                  <i className="fas far fa-check-circle text-success"></i>
                                ) : (
                                  <>
                                    <i className="fas fa-sms me-2"></i> Enviar
                                    PIN
                                  </>
                                )}
                              </span>
                            )}
                            {isSendingPinSMS && (
                              <span className="input-group-text">
                                <i className="bx bx-loader-circle bx-spin"></i>
                              </span>
                            )}
                          </div>

                          <FormFeedback type="invalid">
                            {validation.errors.ti_phone}
                          </FormFeedback>

                          {validation.submitCount &&
                          !validation.values.pin_phone_sent ? (
                            <div className="mb-3 col-md-12 text-danger invalid-feedback d-block">
                              Es requerido validar tu teléfono móvil
                            </div>
                          ) : null}

                          {validation.values.pin_phone_sent ? (
                            <NibbleInput
                              md={12}
                              label={
                                <>
                                  {"PIN teléfono móvil:"}
                                  {isValidatedPhone && (
                                    <i className="ms-1 bx bxs-check-circle text-success"></i>
                                  )}
                                </>
                              }
                              fieldName="pin_phone"
                              fieldType="text"
                              placeHolder="Ingresa el PIN recibido por SMS"
                              validation={validation}
                            />
                          ) : null}
                        </Col>
                      </Col>
                    </Row>

                    <Row>
                      {/* <NibbleInput
                      md={6}
                      label="Correo:"
                      fieldName="email"
                      fieldType="text"
                      validation={validation}
                    /> */}

                      <NibbleInput
                        md={6}
                        label="Contraseña:"
                        fieldName="password"
                        fieldType="password"
                        validation={validation}
                      />

                      <NibbleInput
                        md={6}
                        label="Repetir contraseña:"
                        fieldName="password2"
                        fieldType="password"
                        validation={validation}
                      />
                    </Row>
                  </div>

                  <div className="row mt-5">
                    <h5>Documento de identidad</h5>
                    <p className="card-title-desc mb-3">
                      Introduce tu documentación a través de archivos en formato
                      JPG o PNG, la documentación necesaria es INE anverso y
                      reverso.
                    </p>
                    <SeccionArchivos
                      setArchivos={setArchivos}
                      validation={validation}
                    ></SeccionArchivos>
                  </div>
                </div>
              </div>

              <Col md={12} className="text-center mt-5">
                <button
                  disabled={!validation.values.face || isSavingProspect}
                  className="btn btn-primary"
                  type="submit"
                >
                  {isSavingProspect ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="fas fa-save"></i>
                  )}{" "}
                  Registrar
                </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </TabPane>

      {modalId && (
        <Nubarium
          captureType={"Id"}
          show={modalId}
          toggle={toggleId}
          setIdInfo={() => {}}
          archi
        ></Nubarium>
      )}

      {modalMessage && (
        <Modal isOpen={modalMessage} className="modal-lg">
          <ModalHeader>Alerta</ModalHeader>
          <ModalBody>
            <div>
              <div className="mt-4 mb-4">
                Ha completado su registro inicial, recuerde que puede continuar
                el proceso de registro iniciando sesión en la plataforma. Para
                esto se ha enviado un mensaje a tu correo electrónico con los
                datos de acceso.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                setModalMessage(false);
                toggleTabProgress(2);
              }}
            >
              <i className="fa fa-times"></i> Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TabRegistro;
