import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { retireClientContract } from "../../helpers/backend_helper";

const ConfirmRetireContractModal = ({
  isModalOpen,
  clientContract,
  setIsModalOpen,
  getMyInvestings,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const retireContract = () => {
    setIsLoading(true);

    retireClientContract(clientContract.id)
      .then(() => {
        getMyInvestings();
        setIsModalOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <ModalHeader className="bg-primary">
          <span className="text-light">Retiro de Inversión</span>
        </ModalHeader>
        <ModalBody>
          Se enviará una solicitud de retiro que se aplicará para el día{" "}
          {clientContract?.product?.withdrawal_day_month} de MES
        </ModalBody>
        <ModalFooter>
          <Button disabled={isLoading} color="primary" onClick={retireContract}>
            Continuar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmRetireContractModal;
