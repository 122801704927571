import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";
import Currency from "../../components/Common/Currency";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const DetailModal = ({ modal, setModal, setItem, item, realoadData }) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setModal({ ...modal, detail: false });
  };

  return (
    <div>
      <Modal isOpen={modal.detail} size="sm">
        <ModalHeader className="bg-primary">
          <span className="text-light">Detalle</span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <p>
              Fecha de operación: <strong>{item.operation_date}</strong>
            </p>
            <p>Cliente: {item?.prospect?.full_name}</p>
            <p>
              Monto: <Currency amount={item?.amount} />
            </p>
            <p>Descripción: {item?.description}</p>
            <p>Referencia: {item?.reference}</p>
            <p>Subtipo: {item?.subtype}</p>
            {item?.file?.id ? (
              <a
                download
                href={`${BASE_URL}/files/${item?.file?.id}`}
                className="fw-medium"
                title=""
                target="_blank"
                rel="noreferrer"
              >
                <i className="fas fa-file-download"></i> Descargar baucher
              </a>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DetailModal;
