import React from "react";
import Currency from "../../components/Common/Currency";

const Columns = () => {
  let columns = [
    {
      id: "amount",
      name: "Importe",
      selector: (row) => <Currency amount={row?.amount} />,
      sortable: true,
      wrap: true,
    },
    {
      id: "operation_date",
      name: "Fecha de solicitud",
      selector: (row) => row.request_date,
      sortable: true,
      wrap: true,
    },
    {
      id: "operation_date",
      name: "Fecha de retiro efectiva",
      selector: (row) => row.withdrawal_date,
      sortable: true,
      wrap: true,
    },
    {
      id: "status",
      name: "Estatus",
      selector: (row) => {
        const status = {
          PENDING: "PENDIENTE",
          COMPLETED: "COMPLETADO",
          REFUNDED: "REINTEGRADO A SALDO",
        };

        if (row.status == status.COMPLETED)
          return (
            <span
              /* style={{ textTransform: "lowercase" }} */
              className="bg-success badge me-2"
            >
              {row.status}
            </span>
          );
        if (row.status == status.PENDING)
          return (
            <span
              /* style={{ textTransform: "lowercase" }} */
              className="badge bg-info me-2"
            >
              {row.status}
            </span>
          );
        if (row.status == status.REFUNDED)
          return (
            <span
              /* style={{ textTransform: "lowercase" }} */
              className="badge bg-warning me-2"
            >
              {row.status}
            </span>
          );

        return null;
      },
      sortable: true,
      wrap: true,
    },
    /* {
      id: "file",
      name: "Baucher",
      selector: (row) =>
        row?.file?.id ? (
          <a
            download
            href={`${BASE_URL}/files/${row?.file?.id}`}
            className="fw-medium"
            title=""
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-file-download"></i> Descargar
          </a>
        ) : null,
      sortable: true,
      wrap: true,
    },
    {
      id: "actions",
      name: "Acciones",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <button
              className="btn btn-primary btn-sm"
              title="Eliminar"
              onClick={() => showDetail(row)}
            >
              <i className="fas fa-eye"></i>
            </button>
          </div>
        </div>
      ),
    }, */
  ];
  return columns;
};

export default Columns;
