const REGISTRATION_TABS = "registrationTab";
const REGISTRATION_PROSPECT = "registrationProspect";

const registrationStorage = {
  saveTabs(data) {
    const stateJSON = JSON.stringify(data);
    sessionStorage.setItem(REGISTRATION_TABS, stateJSON);

    return this;
  },
  saveProspect(data) {
    const registrationJSON = JSON.stringify(data);
    sessionStorage.setItem(REGISTRATION_PROSPECT, registrationJSON);

    return this;
  },
  getTabs() {
    const tabsJSON = sessionStorage.getItem(REGISTRATION_TABS);

    return tabsJSON ? JSON.parse(tabsJSON) : null;
  },
  getProspect() {
    const prospectJSON = sessionStorage.getItem(REGISTRATION_PROSPECT);

    return prospectJSON ? JSON.parse(prospectJSON) : null;
  },
  clean() {
    sessionStorage.removeItem(REGISTRATION_TABS);
    sessionStorage.removeItem(REGISTRATION_PROSPECT);
  },
};

export default registrationStorage;
