import * as Yup from "yup";

const extPersonValidation = Yup.string().when("peps_ext", {
  is: (value) => value === true,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

const relatedExtPersonValidation = Yup.string().when("peps_rel", {
  is: (value) => value === true,
  then: Yup.string().nullable().required("Requerido"),
  otherwise: Yup.string().nullable(),
});

export const validationSchema = Yup.object({
  ask1: Yup.string().nullable().required("Requerido"),
  ask2: Yup.boolean().nullable().required("Requerido"),
  ask3: Yup.bool().nullable().required("Requerido"),
  ask4: Yup.bool().nullable().required("Requerido"),
  facta_ask1: Yup.bool().nullable().required("Requerido"),
  facta_ask2: Yup.bool().nullable().required("Requerido"),
  facta_ask3: Yup.bool().nullable().required("Requerido"),
  facta_tin: Yup.string().when("facta_ask3", {
    is: true,
    then: Yup.string().nullable().required("Requerido"),
    otherwise: Yup.string().nullable(),
  }),
  bank_person: Yup.string().nullable().required("Requerido"),
  bank_name: Yup.string().nullable().required("Requerido"),
  bank_account: Yup.string()
    .nullable()
    .required("Requerido")
    .matches(/^\d{18}$/, "El campo debe contener 18 dígitos"),
  bank_account_number: Yup.string()
    .nullable()
    .required("Requerido")
    .matches(/^\d+$/, "El campo debe contener solo números"),
  bank_branch: Yup.string().nullable(),
  sociedades: Yup.boolean().nullable().required("Requerido"),
  ae_actividad: Yup.string().nullable().required("Requerido"),
  ae_empresa: Yup.string()
    .nullable()
    .when("ae_actividad", {
      is: "1",
      then: Yup.string().nullable().required("Requerido"),
      otherwise: Yup.string().nullable(),
    }),
  ae_puesto: Yup.string()
    .nullable()
    .when("ae_actividad", {
      is: "1",
      then: Yup.string().nullable().required("Requerido"),
      otherwise: Yup.string().nullable(),
    }),
  ae_pagina: Yup.string().nullable().when("ae_actividad", {
    is: "1",
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable(),
  }),
  ae_tel: Yup.string()
    .nullable()
    .when("ae_actividad", {
      is: "1",
      then: Yup.string().nullable().required("Requerido"),
      otherwise: Yup.string().nullable(),
    }),
  ae_sector: Yup.string()
    .nullable()
    .when("ae_actividad", {
      is: "1",
      then: Yup.string().nullable().required("Requerido"),
      otherwise: Yup.string().nullable(),
    }),
  ae_giro: Yup.string()
    .nullable()
    .when("ae_actividad", {
      is: "1",
      then: Yup.string().nullable().required("Requerido"),
      otherwise: Yup.string().nullable(),
    }),
  ae_profession: Yup.string().nullable().required("Requerido"),
  ae_type_income: Yup.string().nullable().required("Requerido"),
  ae_additional_income: Yup.string().nullable(),
  ae_retired_company: Yup.string().nullable(),
  ae_name_lender: Yup.string().nullable().required("Requerido"),
  peps_peps: Yup.boolean().nullable().required("Requerido"),
  peps_ext: Yup.boolean().nullable().required("Requerido"),
  peps_ext_institucion: extPersonValidation,
  peps_rel: Yup.boolean().nullable().required("Requerido"),
  peps_rel_institucion: relatedExtPersonValidation,
  cuentas_carga: Yup.string()
    .nullable()
    .required("Requerido")
    .test({
      message: "El valor debe ser 100",
      test: function (value) {
        return value == 100;
      },
    }),
  cuentas_procedencia: Yup.string().nullable().required("Requerido"),
  cuentas_procedencia_other: Yup.string()
  .nullable()
  .when("cuentas_procedencia", {
    is: "7",
    then: Yup.string().nullable().required("Requerido"),
    otherwise: Yup.string().nullable(),
  }),
  cuentas_intereses: Yup.number()
    //.min(1, "Porcentaje mínimo 1")
    //.max(100, "Porcentaje máximo 100")
    .required("Requerido")
    .test({
      message: "El valor debe ser 100",
      test: function (value) {
        return value == 100;
      },
    }),
  movimientos_inicial: Yup.number()
    .min(5000, "Monto mínimo 5,000")
    .max(100000, "Monto máximo 100,000")
    .required("Requerido"),
  movimientos_depositos: Yup.number()
    .min(1, "Debe ser mayor a 0")
    .required("Requerido"),
  movimientos_dep_prom: Yup.string().nullable().required("Requerido"),
  movimientos_retiros: Yup.number()
    .min(1, "Debe ser mayor a 0")
    .required("Requerido"),
  movimientos_ret_prom: Yup.string().nullable().required("Requerido"),
  movimientos_otra_casa: Yup.boolean().nullable().required("Requerido"),
  movimientos_otro_instrumento: Yup.string().when("movimientos_otra_casa", {
    is: true,
    then: Yup.string().nullable().required("Requerido"),
    otherwise: Yup.string().nullable(),
  }),
  beneficiaries: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Requerido"),
      percent: Yup.number()
        .min(1, "Porcentaje mínimo 1")
        .max(100, "Porcentaje máximo 100")
        .required("Requerido"),
      relationship: Yup.string().required("Requerido"),
      relationship_other: Yup.string().when("relationship", {
        is: "Otro",
        then: Yup.string().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      birth: Yup.string().required("Requerido"),
      nation: Yup.string().required("Requerido"),
      country: Yup.string().required("Requerido"),
      state_tmp: Yup.string().when("country", {
        is: (country) => country === "México",
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      state: Yup.string().when("country", {
        is: (country) => country !== "México",
        then: Yup.string().nullable().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      address: Yup.string().required("Requerido"),
    })
  ),
  economic_dependents_ask: Yup.boolean().nullable(),
  economic_dependents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Requerido"),
      relationship: Yup.string().required("Requerido"),
      relationship_other: Yup.string().when("relationship", {
        is: "Otro",
        then: Yup.string().required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      birth: Yup.string().required("Requerido"),
      nation: Yup.string().required("Requerido"),
      country: Yup.string().required("Requerido"),
      state: Yup.string().required("Requerido"),
      address: Yup.string().required("Requerido"),
    })
  ),
  societies: Yup.array().of(
    Yup.object().shape({
      sociedad_name: Yup.string().required("Requerido"),
      sociedad_commercial: Yup.string().required("Requerido"),
      sociedad_nation: Yup.string().required("Requerido"),
      sociedad_apoderado: Yup.string().required("Requerido"),
      sociedad_tel: Yup.string()
        .matches(
          /^\d{10}$/,
          "El número de teléfono debe tener 10 dígitos numéricos."
        )
        .required("Requerido"),
      sociedad_percent: Yup.number()
        .min(1, "Debe ser mayor a 0")
        .required("Requerido"),
      sociedad_counselor: Yup.boolean().nullable().required("Requerido"),
    })
  ),
  totalBeneficiariesPercent: Yup.number().test(
    "total-percent",
    "La suma de los porcentajes debe ser 100",
    function () {
      const beneficiaries = this.parent.beneficiaries;

      const totalPercent = beneficiaries.reduce(
        (total, beneficiary) => total + Number(beneficiary.percent),
        0
      );

      return totalPercent === 100;
    }
  ),
  tax_information_regime: Yup.string().nullable().required("Requerido"),
  tax_information_country: Yup.string().nullable().required("Requerido"),
});
