import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DataTableHelper from "../../../helpers/tables/DataTableHelper"
import Columns from './Columns'
import AddUpdModal from './AddUpdModal'
import { delAsk, getProfileRisk, getProfileRiskPermissions, getSurvey } from '../../../helpers/backend_helper';
import {DestroyAlertHelper,Alert,ToastHelper} from '../../../helpers/alerts/alertHelper';
import {Container, Table} from "reactstrap";
import { Tbody, Thead } from "react-super-responsive-table";

const Survey = ({match}) => {
    const surveyId = match.params.id
    const itemBase = {
        id: 0,
        name: '',
        description: '',
        bechmark: '',
        composition: '',
        operation: '',
        risks: '',
        administration: '',
    };
    const [survey, setSurvey] = useState(null);
    const [pending, setPending] = useState(false);
    const [modal, setModal] = useState({show:false,update:false});
    const [dataPag, setDataPag] = useState({});
    const [filters,setFilters] = useState({page:1,perPage:20,search:'',orderBy:'id',order:'asc'});
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(itemBase);

    const fetchProfileRisk = async () => {
        setPending(true)
        const resp = await getProfileRisk(surveyId)
        setPending(false)
        setProfileRisk(resp.data)
        console.log('resp', resp.data)
    }

    const fetchSurvey = async () => {
        setPending(true)
        const resp = await getSurvey(surveyId)
        setPending(false)
        setSurvey(resp.data)
        console.log('resp', resp.data)
    }

    useEffect(() => {
        fetchSurvey();
    }, [])

    const addUpd = (row=false) => {
        if(row.id){
            setItem(row)
            setModal({...modal,show:true,update:true})
        }else{
            setItem({...itemBase, survey_id: survey.id})
            setModal({...modal,show:true,update:false})
        }
    }

    const destroy = (id) => {
        DestroyAlertHelper(async confirmed => {
            if (!confirmed) { return; }
            const response = await delAsk(id)
            Alert(response.message,response.status)
            if (response.status)  fetchSurvey();
        });
    }
    
    const columns = Columns(addUpd, destroy);
	document.title = "Encuesta";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="card">

                    <div className="card-body pb-0">
                        <Breadcrumbs title="Encuesta" breadcrumbItem="Encuesta" />
                        <h4>Encuesta {survey?.name}</h4>

                        { survey && 
                        <>
                        <div className="p-2 text-end">
                            <button className="btn btn-sm btn-success" onClick={() => {addUpd()}}><i className="fas fa-solid fa-plus"></i> Agregar Pregunta</button>
                        </div>
                        
                            
                        <Table className="table table-striped table-bordered">
                            <Thead className="table-light">
                                <tr>
                                    <th>Sección</th>
                                    <th style={{width:'35%'}}>Pregunta</th>
                                    <th style={{width:'35%'}}>Respuesta</th>
                                    <th style={{width:'120px'}}>Puntos</th>
                                    <th style={{width:'120px'}}>Acciones</th>
                                </tr>
                            </Thead>
                            <Tbody>
                                
                                { (survey?.asks.filter( e => { return e.survey_section_id == null }) || []).map( (e) => (
                                <tr key={e.id}>
                                    <td></td>
                                    <td>{e.ask}</td>
                                    <td>
                                        <div>A. {e.optionA}</div>
                                        <div>B. {e.optionB}</div>
                                        <div>C. {e.optionC}</div>
                                        <div>D. {e.optionD}</div>
                                        <div>E. {e.optionE}</div>
                                        <div>F. {e.optionF}</div>
                                    </td>
                                    <td>
                                        <div>A. {e.valueA > 0 ? e.valueA:''}</div>
                                        <div>B. {e.valueB > 0 ? e.valueB:''}</div>
                                        <div>C. {e.valueC > 0 ? e.valueC:''}</div>
                                        <div>D. {e.valueD > 0 ? e.valueD:''}</div>
                                        <div>E. {e.valueE > 0 ? e.valueE:''}</div>
                                        <div>F. {e.valueF > 0 ? e.valueF:''}</div>
                                    </td>
                                    <td>
                                        <div className="p-2">
                                            <button className="btn btn-warning btn-sm me-2" title='Editar' onClick={() => addUpd(e)}><i className="fas fa-edit"></i></button>
                                            <button className="btn btn-danger btn-sm" title='Eliminar' onClick={() => destroy(e.id)}><i className="fas fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                ))}

                                { (survey?.sections || []).map( (f) => (
                                    <>
                                    { (survey?.asks.filter( e => { return e.survey_section_id == f.id }) || []).map( (e) => (
                                    <tr key={e.id}>
                                        <td>{f.name }</td>
                                        <td>{e.ask}</td>
                                        <td>
                                            <div>A. {e.optionA}</div>
                                            <div>B. {e.optionB}</div>
                                            <div>C. {e.optionC}</div>
                                            <div>D. {e.optionD}</div>
                                            <div>E. {e.optionE}</div>
                                            <div>F. {e.optionF}</div>
                                        </td>
                                        <td>
                                            <div>A. {e.valueA > 0 ? e.valueA:''}</div>
                                            <div>B. {e.valueB > 0 ? e.valueB:''}</div>
                                            <div>C. {e.valueC > 0 ? e.valueC:''}</div>
                                            <div>D. {e.valueD > 0 ? e.valueD:''}</div>
                                            <div>E. {e.valueE > 0 ? e.valueE:''}</div>
                                            <div>F. {e.valueF > 0 ? e.valueF:''}</div>
                                        </td>
                                        <td>
                                            <div className="p-2">
                                                <button className="btn btn-warning btn-sm me-2" title='Editar' onClick={() => addUpd(e)}><i className="fas fa-edit"></i></button>
                                                <button className="btn btn-danger btn-sm" title='Eliminar' onClick={() => destroy(e.id)}><i className="fas fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                    ))}
                                    </>
                                ))}
                            </Tbody>
                        </Table>
                        </>
                        }
                        {modal.show &&
                            <AddUpdModal 
                                modal={modal}
                                setModal={setModal}
                                items={items}
                                setItems={setItems}
                                item={item}
                                setItem={setItem}
                                sections={survey?.sections}
                                survey={survey}
                                fetchData={fetchSurvey}
                            />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Survey;