import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner,Form, Input, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postUser,putUser,getCompanies } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import * as Yup from "yup";
import { useFormik } from "formik";

const AddUpdModal = ({modal,setModal,items, setItems, setItem,item}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
	}

	const validation = useFormik({
		enableReinitialize: true,

		initialValues: item,
		validationSchema: Yup.object({
			name: Yup.string().required('Required').min(1),
			email:Yup.string().required('Required').email(),
			profile:Yup.object().required('Required')
		}),
		onSubmit: async values => {
			await save(values)
			handleClose()
			validation.resetForm();
		},
	});

	const save = (data) => {
		setLoading(true)
		if(modal.update){
			putUser(data).then(response => {
				console.log('kdo')
				responseAction(response);			
			})
    	}else{
    		postUser(data).then(response => {
    			responseAction(response);

    		})	
    	}

    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.user;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.data,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    		
    	});
    	
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>Modal title</ModalHeader>
				<Form
					onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
					}}
				>
				<ModalBody>
				

				<div className="row">
					<div className="col-sm-12">
					    <label className="form-label">Nombre:</label>
					    <Input
							name="name"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.name || ""}
							invalid={
								validation.touched.name &&
								validation.errors.name
								? true
								: false
							}
						/>
					    {(validation.touched.name && validation.errors.name) && <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>}
					</div>
					<div className="col-sm-6">
					    <label className="form-label">Email:</label>
					    <Input
							name="email"
							type="email"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.email || ""}
							invalid={
								validation.touched.email &&
								validation.errors.email
								? true
								: false
							}
						/>
					    {errors && <div className="form-text text-danger">{errors.email && errors.email[0]}</div>}
					    {(validation.touched.email && validation.errors.email) && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
					</div>
					<div className="col-sm-6">
					    <label className="form-label">Perfil:</label>
						<Select
							className={'is-invalid'}
							name="profile_id"
							options={selects.profiles}
							value={validation.values.profile || null}
							onChange={(option, actionMeta) => { [validation.setFieldValue('profile_id', option.id,true ),validation.setFieldValue('profile', option,true )]}}
							onBlur={ (option) => { validation.setFieldTouched('profile', true, true);validation.setFieldTouched('profile_id', true, true)} }
							placeholder={'Select...'}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => option}
						/>
						{(validation.touched.profile && validation.errors.profile) && <FormFeedback type="invalid">{validation.errors.profile.value ? validation.errors.profile.value:'Error'}</FormFeedback>}
					    {errors && <div className="form-text text-danger">{errors.profile_id && errors.profile_id[0]}</div>}
					</div>

					</div>	
				
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="primary" disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
				</Form>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;