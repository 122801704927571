import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input, Form,FormFeedback} from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postProfile,putProfile,getCompanies } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import * as Yup from "yup";
import { useFormik } from "formik";


const AddUpdModal = ({modal,setModal,items, setItems, setItem,item}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
    }

    const validation = useFormik({
		enableReinitialize: true,
		initialValues: item,
		validationSchema: Yup.object({
			name: Yup.string().required('Required').min(1),
		}),
		onSubmit: async values => {
			await save(values)
			handleClose()
			validation.resetForm();
		},
	});

    const save = (data) => {
    	setErrors([])
    	setLoading(true)
    	if(modal.update){
    		putProfile(data).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postProfile(data).then(response => {
    			responseAction(response);
    		})	
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.profile;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.companies,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    		
    	});
    	
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>{modal.update ? 'Editar ':'Agregar '}Emresa</ModalHeader>
				<Form
					onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
					}}
				>
				<ModalBody>
				<div className="row">
					<div className="col-sm-12">
					    <Input
							name="name"
							type="text"
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							value={validation.values.name || ""}
							invalid={
								validation.touched.name &&
								validation.errors.name
								? true
								: false
							}
						/>
					    {(validation.touched.name && validation.errors.name) && <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>}
					</div>
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit" disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
				</Form>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;