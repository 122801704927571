import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  Alert,
  Spinner,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  postProspectDocs,
  postProspectRegistration,
} from "../../helpers/backend_helper";
import registrationStorage from "./registrationStorage";
import SeccionArchivosDocs from "./SeccionArchivosDocs";

//const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TabDocs = ({ tabId, toggleTabProgress, setRegistrationData, prospect_id }) => {
  const [errors, setErrors] = useState("");
  const [archivos, setArchivos] = useState([]);
  const [isSavingProspect, setIsSavingProspect] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      files: false,
    },
    validationSchema: Yup.object({
      files: Yup.bool().isTrue("Los documentos son requeridos"),
    }),
    onSubmit: (values) => {
      console.log({ values });
      // dispatch(loginUser(values, props.history));
      save(values);
    },
  });

  const save = async (formValues) => {
    setErrors("");
    setIsSavingProspect(true);

    const data = {
      prospect_id: prospect_id,
      files: archivos,
    };

    console.log({ data });

    const resp = await postProspectDocs(data);

    responseAction(resp);
  };

  const responseAction = (response) => {
    if (response.status) {
      toggleTabProgress(5);
    } else {
      setErrors(response?.message);
    }
    setIsSavingProspect(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (archivos.length == 2) {
      validation.setFieldValue("files", true);
    }
  }, [archivos]);

  /*
  if(!isMobile){
    return (
      <>
        <Alert color={'warning'}>Debe registrarse en un teléfono móvil</Alert>
      </>
    )
  }
  */
console.log(setRegistrationData)
  return (
    <React.Fragment>
      <TabPane tabId={tabId}>
        <div className="text-center mb-4">
          <h5>Documentos adicionales</h5>
          <p className="card-title-desc">
            Adjunta tu documentación y valida tu identidad.
          </p>
        </div>
        {errors && <Alert color="danger">{errors}</Alert>}
        <Row>
          <Col md={12} className="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="d-flex flex-column ">
                <div className="auth-content my-auto">
                  <div className="row mt-5">
                    <p className="card-title-desc mb-3">
                      Introduce tu documentación a través de archivos en formato
                      JPG o PNG, la documentación necesaria es comprobante de domicilio
                      (recibos válidos son: CFE, Telmex, Telcel, Megacable, SKY, IZZI)
                      y un PDF con el un estado de cuenta bancario completo.
                    </p>
                    <SeccionArchivosDocs
                      setArchivos={setArchivos}
                      validation={validation}
                    ></SeccionArchivosDocs>
                  </div>
                </div>
              </div>

              <Col md={12} className="text-center mt-5">
                <button
                  disabled={archivos.length != 2 || isSavingProspect}
                  className="btn btn-primary"
                  type="submit"
                >
                  {isSavingProspect ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="fas fa-save"></i>
                  )}{" "}
                  Guardar
                </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </TabPane>

    </React.Fragment>
  );
};

export default TabDocs;
