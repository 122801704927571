import { Beneficiary } from "./constants";

export const complementaryDataBase = {
  ask1: "",
  ask2: null,
  ask3: null,
  ask4: null,
  facta_ask1: false,
  facta_ask2: false,
  facta_ask3: false,
  facta_tin: "",
  bank_person: "",
  bank_name: "",
  bank_account: "",
  bank_account_number: "",
  bank_branch: "",
  sociedades: false,
  ae_actividad: 1,
  ae_empresa: "",
  ae_puesto: "",
  ae_pagina: "",
  ae_tel: "",
  ae_sector: "",
  ae_giro: "",
  ae_profession: "",
  ae_type_income: "",
  ae_additional_income: "",
  ae_retired_company: "",
  ae_name_lender: "",
  peps_peps: false,
  peps_ext: false,
  peps_ext_institucion: "",
  peps_rel: false,
  peps_rel_institucion: "",
  cuentas_carga: "",
  cuentas_procedencia: "",
  cuentas_intereses: "",
  movimientos_inicial: "",
  movimientos_depositos: "",
  movimientos_dep_prom: null,
  movimientos_retiros: "",
  movimientos_ret_prom: null,
  movimientos_otra_casa: null,
  movimientos_otro_instrumento: "",
  beneficiaries: [{ ...Beneficiary }],
  economic_dependents_ask: false,
  economic_dependents: [],
  societies: [],
  totalBeneficiariesPercent: 0,
  tax_information_regime: null,
  tax_information_country: null,
};
