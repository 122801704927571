import React, { useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Button, Label, Row, Spinner } from "reactstrap";
import { dataURItoBlob } from "../Dashboard/ContractDocuments";
import { configOptions } from "./static";
import { updateConfigurationOptions } from "../../helpers/backend_helper";
import Dropzone from "react-dropzone";

const Signature = () => {
  const signatureRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [finishedSignature, setFinishedSignature] = useState(false);

  const cleanSignature = () => {
    signatureRef.current.clear();
  };

  const submitSignaturePad = () => {
    if (signatureRef.current.isEmpty()) return;

    const image = signatureRef.current.toDataURL();
    const file = dataURItoBlob(image);

    onSubmitSignature(file);
  };

  const onDropFiles = (files) => {
    if (!files?.length) return;

    onSubmitSignature(files[0]);
  };

  const onSubmitSignature = (file) => {
    const data = [
      {
        configuration_id: configOptions.FIRMA_SMART_BROKERS,
        value: file,
      },
    ];

    const formData = new FormData();
    data.forEach((item, index) => {
      formData.append(
        `configs[${index}][configuration_id]`,
        item.configuration_id
      );
      formData.append(`configs[${index}][value]`, item.value);
    });

    setIsLoading(true);

    updateConfigurationOptions(formData).finally(() => setIsLoading(false));
  };

  return (
    <Row className="pt-4">
      <div className="col-md-12">
        <Label for="active">
          Configuración de firma: {isLoading && <Spinner size="sm" />}{" "}
        </Label>{" "}
        <br />
      </div>
      <div className="mt-2 col-md-6">
        <div
          style={{
            width: "300px",
            height: "150px",
            border: "solid 1px #AAA",
            display: "inline-block",
          }}
        >
          <SignaturePad
            ref={signatureRef}
            onAfterUpdate={(e) => {
              console.log("onAfterUpdate", e);
            }}
            afterUpdateStroke={(e) => {
              console.log("afterUpdateStroke", e);
            }}
            onChange={(e) => {
              console.log("onChange", e);
            }}
            options={{
              dotSize: 1.5,
              minWidth: 1.5,
              maxWidth: 2.5,
              penColor: "black",
              onChange: (e) => {
                console.log("onChange", e);
              },
            }}
          />

          <Button
            className={"mt-2"}
            type="button"
            color="primary"
            size="sm"
            onClick={cleanSignature}
          >
            Limpiar
          </Button>
          <Button
            className={"ms-2 mt-2"}
            type="button"
            color="success"
            size="sm"
            onClick={submitSignaturePad}
            disabled={finishedSignature || isLoading}
          >
            Guardar firma
          </Button>
        </div>
      </div>
      <div className="col-md-6">
        <Dropzone accept="image/*" onDrop={onDropFiles} disabled={isLoading}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" style={{ minHeight: "120px" }}>
              <div className="text-center needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-1">
                  <i className="display-5 text-muted bx bx-cloud-upload" />
                </div>
                <h5>Arrastra archivos aquí o da clic en cargar</h5>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </Row>
  );
};

export default Signature;
