import React, { useEffect, useState } from "react"
import { useDispatch,useSelector } from "react-redux"
//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DataTableHelper from "../../../helpers/tables/DataTableHelper"
import Columns from './Columns'
import AddUpdModal from './AddUpdModal'
import { getConfigurations,deleteConfiguration } from '../../../helpers/backend_helper';
import { getStorageCompany } from '../../../helpers/api_helper';
import {DestroyAlertHelper,Alert,ToastHelper} from '../../../helpers/alerts/alertHelper';
import {Container} from "reactstrap";
import { useParams } from 'react-router-dom';

const Configuration = () => {
    const [pending, setPending] = useState(false);
    const {id} = useParams();
    const company = id ? {id:id}:getStorageCompany();
    const [dataPag, setDataPag] = useState({});
    const [filters,setFilters] = useState({page:1,perPage:20,search:'',orderBy:'id',order:'asc'});
    //const { user } = useSelector((state) => console.log(state));
    //console.log(user)
    const [modal, setModal] = useState({show:false,update:false});
    //Listado de configuraciones
    const [items, setItems] = useState([]);
    //objeto de configuracion
    const [configuration, setConfiguration] = useState({
        option_id:'',
        company_id:company.id,
        value:'',
        configuration:{
            id:null,
            name:''
        }
    });
    useEffect(() => {
        //ToastHelper('mensaje','error')
        let params={params:filters}
        setPending(true)
        getConfigurations(params).then(response => {
            setItems(response.data)
            setDataPag(response.meta)
            //console.log(response)
            setPending(false)
        }
        )
    }, [filters])
    

    const destroy = (id) => {
        DestroyAlertHelper(async confirmed => {
            if (!confirmed) { return; }
            deleteConfiguration(id).then(response => {
                Alert(response.message,response.status)
                if (response.status) {
                    setItems(
                        items.filter(i => i.id != id)
                    );
                }           
            })
        });
    }
    
    const columns = Columns(destroy);
    document.title = "Configuración";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="col-12">
                        <DataTableHelper tittle="Configuración" columns={columns} items={items} pending={pending} config={dataPag} filters={filters} setFilters={setFilters}>
                                <button className="btn btn-sm btn-success" onClick={() => setModal({...modal,show:true,update:false})}><i className="fas fa-solid fa-plus"></i> Agregar</button>
                        </DataTableHelper>
                    </div>
                </Container>
                <AddUpdModal 
                modal={modal}
                setModal={setModal}
                items={items}
                setItems={setItems}
                configuration={configuration}
                setConfiguration={setConfiguration}
                company={company}
                />
            </div>
        </React.Fragment>
    );
};

export default Configuration;