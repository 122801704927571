import React, { useCallback, useEffect, useState } from "react";
import DataTableHelper from "../../helpers/tables/DataTableHelper";
import Columns, { STATUS_STYLES } from "./Columns";
import {
  cancelClientContract,
  getClientContracts,
  retireClientContract,
} from "../../helpers/backend_helper";
import { Container } from "reactstrap";
import DetailModal from "./DetailModal";
import { getLoggedUser } from "../../helpers/login";
import ConfirmRetireContractModal from "./ConfirmRetireContractModal";

const INIT_FILTERS = {
  page: 1,
  perPage: 20,
  search: "",
  orderBy: "id",
  order: "asc",
  status: "",
};

const statusOptiones = Object.entries(STATUS_STYLES);

const MyInvestings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRetireContractOpenModal, setIsRetireContractOpenModal] =
    useState(false);
  const [clientContract, setClientContract] = useState();
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [isLoading, setIsLoading] = useState(true);
  const [myInvestings, setMyInvestings] = useState({ data: [] });

  const loggedUser = getLoggedUser();

  const getMyInvestings = useCallback((filters) => {
    const config = { params: filters };

    setIsLoading(true);
    getClientContracts(loggedUser?.id, config)
      .then(responseAction)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getMyInvestings(filters);
  }, [filters]);

  const responseAction = (response) => {
    if (response.status) {
      setMyInvestings(response.clientContracts);
    }
  };

  const cancelContract = (row) => {
    cancelClientContract(row.id).then((resp) => {
      getMyInvestings();
    });
  };

  const onRetireContract = (row) => {
    setClientContract(row);
    setIsRetireContractOpenModal(true);
  };

  const detailContract = (row) => {
    setIsModalOpen(true);
    setClientContract(row);
  };

  const columns = Columns(cancelContract, onRetireContract, detailContract);
  document.title = "Mis inversiones";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="col-12">
            <DataTableHelper
              tittle="Mis inversiones"
              columns={columns}
              items={myInvestings.data}
              pending={isLoading}
              config={myInvestings}
              filters={filters}
              setFilters={setFilters}
            >
              <div>
                <select
                  name="status"
                  value={filters.status}
                  onChange={(e) => {
                    const { value, name } = e.target;

                    setFilters((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                  }}
                  placeholder="Filtrar por estatus"
                  className={`form-select form-select-sm ${
                    !filters.status && "text-muted"
                  }`}
                >
                  <option value="" className="text-muted">
                    Filtrar por estatus
                  </option>
                  {statusOptiones.map(([key, value]) => (
                    <option key={key} value={key} className="text-dark">
                      {value.label}
                    </option>
                  ))}
                </select>
              </div>
            </DataTableHelper>
            <DetailModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              clientContract={clientContract}
            />
            <ConfirmRetireContractModal
              clientContract={clientContract}
              isModalOpen={isRetireContractOpenModal}
              setIsModalOpen={setIsRetireContractOpenModal}
              getMyInvestings={getMyInvestings}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyInvestings;
