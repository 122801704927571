import React, { useEffect, useRef, useState } from "react";
import {
  getStorageUser,
  getUserLevel,
  getUserNeedsAcceptTerms,
} from "../../helpers/api_helper";
import { documentItems } from "../Prospects/detail/components/FormatsDropdown";
import { Button, FormFeedback, Input, Label, Spinner } from "reactstrap";
import {
  getDocument,
  postProspectFirmContract,
  postProspectSignContract,
  prospectAcceptTerms,
} from "../../helpers/backend_helper";
import { downloadFile, openFile } from "../../utils/files";
import { toast } from "react-toastify";
import SignaturePad from "react-signature-pad-wrapper";
import { REGISTRATION_FILES } from "../../constants/registration";

const BASE_URL_STORAGE = process.env.REACT_APP_BACKEND_URL;

export function dataURItoBlob(dataURI, callback) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab], { type: mimeString });
  return bb;
}

const ContractDocuments = () => {
  const user = getStorageUser();
  const id = user?.prospect?.id;
  const userLevel = getUserLevel();

  const [isLoading, setisLoading] = useState(false);
  const [userLevelState, setUserLevelState] = useState(userLevel);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInputChecked, setIsInputChecked] = useState(false);
  const signatureRef = useRef("signaturePadRef");
  const iframeRef = useRef();

  const handleDownloadFormat = async (path, filename) => {
    setIsDownloading(true);
    try {
      const config = {
        url: `${path}/${id}`,
        responseType: "blob",
      };

      console.log(iframeRef.current, iframeRef.current.location);

      const file = await getDocument(config);

      const url = window.URL.createObjectURL(
        new Blob([file], { type: "application/pdf" })
      );
      iframeRef.current.src = url;

      //openFile(file, filename);
    } catch (error) {
      toast.error(error?.message || "Error desconocido");
    } finally {
      setIsDownloading(false);
    }
  };

  const onSubmit = async () => {
    if (!signatureRef.current.isEmpty()) {
      setIsInputChecked(true);
      let image = signatureRef.current.toDataURL();
      console.log("signatureRef", image);
      const file = dataURItoBlob(image);
      console.log("signatureRef", file);
      //let img = await handleCapture(file);
      //serviceValidation.setFieldValue("signature", img);
      setisLoading(true);
      postProspectSignContract({ file })
        .then((response) => {
          console.log(response);

          if (response.status) {
            const data = JSON.parse(localStorage.getItem("authUser"));
            const dataUpdated = {
              ...data,
              user: {
                ...user,
                prospect: {
                  ...user?.prospect,
                  level: response.prospect?.level,
                },
              },
            };

            setUserLevelState(response.prospect?.level);
            localStorage.setItem("authUser", JSON.stringify(dataUpdated));
          }
        })
        .finally(() => setisLoading(false));
    } else {
      setIsInputChecked(false);
    }
  };

  const cleanSignature = () => {
    console.log("cleanSignature", signatureRef);
    setIsInputChecked(false);
    signatureRef.current.clear();
  };

  console.log(user);

  useEffect(() => {
    handleDownloadFormat(
      "commercial-commission-contract",
      "contrato-de-comision-mercantil.pdf"
    );
  }, []);

  //if (userLevelState !== "Nivel 1") return <></>;

  return (
    <div className={userLevelState == "Nivel 1" ? "" : "d-none"}>
      <div className="row justify-content-center">
        <div className="col-sm-12">
          <div className="text-center card">
            <div className="card-body">
              {isDownloading ? (
                <div className="pb-4 text-end">
                  <Spinner size="sm" />
                </div>
              ) : null}
              {/* <h5 className="font-size-15 mb-1">
                  <a className="text-dark" href="/contacts-grid">
                    David McHenry{" "}
                  </a>
                </h5> */}
              <div className="d-inline-block text-start py-4 d-none">
                {documentItems.map((item) => (
                  <p
                    key={item.label}
                    style={{ cursor: "pointer" }}
                    title="Descargar"
                    onClick={() =>
                      handleDownloadFormat(item.url, item.filename)
                    }
                  >
                    <i className="fas fa-file-pdf me-1"></i>
                    {item.label}
                  </p>
                ))}
              </div>

              <div className="text-center">
                <iframe src="" height="500" width="100%" ref={iframeRef} />
                <Label for="active">Firmar contrato</Label> <br />
                <div
                  style={{
                    width: "300px",
                    height: "150px",
                    border: "solid 1px #AAA",
                    display: "inline-block",
                  }}
                >
                  <SignaturePad
                    ref={signatureRef}
                    onAfterUpdate={(e) => {
                      console.log("onAfterUpdate", e);
                    }}
                    afterUpdateStroke={(e) => {
                      console.log("afterUpdateStroke", e);
                    }}
                    onChange={(e) => {
                      console.log("onChange", e);
                      setIsInputChecked(true);
                    }}
                    options={{
                      dotSize: 1.5,
                      minWidth: 1.5,
                      maxWidth: 2.5,
                      penColor: "black",
                      onChange: (e) => {
                        console.log("onChange", e);
                        setIsInputChecked(true);
                      },
                    }}
                  />
                  {!isInputChecked && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Debe completar la firma
                    </div>
                  )}

                  <Button
                    className={"mt-2"}
                    type="button"
                    color="primary"
                    onClick={cleanSignature}
                  >
                    Limpiar
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-transparent border-top card-footer">
              <div className="contact-links font-size-20 text-center mt-2">
                <Button
                  type="button"
                  color="primary"
                  disabled={isLoading}
                  onClick={onSubmit}
                >
                  Firmar y Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDocuments;
