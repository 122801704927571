import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  Row,
  Col,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import { putProduct, postProduct } from "../../helpers/backend_helper";
import { getStorageUser } from "../../helpers/api_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import NibbleInput from "../../components/Fields/NibbleInput";
import RowComposition from "./RowComposition";
import Composition from "./Composition";

const getListDays = () => {
  let daysObj = [];
  for (let i = 1; i <= 30; i++) {
    daysObj.push({
      id: i,
      name: i,
    });
  }

  return daysObj;
};

const TIPO_MUTUO = "Mutuo";
const TIPO_DEUDA = "Deuda";
const TIPO_VARIABLE = "Renta Variable";

const AddUpdModal = ({
  modal,
  setModal,
  items,
  setItems,
  setItem,
  item,
  selects,
}) => {
  const [productTypeIds, setProductTypeIds] = useState({
    mutuoId: "",
    deudaId: "",
    variableId: "",
  });
  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(false);
  const authUser = getStorageUser();
  const handleClose = () => {
    setModal({ ...modal, show: false, update: false });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object({
      name: Yup.string().nullable().required("Requerido").min(1),
      type_id: Yup.number().nullable().required("Requerido"),
      mutual_start_date: Yup.date("Requerido").when("type_id", {
        is: productTypeIds.mutuoId,
        then: Yup.date().typeError("Requerido").required("Requerido"),
        otherwise: Yup.date().nullable(),
      }),
      mutual_end_date: Yup.date("Requerido").when("type_id", {
        is: productTypeIds.mutuoId,
        then: Yup.date().typeError("Requerido").required("Requerido"),
        otherwise: Yup.date().nullable(),
      }),
      activation_day_month: Yup.number().when("type_id", {
        is: productTypeIds.deudaId,
        then: Yup.number().typeError("Requerido").required("Requerido"),
        otherwise: Yup.number().nullable(),
      }),
      withdrawal_day_month: Yup.number().when("type_id", {
        is: productTypeIds.deudaId,
        then: Yup.number().typeError("Requerido").required("Requerido"),
        otherwise: Yup.number().nullable(),
      }),
      withdrawal_day_quarter: Yup.number("requerido").when("type_id", {
        is: productTypeIds.variableId,
        then: Yup.number().typeError("Requerido").required("Requerido"),
        otherwise: Yup.number().nullable(),
      }),
      mutual_amount: Yup.number("Requerido").when("type_id", {
        is: productTypeIds.mutuoId,
        then: Yup.number().typeError("Requerido").required("Requerido"),
        otherwise: Yup.number().nullable(),
      }),
      days: Yup.array().when("type_id", {
        is: (type_id) => type_id && type_id !== productTypeIds.mutuoId,
        then: Yup.array()
          .required("Requerido")
          .min(1, "Debe seleccionar al menos un día"),
        otherwise: Yup.array(),
      }),
      hour_processing: Yup.string("requerido").when("type_id", {
        is: (typeId) => typeId && typeId !== productTypeIds.mutuoId,
        then: Yup.string().typeError("Requerido").required("Requerido"),
        otherwise: Yup.string().nullable(),
      }),
      profile_risks: Yup.array()
        .required("Requerido")
        .test({
          message: "Debe seleccionar 1 o más",
          test: (arr) => arr.length > 0,
        }),
      /*  periods: Yup.array()
        .required("Requerido")
        .test({
          message: "Debe seleccionar 1 o más",
          test: (arr) => arr.length > 0,
        }), */
      periods: Yup.array().when("type_id", {
        is: (type_id) => type_id !== 1,
        then: Yup.array()
          .required("Requerido")
          .min(1, "Debe seleccionar al menos 1 período"),
        otherwise: Yup.array().required("Requerido"),
      }),
      active: Yup.boolean().nullable(),
      description: Yup.string().nullable().required("Requerido").min(1),
      benchmark: Yup.string().nullable(),
      composition: Yup.string().nullable(),
      operation: Yup.string().nullable(),
      risks: Yup.string().nullable(),
      administration: Yup.string().nullable().required("Requerido"),
      instruments: Yup.array()
        .min(1)
        .test(
          "validator-custom-name",
          "La composición debe sumar 100%",
          function (value) {
            const total = value.reduce((a, b) => {
              return Number(a ?? 0) + Number(b?.percent ?? 0);
            }, 0);

            if (total != 100) {
              return this.createError({
                path: this.path,
                message: validation.errorMessage,
              });
            }

            const incompletos = value.filter((e) => {
              return e.percent && e.instrument_id ? false : true;
            });
            if (incompletos.length > 0) {
              return this.createError({
                path: this.path,
                message: "Debe seleccionar el instrumento y su porcentaje",
              });
            }
            return true;
          }
        ),
      start_date: Yup.string().nullable(),
      lead_capture_days: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(true)
        .when("type_id", {
          is: (type_id) => type_id && type_id == productTypeIds.mutuoId,
          then: Yup.number()
            .nullable(true)
            .required("Requerido")
            .min(1, "El valor míminmo es 1"),
        }),
      activation_days: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(true)
        .when("type_id", {
          is: (type_id) => type_id && type_id == productTypeIds.mutuoId,
          then: Yup.number()
            .nullable(true)
            .required("Requerido")
            .min(1, "El valor míminmo es 1"),
        }),
      import: Yup.number().nullable().min(1),
      serie: Yup.string()
        .nullable()
        .test(
          "is-one-letter",
          "El campo debe ser de 4 carácteres alfanuméricos",
          (value) => {
            if (!value) return true;
            return /^[0-9A-Za-z]{4}$/.test(value);
          }
        ),
      tasa: Yup.number().nullable(),
      min_amount: Yup.number().nullable(),
      min_time: Yup.number().nullable(),
      fec_liquidacion: Yup.string().nullable(),
      time_liquidacion: Yup.string().nullable(),
      capital_return: Yup.string().nullable(),
      earning_return: Yup.string().nullable(),
      warranty: Yup.string().nullable(),
      external_url: Yup.string().nullable(),
      commission: Yup.number().nullable().required("Requerido"),
    }),
    onSubmit: async (values) => {
      await save(values);
    },
  });

  console.log({ formValues: validation.values, validation });

  const selectedProduct =
    selects.productTypes.find(
      (product) => product.id === validation.values.type_id
    )?.name || "";

  useEffect(() => {
    const mutuoId =
      selects.productTypes.find(({ name }) => name === TIPO_MUTUO)?.id || "";

    const variableId =
      selects.productTypes.find(({ name }) => name === TIPO_VARIABLE)?.id || "";

    const deudaId =
      selects.productTypes.find(({ name }) => name === TIPO_DEUDA)?.id || "";

    setProductTypeIds({
      mutuoId,
      variableId,
      deudaId,
    });
  }, [selects.productTypes]);

  const save = async (data) => {
    try {
      setLoading(true);
      console.log("data", data);
      const response = modal.update
        ? await putProduct(data)
        : await postProduct(data);
      if (response) {
        responseAction(response);
        handleClose();
        validation.resetForm();
      }
    } catch (err) {
      console.log("aca");
      console.error(err);
    }
    setLoading(false);
  };

  const responseAction = (response) => {
    if (response?.status) {
      const newItem = response.item;
      let array = [];
      if (modal.update) {
        array = items.map((item) => (item.id !== newItem.id ? item : newItem));
      } else {
        array = [...items, newItem];
        array.sort((a, b) => {
          return a.id - b.id;
        });
      }
      setItems(array);
      handleClose();
    } else {
      setErrors(response?.response?.data?.errors);
    }
  };

  console.log({ formValues: validation.values, formErrors: validation.errors });

  return (
    <div>
      <Modal isOpen={modal.show} style={{ maxWidth: "50%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            {modal.update ? "Editar" : "Agregar"} Producto
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Col className="d-none" style={{ wordWrap: "break-word" }}>
              {JSON.stringify(validation.values)}
            </Col>
            <div className="row">
              <NibbleInput
                md={6}
                label="Nombre"
                fieldName="name"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={6}
                label="Tipo"
                fieldName="type_id"
                fieldType="select"
                validation={validation}
                selectItems={selects.productTypes}
              />
              {(selectedProduct === TIPO_DEUDA ||
                selectedProduct === TIPO_VARIABLE) &&
                {
                  /* <NibbleInput
                  md={6}
                  label="Día del Mes para activación de inversiones"
                  fieldName="activation_day_month"
                  fieldType="select"
                  validation={validation}
                  selectItems={getListDays()}
                /> */
                }}

              {selectedProduct === TIPO_DEUDA && (
                <NibbleInput
                  md={6}
                  label="Día del retiro del mes"
                  fieldName="withdrawal_day_month"
                  fieldType="select"
                  validation={validation}
                  selectItems={getListDays()}
                />
              )}
              {selectedProduct === TIPO_VARIABLE && (
                <NibbleInput
                  md={6}
                  label="Día del retiro del trimestre"
                  fieldName="withdrawal_day_quarter"
                  fieldType="select"
                  validation={validation}
                  selectItems={getListDays()}
                />
              )}
              {selectedProduct === TIPO_MUTUO && (
                <>
                  <NibbleInput
                    md={6}
                    label="Fecha de inicio mutuo"
                    fieldName="mutual_start_date"
                    fieldType="date"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Fecha de fin mutuo"
                    fieldName="mutual_end_date"
                    fieldType="date"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Monto mutuo"
                    fieldName="mutual_amount"
                    fieldType="number"
                    validation={validation}
                  />
                </>
              )}
              {[TIPO_MUTUO].includes(selectedProduct) && (
                <>
                  <NibbleInput
                    md={6}
                    label="Fecha de alta"
                    fieldName="start_date"
                    fieldType="date"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Días de captación"
                    fieldName="lead_capture_days"
                    fieldType="number"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Días de activación"
                    fieldName="activation_days"
                    fieldType="number"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Monto minímo de mutuo"
                    fieldName="import"
                    fieldType="number"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Serie"
                    fieldName="serie"
                    fieldType="text"
                    validation={validation}
                  />
                  <NibbleInput
                    md={6}
                    label="Plazo mutuo"
                    fieldName="time_limit"
                    fieldType="select"
                    selectItems={[
                      { id: "C", name: "Corto plazo 12 a 24 meses" },
                      { id: "M", name: "Mediano plazo de 36 a 48 meses" },
                      { id: "L", name: "Largo plazo mas de 60 meses" },
                    ]}
                    validation={validation}
                    selectOptionValue={(e) => e.id}
                  />
                  <NibbleInput
                    md={6}
                    label="Monto mutuo actual"
                    fieldName="monto_mutuo_actual"
                    fieldType="text"
                    validation={validation}
                    readOnly
                  />
                </>
              )}
              {selectedProduct && selectedProduct !== TIPO_MUTUO && (
                <>
                  <NibbleInput
                    md={6}
                    label="Horario"
                    fieldName="hour_processing"
                    fieldType="time"
                    validation={validation}
                  />
                  <NibbleInput
                    md={12}
                    label="Días de la semana"
                    fieldName="days"
                    fieldType="checkbox"
                    selectItems={[
                      { id: "Lunes", name: "Lunes" },
                      { id: "Martes", name: "Martes" },
                      { id: "Miercoles", name: "Miercoles" },
                      { id: "Jueves", name: "Jueves" },
                      { id: "Viernes", name: "Viernes" },
                      { id: "Sábado", name: "Sábado" },
                      { id: "Domingo", name: "Domingo" },
                    ]}
                    validation={validation}
                  />
                </>
              )}

              <NibbleInput
                md={12}
                label="Perfiles"
                fieldName="profile_risks"
                fieldType="checkbox"
                selectItems={selects.profilesRisks}
                validation={validation}
              />
              {selectedProduct && selectedProduct !== TIPO_MUTUO && (
                <NibbleInput
                  md={12}
                  label="Plazos disponibles"
                  fieldName="periods"
                  fieldType="checkbox"
                  selectItems={selects.periods}
                  validation={validation}
                />
              )}
              <NibbleInput
                md={12}
                label="Activo"
                fieldName="active"
                fieldType="select"
                selectItems={[
                  { id: true, name: "Si" },
                  { id: false, name: "No" },
                ]}
                validation={validation}
                selectOptionValue={(e) => e.id}
              />

              <NibbleInput
                md={12}
                label="Comisión del producto (%)"
                fieldName="commission"
                fieldType="number"
                min={0}
                validation={validation}
                step="0.01"
              />

              <Col md="12 pt-3">
                <h5>Datos de ficha de usuario</h5>
                {validation.values.type_id != "1" ? (
                  <Row>
                    <NibbleInput
                      md={6}
                      label="Tasa"
                      fieldName="tasa"
                      fieldType="number"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Monto mínimo sugerido"
                      fieldName="min_amount"
                      fieldType="number"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Permanencia mínima meses"
                      fieldName="min_time"
                      fieldType="number"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Fecha de liquidación"
                      fieldName="fec_liquidacion"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Tiempo de retiro del recursos"
                      fieldName="time_liquidacion"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={12}
                      label="Url detalle"
                      fieldName="external_url"
                      fieldType="text"
                      validation={validation}
                    />
                  </Row>
                ) : null}
                {validation.values.type_id == "1" ? (
                  <Row>
                    <NibbleInput
                      md={6}
                      label="Inversión mínima"
                      fieldName="min_investing"
                      fieldType="number"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Retorno de capital"
                      fieldName="capital_return"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Retorno de ganacias"
                      fieldName="earning_return"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Garantía"
                      fieldName="warranty"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Plazo"
                      fieldName="plazo"
                      fieldType="text"
                      validation={validation}
                    />
                    <NibbleInput
                      md={6}
                      label="Nivel de Riesgo"
                      fieldName="risk_level"
                      fieldType="select"
                      selectItems={[
                        { id: 1, name: "1 estrella" },
                        { id: 2, name: "2 estrellas" },
                        { id: 3, name: "3 estrellas" },
                        { id: 4, name: "4 estrellas" },
                        { id: 5, name: "5 estrellas" },
                      ]}
                      validation={validation}
                      selectOptionValue={(e) => e.id}
                    />
                  </Row>
                ) : null}
                <hr />
              </Col>

              <NibbleInput
                md={12}
                label="Descripción"
                fieldName="description"
                fieldType="textarea"
                validation={validation}
              />

              <NibbleInput
                md={12}
                label="Benchmark"
                fieldName="benchmark"
                fieldType="textarea"
                validation={validation}
              />

              <NibbleInput
                md={12}
                label="Composición"
                fieldName="composition"
                fieldType="textarea"
                validation={validation}
              />

              <NibbleInput
                md={12}
                label="Operación"
                fieldName="operation"
                fieldType="textarea"
                validation={validation}
              />

              <NibbleInput
                md={12}
                label="Riesgos"
                fieldName="risks"
                fieldType="textarea"
                validation={validation}
              />

              <Composition
                fieldName="instruments"
                onChange={(listItem) => {
                  validation.setFieldValue("instruments", listItem);
                }}
                instruments={selects.instruments}
                validation={validation}
              />

              <NibbleInput
                md={12}
                label="Administración"
                fieldName="administration"
                fieldType="textarea"
                validation={validation}
              />

              {false && (
                <NibbleInput
                  md={12}
                  label="Brochure"
                  fieldName="file"
                  fieldType="file"
                  validation={validation}
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUpdModal;
