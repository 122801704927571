export const prospectGeneralBase = {
  id: null,
  email: "",
  password: "",
  password2: "",
  ti_name: "",
  ti_lname1: "",
  ti_lname2: "",
  ti_birth: "",
  ti_rfc: "",
  ti_curp: "",
  ti_phone: "",
  ti_landline: "",
  ti_birth_country: "",
  ti_birth_state: "",
  ti_civil: "",
  ti_genere: "",
  ti_genere: "",
  fiscal_country: null,
  fiscal_state: null,
  fiscal_address1: "",
  fiscal_address2: "",
  fiscal_address3: "",
  fiscal_cross_streets: "",
  fiscal_ext: "",
  fiscal_int: "",
  fiscal_zipcode: "",
  fiscal_years: "",
  fiscal_type: "",
  commer_ask1: true,
  commer_country: null,
  commer_state: null,
  commer_address1: "",
  commer_address2: "",
  commer_address3: "",
  commer_ext: "",
  commer_int: "",
  commer_zipcode: "",
};
