import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
} from "reactstrap";

import {
  getClientsPermissions,
  postAdminDeposit,
  postAdminPinDeposit,
  uploadFile,
} from "../../helpers/backend_helper";
import NibbleInput from "../../components/Fields/NibbleInput";
import { transactionSubtype } from "../../constants/transactions";
import { useEffect } from "react";
import NibbleInputFile from "../../components/Fields/NibbleInputFile";

const AddUpdModal = ({ modal, setModal, setItem, item, realoadData }) => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const handleClose = () => {
    setModal({ ...modal, show: false, update: false, pin: false });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object({
      type: Yup.string().nullable().required("Requerido"),
      prospect_id: Yup.string().nullable().required("Requerido"),
      amount: Yup.number().nullable().required("Requerido"),
      operation_date: Yup.string().nullable().required("Requerido"),
      reference: Yup.string().nullable().required("Requerido"),
      description: Yup.string().nullable().required("Requerido"),
      subtype: Yup.string().nullable().required("Requerido"),
      file_id: Yup.string().nullable().required("Requerido"),
      pin: Yup.string().nullable(),
    }),
    onSubmit: (values) => {
      save(values);
    },
  });

  const pinValidation = useFormik({
    enableReinitialize: true,
    initialValues: { pin: "" },
    validationSchema: Yup.object({
      pin: Yup.number().nullable().required("Requerido"),
    }),
    onSubmit: async (values) => {
      validation.setFieldValue("pin", values.pin);

      await validation.submitForm();
    },
  });

  const save = async (data) => {
    setLoading(true);

    if (!validation.values.pin) {
      await sendPin();
      return;
    }

    const response = await postAdminDeposit(data);
    responseAction(response);
  };

  const responseAction = (response) => {
    if (response.status) {
      handleClose();
      validation.resetForm();
      realoadData && realoadData();
    } else {
      validation.setFieldValue("pin", "");
    }

    setLoading(false);
  };

  const sendPin = async () => {
    const response = await postAdminPinDeposit();
    if (response.status) {
      setModal((prev) => ({ ...prev, pin: true }));
    }
    setLoading(false);

    return response;
  };

  const onUploadFile = (file) => {
    const formData = new FormData();
    formData.file = file;
    formData.name = "baucher-deposito";

    return uploadFile(formData);
  };

  useEffect(() => {
    const config = {
      params: {
        page: 1,
        perPage: 100,
        search: "",
        orderBy: "id",
        order: "asc",
      },
    };

    getClientsPermissions(config).then((response) => {
      setClients(response.data);
    });
  }, []);

  return (
    <div>
      <Modal isOpen={modal.show} style={{ maxWidth: "60%" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            {modal.update ? "Editar" : "Agregar"} Prospectos
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row">
              <NibbleInput
                md={4}
                label="Cliente"
                fieldName="prospect_id"
                fieldType="select"
                validation={validation}
                selectItems={clients.map((client) => ({
                  id: client.id,
                  name: client.full_name,
                }))}
              />
              <NibbleInput
                md={4}
                label="Importe"
                fieldName="amount"
                fieldType="number"
                validation={validation}
              />
              <NibbleInput
                md={4}
                label="Fecha depósito bancario"
                fieldName="operation_date"
                fieldType="date"
                validation={validation}
              />
              <NibbleInput
                md={4}
                label="Referencia"
                fieldName="reference"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={4}
                label="Descripción"
                fieldName="description"
                fieldType="text"
                validation={validation}
              />
              <NibbleInput
                md={4}
                label="Subtipo"
                fieldName="subtype"
                fieldType="select"
                validation={validation}
                selectItems={[
                  {
                    id: transactionSubtype.DEPOSIT,
                    name: "Depósito",
                  },
                  {
                    id: transactionSubtype.TRANSFER,
                    name: "Transferencia",
                  },
                ]}
              />
              <NibbleInputFile
                md={12}
                label="Adjuntar documento de respaldo"
                fieldName="file_id"
                validation={validation}
                postFileFunction={onUploadFile}
                existFile={validation.values.file_id}
                /* acceptFiles="application/pdf" */
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modal.pin}>
        <ModalHeader className="bg-primary">
          <span className="text-light">
            Ingresa el PIN enviado a tu número teléfonico
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            pinValidation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="row justify-content-center">
              <NibbleInput
                md={6}
                label="PIN"
                fieldName="pin"
                fieldType="text"
                validation={pinValidation}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading && <Spinner size="sm"></Spinner>}{" "}
              <i className="fa fa-save"></i> Guardar
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => setModal((prev) => ({ ...prev, pin: false }))}
            >
              <i className="fa fa-times"></i> Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUpdModal;
