import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";

const RowComposition = ({
  idx,
  instruments,
  onChange = null,
  onBlur = null,
  value = {},
  onDelete = null,
  validation = null,
  fieldName
}) => {
  

  return (
    <Row className="pt-2">
      <Col md={1} className="text-center pt-2">
        {idx + 1}
      </Col>
      <Col md={3}>
        <Input
          className="text-end"
          name={idx + "_percent"}
          type="number"
          value={value?.percent ?? 0}
          max={100}
          min={0}
          step={0.01}
          onChange={(e) => {
            let newValue = { ...value };
            newValue.percent = e.target.value > 100 ? 100 : Number(Number(e.target.value).toFixed(2));
            if (onChange) onChange(newValue);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e);
          }}
        />
        
      </Col>
      <Col md={7}>
        <ReactSelect
          fieldName={idx + "_instrument"}
          fieldType="instrument"
          options={instruments}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e.id}
          onChange={(e) => {
            let newValue = { ...value };
            newValue.instrument_id = e.id;
            if (onChange) onChange(newValue);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e);
          }}
          value={
            instruments.filter((e) => {
              return e.id == value.instrument_id;
            })[0] ?? null
          }
        />
      </Col>
      <Col md={1} className="pt-1">
         { idx > 0 &&
        <Button type="button" className="btn btn-sm btn-danger" onClick={onDelete}>
          <i className="fas fa-times"></i>
        </Button>
        }
      </Col>
    </Row>
  );
};

export default RowComposition;
