import React, { useCallback, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";

import {
  delProspect,
  getProspect,
  postProspectApprove,
  postProspectReject,
} from "../../../../helpers/backend_helper";

import classnames from "classnames";
import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import NibbleInput from "../../../../components/Fields/NibbleInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormatsDropdown from "./FormatsDropdown";
import GeneralDataTab from "../../../../components/Prospect/Detail/Tabs/GeneralDataTab";
import CustomerValidationsTab from "../../../../components/Prospect/Detail/Tabs/CustomerValidationsTab";
import ComplementsTab from "../../../../components/Prospect/Detail/Tabs/ComplementsTab";
import DocumentsTab from "../../../../components/Prospect/Detail/Tabs/DocumentsTab";
import RiskProfileTab from "../../../../components/Prospect/Detail/Tabs/RiskProfileTab";
import { prospectLevels } from "../../../../constants/prospectStatus";

const ProfileTab = () => {
  const { id } = useParams();

  const history = useHistory();

  const [activeTab, toggleTab] = useState("1");
  const [prospect, setProspect] = useState();
  const [modal, setModal] = useState({ show: false, action: "", error: "" });
  const [isLoadingClient, setIsLoadingClient] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      level: "",
      reason: "",
    },
    validationSchema: Yup.object({
      level: Yup.string(),
      reason: Yup.string(),
    }),
  });

  const fetchProspect = useCallback(() => {
    setIsLoadingClient(true);
    getProspect(id)
      .then((resp) => {
        if (resp.status) {
          setProspect(resp.prospect);
        }
      })
      .finally(() => {
        setIsLoadingClient(false);
      });
  }, [id]);

  const handleActionSelected = (action) => {
    setModal({
      show: true,
      action,
    });
  };

  const handleCloseModal = () => {
    setModal({
      show: false,
      action: "",
      error: "",
    });
  };

  const handleSaveAction = async () => {
    switch (modal.action) {
      case "Aprobar":
        const level = validation.values.level;
        if (!level) {
          setModal((prev) => ({
            ...prev,
            error: "Por favor selecciona el nivel",
          }));

          return;
        }

        handleProspectApprove({
          prospectId: id,
          level,
        });
        break;
      case "Rechazar":
        const reason = validation.values.reason;
        if (!reason) {
          setModal((prev) => ({
            ...prev,
            error: "Por favor ingresa el motivo",
          }));

          return;
        }

        handleProspectReject({
          prospectId: id,
          reason,
        });
        break;
      case "Eliminar":
        handleProspectDelete();
        break;
      default:
        break;
    }
  };

  const handleProspectApprove = async (data) => {
    setIsLoadingAction(true);
    try {
      const resp = await postProspectApprove(data);
      if (resp?.status) {
        fetchProspect();
        handleCloseModal();
        return;
      }

      setModal((prev) => ({
        ...prev,
        error: resp?.message,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleProspectReject = async (data) => {
    setIsLoadingAction(true);
    try {
      const resp = await postProspectReject(data);
      if (resp?.status) {
        fetchProspect();
        handleCloseModal();
        return;
      }

      setModal((prev) => ({
        ...prev,
        error: resp?.message,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleProspectDelete = async () => {
    setIsLoadingAction(true);
    try {
      const resp = await delProspect(id);
      if (resp?.status) {
        history.push("/prospects");
        return;
      }

      setModal((prev) => ({
        ...prev,
        error: resp?.message,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingAction(false);
    }
  };

  useEffect(() => {
    fetchProspect();
  }, [fetchProspect]);

  if (isLoadingClient)
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );

  if (!prospect) return <p>No se encontro información del prospecto</p>;

  return (
    <React.Fragment>
      <Row>
        <Col xl={24} lg={24} md={24}>
          <Card>
            <CardBody>
              <Row>
                <div className="col-sm-9 order-2 order-sm-1">
                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                    <div className="flex-shrink-0">
                      <div className="avatar-xl mx-auto mb-4">
                        <span
                          style={{ backgroundColor: "#ffffff" }}
                          className="avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle"
                        >
                          <i className="bx bxs-user-circle"></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-16 mb-1 text-uppercase">
                          {prospect?.ti_name} {prospect?.ti_lname1}{" "}
                          {prospect?.ti_lname2} (Contrato -{" "}
                          {prospect?.contract_number})
                        </h5>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          Estatus: {prospect?.status}
                        </p>
                        <p className="text-muted font-size-13 mb-0 text-uppercase">
                          RFC: {prospect?.ti_rfc}{" "}
                          <span className="ps-2">
                            CURP: {prospect?.ti_curp}
                          </span>
                        </p>
                        <p className="text-muted font-size-13 text-uppercase">
                          Correo: {prospect?.user?.email}
                        </p>
                        <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                          <div title="Fecha de nacimiento">
                            <i className="fas fa-calendar-alt me-1 text-muted align-middle"></i>
                            {prospect?.ti_birth}
                          </div>
                          <div title="Teléfono">
                            <i className="fas fa-phone-alt me-1 text-muted align-middle"></i>
                            {prospect?.ti_phone}
                          </div>
                          <div className="text-uppercase">
                            <i className="far fa-address-card me-1 text-muted align-middle"></i>
                            {prospect?.fiscal_address1} {prospect?.fiscal_ext}
                            {", "}
                            {prospect?.fiscal_address2} {", "}{" "}
                            {prospect?.fiscal_address3} {", CP "}{" "}
                            {prospect?.fiscal_zipcode}
                          </div>
                          {/* <div>
                            <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                            phyllisgatlin@minia.com
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 order-1 order-sm-2">
                  {prospect?.status === "Registro identidad" && (
                    <div className="d-flex justify-content-end">
                      <FormatsDropdown id={id} />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          type="button"
                          color="primary"
                          className="btn btn-sm"
                        >
                          Acciones <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            to="#"
                            onClick={() => handleActionSelected("Aprobar")}
                          >
                            <i className="mdi mdi-account"></i> Aprobar
                          </DropdownItem>
                          <DropdownItem
                            to="#"
                            onClick={() => handleActionSelected("Rechazar")}
                          >
                            <i className="mdi mdi-cancel"></i> Rechazar
                          </DropdownItem>
                          <DropdownItem
                            to="#"
                            onClick={() => handleActionSelected("Eliminar")}
                          >
                            <i className="mdi mdi-trash-can"></i> Eliminar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
                </div>
              </Row>

              <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "1",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Generales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "2",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Complementos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "3",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Documentos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "4",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Validaciones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "5",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Perfil de riesgo
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <GeneralDataTab prospect={prospect} />
            </TabPane>
            <TabPane tabId="2">
              <ComplementsTab prospect={prospect} />
            </TabPane>
            <TabPane tabId="3">
              <DocumentsTab files={prospect?.files} />
            </TabPane>
            <TabPane tabId="4">
              <CustomerValidationsTab
                nubarium_history={prospect?.nubarium_history}
              />
            </TabPane>
            <TabPane tabId="5">
              <RiskProfileTab prospect={prospect} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Modal isOpen={modal.show} style={{ maxWidth: "400px" }}>
        <ModalHeader className="bg-primary">
          <span className="text-light">{modal.action}</span>
        </ModalHeader>
        <ModalBody>
          {modal.error && <Alert color="danger">{modal.error}</Alert>}
          {modal.action === "Aprobar" && (
            <div className="row">
              <NibbleInput
                md={12}
                label="Selecciona nivel"
                fieldName="level"
                fieldType="select"
                selectItems={prospectLevels}
                validation={validation}
                selectOptionValue={(e) => e.id}
              />
            </div>
          )}
          {modal.action === "Rechazar" && (
            <div className="row">
              <NibbleInput
                md={12}
                label="Motivo"
                fieldName="reason"
                fieldType="textarea"
                validation={validation}
                placeHolder="Escribe el motivo"
              />
            </div>
          )}
          {modal.action === "Eliminar" && (
            <p>¿Esta seguro que desea eliminar el registro?</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            disabled={isLoadingAction}
            onClick={handleSaveAction}
          >
            {isLoadingAction && <Spinner size="sm"></Spinner>}{" "}
            <i className="fa fa-save"></i> Guardar cambios
          </Button>
          <Button
            color="secondary"
            disabled={isLoadingAction}
            onClick={handleCloseModal}
          >
            <i className="fa fa-times"></i> Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileTab;
